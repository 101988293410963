import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

export const ToastGotIt = ({ storageKey, toastId }: { storageKey: string; toastId: string }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    toast.dismiss(toastId)
    psLocalStorage.addGotIt(storageKey)
  }

  return (
    <Button
      className="group ml-3"
      variant={ButtonVariantEnum.Text}
      onClick={handleClick}
      textColorVariant={ButtonTextColorVariantEnum.Muted}
    >
      <span className="text-white group-hover:text-gray-normal transition-colors">
        {t('gotIt')}
      </span>
    </Button>
  )
}

export const showGotItToaster = (message: string, storageKey: string) => {
  const toastId = `${storageKey}_${Date.now()}`
  toast.success(message, {
    id: toastId,
    icon: <ToastGotIt storageKey={storageKey} toastId={toastId} />,
    duration: Infinity,
  })
}
