import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

import { Icon } from 'components/Icon'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { ApiError, ImageDataDto } from 'api/models'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'

interface ProjectImageProps {
  className?: string
  image?: ImageDataDto
  onImageIdChange: (newImageId: number | null) => void
}

export const ProjectImage = ({ className, image, onImageIdChange }: ProjectImageProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [imageValue, setImageValue] = useState<ImageDataDto | null>(null)

  useEffect(() => {
    if (image) {
      setImageValue(image)
    }
  }, [image])

  const postImagesMutation = useMutation(
    ({ formData }: { formData: FormData }) => {
      return api.postImages(formData)
    },
    {
      onSuccess: (data) => {
        NProgress.done()
        if (data) {
          setImageValue(data)
          onImageIdChange(data.id)
        }
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
        handleDeleteClick()
      },
    },
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    const files = event.target.files
    if (files && files.length > 0) {
      formData.append('image', files[0])
      NProgress.start()
      postImagesMutation.mutate({ formData })
    }
  }

  const handleDeleteClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setImageValue(null)
    onImageIdChange(null)
  }

  const ViewComponent = imageValue ? 'div' : 'label'
  const viewProps = imageValue
    ? {}
    : {
        htmlFor: 'image-input',
        className: 'cursor-pointer',
        'aria-label': t('projects.projectImage.uploadNewImage'),
      }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="image-input"
        onChange={handleFileChange}
        accept="image/*"
        className="hidden"
      />
      <ViewComponent
        {...viewProps}
        className={classNames(
          'group relative overflow-hidden flex items-center justify-center w-[108px] h-[108px] rounded-[28px] bg-dark-dark2',
          className,
          viewProps?.className,
        )}
      >
        {imageValue ? (
          <>
            <img src={imageValue.tq.url} className="w-full h-full object-cover" />
            <div
              className="absolute bg-dark-default w-[64px] h-[32px] flex rounded-sm opacity-0
              group-hover:opacity-100 transition-opacity"
            >
              <Button
                as="label"
                htmlFor="image-input"
                className="cursor-pointer"
                variant={ButtonVariantEnum.Text}
                textColorVariant={ButtonTextColorVariantEnum.Muted}
                aria-label={t('projects.projectImage.uploadNewImage')}
              >
                <Icon icon="picture" className="text-icon" />
              </Button>
              <Button
                onClick={handleDeleteClick}
                variant={ButtonVariantEnum.Text}
                textColorVariant={ButtonTextColorVariantEnum.Muted}
                aria-label={t('projects.projectImage.deleteImage')}
              >
                <Icon icon="bin" className="text-icon" />
              </Button>
            </div>
          </>
        ) : (
          <Icon icon="picture" className="text-icon text-gray-normal" />
        )}
      </ViewComponent>
    </>
  )
}
