import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Slice } from 'components/ps-chart/models/Slice'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { pipe } from 'utils/pipe'
import {
  linkTreeNodes,
  TreeNode,
} from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { findOrCreateTreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/findOrCreateTreeNode'
import { mergeSameTargetStackChains } from 'components/ps-chart/stores/connections-store/mergeSameTargetStackChains'
import { mergeSameTargetSliceChains } from 'components/ps-chart/stores/connections-store/mergeSameTargetSliceChains'
import { getStackChainsLinks } from 'components/ps-chart/stores/connections-store/getStackChainsLinks'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

/**
 * There definitely can't be a regular backward connection between ancestor to child. But we have cycle slices
 * (such as Android's Choreographer \ iOS lifecycle functions) which have tool suggestion tool for variants
 * of possible execution path. That tool will create link which will cause such backward connection in case
 * when variant path's first slice is child of cycle slice. So we need to simply filter out these connections
 */

const filterOutAncestorCycleSliceReturnChain = (currentStepSliceId: number) => {
  return (chainsRoots: SliceLink[]) => {
    return chainsRoots.filter((link) => link.toSliceId !== currentStepSliceId)
  }
}

// TODO: check the cycles protection
export const getLinksTree = (
  selectedSlice: Slice | null,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  sliceById: ReadonlySliceById,
  isFirstSliceFixed = false,
): TreeNode | null => {
  if (selectedSlice == null) {
    return null
  }
  const treeNodeById = new Map<number, TreeNode>()
  const treeRoot = findOrCreateTreeNode(selectedSlice.id, treeNodeById)
  const passedSlices = new Set<number>([selectedSlice.id])

  let curLevelNodes = [treeRoot]
  while (curLevelNodes.length > 0) {
    const nextLevelNodes = []
    for (const curTreeNode of curLevelNodes) {
      const curSlice = sliceById.get(curTreeNode.sliceId)!

      const stackChainsLinks = getStackChainsLinks(
        curSlice,
        sliceLinksBySliceId,
        isFirstSliceFixed && curSlice.id === selectedSlice.id,
      )

      const curStackChainLinks = pipe(stackChainsLinks)([
        filterOutAncestorCycleSliceReturnChain(curSlice.id),
        mergeSameTargetSliceChains(sliceById),
        mergeSameTargetStackChains(sliceLinksBySliceId, sliceById),
      ])

      for (const stackChainLink of curStackChainLinks) {
        const fromTreeNode = findOrCreateTreeNode(stackChainLink.fromSliceId, treeNodeById)
        const toTreeNode = findOrCreateTreeNode(stackChainLink.toSliceId, treeNodeById)

        linkTreeNodes(fromTreeNode, toTreeNode, stackChainLink.connectionType)

        // Add a connection from the current slice into an ancestor with a link
        if (fromTreeNode !== curTreeNode) {
          linkTreeNodes(curTreeNode, fromTreeNode, ConnectionType.TREE)
        }

        if (!passedSlices.has(toTreeNode.sliceId)) {
          nextLevelNodes.push(toTreeNode)
          passedSlices.add(toTreeNode.sliceId)
        }
      }
    }
    curLevelNodes = nextLevelNodes
  }

  return treeRoot
}
