import React, { ReactNode } from 'react'
import classNames from 'classnames'
import styled, { css } from 'styled-components/macro'

interface CardLineProps {
  label: string
  nested?: boolean
  level?: number
  isCollapsed?: boolean
  children: ReactNode
  onClick?: () => void
}

/**
 * Slice data line which is used on Slice Card. Data can be nested.
 */
export const CardLine = ({
  label,
  nested = false,
  level = 1,
  isCollapsed = true,
  children,
  onClick,
}: CardLineProps) => (
  <Line
    nested={nested}
    className={classNames(
      'flex text-small tracking-wide leading-[140%] pt-[5px]',
      nested && 'font-semibold justify-between',
      !isCollapsed ? 'text-white' : 'text-gray-normal',
    )}
    onClick={onClick}
  >
    <Label
      nested={nested}
      level={level}
      isCollapsed={isCollapsed}
      className="w-[128px] min-w-[128px]"
    >
      {label}
    </Label>
    <div className="break-all pl-[8px]">{children}</div>
  </Line>
)

const Line = styled.div<{ nested: boolean }>`
  ${({ nested }) => css`
    ${nested &&
    css`
      ${({ theme }) => theme.notTouchScreen} {
        cursor: pointer;
        &:hover ${Label} {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
  `}
`

const Label = styled.div<{ level: number; nested: boolean; isCollapsed: boolean }>`
  color: ${({ nested, isCollapsed, level }) =>
    nested && !isCollapsed && level && lineColors.get(level)};
`

export const lineColors = new Map<number, string>([
  [2, '#C280F6'],
  [3, '#F7D377'],
])
