import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Slice } from 'components/ps-chart/models/Slice'
import { Thread } from 'components/ps-chart/models/Thread'

export const moveToSlice = (
  sliceId: number,
  psChartStore: PsChartStore,
  verticalShift?: number,
) => {
  const { hState, vState } = psChartStore

  const move = { x: hState.xStart, y: vState.yStart }

  const slice = psChartStore.sliceById.get(sliceId)
  if (slice == null) {
    throw new Error(`The slice: ${sliceId} was not found!`)
  }

  const isFav = psChartStore.traceAnalyzeStore.favIdSet.has(slice.threadId)
  const yPx = getSliceY(slice, psChartStore)

  const isYScrollable = psChartStore.vState.mainTotalHeight > psChartStore.vState.scrollableHeight

  if (!isFav && isYScrollable) {
    const headerHeight = psChartStore.chartSettings.renderEngine.headerHeight
    const maxScrollY = psChartStore.vState.mainTotalHeight - psChartStore.vState.scrollableHeight
    const shiftY =
      verticalShift ?? headerHeight + Math.floor(psChartStore.vState.scrollableHeight / 2)

    move.y = yPx - shiftY
    move.y = Math.min(Math.max(move.y, 0), maxScrollY)
  }

  const isXScrollable = psChartStore.hState.zoom > 1
  if (isXScrollable) {
    const visibleWidthTime = psChartStore.hState.width * psChartStore.hState.timePerPx
    const maxScrollTime = psChartStore.hState.xWidthTotal - visibleWidthTime

    const sliceWidthTime = slice.end - slice.start
    move.x = slice.start + sliceWidthTime / 2 - visibleWidthTime / 2
    move.x = Math.min(Math.max(move.x, 0), maxScrollTime)
  }

  psChartStore.moveTo(move.x, move.y)
}

export const moveToThread = (
  thread: Thread,
  psChartStore: PsChartStore,
  verticalShift?: number,
) => {
  const { hState, vState } = psChartStore

  const move = { x: hState.xStart, y: vState.yStart }

  const isFav = psChartStore.traceAnalyzeStore.favIdSet.has(thread.id)
  const yPx = getThreadY(thread.id, psChartStore)

  const isYScrollable = psChartStore.vState.mainTotalHeight > psChartStore.vState.scrollableHeight

  if (!isFav && isYScrollable) {
    const headerHeight = psChartStore.chartSettings.renderEngine.headerHeight
    const maxScrollY = psChartStore.vState.mainTotalHeight - psChartStore.vState.scrollableHeight
    const shiftY =
      verticalShift ?? headerHeight + Math.floor(psChartStore.vState.scrollableHeight / 2)

    move.y = yPx - shiftY
    Math.min(Math.max(move.y, 0), maxScrollY)
  }
  psChartStore.moveTo(move.x, move.y)
}

const getThreadY = (threadId: number, psChartStore: PsChartStore): number => {
  const isFav = psChartStore.traceAnalyzeStore.favIdSet.has(threadId)
  const vState = psChartStore.vState
  const threadsTopMap = isFav ? vState.favThreadsTopMap : vState.mainThreadsTopMap

  const threadTopPos = threadsTopMap.get(threadId)
  if (threadTopPos == null) {
    throw new Error(`The thread: ${threadId} was not found in the threadTopPos!`)
  }

  return threadTopPos
}

const getSliceY = (slice: Slice, psChartStore: PsChartStore): number => {
  const blockHeight = psChartStore.chartSettings.renderEngine.threads.blockHeight

  return getThreadY(slice.threadId, psChartStore) + blockHeight * slice.level
}
