import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { CanvasRect } from 'components/ps-chart/models/helper-types'
import { Slice } from 'components/ps-chart/models/Slice'
import { Thread } from 'components/ps-chart/models/Thread'
import {
  CANVAS_POSITIONS,
  getHorizontalFlag,
  getVerticalFlag,
} from 'components/ps-chart/connections-render/canvasPositions'

export const getSliceVisibleRect = (
  thread: Thread,
  threadActiveDepthsFromChain: number[],
  slice: Slice,
  psChartStore: PsChartStore,
  minWidthPx = 0,
): CanvasRect => {
  const h = psChartStore.chartSettings.renderEngine.threads.blockHeight
  const isFav = psChartStore.traceAnalyzeStore.favIdSet.has(thread.id)
  const chainExists = psChartStore.traceAnalyzeStore.chainExists
  const showShrunkModeDepth = psChartStore.traceAnalyzeStore.showShrunkModeDepth

  const threadsTopMap = isFav
    ? psChartStore.vState.favThreadsTopMap
    : psChartStore.vState.mainThreadsTopMap

  const curThreadTopPos = threadsTopMap.get(thread.id)
  if (curThreadTopPos == null) {
    throw new Error(`The curThreadTopPos is not found! threadId: ${thread.id}`)
  }

  const yStart = isFav ? psChartStore.vState.yStartFav : psChartStore.vState.yStart
  const threadTopY = curThreadTopPos - yStart
  let level = 0
  if (chainExists && showShrunkModeDepth && threadActiveDepthsFromChain.length) {
    level = threadActiveDepthsFromChain.indexOf(slice.level)
  } else {
    level = slice.level
  }
  const y = threadTopY + level * h

  const xStartPx = (slice.start - psChartStore.hState.xStart) / psChartStore.hState.timePerPx
  const widthPx = (slice.end - slice.start) / psChartStore.hState.timePerPx
  const w = Math.max(
    minWidthPx,
    Math.min(
      widthPx + xStartPx, // If the slice starts lefter then zero
      widthPx, // If the slice is fully inside the canvas
      psChartStore.hState.width - xStartPx, // If the slice ends righter than the right canvas' edge
      psChartStore.hState.width, // If the slice is wider than the canvas itself
    ),
  )
  let x
  if (xStartPx < 0 && xStartPx + widthPx > 0) {
    // If the slice starts lefter then window view
    x = 0
  } else if (minWidthPx !== 0 && w === minWidthPx) {
    // If the slice is smaller than minWidth but still visible (example: selected in execution path)
    x = xStartPx - minWidthPx / 2
  } else {
    x = xStartPx
  }

  const xPositionFlag = getHorizontalFlag(xStartPx, widthPx, psChartStore.hState.width)
  const yPositionFlag = isFav
    ? CANVAS_POSITIONS.Y_CENTER
    : getVerticalFlag(y, h, psChartStore.vState.height)
  const positionFlag = xPositionFlag | yPositionFlag

  return { x, y, w, h, positionFlag }
}
