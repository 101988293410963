import React, { useContext } from 'react'

import { Layout } from 'components/Layout'
import { PsAnnotations } from 'components/annotations/PsAnnotations'
import { HeaderProps } from 'components/Header'
import { HeaderTraceBreadcrumbs } from 'components/HeaderTraceBreadcrumbs'
import { PsChartFeatures, psChartStoreContext } from 'components/ps-chart/PsChartStore'
import { useNavigationRules } from 'hooks/useNavigationRules'

export const PATH_ANNOTATIONS_FOLDER = 'annotations'
export const PATH_ANNOTATIONS = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_ANNOTATIONS_FOLDER}`

const annotationPageChartFeatures: PsChartFeatures = {
  measurementTool: false,
  flags: false,
  annotations: { enabled: true, draggable: true, clickable: true },
  sliceNameTooltip: false,
}

export const PsAnnotationsPage = () => {
  const headerProps: HeaderProps = {
    screenTitleKey: 'annotations',
    leftText: <HeaderTraceBreadcrumbs />,
  }
  const psChartStore = useContext(psChartStoreContext)!
  psChartStore.setChartFeatures(annotationPageChartFeatures)

  useNavigationRules()

  return (
    <Layout headerProps={headerProps} pageConfig={{ withoutStyledContent: true }}>
      <PsAnnotations psChartStore={psChartStore} />
    </Layout>
  )
}
