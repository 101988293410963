import Konva from 'konva'
import { FlagsSettings } from 'components/ps-chart/local-timeline/flags/FlagsSettings'
import { Flag } from 'components/ps-chart/models/Flag'

export class KonvaFlag {
  private flag: Flag
  private readonly height: number
  private readonly settings: FlagsSettings

  private readonly flagLine: Konva.Line
  private readonly flagText: Konva.Text
  private readonly textFrame: Konva.Rect
  readonly group = new Konva.Group({ draggable: true })

  constructor(flag: Flag, height: number, showLabels: boolean, settings: FlagsSettings) {
    this.flag = flag
    this.height = height
    this.settings = settings

    const size = KonvaFlag.calcSize(height, settings)
    const color = settings.colors[flag.color]
    this.flagLine = KonvaFlag.drawFlag(color, color, size, this.settings)
    this.flagText = KonvaFlag.drawText(flag, size, this.settings)
    this.textFrame = KonvaFlag.drawFrame(color, flag, size, this.settings)

    this.initGroup(showLabels)
  }

  private initGroup(showLabels: boolean) {
    const wrapperRect = this.createWrapper()
    this.group.add(this.flagLine, wrapperRect, this.textFrame, this.flagText)
    this.updateTextAndFramePos(this.flag.title, showLabels)
    this.group.on('dragstart', this.setCursorResize)
    this.group.on('dragend', this.setCursorDefault)
    this.group.on('mousedown', this.setCursorResize)
    this.group.on('mouseup', this.setCursorDefault)
    this.group.on('contextmenu', (ev) => ev.evt.preventDefault())
  }

  setCursorDefault = () => (document.body.style.cursor = this.settings.cursorDefault)

  private setCursorResize = () => (document.body.style.cursor = this.settings.cursorResize)

  get x(): number {
    return this.group.x()
  }

  set x(value: number) {
    this.group.x(value)
  }

  set y(value: number) {
    this.group.y(value)
  }

  remove() {
    this.group.remove()
  }

  removeAndUnsubscribe() {
    this.group.off('dragstart dragend mousedown mouseup contextmenu')
    this.remove()
  }

  createWrapper(): Konva.Rect {
    const x = 0
    const wrapperHeight =
      this.settings.flagHeight + this.settings.flagBottomPadding + this.settings.dragPadding
    const wrapperTop = this.height - wrapperHeight
    const wrapperX = x - this.settings.dragPadding
    const wrapperWidth = this.settings.flagWidth + this.settings.dragPadding * 2

    return new Konva.Rect({
      x: wrapperX,
      y: wrapperTop,
      width: wrapperWidth,
      height: wrapperHeight,
    })
  }

  static drawFlag(
    strokeColor: string,
    fillColor: string,
    size: Size,
    settings: FlagsSettings,
  ): Konva.Line {
    const x = 0
    return new Konva.Line({
      points: [
        x,
        size.flagTop,
        x + size.flagWidth,
        size.flagTop,
        x + size.flagCenterLeftPadding,
        size.flagTop + size.flagHeight / 2,
        x + size.flagWidth,
        size.flagBottomWithoutPadding,
        x,
        size.flagBottomWithoutPadding,
        x,
        size.flagBottom,
        x,
        size.flagTop,
      ],
      stroke: strokeColor,
      strokeWidth: settings.strokeWidth,
      fill: fillColor,
      closed: true,
    })
  }

  select() {
    this.flagLine.stroke(this.settings.selectedColor)
  }

  clearSelection() {
    this.flagLine.stroke(this.settings.colors[this.flag.color])
  }

  updateFlag(flag: Flag, showLabels: boolean) {
    this.flag = flag
    const color = this.settings.colors[flag.color]
    this.flagLine.stroke(color)
    this.flagLine.fill(color)
    this.textFrame.stroke(color)

    const visible = KonvaFlag.isTextVisible(showLabels, flag.title)
    this.textFrame.visible(visible)
    this.flagText.visible(visible)

    if (this.flagText.text() !== flag.title) {
      this.updateTextAndFramePos(flag.title, showLabels)
    }
  }

  private updateTextAndFramePos(title: string, showLabels: boolean) {
    this.flagText.width(this.settings.flagTitleWidth)
    this.flagText.text(title.split('\n', 1)[0])
    const textWidth = this.flagText.getTextWidth()
    this.flagText.width(textWidth)
    const left = this.flagLine.x() - Math.round(textWidth / 2) + this.settings.flagTitlePadding
    this.flagText.x(left)
    this.textFrame.x(left - this.settings.flagTitlePadding)
    this.textFrame.width(this.flagText.getTextWidth() + this.settings.flagTitlePadding * 2)

    const visible = KonvaFlag.isTextVisible(showLabels, title)
    this.flagText.visible(visible)
    this.textFrame.visible(visible)
  }

  private static isTextVisible(showLabels: boolean, title: string): boolean {
    return showLabels && !!title && title.length > 0
  }

  private static drawText(flag: Flag, size: Size, settings: FlagsSettings): Konva.Text {
    return new Konva.Text({
      x: -size.titleMiddle + settings.flagTitlePadding,
      width: size.titleWidth,
      y: size.titleTop - Math.round(settings.flagTitleVerticalMargin / 2),
      text: flag.title,
      fill: settings.titleColor,
      fontSize: settings.fontSize,
      fontFamily: settings.fontFamily,
      wrap: 'none',
      ellipsis: true,
      align: 'center',
    })
  }

  private static drawFrame(color: string, flag: Flag, size: Size, settings: FlagsSettings) {
    return new Konva.Rect({
      x: -size.titleMiddle - settings.flagTitlePadding,
      y: size.titleTop - 5,
      width: size.titleWidth + settings.flagTitlePadding * 2,
      height: settings.flagTitlePadding * 2 + settings.flagTitleVerticalMargin + 1,
      stroke: color,
      strokeWidth: settings.titleFrameStrokeWidth,
      cornerRadius: settings.titleFrameCornerRadius,
      fill: settings.titleBgColor,
      visible: (flag.title ? flag.title.length : 0) > 0,
    })
  }

  static calcSize(height: number, settings: FlagsSettings): Size {
    const flagBottomPadding = settings.flagBottomPadding
    const flagHeight = settings.flagHeight
    const flagCenterLeftPadding = settings.flagCenterLeftPadding
    const flagTop = height - flagHeight - flagBottomPadding
    const flagBottomWithoutPadding = height - flagBottomPadding
    const flagBottom = height

    const titleTop =
      height -
      settings.flagHeight -
      settings.flagBottomPadding -
      settings.fontSize -
      settings.flagTitleVerticalMargin

    return {
      flagWidth: settings.flagWidth,
      flagBottomPadding: flagBottomPadding,
      flagHeight: flagHeight,
      flagCenterLeftPadding: flagCenterLeftPadding,
      flagTop: flagTop,
      flagBottomWithoutPadding: flagBottomWithoutPadding,
      flagBottom: flagBottom,

      titleTop: titleTop - Math.round(settings.flagTitleVerticalMargin / 2),
      titleWidth: settings.flagTitleWidth,
      titleMiddle: Math.round(settings.flagTitleWidth / 2),
    }
  }
}

export interface Size {
  readonly flagWidth: number
  readonly flagBottomPadding: number
  readonly flagHeight: number
  readonly flagCenterLeftPadding: number
  readonly flagTop: number
  readonly flagBottomWithoutPadding: number
  readonly flagBottom: number

  readonly titleTop: number
  readonly titleWidth: number
  readonly titleMiddle: number
}
