import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Slice } from 'components/ps-chart/models/Slice'

export const getStackChainsLinks = (
  startSlice: Slice,
  sliceLinksById: Map<number, ReadonlyArray<SliceLink>>,
  isGoUpDisabled = false,
): SliceLink[] => {
  const chainsRoots: SliceLink[] = []

  let curSlice = startSlice
  while (true) {
    const curLinks = sliceLinksById.get(curSlice.id)
    if (curLinks != null && curLinks.length > 0) {
      chainsRoots.push(...curLinks)
    }
    if (curSlice.parent == null || isGoUpDisabled) {
      break
    }
    curSlice = curSlice.parent
  }

  return chainsRoots
}
