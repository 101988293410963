import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Icon } from 'components/Icon'
import { observer } from 'mobx-react-lite'
import { ActionTooltip } from 'components/ActionTooltip'

export interface LocalTimelineOptionsPanelProps {
  psChartStore: PsChartStore
}

export const LocalTimelineOptionsPanel = observer(function LocalTimelineOptionsPanel({
  psChartStore,
}: LocalTimelineOptionsPanelProps) {
  return (
    <div className="absolute bottom-[8px] right-[8px] flex">
      <ActionTooltip
        tooltipId={
          psChartStore.isVideoPreviewInGlobalTimelineEnabled
            ? 'aggregationModeEnable'
            : 'aggregationModeDisable'
        }
        offsetY={9}
      >
        <button
          className="flex focus:outline-1"
          onClick={() =>
            psChartStore.setIsVideoModeEnabled(!psChartStore.isVideoPreviewInGlobalTimelineEnabled)
          }
        >
          <span className="w-[32px] h-[20px] flex items-center justify-center">
            <Icon
              className={
                'text-icon hover:text-white focus:text-white text-[10px] ' +
                (psChartStore.isVideoPreviewInGlobalTimelineEnabled
                  ? 'text-gray-normal'
                  : 'text-electro hover:text-sky')
              }
              icon="aggregation"
            />
          </span>
        </button>
      </ActionTooltip>
      {psChartStore.chartFeatures.flags && (
        <ActionTooltip
          tooltipId={psChartStore.flagsStore.showLabels ? 'flagDetailsHide' : 'flagDetailsShow'}
          offsetY={9}
        >
          <button
            className="flex focus:outline-1"
            onClick={() => psChartStore.flagsStore.toggleShowLabels()}
          >
            <span className="w-[32px] h-[20px] flex items-center justify-center">
              <Icon
                className={
                  'text-icon hover:text-white focus:text-white ' +
                  (psChartStore.flagsStore.showLabels
                    ? 'text-electro hover:text-sky'
                    : 'text-gray-normal')
                }
                icon="timeline-labels"
              />
            </span>
          </button>
        </ActionTooltip>
      )}
    </div>
  )
})
