import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Icon'
import { QuickstartPages } from 'components/quickstart/models'
import { QuickstartModal } from 'components/quickstart/modal/Modal'
import { useModal } from 'hooks/useModal'
import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'
import { useQuickstartContent } from '../hooks/useQuickstartContent'
import { QUICKSTART } from 'components/quickstart/constants'

export const QuickstartButton = () => {
  const isQuickStartEnabled = useFeatureFlag(FEATURE_FLAGS.SELF_SERVE)
  const { t } = useTranslation()
  const { pageToContentMap, linksToPages, video, videoThumbnail } = useQuickstartContent()
  const { openModal } = useModal(QUICKSTART)

  const handleClick = useCallback(() => {
    const progressFromLocalStorage = localStorage.getItem(QUICKSTART)
    openModal(progressFromLocalStorage || QuickstartPages.Contents)
  }, [openModal])

  if (isQuickStartEnabled) {
    const isDataLoaded = pageToContentMap && linksToPages
    return (
      <>
        <div className="h-[100%] absolute flex items-center min-w-[140px] ml-[10px]">
          <button
            onClick={handleClick}
            className="h-[30px] hover:text-white font-[500] text-[12px] text-gray-normal flex items-center absolute"
          >
            <Icon icon="arrow-round-l" className="mr-[10px] text-[28px] rotate-180" />
            {t('quickstart.modalOpenButton')}
          </button>
        </div>
        {isDataLoaded && (
          <QuickstartModal
            pageToContentMap={pageToContentMap}
            linksToPages={linksToPages}
            video={video}
            videoThumbnail={videoThumbnail}
          />
        )}
      </>
    )
  } else {
    return null
  }
}
