import { Button, ButtonVariantEnum } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useCustomerSupportModal } from 'contexts/customer-support-modal-context'
import { colors } from 'utils/styles/colors'

interface Props {
  children: React.ReactNode
}

export const HavingTroubles = ({ children }: Props) => {
  const { t } = useTranslation()
  const { openModal } = useCustomerSupportModal()
  return (
    <div className=" px-[48px] flex flex-col justify-center items-center bg-[#191919]">
      <h3 className="mt-[24px] text-[16px] text-white font-[500] leading-[22.4px] tracking-[2%]">
        {t('guidePage.havingTroubles')}
      </h3>
      {children}
      <Button
        onClick={openModal}
        borderColor={colors.state.good}
        variant={ButtonVariantEnum.Outlined}
        className="mt-[30px] mb-[24px] border-2 border-lime max-h-[32px] max-w-[120px] text-[12px] text-white"
      >
        {t('guidePage.getHelp')}
      </Button>
    </div>
  )
}
