import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Input } from 'components/Input'
import { Icon } from 'components/Icon'
import { ApiError, TeamRole, TeamUsersResponse } from 'api/models'
import { getTeamRoleName } from 'utils/getTeamRoleName'
import { queryKeys } from 'hooks/useApiQuery'
import { AxiosError } from 'axios'
import { isEmailValid } from 'utils/validation'
import { useApi } from 'contexts/di-context'
import { ListBox } from 'components/dropdowns/ListBox'

type TeamInviteModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>

export const TeamInviteModal = ({ isOpen, onClose }: TeamInviteModalProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const { teamUrlName } = useParams() as { teamUrlName: string }
  const queryClient = useQueryClient()

  const [email, setEmail] = useState('')
  const [isEmailValidState, setIsEmailValidState] = useState(false)

  useEffect(() => {
    if (email) {
      setIsEmailValidState(isEmailValid(email))
    }
  }, [email])

  const [role, setRole] = useState<TeamRole>(TeamRole.ADMIN)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value)
  const handleRoleClick = (newRole: TeamRole) => () => setRole(newRole)

  const getRoleOptions = useMemo(() => {
    return [
      {
        name: getTeamRoleName(TeamRole.ADMIN, t),
        onClick: handleRoleClick(TeamRole.ADMIN),
        isSelect: role === TeamRole.ADMIN,
      },
      {
        name: getTeamRoleName(TeamRole.CONTRIBUTOR, t),
        onClick: handleRoleClick(TeamRole.CONTRIBUTOR),
        isSelect: role === TeamRole.CONTRIBUTOR,
      },
    ]
  }, [role, t])

  const resetData = () => {
    setEmail('')
    setRole(TeamRole.ADMIN)
  }

  const postTeamUserMutation = useMutation(
    () => api.postTeamUser({ teamUrlName }, { email, role }),
    {
      onSuccess: (data) => {
        NProgress.done()
        queryClient.setQueryData<TeamUsersResponse | undefined>(
          queryKeys.teamUsers({ teamUrlName }),
          (oldData) => {
            if (oldData) {
              return { ...oldData, users: oldData.users.concat(data) }
            }
          },
        )
        resetData()
        onClose()
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleInviteClick = () => {
    NProgress.start()
    postTeamUserMutation.mutate()
  }

  return (
    <Modal
      title={t('teams.teamInviteModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        children: t('teams.teamInviteModal.button'),
        onClick: handleInviteClick,
        disabled: postTeamUserMutation.isLoading || !isEmailValidState,
      }}
    >
      <div className="my-[16px] text-small tracking-wide text-gray-normal">
        {t('teams.teamInviteModal.text')}
      </div>
      <div className="flex item-center mb-[14px]">
        <Input
          value={email}
          onChange={handleEmailChange}
          type="email"
          placeholder={t('teams.teamInviteModal.placeholder')}
          containerClassName="mb-0"
          inModal
        />
        <ListBox
          onSelect={(index: number) => getRoleOptions[index].onClick()}
          menuSections={[{ options: getRoleOptions }]}
          menuClass="right-[10px] border-[1px] border-dark-dark2 py-[2px]"
          buttonChildren={() => (
            <>
              {getRoleOptions.find((item) => item.isSelect)?.name}
              <Icon icon="arrow-drop-d" className="text-icon relative left-[-4px]" />
            </>
          )}
          buttonClass={(open) => {
            return classNames(
              'flex items-center text-small tracking-wide transition font-medium pl-[8px]',
              open ? 'text-white' : 'text-gray-normal',
            )
          }}
          className="ml-[6px]"
          withSelect
        />
      </div>
    </Modal>
  )
}
