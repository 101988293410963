import { Trans } from 'react-i18next'
import { PRIVACY_LINK, TERMS_LINK } from 'utils/links'

export const TermsAndConditions = () => {
  return (
    <div className="text-small text-gray-normal my-[24px]">
      <Trans
        i18nKey="auth.freeTrial.signUp.termsPrivacy"
        components={[
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={TERMS_LINK}
            className="text-white"
          ></a>,
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={PRIVACY_LINK}
            className="text-white"
          ></a>,
        ]}
      />
    </div>
  )
}
