import { getPart } from 'components/ps-chart/utils/nanoToString'

export const getDurationString = (durationNs: number, isLong = false) => {
  const signStr = Math.sign(durationNs) === -1 ? '-' : ''
  const durSections = []

  const absDur = Math.abs(durationNs)
  for (const type of ['s', 'ms', 'us'] as ('s' | 'ms' | 'us')[]) {
    if (type === 'us' && durSections.length > 0) {
      break
    }
    const part = getPart(absDur, type)
    if (part > 0) {
      durSections.push(`${part}${type}`)
    } else if (isLong && type === 'ms') {
      durSections.push(`000${type}`)
    }
  }

  return signStr + durSections.join(' ')
}
