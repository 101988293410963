import { getServerHost } from 'utils/getServerHost'

export const ROOT_HOST = 'productscience.app'

export enum HostType {
  PROD = 'production',
  /** @deprecated */
  BETA = 'beta',
  STAGING = 'staging',
  DEV = 'dev',
  UNKNOWN = 'unknown',
}

export const getHostType = (): HostType => {
  const host = getServerHost()
  if (host === ROOT_HOST || host === `prod.${ROOT_HOST}`) {
    return HostType.PROD
  } else if (host === `beta.${ROOT_HOST}`) {
    return HostType.BETA
  } else if (host.includes(`staging.${ROOT_HOST}`) || host === `test.${ROOT_HOST}`) {
    return HostType.STAGING
  } else if (host === `dev.${ROOT_HOST}` || host === `dev-1.${ROOT_HOST}`) {
    return HostType.DEV
  } else {
    return HostType.UNKNOWN
  }
}
