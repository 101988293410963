import { VerticalState } from 'components/ps-chart/models/VerticalState'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { ThreadsTopMap } from 'components/ps-chart/flame-chart/RenderEngine'
import {
  TopBottom,
  TopBottomByThreadId,
  TraceAnalyzeStore,
} from 'components/ps-chart/stores/TraceAnalyzeStore'
import { makeAutoObservable } from 'mobx'
import { Thread } from 'components/ps-chart/models/Thread'

export class VerticalStateStore implements VerticalState {
  readonly chartSettings: PsChartSettings

  readonly traceAnalyzeStore: TraceAnalyzeStore

  height = 0
  yStart = 0
  yStartFav = 0

  constructor(traceAnalyzeStore: TraceAnalyzeStore, chartSettings: PsChartSettings) {
    makeAutoObservable(this, {
      chartSettings: false,
    })
    this.traceAnalyzeStore = traceAnalyzeStore
    this.chartSettings = chartSettings
  }

  get yEndMain(): number {
    return this.yStart + Math.min(this.mainTotalHeight, this.mainScrollableHeight)
  }

  get mainScrollableHeight(): number {
    const headerHeight = this.chartSettings.renderEngine.headerHeight
    return this.mainCanvasHeight - headerHeight
  }

  get scrollableHeight(): number {
    return this.mainScrollableHeight
  }

  get yEndFav(): number {
    return this.yStartFav + this.favTotalHeight
  }

  get mainTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.mainThreads)
  }

  private threadsTotalHeight(threads: Thread[]): number {
    return threads.reduce((sum, thread) => {
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      return sum + height
    }, 0)
  }

  get mainCanvasHeight(): number {
    return this.height - this.favCanvasHeight
  }

  get favTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.favThreads)
  }

  get favCanvasHeight(): number {
    const headerHeight = this.chartSettings.renderEngine.headerHeight
    return this.favTotalHeight > 0 ? this.favTotalHeight + headerHeight : 0
  }

  get mainThreadsTopMap(): ThreadsTopMap {
    return this.threadsTopMap(this.traceAnalyzeStore.mainThreads)
  }

  get favThreadsTopMap(): ThreadsTopMap {
    return this.threadsTopMap(this.traceAnalyzeStore.favThreads)
  }

  get mainThreadsTopBottomMap(): TopBottomByThreadId {
    return this.threadsTopBottomMap(this.traceAnalyzeStore.mainThreads, this.mainThreadsTopMap)
  }

  get favThreadsTopBottomMap(): TopBottomByThreadId {
    return this.threadsTopBottomMap(this.traceAnalyzeStore.favThreads, this.favThreadsTopMap)
  }

  private threadsTopBottomMap(threads: Thread[], topMap: ThreadsTopMap): TopBottomByThreadId {
    const result: TopBottomByThreadId = new Map()
    threads.forEach((thread) => {
      const threadTop = topMap.get(thread.id)!
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      const threadBottom = threadTop + height
      const topBottom: TopBottom = [threadTop, threadBottom]
      result.set(thread.id, topBottom)
    })
    return result
  }

  private threadsTopMap(threads: Thread[]): ThreadsTopMap {
    const threadsTopTable: ThreadsTopMap = new Map()

    let currentTop = 0
    for (const thread of threads) {
      threadsTopTable.set(thread.id, currentTop)
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      currentTop += height
    }

    return threadsTopTable
  }

  setHeight(height: number) {
    this.height = height
  }

  scrollY(deltaY: number) {
    const yStart = VerticalStateStore.getScrolled(
      deltaY,
      this.yStart,
      this.mainTotalHeight,
      this.scrollableHeight,
    )
    return this.setYStart(yStart)
  }

  setYStart(yStart: number) {
    this.yStart = yStart
  }

  static getScrolled(
    delta: number,
    prevValue: number,
    totalDist: number,
    visibleDist: number,
  ): number {
    let newValue = Math.max(0, prevValue + delta)

    const maxScroll = Math.max(0, totalDist - visibleDist)
    if (newValue > maxScroll) {
      newValue = maxScroll
    }

    return newValue
  }
}
