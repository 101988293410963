import { Code, Content, Heading, PhrasingContent } from 'mdast'
import {
  EMPTY_TEMPLATE_VALUES,
  TemplateEngine,
  TemplateValues,
} from 'components/guide/markdown/TemplateEngine'
import React, { ReactNode } from 'react'
import { MdSpan } from 'components/guide/markdown/blocks/MdSpan'
import { MdStrong } from 'components/guide/markdown/blocks/MdStrong'
import { MdAnchor } from 'components/guide/markdown/blocks/MdAnchor'
import { MdImage } from 'components/guide/markdown/blocks/MdImage'
import { MdInlineCode } from 'components/guide/markdown/blocks/MdInlineCode'
import { MdHeader } from 'components/guide/markdown/blocks/MdHeader'
import { MdPara } from 'components/guide/markdown/blocks/MdPara'
import { MdCode } from 'components/guide/markdown/blocks/MdCode'
import { MdThematicBreak } from 'components/guide/markdown/blocks/MdThematicBreak'
import { MdList } from 'components/guide/markdown/blocks/MdList'
import { MdListItem } from 'components/guide/markdown/blocks/MdListItem'
import { GenIdFunction } from 'components/guide/markdown/Markdown'
import { CodeTabs } from 'components/guide/markdown/CodeTabs'
import { MdCollapsibleHeader } from 'components/guide/markdown/blocks/MdCollapsibleHeader'
import {
  headerStylesByLevel,
  MarkdownConfig,
  MdStyles,
} from 'components/guide/markdown/MarkdownConfig'

export class MarkdownProcessor {
  static processContent(
    genId: GenIdFunction,
    contentArray: Content[],
    templateValues: TemplateValues,
    config: MarkdownConfig,
  ): ReactNode {
    const result: ReactNode[] = []
    for (let index = 0; index < contentArray.length; index++) {
      const content = contentArray[index]
      const isTwoOrMoreCodeContent =
        content.type === 'code' &&
        contentArray.length - 1 >= index + 1 &&
        contentArray[index + 1].type === 'code'
      if (isTwoOrMoreCodeContent) {
        const codeContentArray: Code[] = MarkdownProcessor.getNextRepeatedCodeElements(
          contentArray,
          index,
        )
        result.push(
          <CodeTabs
            key={genId()}
            genId={genId}
            codeContentArray={codeContentArray}
            templateValues={templateValues ?? EMPTY_TEMPLATE_VALUES}
            config={config}
          />,
        )
        index += codeContentArray.length - 1
      } else if (
        config &&
        content.type === 'heading' &&
        config.headerLevelsToCollapse.includes(content.depth)
      ) {
        const nextCollapsibleHeaderElements = MarkdownProcessor.getNextCollapsibleHeaderElements(
          contentArray,
          index,
        )
        const { heading, contentToCollapse, nextIndex } = nextCollapsibleHeaderElements
        if (contentToCollapse.length > 0) {
          const contentChildren = MarkdownProcessor.processContent(
            genId,
            contentToCollapse,
            templateValues,
            config,
          )
          const styles = headerStylesByLevel(config.styles, heading.depth)
          result.push(
            <MdCollapsibleHeader
              key={genId()}
              level={heading.depth}
              contentChildren={contentChildren}
              headerStyles={styles}
            >
              {MarkdownProcessor.processPhrasingContent(
                genId,
                heading.children,
                templateValues,
                config.styles,
              )}
            </MdCollapsibleHeader>,
          )
        } else {
          const node = MarkdownProcessor.processNode(
            genId,
            content,
            templateValues ?? EMPTY_TEMPLATE_VALUES,
            config,
          )
          result.push(node)
        }
        index = nextIndex
      } else {
        const node = MarkdownProcessor.processNode(
          genId,
          content,
          templateValues ?? EMPTY_TEMPLATE_VALUES,
          config,
        )
        result.push(node)
      }
    }
    return <>{result}</>
  }

  static processNode(
    genId: GenIdFunction,
    content: Content,
    templateValues: TemplateValues,
    config: MarkdownConfig,
    spread = true,
  ): ReactNode {
    if (content.type === 'heading') {
      const styles = headerStylesByLevel(config.styles, content.depth)
      return (
        <MdHeader
          key={genId()}
          level={content.depth}
          className={`${styles.marginClassName} ${styles.otherClassName}`}
        >
          {MarkdownProcessor.processPhrasingContent(
            genId,
            content.children,
            templateValues,
            config.styles,
          )}
        </MdHeader>
      )
    } else if (content.type === 'paragraph') {
      return (
        <MdPara key={genId()} className={config.styles.para.className} inline={!spread}>
          {MarkdownProcessor.processPhrasingContent(
            genId,
            content.children,
            templateValues,
            config.styles,
          )}
        </MdPara>
      )
    } else if (content.type === 'code') {
      return (
        <MdCode
          key={genId()}
          code={TemplateEngine.processToString(content.value, templateValues)}
          codeLang={content.lang}
          codeTitle={content.meta}
          codeStyle={config.styles.code}
        />
      )
    } else if (content.type === 'thematicBreak') {
      return <MdThematicBreak className={config.styles.thematicBreak.className} key={genId()} />
    } else if (content.type === 'list') {
      return (
        <MdList
          key={genId()}
          className={config.styles.list.className}
          ordered={content.ordered ?? false}
        >
          {content.children.map((value) =>
            MarkdownProcessor.processNode(genId, value, templateValues, config),
          )}
        </MdList>
      )
    } else if (content.type === 'listItem') {
      return (
        <MdListItem className={config.styles.listItem.className} key={genId()}>
          {content.children.map((value, index) =>
            MarkdownProcessor.processNode(
              genId,
              value,
              templateValues,
              config,
              index > 0 ? content.spread ?? false : false,
            ),
          )}
        </MdListItem>
      )
    } else {
      console.warn('Markdown type not implemented', content.type)
      return null
    }
  }

  static processPhrasingContent(
    genId: GenIdFunction,
    phrases: PhrasingContent[],
    templateValues: TemplateValues,
    styles: MdStyles,
  ): React.ReactNode {
    return (
      <>
        {phrases.map((phrase) => {
          if (phrase.type === 'text') {
            return (
              <MdSpan key={genId()}>{TemplateEngine.process(phrase.value, templateValues)}</MdSpan>
            )
          } else if (phrase.type === 'strong') {
            return (
              <MdStrong key={genId()}>
                {MarkdownProcessor.processPhrasingContent(
                  genId,
                  phrase.children,
                  templateValues,
                  styles,
                )}
              </MdStrong>
            )
          } else if (phrase.type === 'link') {
            return (
              <MdAnchor
                key={genId()}
                className={styles.anchor.className}
                href={TemplateEngine.processToString(phrase.url, templateValues)}
                title={phrase.title ?? undefined}
                target={phrase.url.startsWith('/') ? undefined : '_blank'}
              >
                {MarkdownProcessor.processPhrasingContent(
                  genId,
                  phrase.children,
                  templateValues,
                  styles,
                )}
              </MdAnchor>
            )
          } else if (phrase.type === 'emphasis') {
            return (
              <MdSpan className="italic" key={genId()}>
                {MarkdownProcessor.processPhrasingContent(
                  genId,
                  phrase.children,
                  templateValues,
                  styles,
                )}
              </MdSpan>
            )
          } else if (phrase.type === 'image') {
            return (
              <MdImage
                key={genId()}
                wrapperclassname={styles.image?.imageWrapper?.className}
                className={`block ${styles.image.className}`}
                src={phrase.url}
                alt={phrase.alt ?? undefined}
                title={phrase.title ?? undefined}
              />
            )
          } else if (phrase.type === 'inlineCode') {
            return (
              <MdInlineCode key={genId()} className={styles.inlineCode.className}>
                {TemplateEngine.process(phrase.value, templateValues)}
              </MdInlineCode>
            )
          } else if (phrase.type === 'break') {
            return <br key={genId()} />
          } else {
            console.warn('Markdown type not implemented', phrase.type)
            return null
          }
        })}
      </>
    )
  }

  static getNextRepeatedCodeElements(source: Content[], startIndex: number): Code[] {
    const result: Code[] = []
    let isNextContentCode = source[startIndex].type === 'code'
    let codeIndex = startIndex
    while (isNextContentCode) {
      result.push(source[codeIndex] as Code)
      isNextContentCode = codeIndex + 1 < source.length && source[codeIndex + 1].type === 'code'
      if (isNextContentCode) {
        codeIndex++
      }
    }
    return result
  }

  static getNextCollapsibleHeaderElements(
    source: Content[],
    startIndex: number,
  ): NextCollapsableHeaderElementsResult {
    const result: Content[] = []
    const headingContent = source[startIndex]
    if (headingContent.type !== 'heading') {
      throw new Error('First source element must be a heading element')
    }
    const heading: Heading = headingContent
    for (let i = startIndex + 1; i < source.length; i++) {
      const nextChild = source[i]
      const isNextHeading =
        (nextChild.type === 'heading' && nextChild.depth <= heading.depth) ||
        nextChild.type === 'thematicBreak'
      if (isNextHeading) {
        break
      }
      result.push(nextChild)
    }
    return {
      heading: heading,
      contentToCollapse: result,
      nextIndex: startIndex + result.length,
    }
  }
}

export interface NextCollapsableHeaderElementsResult {
  heading: Heading
  contentToCollapse: Content[]
  nextIndex: number
}
