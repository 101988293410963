import { useCallback, useState } from 'react'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useToaster } from 'hooks/useToaster'
import classNames from 'classnames'
import { Icon } from 'components/Icon'
import { FlagColorItem } from './FlagColorItem'
import { ListBox } from 'components/dropdowns/ListBox'
import { OptionsVariant } from 'components/dropdowns/models'

interface FlagColorSelectProps {
  flagsStore: FlagsStore
}

export const FlagColorSelect = observer(function FlagColorSelect({
  flagsStore,
}: FlagColorSelectProps) {
  const { t } = useTranslation()
  const colors = flagsStore.settings.colors
  const toaster = useToaster()

  const changeColor = useCallback(
    (colorId: number) => {
      setCurrentColorIndex(colorId)
      flagsStore
        .updateSelectedColorId(colorId)
        .catch((reason) => toaster.error(reason, 'psChart.flag.error.changeColor'))
    },
    [flagsStore, toaster],
  )

  const [currentColorIndex, setCurrentColorIndex] = useState(flagsStore.selectedFlagColorId)

  const menuSections = [
    {
      name: t('psChart.flag.color'),
      withSelect: true,
      options: colors.map((_value, index) => ({
        name: colors[index],
        isSelect: index === currentColorIndex,
        customRender: <FlagColorItem backgroundColor={colors[index]} />,
      })),
    },
  ]

  return (
    <ListBox
      onSelect={changeColor}
      menuClass="w-[272px] border-t border-t-dark-dark6 top-[39px]"
      optionsVariant={OptionsVariant.DARK}
      menuSections={menuSections}
      buttonClass={(open) =>
        classNames(
          'relative flex items-center text-small tracking-wide transition pl-[8px] bg-dark-dark1 w-full mb-[6px] p-[6px]',
          open ? 'text-white' : 'text-gray-normal',
        )
      }
      buttonChildren={(open) => (
        <>
          <FlagColorItem
            className="px-[8px] py-[4px]"
            backgroundColor={colors[currentColorIndex]}
            noPadding
          />
          {!open ? (
            <Icon
              icon="arrow-drop-d"
              className="inline-block align-middle text-icon absolute right-[2px]"
            />
          ) : null}
        </>
      )}
    />
  )
})
