import { HorizontalState } from 'components/ps-chart/models/HorizontalState'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { walkSlices } from 'components/ps-chart/utils/slice'
import { makeAutoObservable } from 'mobx'
import { roundScalePeriod } from 'components/ps-chart/utils/roundScalePeriod'
import { TraceDataStore } from 'components/ps-chart/stores/TraceDataStore'

export class HorizontalStateStore implements HorizontalState {
  readonly chartSettings: PsChartSettings

  readonly traceDataStore: TraceDataStore

  /**
   * Px
   */
  width = 0

  /**
   * Nanoseconds
   */
  xStart = 0

  zoom = 1

  constructor(traceDataStore: TraceDataStore, chartSettings: PsChartSettings) {
    makeAutoObservable(this, {
      chartSettings: false,
    })
    this.traceDataStore = traceDataStore
    this.chartSettings = chartSettings
  }

  /**
   * Nanoseconds
   */
  get xMin(): number {
    return this.minMax.xMin
  }

  /**
   * Nanoseconds
   */
  get xMax(): number {
    return this.minMax.xMax
  }

  /**
   * Nanoseconds
   */
  get xWidthTotal(): number {
    return this.xMax - this.xMin
  }

  /**
   * Nanoseconds
   */
  get xEnd(): number {
    return HorizontalStateStore.calcXEnd(this.xStart, this.width, this.timePerPx)
  }

  /**
   * Nanoseconds
   */
  get xWidth(): number {
    return this.xEnd - this.xStart
  }

  /**
   * Nanoseconds
   */
  get xCacheStart(): number {
    return Math.max(this.xMin, this.xStart - this.xWidth / 2)
  }

  /**
   * Nanoseconds
   */
  get xCacheEnd(): number {
    return Math.min(this.xMax, this.xEnd + this.xWidth / 2)
  }

  get xGridLines(): number[] {
    const gridLinesCount = this.chartSettings.localTimelineGridLinesCount
    return HorizontalStateStore.calcGridLines(this.xStart, this.xEnd, this.xMin, gridLinesCount)
  }

  /**
   * Nanoseconds / Px
   */
  get timePerPx(): number {
    const timeWidth = this.xWidthTotal / this.zoom
    return HorizontalStateStore.timePerPx(timeWidth, this.width)
  }

  /**
   * For optimization for high detail slice chart rendering we should
   * truncate the resolution to the closest power of 2 (in nanosecond space).
   * In other words resolution changes approximately every 6 zoom levels.
   * For example each zoom level represents a delta of 0.1 * (visible space).
   * Zooming out by six levels is 1.1^6 ~= 2. Or zooming in six levels is 0.9^6 ~= 0.5.
   */
  static truncateTimePerPx = (timePerPx: number) => Math.pow(2, Math.floor(Math.log2(timePerPx)))

  get timeResolution(): number {
    return HorizontalStateStore.truncateTimePerPx(this.timePerPx)
  }

  get maxZoom(): number {
    return this.traceDataStore.minSliceLengthNs > 0
      ? this.xWidthTotal / this.traceDataStore.minSliceLengthNs
      : 1
  }

  /**
   * @param width Px
   */
  setWidth(width: number) {
    this.width = width
  }

  private get minMax(): MinMax {
    let xMin = Number.MAX_SAFE_INTEGER
    let xMax = Number.MIN_SAFE_INTEGER
    this.traceDataStore.threads.forEach((thread) => {
      walkSlices(thread.slices, (slice) => {
        xMin = Math.min(slice.start, xMin)
        xMax = Math.max(slice.end, xMax)
      })
    })
    return {
      xMin: xMin,
      xMax: xMax,
    }
  }

  changeXStart(xDelta: number) {
    if (this.xStart + xDelta < this.xMin) {
      this.setXStartAndZoom(this.xMin, this.zoom)
    } else if (this.xEnd + xDelta > this.xMax) {
      this.setXStartAndZoom(this.xStart + this.xMax - this.xEnd, this.zoom)
    } else {
      this.setXStartAndZoom(Math.floor(this.xStart + xDelta), this.zoom)
    }
  }

  /**
   * @param xStart Nanoseconds
   * @param zoom
   */
  setXStartAndZoom(xStart: number, zoom?: number) {
    this.xStart = xStart
    if (zoom) {
      this.zoom = zoom
    }
  }

  applyWindowChange(start: number, end: number) {
    const totalTime = this.xWidthTotal
    const timePerPx = totalTime / this.width
    const zoom = totalTime / ((end - start) * timePerPx)
    const xStart = start * timePerPx + this.xMin
    this.setXStartAndZoom(xStart, zoom)
  }

  increaseZoom(xCenter?: number, deltaY?: number) {
    const zoomFactor =
      1 + this.chartSettings.zoomChangeFactor * (deltaY ? Math.log2(1 + Math.abs(deltaY)) : 1)
    this.changeZoom(zoomFactor, xCenter)
  }

  decreaseZoom(xCenter?: number, deltaY?: number) {
    const zoomFactor =
      1 - this.chartSettings.zoomChangeFactor * (deltaY ? Math.log2(1 + Math.abs(deltaY)) : 1)
    this.changeZoom(zoomFactor, xCenter)
  }

  public changeZoom(zoomFactor: number, xCenter?: number) {
    const prevState = this as HorizontalState
    const changeZoomResult = HorizontalStateStore.changeZoomUtil(
      prevState.xStart,
      prevState.zoom,
      prevState.timePerPx,
      zoomFactor,
      this.minMax.xMin,
      this.minMax.xMax,
      prevState.width,
      this.maxZoom,
      xCenter,
    )
    if (changeZoomResult.wasChanged) {
      this.setXStartAndZoom(changeZoomResult.startX, changeZoomResult.zoom)
    }
  }

  static timePerPx(timeWidth: number, pxWidth: number): number {
    return timeWidth / pxWidth
  }

  private static calcGridLines(
    start: number,
    end: number,
    min: number,
    gridLinesCount: number,
  ): number[] {
    const localWidth = end - start
    let segmentWidth = localWidth / gridLinesCount
    segmentWidth = roundScalePeriod(segmentWidth)

    if (segmentWidth < 1_000_000 && localWidth / segmentWidth > 5) {
      segmentWidth = segmentWidth * 2
    }

    const result = []
    // Calculate number of first visible line
    let currentNumberOfLine = Math.floor((start - min) / segmentWidth)
    let currentLineTime = 0
    while (currentLineTime < end) {
      currentLineTime = currentNumberOfLine * segmentWidth
      result.push(Math.round(currentLineTime))
      currentNumberOfLine++
    }
    return result
  }

  /**
   * @param start Nanoseconds
   * @param width Px
   * @param timePerPx Nanoseconds / Px
   * @return xEnd in Nanoseconds
   * @private
   */
  private static calcXEnd(start: number, width: number, timePerPx: number): number {
    return Math.round(start + width * timePerPx)
  }

  private static changeZoomUtil(
    xStartOld: number,
    zoomOld: number,
    timePerPxOld: number,
    zoomFactor: number,
    xMin: number,
    xMax: number,
    width: number,
    maxZoom: number,
    xCenter?: number,
  ): ChangeZoomResult {
    let newZoom = zoomFactor * zoomOld
    const oldEndX = xStartOld + width * timePerPxOld
    const oldWidth = oldEndX - xStartOld

    const newWidth = Math.floor((oldWidth * zoomOld) / newZoom)

    if (newZoom < 1) {
      newZoom = 1
    }

    const xMaxWidth = xMax - xMin
    const newTotalTime = xMaxWidth / newZoom
    if (newZoom > maxZoom) {
      newZoom = maxZoom
    }

    let xStartNew: number
    if (newZoom === 1) {
      // zoom 1 is equal to Max Zoom Out aka default state, so we don't need to check xEndNew
      xStartNew = xMin
    } else {
      const widthDelta = oldWidth - newWidth
      if (xCenter) {
        const add = Math.floor(widthDelta * (xCenter / width))
        xStartNew = xStartOld + add
      } else {
        const add = Math.floor(widthDelta / 2)
        xStartNew = xStartOld + add
      }

      const timePerPxNew = HorizontalStateStore.timePerPx(newTotalTime, width)
      const xEndNew = xStartNew + width * timePerPxNew
      if (xStartNew < xMin) {
        xStartNew = xMin
      } else if (xEndNew > xMax) {
        xStartNew = xStartNew - (xEndNew - xMax)
      }
    }

    return {
      startX: xStartNew,
      zoom: newZoom,
      wasChanged: zoomOld !== newZoom,
    }
  }
}

interface ChangeZoomResult {
  startX: number
  zoom: number
  wasChanged: boolean
}

interface MinMax {
  xMin: number
  xMax: number
}
