import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useParams, useSearchParams } from 'react-router-dom'
import NProgress from 'nprogress'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Layout } from 'components/Layout'
import { Icon } from 'components/Icon'
import { Button, ButtonVariantEnum } from 'components/Button'
import { PATH_TEAM } from 'pages/TeamPage'
import { TeamUser } from 'components/teams/TeamUser'
import { TeamsList } from 'components/teams/TeamsList'
import { TeamInviteModal } from 'components/teams/TeamInviteModal'
import { TeamRole } from 'api/models'
import { useTeamsQuery, useTeamUsersQuery, useUserQuery } from 'hooks/useApiQuery'
import { OrderSortingType, OrderStorageKey, useOrderStorage } from 'hooks/useOrderStorage'

export const PATH_TEAM_CONTRIBUTORS = '/:teamUrlName/team-contributors'

export const TeamContributorsPage = () => {
  const { t } = useTranslation()
  const { teamUrlName } = useParams() as { teamUrlName: string }
  const [searchParams, setSearchParams] = useSearchParams()

  const { currentSortingType, writeSortingToStorage } = useOrderStorage(
    OrderStorageKey.TEAM_CONTRIBUTORS_SORTING_KEY,
  )

  const [teamInviteModalOpened, setTeamInviteModalOpened] = useState(false)
  const handleInviteContributorClick = () => setTeamInviteModalOpened(true)
  const handleTeamInviteModalClose = () => setTeamInviteModalOpened(false)

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess } = useTeamsQuery()
  const { isSuccess: isTeamUsersSuccess, data: teamUsers } = useTeamUsersQuery({ teamUrlName })

  const isSuperAdmin = user?.roles.isSuperAdmin
  const isLoaded = isUserSuccess && isTeamsSuccess && isTeamUsersSuccess

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const isLastTeamAdmin = useMemo(
    () => teamUsers?.users.filter((item) => item.teamRole === TeamRole.ADMIN).length === 1,
    [teamUsers?.users],
  )

  const sortedProjects = useMemo(
    () =>
      teamUsers?.projects.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      ) || [],
    [teamUsers?.projects],
  )

  const headList = useMemo(() => {
    let data: { name: string; columnOrder?: string }[] = [
      { name: t('teams.teamContributorsPage.columns.name'), columnOrder: 'name' },
      {
        name: t('teams.teamContributorsPage.columns.teamPermissions'),
        columnOrder: 'teamPermissions',
      },
    ]
    data = data.concat(sortedProjects.map((item) => ({ name: item.name })) || [])
    data = data.concat({
      name: t('teams.teamContributorsPage.columns.lastActive'),
      columnOrder: 'lastActive',
    })
    return data
  }, [sortedProjects, t])

  const getActiveOrder = useMemo(() => {
    if (searchParams.get('columnOrder')) {
      return searchParams.get('columnOrder')
    }
    return currentSortingType?.columnOrder || 'lastActive'
  }, [searchParams, currentSortingType?.columnOrder])

  const isOrderDesc = useMemo(() => {
    if (searchParams.get('order')) {
      return searchParams.get('order') === 'desc'
    } else if (currentSortingType?.order) {
      return currentSortingType.order === 'desc'
    }
    return false
  }, [searchParams, currentSortingType?.order])

  const handleOrderButtonClick = (param: string) => () => {
    const newSorting: OrderSortingType = {
      columnOrder: param,
    }

    searchParams.set('columnOrder', param)
    if (isOrderDesc) {
      delete newSorting.order
      searchParams.delete('order')
    } else {
      newSorting.order = 'desc'
      searchParams.set('order', 'desc')
    }

    writeSortingToStorage(newSorting)
    setSearchParams(searchParams)
  }

  const sortedTeamUsers = useMemo(() => {
    const multiplier = isOrderDesc ? -1 : 1
    return teamUsers?.users.sort((a, b) => {
      switch (getActiveOrder) {
        case 'teamPermissions':
          const sortRolesArray = [
            TeamRole.ADMIN,
            TeamRole.CONTRIBUTOR,
            TeamRole.NONE,
            null,
            undefined,
          ]
          return (
            (sortRolesArray.indexOf(a.teamRole || null) -
              sortRolesArray.indexOf(b.teamRole || null)) *
            multiplier
          )
        case 'name':
          if (a.user?.name === null || a.user?.name === undefined) {
            return 1 * multiplier
          }
          if (b.user?.name === null || b.user?.name === undefined) {
            return -1 * multiplier
          }
          return (
            String(a.user?.name || '').localeCompare(String(b.user?.name || ''), undefined, {
              numeric: true,
              sensitivity: 'base',
            }) * multiplier
          )
        default:
          if (a.lastActive === null || a.lastActive === undefined) {
            return 1 * multiplier
          }
          if (b.lastActive === null || b.lastActive === undefined) {
            return -1 * multiplier
          }
          return (
            b.lastActive.localeCompare(a.lastActive, undefined, {
              numeric: true,
              sensitivity: 'base',
            }) * multiplier
          )
      }
    })
  }, [teamUsers?.users, isOrderDesc, getActiveOrder])

  return (
    <Layout
      headerProps={{ screenTitleKey: 'teamContributors', hiddenProjectSelect: true }}
      pageConfig={{
        withoutNavbar: true,
        withoutStyledContent: true,
      }}
    >
      {isUserSuccess && isTeamsSuccess && (
        <main className="flex flex-1 h-full">
          <TeamsList />
          {isTeamUsersSuccess && (
            <div className="flex-1 flex flex-col h-full overflow-hidden">
              <div className="pr-[24px] pl-[109px]">
                <div className="relative flex items-center py-[28px]">
                  <Icon
                    as={Link}
                    to={generatePath(PATH_TEAM, { teamUrlName })}
                    icon="arrow-round-l"
                    aria-label={t('goToBack')}
                    className="text-icon transition-colors absolute left-[-85px] mt-[3px] text-gray-normal hover:text-white 1920:left-[-138px]"
                  />
                  <div className="flex items-center flex-1">
                    <h1 className="text-header-small">{teamUsers?.team.name}</h1>
                  </div>
                  <div className="flex items-center shrink-0">
                    <Button
                      variant={ButtonVariantEnum.Outlined}
                      onClick={handleInviteContributorClick}
                      isSmall
                    >
                      {t('teams.teamContributorsPage.inviteButton')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="overflow-auto">
                <div className="inline-block min-w-full pl-[92px] pr-[24px] pb-[110px]">
                  <Head
                    className="sticky top-0 z-10 grid w-fit min-w-full rounded-sm bg-dark-dark3 cursor-default"
                    projectsLength={teamUsers?.projects.length}
                  >
                    {headList.map((item, index) => (
                      <div
                        className="group px-[16px] py-[8px] text-small tracking-wide text-gray-normal hover:bg-rollover-dark"
                        key={String(index)}
                      >
                        {item.name}
                        {item.columnOrder && (
                          <Icon
                            as="button"
                            icon="arrow-drop-d"
                            className={classNames(
                              'inline-flex items-center justify-center align-middle -mt-[2px] ml-[3px] transition text-icon w-[19px] h-[19px] opacity-0 group-hover:opacity-100 hover:text-white',
                              isOrderDesc && '-scale-y-100',
                              getActiveOrder === item.columnOrder && 'opacity-100',
                            )}
                            onClick={handleOrderButtonClick(item.columnOrder)}
                          />
                        )}
                      </div>
                    ))}
                  </Head>
                  {teamUsers?.users &&
                    sortedTeamUsers?.map((item, index) => (
                      <TeamUser
                        {...item}
                        projects={sortedProjects}
                        authUserId={user?.id}
                        teamId={teamUsers?.team.id}
                        isSuperAdmin={isSuperAdmin}
                        getActiveOrder={getActiveOrder}
                        isLastTeamAdmin={isLastTeamAdmin}
                        key={String(index)}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </main>
      )}
      <TeamInviteModal isOpen={teamInviteModalOpened} onClose={handleTeamInviteModalClose} />
    </Layout>
  )
}

const Head = styled.div<{ projectsLength?: number }>`
  grid-template-columns: 320px minmax(168px, 1fr) ${({ projectsLength }) =>
      'minmax(168px, 1fr) '.repeat(projectsLength || 0)} minmax(168px, 1fr) 50px;
`
