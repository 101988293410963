import React from 'react'
import { observer } from 'mobx-react-lite'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { SearchAction } from 'components/ps-chart/actions-panel/SearchAction'
import { TransparentModeAction } from 'components/ps-chart/actions-panel/TransparentModeAction'
import { MeasureAction } from 'components/ps-chart/actions-panel/MeasureAction'
import { RenderTypeModeAction } from 'components/ps-chart/actions-panel/RenderTypeModeAction'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
// import { IconColored } from "components/common/IconColored"

export const ActionsPanel = observer(function ActionsPanel({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const chartRenderTypeToggleEnabled = useFeatureFlag(FEATURE_FLAGS.CHART_RENDER_TOGGLE)

  return (
    <div className="absolute right-[8px] bottom-[8px] flex flex-row">
      <div className="mx-[1px]">
        <SearchAction psChartStore={psChartStore} />
      </div>
      {psChartStore.chartFeatures.measurementTool && (
        <div className="mx-[1px]">
          <MeasureAction psChartStore={psChartStore} />
        </div>
      )}
      {psChartStore.chartFeatures.flags && (
        <div className="mx-[1px]">
          <TransparentModeAction psChartStore={psChartStore} />
        </div>
      )}
      {chartRenderTypeToggleEnabled && (
        <div className="mx-[1px]">
          <RenderTypeModeAction psChartStore={psChartStore} />
        </div>
      )}
      {/* {psChartStore.chartFeatures.annotations.enabled && (
        <>
          <div className="mx-[1px]">
            <div className="flex items-center justify-center align-center h-[32px] px-[16px] bg-dark-dark3">
              <IconColored icon="pin" size={32} />
            </div>
          </div>
          <div className="mx-[1px]">
            <div className="flex items-center justify-center align-center h-[32px] px-[16px] bg-dark-dark3">
              <IconColored icon="expand" size={32} />
              <IconColored icon="narrow" size={32} />
            </div>
          </div>
        </>
      )} */}
    </div>
  )
})
