import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FlowInfo } from '../FlowInfo'
import { CreateAnnotationForm } from './create/CreateAnnotationForm'
import { AnnotationList } from 'components/annotations/details-view/list/AnnotationList'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { useFlowQuery } from 'hooks/useApiQuery'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { Button, ButtonVariantEnum } from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import { useToaster } from 'hooks/useToaster'
import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { reaction } from 'mobx'
import { showGotItToaster } from 'components/ToastGotIt'

interface Props {
  annotationsStore: AnnotationsStore
  videoPlayerState: VideoPlayerState
}

export const AnnotationsDetails = observer(function AnnotationsDetails({
  annotationsStore,
  videoPlayerState,
}: Props) {
  const { t } = useTranslation()
  const toaster = useToaster()

  const { projectUrlName, flowProjectLocalId } = useParams()
  const { isSuccess: isFlowSuccess, data: flow } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })

  const [newAnnotationId, setNewAnnotationId] = useState<number | null>(null)

  const createNewAnnotation = useCallback(() => {
    if (videoPlayerState.hasFullData) {
      const newAnnotation = annotationsStore.createLocally()
      setNewAnnotationId(newAnnotation.id)
    } else {
      toaster.error(t('annotations.validation.specifyActionTitle'), 'annotation.error.create')
    }
  }, [annotationsStore, t, toaster, videoPlayerState.hasFullData])

  const saveNewAnnotation = useCallback(() => {
    if (newAnnotationId === null) {
      return
    }
    const newAnnotation = annotationsStore.getById(newAnnotationId)!
    if (!newAnnotation.action.title) {
      toaster.error(t('annotations.validation.specifyActionTitle'))
      return
    }
    if (!newAnnotation.reaction.title) {
      toaster.error(t('annotations.validation.specifyReactionTitle'))
      return
    }

    const tempAnnotationId = newAnnotationId
    setNewAnnotationId(null)

    annotationsStore.create(newAnnotationId).catch((reason) => {
      toaster.error(reason, 'annotation.error.create')
      setNewAnnotationId(tempAnnotationId)
    })
  }, [annotationsStore, newAnnotationId, t, toaster])

  useEffect(
    () =>
      reaction(
        () => annotationsStore.annotationsDataStore.hasRemoteMappedAnnotations,
        (hasRemoteMappedAnnotations) => {
          if (hasRemoteMappedAnnotations) {
            const messageId = 'annotations.create.success'
            const alreadyGotIt = psLocalStorage.hasGotIt(messageId)
            if (alreadyGotIt) {
              toaster.info(messageId)
            } else {
              showGotItToaster(t(messageId), messageId)
            }
          }
        },
        { name: 'reaction @ AnnotationDetails & hasRemoteMappedAnnotations -> toaster' },
      ),
    [annotationsStore, toaster, t],
  )

  const cancelNewAnnotation = useCallback(() => {
    if (newAnnotationId === null) {
      return
    }
    annotationsStore
      .delete(newAnnotationId)
      .catch((reason) => toaster.error(reason, 'annotation.error.delete'))
    setNewAnnotationId(null)
  }, [annotationsStore, newAnnotationId, toaster])

  return (
    <div className="overflow-y-auto absolute top-0 right-0 bottom-0 left-0 p-[24px]">
      {isFlowSuccess && flow !== undefined && (
        <FlowInfo title={flow.name ?? ''} description={flow.description ?? ''} />
      )}

      {annotationsStore.notPlacedOnTimelineAnnotations.length > 0 ? (
        <div className="text-small text-gray-normal my-[16px]">
          {t('annotations.create.unmapped')}
        </div>
      ) : null}
      <AnnotationList
        mapped={false}
        annotationsStore={annotationsStore}
        videoPlayerState={videoPlayerState}
      />

      <div className="text-small text-gray-normal mb-[16px] mt-[16px]">
        {t('annotations.create.annotations')}
      </div>
      <div className="mb-[10px]">
        {newAnnotationId === null && (
          <ActionTooltip
            disabled={videoPlayerState.hasFullData}
            place="bottom"
            tooltipId="noVideoWarning"
          >
            <Button
              onClick={createNewAnnotation}
              variant={ButtonVariantEnum.Outlined}
              className="w-full"
              isSmall
              disabled={!videoPlayerState.hasFullData}
            >
              {t('annotations.create.addAnnotation')}
            </Button>
          </ActionTooltip>
        )}
      </div>

      {newAnnotationId !== null && (
        <CreateAnnotationForm
          annotationId={newAnnotationId}
          annotationsStore={annotationsStore}
          videoPlayerState={videoPlayerState}
          onSave={saveNewAnnotation}
          onCancel={cancelNewAnnotation}
        />
      )}

      <AnnotationList
        mapped={true}
        annotationsStore={annotationsStore}
        videoPlayerState={videoPlayerState}
      />
    </div>
  )
})
