import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { NamedLinkDto, NamedLinkType } from 'api/models'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Slice } from 'components/ps-chart/models/Slice'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export const fillNamedLinks = (
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  namedLinksById: Map<string, NamedLinkDto>,
  namedLinks: NamedLinkDto[],
) => {
  const filteredSlicesByLinkName = getFilteredSlicesByLinkName(sliceById, namedLinks)

  const objectNamedLinks = []
  const directNamedLinks = []
  const restNamedLinks = []
  for (const link of namedLinks) {
    namedLinksById.set(link.id, link)
    switch (link.type) {
      case NamedLinkType.OBJECT:
        objectNamedLinks.push(link)
        continue
      case NamedLinkType.DIRECT:
        directNamedLinks.push(link)
        continue
      default:
        restNamedLinks.push(link)
    }
  }
  console.info(
    'recognized object/direct/rest links',
    objectNamedLinks.length,
    directNamedLinks.length,
    restNamedLinks.length,
  )

  if (objectNamedLinks.length > 0) {
    const linkNameObjectIdIndex = getSubIndex(filteredSlicesByLinkName, (slice) => slice.objectId)
    for (let i = 0; i < objectNamedLinks.length; i++) {
      const namedLink = objectNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, linkNameObjectIdIndex)(namedLink)
    }
  }

  if (directNamedLinks.length > 0) {
    const linkNamesDirectIdIndex = getSubIndex(
      filteredSlicesByLinkName,
      (slice) => `${slice.threadId}:${slice.level}`,
    )
    for (let i = 0; i < directNamedLinks.length; i++) {
      const namedLink = directNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, linkNamesDirectIdIndex)(namedLink)
    }
  }

  if (restNamedLinks.length > 0) {
    for (let i = 0; i < restNamedLinks.length; i++) {
      const namedLink = restNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, filteredSlicesByLinkName)(namedLink)
    }
  }
}

const addFoundConnections = (
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  toFromFoundPairs: Map<Slice, Slice[]>,
  sourceId: string,
  isEditable: boolean,
  isAsync = false,
): number => {
  let addedLinksCount = 0
  toFromFoundPairs.forEach((fromSlicesList, toSlice) => {
    for (const fromSlice of fromSlicesList) {
      const conError = getConnectionError(
        fromSlice,
        toSlice,
        sliceById,
        sliceLinksBySliceId,
        isAsync,
      )
      if (conError == null) {
        const links = Array.from(sliceLinksBySliceId.get(fromSlice.id) ?? [])
        links.push({
          sourceId,
          fromSliceId: fromSlice.id,
          toSliceId: toSlice.id,
          connectionType: ConnectionType.MANUAL,
          isEditable,
        })
        sliceLinksBySliceId.set(fromSlice.id, links)
        addedLinksCount++
      }
    }
  })
  return addedLinksCount
}

const addToFromConnection = (
  fromSlice: Slice,
  toSlice: Slice,
  connections: Map<Slice, Slice[]>,
) => {
  const fromSlices = connections.get(toSlice) || []
  fromSlices.push(fromSlice)
  connections.set(toSlice, fromSlices)
}

/**
 * getClosestToFromPairs: is going through two arrays of pairs from named link rule. From the furthest slices to earliest.
 * function is written as it presented to user - in reverse order
 * @toSlices is array of slices where each slice is a caller function
 * @fromSlices is array of slice where each slice is being called by caller function
 */
export const getClosestToFromPairs = (
  fromSlices: Slice[],
  toSlices: Slice[],
  isAsync = false,
): Map<Slice, Slice[]> => {
  const connections = new Map<Slice, Slice[]>()

  let fromPointer = fromSlices.length - 1
  let toPointer = toSlices.length - 1

  const toSliceCopy = isAsync ? toSlices : toSlices.sort((a, b) => a.end - b.end)

  while (fromPointer >= 0 && toPointer >= 0) {
    const curFromSlice = fromSlices[fromPointer]
    const curToSlice = toSliceCopy[toPointer]

    if (isAsync) {
      if (curToSlice.start > curFromSlice.end) {
        toPointer--
        continue
      }
    } else {
      if (curToSlice.start > curFromSlice.start) {
        toPointer--
        continue
      }
    }

    addToFromConnection(curFromSlice, curToSlice, connections)
    fromPointer--
  }

  return connections
}

export const getFilteredSlicesByLinkName = (
  sliceById: ReadonlySliceById,
  namedLinks: NamedLinkDto[],
): Map<string, Slice[]> => {
  const linksNames = namedLinks.reduce(
    (names, link) => names.add(link.fromName.toLowerCase()).add(link.toName.toLowerCase()),
    new Set<string>(),
  )

  const slicesByTitle = new Map<string, Slice[]>()
  sliceById.forEach((slice) => {
    const normalizedTitle = slice.title.toLowerCase()
    const slices = slicesByTitle.get(normalizedTitle) ?? []
    slices.push(slice)
    slicesByTitle.set(normalizedTitle, slices)
  })

  /**
   * Complexity:
   * [unique slice names in a trace] X [unique titles in links] + [all slices]
   */
  const filteredSlicesByLinkName = new Map<string, Slice[]>()
  linksNames.forEach((linkName) => {
    let slicesForLinkName: Slice[] = []
    slicesByTitle.forEach((slices, slicesTitle) => {
      if (slicesTitle.indexOf(linkName) !== -1) {
        slicesForLinkName = slicesForLinkName.concat(slices)
      }
    })
    filteredSlicesByLinkName.set(linkName, slicesForLinkName)
  })

  filteredSlicesByLinkName.forEach((slices) => {
    slices.sort((sliceA, sliceB) => sliceA.start - sliceB.start)
  })

  return filteredSlicesByLinkName
}

export const getSubIndex = <IndexType>(
  slicesByKeyName: Map<string, Slice[]>,
  getSubIndexKey: (slice: Slice) => IndexType,
): Map<string, Map<IndexType, Slice[]>> => {
  const subIndexByKeyName = new Map<string, Map<IndexType, Slice[]>>()
  slicesByKeyName.forEach((slices, keyName) => {
    const slicesSubIndex = new Map<IndexType, Slice[]>()
    for (const slice of slices) {
      const subIndexKey = getSubIndexKey(slice)
      const sameSubIndexKeySlices = slicesSubIndex.get(subIndexKey) ?? []
      sameSubIndexKeySlices.push(slice)
      slicesSubIndex.set(subIndexKey, sameSubIndexKeySlices)
    }
    subIndexByKeyName.set(keyName, slicesSubIndex)
  })
  return subIndexByKeyName
}

const getNamedLinkHandler =
  <SubIndexKey>(
    sliceById: ReadonlySliceById,
    sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
    slicesIndexByName: Map<string, Map<SubIndexKey, Slice[]> | Slice[]>,
  ) =>
  (sourceNamedLink: NamedLinkDto) => {
    const { id, fromName, toName, type, isEditable } = sourceNamedLink
    const nFromName = fromName.toLowerCase()
    const nToName = toName.toLowerCase()

    const fromSlicesIndex = slicesIndexByName.get(nFromName)
    const toSlicesIndex = slicesIndexByName.get(nToName)

    const logWarning = () =>
      console.warn(
        `Skip the link because can't find it's names: ${id}/${fromName}/${toName}/${type}`,
      )

    if (fromSlicesIndex == null || toSlicesIndex == null) {
      logWarning()
      return null
    } else {
      const fromSlicesIndexSize = Array.isArray(fromSlicesIndex)
        ? fromSlicesIndex.length
        : fromSlicesIndex.size
      const toSlicesIndexSize = Array.isArray(toSlicesIndex)
        ? toSlicesIndex.length
        : toSlicesIndex.size

      if (fromSlicesIndexSize === 0 || toSlicesIndexSize === 0) {
        logWarning()
        return null
      }
    }

    let addedLinksCount = 0
    const isAsync = type === NamedLinkType.ASYNC
    if (Array.isArray(fromSlicesIndex) && Array.isArray(toSlicesIndex)) {
      addedLinksCount = addFoundConnections(
        sliceById,
        sliceLinksBySliceId,
        getClosestToFromPairs(fromSlicesIndex, toSlicesIndex, isAsync),
        id,
        isEditable,
        isAsync,
      )
    } else if (!Array.isArray(fromSlicesIndex) && !Array.isArray(toSlicesIndex)) {
      fromSlicesIndex.forEach((sameSubIndexKeyFromSlices, subIndexKey) => {
        const sameSubIndexKeyToSlices = toSlicesIndex.get(subIndexKey)
        if (sameSubIndexKeyToSlices == null || sameSubIndexKeyToSlices.length === 0) {
          return null
        }

        addedLinksCount += addFoundConnections(
          sliceById,
          sliceLinksBySliceId,
          getClosestToFromPairs(sameSubIndexKeyFromSlices, sameSubIndexKeyToSlices),
          id,
          isEditable,
          isAsync,
        )
      })
    }
    console.info(
      `Added ${addedLinksCount} named links fromName:${fromName}, toName:${toName}, type:${type}`,
    )
  }
