import { useEffect, useRef, useState } from 'react'

import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { PsChartStore } from '../PsChartStore'
import { observer } from 'mobx-react-lite'

function useOnMouseUpDown(psChartStore: PsChartStore, actionRef: React.RefObject<HTMLDivElement>) {
  const [isMousePressed, setIsMousePressed] = useState(false)

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      // did the user click outside the action?
      if (actionRef.current && !actionRef.current.contains(e.target as Node)) {
        // do not enable transparent line mode
        return setIsMousePressed(false)
      }
      // otherwise, if the user clicked in the action, enable transparent line mode
      setIsMousePressed(true)
    }
    const handleMouseUp = () => setIsMousePressed(false)

    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [actionRef])

  useEffect(() => {
    // whenever isMousePressed changes, toggle transparency mode
    if (isMousePressed) {
      psChartStore.enableTransparentConnection()
    } else {
      psChartStore.disableTransparentConnection()
    }
  }, [isMousePressed, psChartStore])

  return isMousePressed
}

export const TransparentModeAction = observer(function TransparentModeAction({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const actionRef = useRef<HTMLDivElement>(null)

  // spies on the mouse state and enables transparency
  // on mouse down and disables on mouse up
  useOnMouseUpDown(psChartStore, actionRef)

  return (
    <div
      ref={actionRef}
      className="flex items-center justify-center align-center h-[32px] w-[64px] bg-dark-dark3"
    >
      <ActionTooltip place="top" tooltipId="transparentMode">
        <IconColored
          className="p-[8px]"
          icon="transparent-connection"
          size={16}
          isActive={psChartStore.isTransparentConnectionEnabled}
          isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
          isHold={psChartStore.isTransparentConnectionEnabled}
        />
      </ActionTooltip>
    </div>
  )
})
