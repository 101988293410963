export const Logo = () => {
  return (
    <svg
      width="246"
      height="24"
      viewBox="0 0 246 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.86076 0H1.55696V5.73913H0V13.5652H1.55696V24H25.9494V16.1739H9.86076V13.5652H25.9494V5.73913H9.86076V0Z"
        fill="#495BFB"
      />
      <path
        d="M43.5949 24V5.63934H47.2299V7.34426H47.3597C47.6366 6.97705 47.9742 6.62732 48.3723 6.29508C49.307 5.59563 50.3542 5.2459 51.5139 5.2459C53.2449 5.2459 54.6902 5.90164 55.8499 7.21311C57.027 8.5071 57.6155 10.212 57.6155 12.3279C57.6155 14.4437 57.027 16.1574 55.8499 17.4689C54.6902 18.7628 53.2449 19.4098 51.5139 19.4098C50.3542 19.4098 49.307 19.0601 48.3723 18.3607C47.9742 18.0284 47.6366 17.6787 47.3597 17.3115H47.2299V24H43.5949ZM48.1646 9.44262C47.5414 10.1246 47.2299 11.0863 47.2299 12.3279C47.2299 13.5694 47.5414 14.5399 48.1646 15.2393C48.7877 15.9213 49.6013 16.2623 50.6052 16.2623C51.5572 16.2623 52.3361 15.9126 52.942 15.2131C53.5478 14.5137 53.8507 13.5519 53.8507 12.3279C53.8507 11.1038 53.5478 10.1421 52.942 9.44262C52.3361 8.74317 51.5572 8.39344 50.6052 8.39344C49.6013 8.39344 48.7877 8.74317 48.1646 9.44262Z"
        fill="white"
      />
      <path
        d="M59.5628 19.0164V5.63934H63.1977V7.60656H63.3276C63.4833 7.18688 63.717 6.81967 64.0286 6.50492C64.7383 5.753 65.6297 5.37705 66.7029 5.37705H68.2619V8.91803H66.3134C65.3268 8.91803 64.5565 9.18907 64.0026 9.73115C63.466 10.2557 63.1977 10.9902 63.1977 11.9344V19.0164H59.5628Z"
        fill="white"
      />
      <path
        d="M70.8654 17.3639C69.4807 15.9825 68.7883 14.3038 68.7883 12.3279C68.7883 10.3519 69.4807 8.68197 70.8654 7.31803C72.2502 5.93661 73.9811 5.2459 76.0582 5.2459C78.118 5.2459 79.8403 5.93661 81.225 7.31803C82.6271 8.68197 83.3281 10.3519 83.3281 12.3279C83.3281 14.3038 82.6271 15.9825 81.225 17.3639C79.8403 18.7279 78.118 19.4098 76.0582 19.4098C73.9811 19.4098 72.2502 18.7279 70.8654 17.3639ZM73.5657 9.49508C72.8906 10.2295 72.5531 11.1738 72.5531 12.3279C72.5531 13.482 72.8906 14.4262 73.5657 15.1607C74.2407 15.8951 75.0716 16.2623 76.0582 16.2623C77.0448 16.2623 77.8757 15.8951 78.5508 15.1607C79.2258 14.4262 79.5634 13.482 79.5634 12.3279C79.5634 11.1738 79.2258 10.2295 78.5508 9.49508C77.8757 8.76066 77.0448 8.39344 76.0582 8.39344C75.0716 8.39344 74.2407 8.76066 73.5657 9.49508Z"
        fill="white"
      />
      <path
        d="M86.2452 17.4689C85.0855 16.1574 84.5056 14.4437 84.5056 12.3279C84.5056 10.212 85.0855 8.5071 86.2452 7.21311C87.4223 5.90164 88.8763 5.2459 90.6072 5.2459C91.7669 5.2459 92.8141 5.59563 93.7488 6.29508C94.147 6.62732 94.4845 6.97705 94.7614 7.34426H94.8913V0H98.5262V19.0164H94.8913V17.3115H94.7614C94.4845 17.6787 94.147 18.0284 93.7488 18.3607C92.8141 19.0601 91.7669 19.4098 90.6072 19.4098C88.8763 19.4098 87.4223 18.7628 86.2452 17.4689ZM89.1792 9.44262C88.5733 10.1421 88.2704 11.1038 88.2704 12.3279C88.2704 13.5519 88.5733 14.5137 89.1792 15.2131C89.785 15.9126 90.5639 16.2623 91.5159 16.2623C92.5199 16.2623 93.3334 15.9213 93.9565 15.2393C94.5797 14.5399 94.8913 13.5694 94.8913 12.3279C94.8913 11.0863 94.5797 10.1246 93.9565 9.44262C93.3334 8.74317 92.5199 8.39344 91.5159 8.39344C90.5639 8.39344 89.785 8.74317 89.1792 9.44262Z"
        fill="white"
      />
      <path
        d="M101.123 13.7705V5.63934H104.758V13.2459C104.758 14.2251 104.991 14.977 105.459 15.5016C105.943 16.0087 106.618 16.2623 107.484 16.2623C108.349 16.2623 109.085 15.9301 109.691 15.2656C110.297 14.6011 110.599 13.753 110.599 12.7213V5.63934H114.234V19.0164H110.599V17.1803H110.47C110.262 17.5825 109.942 17.9585 109.509 18.3082C108.574 19.0426 107.51 19.4098 106.315 19.4098C104.671 19.4098 103.39 18.929 102.473 17.9672C101.573 17.0055 101.123 15.6066 101.123 13.7705Z"
        fill="white"
      />
      <path
        d="M118.109 17.3639C116.742 15.9825 116.058 14.3038 116.058 12.3279C116.058 10.3519 116.742 8.68197 118.109 7.31803C119.494 5.93661 121.19 5.2459 123.198 5.2459C125.604 5.2459 127.439 6.12022 128.702 7.86885C129.274 8.62076 129.689 9.49508 129.949 10.4918H126.184C126.028 10.1071 125.82 9.75738 125.561 9.44262C124.903 8.74317 124.116 8.39344 123.198 8.39344C122.281 8.39344 121.484 8.76066 120.809 9.49508C120.152 10.2295 119.823 11.1738 119.823 12.3279C119.823 13.482 120.152 14.4262 120.809 15.1607C121.484 15.8951 122.281 16.2623 123.198 16.2623C124.116 16.2623 124.903 15.9126 125.561 15.2131C125.82 14.8984 126.028 14.5486 126.184 14.1639H129.949C129.706 15.1781 129.308 16.0525 128.754 16.7869C127.491 18.5355 125.639 19.4098 123.198 19.4098C121.19 19.4098 119.494 18.7279 118.109 17.3639Z"
        fill="white"
      />
      <path
        d="M130.727 9.30862V5.63934H132.414V0H136.049V5.63934H150.331V9.30862H136.049V15.3471H150.331V19.0164H132.414V9.30862H130.727Z"
        fill="white"
      />
      <path
        d="M151.227 14.6885H154.992C155.113 15.0033 155.304 15.2918 155.563 15.5541C156.117 16.1137 156.879 16.3934 157.848 16.3934C159.319 16.3934 160.055 15.9563 160.055 15.082C160.055 14.9421 160.02 14.8197 159.951 14.7148C159.882 14.5923 159.761 14.4874 159.588 14.4C159.415 14.2951 159.25 14.2076 159.094 14.1377C158.939 14.0678 158.705 13.9978 158.393 13.9279C158.099 13.8404 157.857 13.7792 157.666 13.7443C157.493 13.7093 157.208 13.6481 156.81 13.5607C156.429 13.4732 156.152 13.412 155.979 13.377C153.244 12.7825 151.876 11.4273 151.876 9.31147C151.876 8.19235 152.37 7.23934 153.356 6.45246C154.36 5.64809 155.728 5.2459 157.459 5.2459C159.778 5.2459 161.518 5.97158 162.677 7.42295C163.179 8.05246 163.517 8.7694 163.69 9.57377H160.055C159.934 9.31147 159.778 9.0929 159.588 8.91803C159.051 8.48087 158.341 8.26229 157.459 8.26229C156.835 8.26229 156.377 8.36721 156.083 8.57705C155.788 8.7694 155.641 9.01421 155.641 9.31147C155.641 9.41639 155.658 9.52131 155.693 9.62623C155.745 9.71366 155.823 9.80109 155.927 9.88852C156.031 9.95847 156.134 10.0284 156.238 10.0984C156.342 10.1508 156.489 10.212 156.68 10.282C156.87 10.3344 157.035 10.3869 157.173 10.4393C157.329 10.4918 157.528 10.5443 157.77 10.5967C158.013 10.6492 158.22 10.6929 158.393 10.7279C158.566 10.7628 158.791 10.8153 159.068 10.8852C159.345 10.9377 159.57 10.9814 159.743 11.0164C162.461 11.6284 163.82 12.9836 163.82 15.082C163.82 16.2536 163.283 17.2678 162.21 18.1246C161.137 18.9814 159.683 19.4098 157.848 19.4098C155.39 19.4098 153.573 18.6229 152.396 17.0492C151.859 16.3322 151.47 15.5454 151.227 14.6885Z"
        fill="white"
      />
      <path
        d="M166.917 17.3639C165.549 15.9825 164.865 14.3038 164.865 12.3279C164.865 10.3519 165.549 8.68197 166.917 7.31803C168.301 5.93661 169.998 5.2459 172.006 5.2459C174.412 5.2459 176.246 6.12022 177.51 7.86885C178.081 8.62076 178.497 9.49508 178.756 10.4918H174.991C174.836 10.1071 174.628 9.75738 174.368 9.44262C173.711 8.74317 172.923 8.39344 172.006 8.39344C171.088 8.39344 170.292 8.76066 169.617 9.49508C168.959 10.2295 168.63 11.1738 168.63 12.3279C168.63 13.482 168.959 14.4262 169.617 15.1607C170.292 15.8951 171.088 16.2623 172.006 16.2623C172.923 16.2623 173.711 15.9126 174.368 15.2131C174.628 14.8984 174.836 14.5486 174.991 14.1639H178.756C178.514 15.1781 178.116 16.0525 177.562 16.7869C176.298 18.5355 174.446 19.4098 172.006 19.4098C169.998 19.4098 168.301 18.7279 166.917 17.3639Z"
        fill="white"
      />
      <path
        d="M180.91 3.35738C180.512 2.95519 180.313 2.4918 180.313 1.96721C180.313 1.44262 180.512 0.987978 180.91 0.603278C181.326 0.201093 181.819 0 182.39 0C182.961 0 183.446 0.201093 183.844 0.603278C184.26 0.987978 184.467 1.44262 184.467 1.96721C184.467 2.4918 184.26 2.95519 183.844 3.35738C183.446 3.74208 182.961 3.93443 182.39 3.93443C181.819 3.93443 181.326 3.74208 180.91 3.35738ZM180.573 19.0164V5.63934H184.208V19.0164H180.573Z"
        fill="white"
      />
      <path
        d="M188.076 17.3639C186.709 15.9825 186.025 14.3038 186.025 12.3279C186.025 10.3519 186.709 8.68197 188.076 7.31803C189.461 5.93661 191.157 5.2459 193.165 5.2459C195.208 5.2459 196.887 5.92787 198.202 7.2918C199.518 8.65574 200.176 10.3344 200.176 12.3279V13.1148H189.79C189.928 13.9891 190.318 14.7322 190.958 15.3443C191.599 15.9563 192.334 16.2623 193.165 16.2623C194.083 16.2623 194.87 16 195.528 15.4754C195.788 15.2481 195.995 14.9858 196.151 14.6885H200.046C199.751 15.5803 199.319 16.3672 198.747 17.0492C197.363 18.6229 195.502 19.4098 193.165 19.4098C191.157 19.4098 189.461 18.7279 188.076 17.3639ZM189.92 10.8852H196.281C196.142 10.1858 195.788 9.6 195.216 9.12787C194.663 8.63825 193.979 8.39344 193.165 8.39344C192.352 8.39344 191.659 8.62951 191.088 9.10164C190.517 9.57377 190.127 10.1683 189.92 10.8852Z"
        fill="white"
      />
      <path
        d="M202.113 19.0164V5.63934H205.748V7.47541H205.877C206.102 7.07322 206.423 6.70601 206.838 6.37377C207.79 5.62186 208.855 5.2459 210.032 5.2459C211.676 5.2459 212.948 5.72678 213.848 6.68852C214.766 7.65027 215.225 9.04918 215.225 10.8852V19.0164H211.59V11.4098C211.59 10.4306 211.347 9.68743 210.863 9.18033C210.395 8.65574 209.729 8.39344 208.863 8.39344C207.998 8.39344 207.262 8.72568 206.656 9.39016C206.051 10.0546 205.748 10.9027 205.748 11.9344V19.0164H202.113Z"
        fill="white"
      />
      <path
        d="M218.972 17.3639C217.605 15.9825 216.921 14.3038 216.921 12.3279C216.921 10.3519 217.605 8.68197 218.972 7.31803C220.357 5.93661 222.054 5.2459 224.061 5.2459C226.467 5.2459 228.302 6.12022 229.566 7.86885C230.137 8.62076 230.552 9.49508 230.812 10.4918H227.047C226.891 10.1071 226.684 9.75738 226.424 9.44262C225.766 8.74317 224.979 8.39344 224.061 8.39344C223.144 8.39344 222.348 8.76066 221.673 9.49508C221.015 10.2295 220.686 11.1738 220.686 12.3279C220.686 13.482 221.015 14.4262 221.673 15.1607C222.348 15.8951 223.144 16.2623 224.061 16.2623C224.979 16.2623 225.766 15.9126 226.424 15.2131C226.684 14.8984 226.891 14.5486 227.047 14.1639H230.812C230.57 15.1781 230.172 16.0525 229.618 16.7869C228.354 18.5355 226.502 19.4098 224.061 19.4098C222.054 19.4098 220.357 18.7279 218.972 17.3639Z"
        fill="white"
      />
      <path
        d="M233.901 17.3639C232.533 15.9825 231.85 14.3038 231.85 12.3279C231.85 10.3519 232.533 8.68197 233.901 7.31803C235.286 5.93661 236.982 5.2459 238.99 5.2459C241.032 5.2459 242.711 5.92787 244.027 7.2918C245.342 8.65574 246 10.3344 246 12.3279V13.1148H235.614C235.753 13.9891 236.142 14.7322 236.783 15.3443C237.423 15.9563 238.159 16.2623 238.99 16.2623C239.907 16.2623 240.695 16 241.352 15.4754C241.612 15.2481 241.82 14.9858 241.976 14.6885H245.87C245.576 15.5803 245.143 16.3672 244.572 17.0492C243.187 18.6229 241.326 19.4098 238.99 19.4098C236.982 19.4098 235.286 18.7279 233.901 17.3639ZM235.744 10.8852H242.105C241.967 10.1858 241.612 9.6 241.041 9.12787C240.487 8.63825 239.803 8.39344 238.99 8.39344C238.176 8.39344 237.484 8.62951 236.913 9.10164C236.341 9.57377 235.952 10.1683 235.744 10.8852Z"
        fill="white"
      />
    </svg>
  )
}
