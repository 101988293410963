import React, { ReactNode, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ThreadItem } from 'components/ps-chart/threads-panel/ThreadItem'
import { useTranslation } from 'react-i18next'

export const Threads = observer(function Threads({ psChartStore }: { psChartStore: PsChartStore }) {
  const { t } = useTranslation()
  return (
    <div className="w-[240px] bg-dark-dark2 flex flex-col">
      {psChartStore.isLoading ? (
        <div className="flex flex-grow relative">
          <ModuleProgressCircle />
        </div>
      ) : (
        <>
          {psChartStore.traceAnalyzeStore.favThreads.length > 0 && (
            <div className="w-full">
              <SectionHeader psChartStore={psChartStore}>Favorites</SectionHeader>
              {psChartStore.traceAnalyzeStore.favThreads.map((thread, key) => (
                <ThreadItem
                  psChartStore={psChartStore}
                  thread={thread}
                  height={psChartStore.traceAnalyzeStore.heightByThreadId.get(thread.id)!}
                  key={thread.title}
                  isLast={key === psChartStore.traceAnalyzeStore.favThreads.length - 1}
                  isFavorite={true}
                />
              ))}
            </div>
          )}
          {psChartStore.traceAnalyzeStore.mainThreads.length > 0 && (
            <>
              <SectionHeader psChartStore={psChartStore}>
                {t('psChart.threads.title')}
              </SectionHeader>
              <ThreadsList psChartStore={psChartStore}>
                {psChartStore.traceAnalyzeStore.mainThreads.map((thread, key, all) => (
                  <ThreadItem
                    psChartStore={psChartStore}
                    thread={thread}
                    height={psChartStore.traceAnalyzeStore.heightByThreadId.get(thread.id)!}
                    key={thread.title}
                    isLast={key === all.length - 1}
                    isFavorite={false}
                  />
                ))}
              </ThreadsList>
            </>
          )}
        </>
      )}
    </div>
  )
})

interface ThreadsListProps {
  children: ReactNode
  psChartStore: PsChartStore
}

const ThreadsList = observer(function ThreadsList({ children, psChartStore }: ThreadsListProps) {
  const threadsListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (threadsListRef.current) {
      threadsListRef.current.scrollTop = psChartStore.vState.yStart
    }
  }, [psChartStore.vState.yStart])

  return (
    <div className="relative overflow-hidden flex-grow" ref={threadsListRef}>
      <div className="absolute w-full">{children}</div>
    </div>
  )
})

const SectionHeader = ({
  children,
  psChartStore,
}: {
  children: React.ReactNode
  psChartStore: PsChartStore
}) => {
  const height = psChartStore.chartSettings.renderEngine.headerHeight
  const bgColor = psChartStore.chartSettings.renderEngine.palette.headerColor

  return (
    <div
      className="h-[32px] bg-dark-dark1 pl-[24px] pt-[6px] text-small tracking-wide"
      style={{ height: `${height}px`, backgroundColor: bgColor }}
    >
      {children}
    </div>
  )
}
