import { ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { UserMenu } from 'components/UserMenu'
import { ProjectSelect } from './ProjectSelect'
import { Icon } from './Icon'
import { Button, ButtonVariantEnum } from './Button'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import commonTexts from 'locale/en/en-common.json'
import { PATH_ROOT } from 'pages/MainPage'
import { colors } from 'utils/styles/colors'
import { ShareLink } from 'components/share-with-team/ShareLink'

export type ScreenTitleKey = keyof typeof commonTexts.components.header.screenTitle

export interface HeaderProps {
  screenTitleKey?: ScreenTitleKey
  leftText?: ReactNode
  hiddenProjectSelect?: boolean
  isGuidesPage?: boolean
}

export const Header = ({ screenTitleKey, leftText, hiddenProjectSelect }: HeaderProps) => {
  const { t } = useTranslation()

  const headerRef = useRef<HTMLHeadingElement>(null)
  useDisablePinchZoom(headerRef)

  return (
    <header
      ref={headerRef}
      className={
        'bg-dark-dark3 border-b border-b-gray-strokeLight grid grid-cols-3 z-header items-center justify-between h-[var(--topBarHeight)] px-[16px]'
      }
    >
      <div className="flex items-center">
        <Link to={PATH_ROOT} aria-label={t('appName')} data-tid="logo" className="mr-[20px]">
          <Icon icon="logo-creature" className="text-[24px] leading-none text-electro " />
        </Link>
        {leftText}
      </div>
      <div className="text-normal tracking-wide text-center font-medium">
        {screenTitleKey && t(`components.header.screenTitle.${screenTitleKey}`)}
      </div>
      <div className="w-fit flex items-center justify-self-end">
        {!hiddenProjectSelect && <ProjectSelect />}
        <UserMenu />
      </div>
    </header>
  )
}

export const GuidesHeader = ({ teamUrlName }: { teamUrlName?: string }) => {
  const { t } = useTranslation()

  const headerRef = useRef<HTMLHeadingElement>(null)
  useDisablePinchZoom(headerRef)

  return (
    <header
      ref={headerRef}
      className={
        'bg-dark-dark5 border-none grid grid-cols-2 z-header items-center justify-between h-[var(--topBarHeight)] px-[16px]'
      }
    >
      <Link to={PATH_ROOT} aria-label={t('appName')} data-tid="logo" className="mr-[20px] w-fit">
        <Icon icon="logo-bold" className="text-gray-normal" />
      </Link>
      <div className="w-fit flex items-center justify-self-end">
        <div className="flex min-w-[270px]">
          <Button
            borderColor={colors.state.attention}
            variant={ButtonVariantEnum.Outlined}
            className="border-2 border-lime max-h-[32px] max-w-[120px] text-[12px] text-gray-normal hover:text-white"
          >
            Live Demo
          </Button>
          <ShareLink teamUrlName={teamUrlName} />
        </div>
        <UserMenu />
      </div>
    </header>
  )
}
