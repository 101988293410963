import React, { useEffect } from 'react'

import { Layout, LayoutProps } from 'components/Layout'
import NProgress from 'nprogress'
import { ErrorComponent } from 'components/ErrorComponent'

interface ErrorPageAuthorizedProps {
  layoutProps: LayoutProps
  fullReload?: boolean
}

export const ErrorPageAuthorized = ({
  layoutProps,
  fullReload = false,
}: ErrorPageAuthorizedProps) => {
  useEffect(() => {
    NProgress.done()
  }, [])

  return (
    <Layout
      {...{
        ...layoutProps,
        headerProps: { ...layoutProps.headerProps, hiddenProjectSelect: true },
        pageConfig: { ...layoutProps.pageConfig, withoutStyledContent: true },
        className: 'justify-center',
      }}
    >
      <ErrorComponent fullReload={fullReload} />
    </Layout>
  )
}
