import { ReactNode, useRef, useEffect, useState, RefObject } from 'react'
import styled, { css } from 'styled-components/macro'
import { getTooltipPosition, getPositionOptions, ChildrenRect } from './getTooltipPosition'
import { TooltipPlace } from './types'

type TooltipContainerProps = {
  children: ReactNode
  childrenRect?: ChildrenRect
  offsetY?: number
  offsetX?: number
  place?: TooltipPlace
  childrenRef: RefObject<HTMLElement>
  hideTooltip: () => void
  animated?: boolean
  transitionTime: number
  show: boolean
}

export const TooltipContainer = (props: TooltipContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const {
    children,
    childrenRect,
    offsetX,
    offsetY,
    place,
    childrenRef,
    hideTooltip,
    animated = true,
    show,
    transitionTime,
  } = props
  const [styles, setStyles] = useState<{ left: string; top: string }>()

  const opacity = styles && show ? 1 : 0

  useEffect(() => {
    // Calculating tooltip's position depends on its sizes.
    if ((childrenRef.current || childrenRect) && containerRef.current) {
      const childRect = childrenRef?.current
        ? childrenRef.current.getBoundingClientRect()
        : childrenRect!
      const tooltipRect = containerRef.current.getBoundingClientRect()
      setStyles(
        getTooltipPosition(
          getPositionOptions(
            childRect,
            tooltipRect,
            {
              x: offsetX,
              y: offsetY,
            },
            place,
          ),
        ),
      )
    }
  }, [children, offsetX, offsetY, place, childrenRef, childrenRect, animated, show])

  // Adding listeners only for showing tooltip
  useEffect(() => {
    window.addEventListener('scroll', hideTooltip)
    window.addEventListener('wheel', hideTooltip)
    window.addEventListener('resize', hideTooltip)
    window.addEventListener('orientationchange', hideTooltip)
    return () => {
      window.removeEventListener('scroll', hideTooltip)
      window.removeEventListener('wheel', hideTooltip)
      window.removeEventListener('resize', hideTooltip)
      window.removeEventListener('orientationchange', hideTooltip)
    }
  }, [hideTooltip])

  return (
    <Container
      ref={containerRef}
      style={{ ...styles, opacity }}
      animated={animated}
      transitionTime={transitionTime}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{ animated: boolean; transitionTime: number }>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  white-space: nowrap;
  opacity: 0;

  ${(props) =>
    props.animated &&
    css`
      transition: opacity ${props.transitionTime}ms;
    `}
`
