import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Tab } from '@headlessui/react'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Icon } from 'components/Icon'
import { TabLabel } from 'components/ps-chart/details-view/TabLabel'
import { FlagDetails } from 'components/ps-chart/details-view/flags/FlagDetails'
import { SliceConnections } from 'components/ps-chart/details-view/SliceConnections'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { VideoTab } from 'components/ps-chart/details-view/video/VideoTab'
import { AnnotationsTab } from 'components/ps-chart/details-view/annotations/AnnotationsTab'

enum Tabs {
  Details = 0,
  Video = 1,
}

export const DetailsView = observer(function DetailsView({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const [isCollapsed, setCollapsed] = useState(false)
  const [showContent, setShowContent] = useState(true)
  const [selectedTabIndex, setSelectedTabIndex] = useState(Tabs.Details)
  const { t } = useTranslation()

  const onCollapse = useCallback(() => {
    setCollapsed(true)
    setShowContent(false)
  }, [])

  const onExpand = useCallback(() => {
    setCollapsed(false)
  }, [])

  const onTabChange = useCallback(
    (index: number) => {
      psChartStore.setIsVideoModeEnabled(index === Tabs.Video)
      setSelectedTabIndex(index)
    },
    [psChartStore],
  )

  const detailsRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(detailsRef)

  return (
    <>
      {isCollapsed ? (
        <div
          ref={detailsRef}
          className="w-[32px] bg-dark-dark3 transition-all cursor-pointer"
          onClick={onExpand}
          data-tid="detailsview-open"
        >
          <div className="flex items-center">
            <Icon icon="arrow-drop-l" className="text-gray-normal text-icon" />
          </div>
        </div>
      ) : (
        <div
          ref={detailsRef}
          className="w-[320px] bg-dark-dark3 transition-all flex flex-col"
          onTransitionEnd={() => setShowContent(true)}
        >
          {showContent && (
            <Tab.Group onChange={onTabChange} defaultIndex={selectedTabIndex}>
              <div
                className="w-[32px] h-[32px] bg-dark-dark5 absolute right-0 mt-[4px] mr-[4px] cursor-pointer"
                onClick={onCollapse}
                data-tid="detailsview-close"
              >
                <Icon icon="arrow-drop-r" className="text-gray-normal text-icon" />
              </div>
              <Tab.List className="bg-dark-dark5">
                <TabLabel>{t('psChart.details.detailsTab')}</TabLabel>
                <TabLabel>{t('psChart.details.videoTab')}</TabLabel>
              </Tab.List>
              <Tab.Panels className="flex-grow flex">
                <Tab.Panel className="focus:outline-1 container relative">
                  {psChartStore.chartFeatures.annotations.draggable ? (
                    <AnnotationsTab psChartStore={psChartStore} />
                  ) : psChartStore.flagsStore.selectedFlagId ? (
                    <FlagDetails flagsStore={psChartStore.flagsStore} />
                  ) : psChartStore.traceAnalyzeStore.selectedSlice ? (
                    <SliceConnections psChartStore={psChartStore} />
                  ) : (
                    <div className="p-[24px] text-small font-medium text-gray-normal">
                      {t('psChart.details.emptySliceSelect')}
                    </div>
                  )}
                </Tab.Panel>
                <Tab.Panel className="flex-grow flex">
                  <VideoTab videoStore={psChartStore.videoPlayerStore} isFullScreen={false} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          )}
        </div>
      )}
    </>
  )
})
