import { BorderType, LineType } from 'components/ps-chart/connections-render/NodeRenderData'

export interface ConnectionPaths {
  [LineType.PRIMARY]: Path2D
  [LineType.UNFOCUSED]: Path2D
  [LineType.SECONDARY]: Path2D
  [LineType.ACTIVE]: Path2D
  [LineType.DISABLED]: Path2D
}

export interface SliceBordersPaths {
  [BorderType.PRIMARY]: Path2D[]
  [BorderType.UNFOCUSED]: Path2D[]
  [BorderType.SECONDARY]: Path2D[]
  [BorderType.ACTIVE]: Path2D[]
}

export interface ConnectionCurves {
  localCurvePaths: ConnectionPaths
  crossCurvePaths: ConnectionPaths
  sliceBordersPaths: SliceBordersPaths
}

export interface CanvasesCurvesMap {
  main: ConnectionCurves
  fav: ConnectionCurves
}

export const getEmptyConnectionCurves = (): ConnectionCurves => ({
  localCurvePaths: getEmptyConnectionPaths(),
  crossCurvePaths: getEmptyConnectionPaths(),
  sliceBordersPaths: {
    [BorderType.PRIMARY]: [new Path2D(), new Path2D()],
    [BorderType.UNFOCUSED]: [new Path2D(), new Path2D()],
    [BorderType.SECONDARY]: [new Path2D(), new Path2D()],
    [BorderType.ACTIVE]: [new Path2D(), new Path2D()],
  },
})

export const getEmptyCanvasesCurvesMap = (): CanvasesCurvesMap => ({
  main: getEmptyConnectionCurves(),
  fav: getEmptyConnectionCurves(),
})

export const getEmptyConnectionPaths = (): ConnectionPaths => ({
  [LineType.PRIMARY]: new Path2D(),
  [LineType.UNFOCUSED]: new Path2D(),
  [LineType.SECONDARY]: new Path2D(),
  [LineType.ACTIVE]: new Path2D(),
  [LineType.DISABLED]: new Path2D(),
})
