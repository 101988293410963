import { Flag } from 'components/ps-chart/models/Flag'
import { Extra } from 'components/ps-chart/models/Slice'

export enum TokenDest {
  COOKIE = 'COOKIE',
  BODY = 'BODY',
}

export enum FreeTrialStage {
  PLATFORM_CHOICE = 'PLATFORM_CHOICE',
  INSTRUCTIONS = 'INSTRUCTIONS',
  FLOW_LIBRARY = 'FLOW_LIBRARY',
}

export interface SsoSignupDataDto {
  name: string
  lastName: string
  companyName?: string
  password?: string
}

export interface SsoSignInDto {
  ssoIdToken: string
  inviteToken?: string
  signUpData?: SsoSignupDataDto
  allowFreeTrial?: boolean
  teamToken?: string
}

export interface SignInDto {
  emailData?: {
    email: string
    password: string
  }
  ssoData?: SsoSignInDto
  rememberMe?: boolean
  tokenDest?: TokenDest
}

export interface SignInResRegCompleted {
  user: AuthenticatedUserDto
  token: {
    accessToken: string
    tokenType: string
    expiresIn: number
  }
  isNewUser: boolean
}

export interface SignInResRegNotCompleted {
  name: string
  lastName: string
  email: string
  companyName: string
  companyNameRequired: boolean
}

export type SignInResDto = SignInResRegCompleted | SignInResRegNotCompleted

export interface InviteWhitelistReq {
  email: string
}

export interface CheckInviteTokenReqDto {
  inviteToken?: string
  teamToken?: string
  allowFreeTrial?: boolean
  returnData?: boolean
}

export interface CheckInviteTokenResDto {
  email?: string
  freeTrialSignup: boolean
  name?: string
  lastName?: string
  companyName?: string
}

export interface SignUpRequest {
  emailData?: {
    inviteToken: string
    password: string
    name: string
    lastName: string
  }
  rememberMe?: boolean
  tokenDest?: TokenDest
  allowFreeTrial?: boolean
}

export interface RequestFreeTrialReqDto {
  email: string
  name: string
  lastName: string
  companyName: string
  teamToken?: string
}

export interface SignUpFreeTrialReqDto {
  data: {
    inviteToken: string
    password: string
  }
  rememberMe?: boolean
  tokenDest?: TokenDest
}

export interface RecoveryTokenRequest {
  email: string
}

export interface CheckRecoveryTokenRequest {
  recoveryToken: string
}

export interface ResetPasswordRequest {
  recoveryToken: string
  newPassword: string
}

export interface ImageSize {
  width: number
  height: number
}

export interface ImageDto {
  url: string
  size: ImageSize
}

//TODO: the "| null" part should be removed
export type ImageDataDto = {
  id: number
  format: string
  hq: ImageDto | null
  mq: ImageDto | null
  lq: ImageDto | null
  tq: ImageDto
} | null

export interface UserDto {
  id: number
  name: string
  iconColor: string
  image: ImageDataDto | null
  lastName: string
}

export enum TeamRole {
  ADMIN = 'ADMIN',
  CONTRIBUTOR = 'CONTRIBUTOR',
  NONE = 'NONE',
}

export enum ProjectRole {
  ADMIN = 'ADMIN',
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export interface FlowSettingsDto {
  favorite: boolean
  subscribed: boolean
}

export interface ProjectSettingsDto {
  favorite: boolean
  flows: {
    [key: FlowDto['projectLocalId']]: FlowSettingsDto
  }
}

export interface UserSettingsDto {
  projects: {
    [key: ProjectDto['id']]: ProjectSettingsDto
  }
}

export interface AuthenticatedUserDto extends UserDto {
  email: string
  roles: {
    isSuperAdmin: boolean
    projects: {
      [key: ProjectDto['id']]: ProjectRole
    }
    teams: {
      [key: TeamDto['id']]: TeamRole
    }
  }
  settings: UserSettingsDto
  features: Array<string>
  askQuestionnaire: boolean
}

export interface TeamDtoForm {
  name: string
  domain: string
  doWhitelist: boolean
}

export interface TeamDto {
  id: number
  name: string
  urlName: string
  dateUpdated: string
  domain: string | null
  doWhitelist: boolean | null
  freeTrial?: FreeTrialDto
  supportsMvp: boolean
}

export type TeamsArray = Array<TeamDto>

export interface TeamReq {
  teamUrlName?: string
}

export interface UserIdReq {
  userId: number
}

export interface InviteUserToTeamDto {
  email: string
  role: TeamRole
}

export interface TeamUserDto {
  id: number
  user: UserDto | null
  email: string
  teamRole: TeamRole | null
  projectRoles: {
    [key: ProjectDto['id']]: ProjectRole
  }
  lastActive: string
}

export interface TeamUsersResponse {
  team: TeamDto
  users: Array<TeamUserDto>
  projects: Array<ProjectDescriptorDto>
}

export enum OsType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum TechType {
  JVM = 'JVM',
  SWIFT = 'SWIFT',
  REACT_NATIVE = 'REACT_NATIVE',
}

export interface ProjectDto {
  id: number
  name: string
  urlName: string
  team: TeamDto
  dateCreated: string
  dateUpdated: string
  appId: string
  enableMailing: boolean
  os: OsType
  tech: TechType
  buildSystem?: string
  languages: string[]
  doWhitelist: boolean | null
  image: ImageDataDto
  airtableClientId?: string
}

export type ProjectDescriptorDto = Pick<ProjectDto, 'name' | 'urlName' | 'id'>

export type ProjectsArray = Array<ProjectDto>

export interface ProjectSummaryItem {
  project: ProjectDto
  flows: Array<FlowDto>
  numUnassignedTraces: number
}

export interface ProjectSummaryDto {
  projects: Array<ProjectSummaryItem>
  team: TeamDto
}

export interface ProjectDtoForm {
  name: string
  os: OsType | null
  tech: TechType | null
  appId: string
  doWhitelist: boolean
  enableMailing?: boolean
  imageId?: number | null
  airtableClientId?: string
}

export interface ProjectReq {
  projectUrlName?: string
}

export interface ProjectUserDto {
  id: number
  user: UserDto | null
  email: string
  teamRole: TeamRole | null
  projectRole: ProjectRole | null
  lastActive: string
}

export interface ProjectUsersResponse {
  project: ProjectDto
  users: Array<ProjectUserDto>
}

export interface InviteUserToProjectDto {
  email: string
  role: ProjectRole
}

export interface FlowReq extends ProjectReq {
  flowProjectLocalId?: string
}

export type FlowUserInfo = {
  subscribed: boolean
  favorite: boolean
} | null

export enum AppStartType {
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  COLD_START = 'COLD_START',
  RETURN = 'RETURN',
}

export interface CreateFlowDto {
  name: string
  description: string
}

export interface FlowDto {
  name: string | null
  projectLocalId: number
  description: string | null
  author: UserDto
  dateCreated: string
  dateUpdated: string
  flowUserInfo: FlowUserInfo
  progress: number
  popularity: number
  hasVideo: boolean
  appStartType: AppStartType
}

export type Flows = Array<FlowDto>

export interface UpdateFlowDto {
  name: string
  description: string
  appStartType: AppStartType
}

export enum TraceProcessingStateEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
}

export enum ProcessingErrorCodeEnum {
  APP_ID_NOT_FOUND = 'APP_ID_NOT_FOUND',
  UNSUPPORTED_FILE_FORMAT = 'UNSUPPORTED_FILE_FORMAT',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export type TraceFlow = {
  flowProjectLocalId: number
  flowName: string
}

export interface Trace {
  projectLocalId: number
  processingState: TraceProcessingStateEnum
  processingErrorCode?: ProcessingErrorCodeEnum
  storageId: string
  name: string
  flows: TraceFlow[]
  createdBy: string
  executedBy: string
  dateCreated: string
  dateUpdated: string
  gitBranch: string
  gitCommit: string
  appVersion: string
  device: string
  osVersion: string
  resolution: string
  flowDelayMs: number
  performancePct: number
}

export type Traces = Array<Trace>

export interface TraceReq extends ProjectReq {
  traceProjectLocalId?: string
}

export interface FlowTraceReq extends FlowReq {
  traceProjectLocalId?: string
}

export enum AnnotationDelayDto {
  NO_DELAY = 'NO_DELAY',
  MODERATE_DELAY = 'MODERATE_DELAY',
  EXTREME_DELAY = 'EXTREME_DELAY',
}

export interface AnnotationDto {
  id: number
  cid?: number
  delay: AnnotationDelayDto
  action: AnnotationPinDto
  reaction: AnnotationPinDto
  timestamp?: number
  dateCreated: string
  dateUpdated: string
}

export interface AnnotationPinDto {
  title: string
  description: string
  binding?: AnnotationPinBindingDto
}

export interface AnnotationPinBindingDto {
  time: number
  sliceId?: number
}

export interface SliceDto {
  id: number
  name: string
  start: number
  end: number
  closureId: number | null
  objectId: number | null
  children: SliceDto[]
  arguments?: { value: string }[]
  extra?: Extra
}

export interface ThreadDto {
  id: number
  name: string
  isAsync: boolean
  slices: SliceDto[]
}

export type UtilityThreadDto = Omit<ThreadDto, 'slices'> & {
  slices?: SliceDto[]
}

export interface TraceMetaDto {
  totalTime: number
}

export interface TraceDataDto {
  threads: ThreadDto[]
  metadata: TraceMetaDto
  utilityThreads?: UtilityThreadsDto
}

export interface UtilityThreadsDto {
  frames?: UtilityThreadDto
}

export enum NamedLinkType {
  SYNC = 'SYNC',
  ASYNC = 'ASYNC',
  OBJECT = 'OBJECT',
  DIRECT = 'DIRECT',
}

export interface NamedLinkDto {
  id: string
  fromName: string
  toName: string
  type: NamedLinkType
  isEditable: boolean
}

export interface CreateNamedLinkRequestData {
  fromName: string
  toName: string
  type: NamedLinkType
  isEditable: boolean
}

export const NO_AIRTABLE_PROJECT_STATUS_CODE = 409

export const NO_AIRTABLE_CONNECTION_RECORD_CODE = 404

export interface ApiError {
  code: string
  message: string
}

export interface FlagPostReq extends Flag {
  timestamp: number
}

export interface DeleteEntityReq {
  id?: number
  cid?: number
  timestamp: number
}

export interface FlagPutReq {
  id?: number
  cid?: number
  timestamp: number
  time: number
  color: number
  title: string
}

export type TracePageParams = Required<TraceReq>

export type ChartPageParams = TracePageParams & {
  flowProjectLocalId: string
}

export interface TraceVideoMetadataDto {
  state: VideoProcessingStateDto
  dateCreated: string
  fileName: string
  originalVideo?: VideoMetadataDto
  processingErrorCode?: string
}

export enum VideoProcessingStateDto {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
}

export interface VideoMetadataDto {
  frameToTime: FrameTimestampDto[]
  videoUrl: string
  durationMicros: number
  size: number
  frameRate: number
}

export interface FrameTimestampDto {
  id: number
  videoTime: number
}

export interface ChoreographerReq {
  projectUrlName: string
  traceProjectLocalId: string
}

export interface ChoreographerPathsDto {
  choreographerPath: ChoreographerPath[]
}

export interface ChoreographerPath {
  sliceId: number
  pathId: number
}

export interface UserQuestionnaireDto {
  userRole: string
  customerDoings: string
  userTargetAchievement: string
  howYouLearnedAboutPs: string
}

export interface TeamShareUrlDto {
  url: string
}

export interface SendShareUrlDto {
  emails: string[]
}

export enum AppInstrumentStatus {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export interface AppBuildAndRunStatusDto {
  buildStatus?: {
    buildId: string
    status: AppInstrumentStatus
    message?: string | null
    dateUpdated: string
  }
  appRunStatus?: {
    buildId: string
    status: AppInstrumentStatus
    message?: string | null
    dateUpdated: string
  }
}

export type InstrumentStepKey = keyof AppBuildAndRunStatusDto

export enum InstrumentStep {
  Build = 'buildStatus',
  Run = 'appRunStatus',
}

export interface CreateFreeTrialProjectReqDto {
  os: OsType
  devPlatform: string
  languages: string[]
  buildSystem: string
}

export interface FreTrialOnboardingRequestDto {
  os: string
  devPlatform?: string
  languages?: string[]
  buildSystem?: string
  dateCreated: string
}

export type SubmitFreeTrialOnboardingRequestReqDto = Omit<
  FreTrialOnboardingRequestDto,
  'dateCreated'
>
export type SubmitFreeTrialOnboardingRequestResDto = FreTrialOnboardingRequestDto
export type GetSubmittedFreeTrialOnboardingRequestsResDto = FreTrialOnboardingRequestDto[]

export interface ResendEmailVerificationTokenRequest {
  email?: string
  inviteToken?: string
}

export interface SliceSuggestionCandidateDto {
  sliceId: number
  score: number
}

export interface AnnotationSliceSuggestionDto {
  annotationId: number
  actionSliceIds: SliceSuggestionCandidateDto[]
  reactionSliceIds: SliceSuggestionCandidateDto[]
}

export interface SliceSuggestionsReq {
  projectUrlName: string
  flowProjectLocalId: string
  traceProjectLocalId: string
}

export interface FlowComputationReq {
  projectUrlName: string
  flowProjectLocalId: string
}

export enum ComputationStatus {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

export interface ComputationView {
  id: number
  computationStatus: ComputationStatus
  timeStart: string
  timeEnd?: string
}

export enum SupportIssueCategory {
  BUILD_APP = 'BUILD_APP',
  RUN_APP = 'RUN_APP',
  APP_CRASH_OR_SLOW = 'APP_CRASH_OR_SLOW',
  DOWNLOAD_COMPANION_APP = 'DOWNLOAD_COMPANION_APP',
  INSTALL_COMPANION_APP = 'INSTALL_COMPANION_APP',
  ENABLE_TRACING = 'ENABLE_TRACING',
  RECORD_TRACE = 'RECORD_TRACE',
  RECORD_VIDEO = 'RECORD_VIDEO',
  MISSING_FRAME_COUNTER = 'MISSING_FRAME_COUNTER',
  UPLOAD_TRACE = 'UPLOAD_TRACE',
  UPLOAD_VIDEO = 'UPLOAD_VIDEO',
  TRACE_QUALITY = 'TRACE_QUALITY',
  OTHERS = 'OTHERS',
}

export interface ApkSupportIssueReq {
  category: SupportIssueCategory
  description: string
  subject: string
  projectName?: string
  executionLog?: string
  apkFile?: File // binary
}

export interface ApkSupportIssueDtoOut {
  id: number
  apkAttached: boolean
  category: SupportIssueCategory
  description: string
  subject: string
  executionLog?: string
}

export interface FreeTrialDto {
  isExpired: boolean
  stage: FreeTrialStage
}

export interface FreeTrialExtensionDto {
  expirationDate?: string
  extensionRequestedDate?: string
}
