import React, { useCallback, useContext, useEffect, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PATH_ROOT } from 'pages/MainPage'
import { PATH_SIGN_UP_SSO } from 'pages/auth/SignUpSsoPage'
import {
  ApiError,
  CheckInviteTokenReqDto,
  CheckInviteTokenResDto,
  RequestFreeTrialReqDto,
  ResetPasswordRequest,
  SignInDto,
  SignUpFreeTrialReqDto,
  SignUpRequest,
} from 'api/models'
import { useApi, useDi } from 'contexts/di-context'
import { AxiosError } from 'axios'
import { isAxiosError } from 'utils/axios'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'

export interface AuthContextInterface {
  isSignedIn: boolean
  check: () => Promise<void>
  signIn: (data: SignInDto, redirectPath: string) => Promise<void>
  signInSSO: (data: SignInDto, redirectPath: string, teamToken?: string) => Promise<void>
  signOut: () => Promise<void>
  signUp: (data: SignUpRequest) => Promise<void>
  signUpCheckToken: (data: CheckInviteTokenReqDto) => Promise<CheckInviteTokenResDto>
  signUpFreeTrial: (data: SignUpFreeTrialReqDto) => Promise<void>
  requestFreeTrial: (data: RequestFreeTrialReqDto) => Promise<void>
  resetPassword: (data: ResetPasswordRequest) => Promise<void>
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const AuthContext = React.createContext<AuthContextInterface | null>(null)

export function useAuth(): AuthContextInterface {
  const authContext = useContext(AuthContext)
  if (authContext == null) {
    throw new Error('Auth context is not initialized')
  }
  return authContext
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const cr = useDi().compositionRoot
  const navigate = useNavigate()
  const api = useApi()
  const queryClient = useQueryClient()

  const [isSignedIn, setIsSignedIn] = useState(psLocalStorage.getSignedIn())

  // utility function to update signIn state in memory and local storage
  const updateSignInState = useCallback((value: boolean) => {
    psLocalStorage.setSignedIn(value)
    setIsSignedIn(value)
  }, [])

  const clearAll = useCallback(
    (clearQueryClient: boolean) => {
      psLocalStorage.removeLastTeam()
      updateSignInState(false)
      if (clearQueryClient) {
        queryClient.clear()
      }
    },
    [queryClient, updateSignInState],
  )

  // Setup network requests interceptor to set current signedIn state
  useEffect(() => {
    cr.subscribeToAxiosError((error: unknown) => {
      if (error && isAxiosError(error)) {
        const axiosError = error as AxiosError<ApiError>
        if (axiosError.response?.status === 401) {
          const url = axiosError.config?.url
          const clearQueryClient = url ? !cr.api.urlsNotRequireAuth().includes(url) : true

          // We should clear query client cache only for queries requiring auth.
          // If not, there could be an error message lost in toast during sign-in/sign-up requests
          clearAll(clearQueryClient)
        }
      }
    })
  }, [clearAll, cr])

  const checkHandler = useCallback(() => {
    return api
      .getAuthCheck()
      .then(() => updateSignInState(true))
      .catch(() => {})
  }, [api, updateSignInState])

  const signInHandler = useCallback(
    (data: SignInDto, redirectPath: string) => {
      return api.postSignIn(data).then(() => {
        updateSignInState(true)
        navigate(redirectPath, { replace: true })
      })
    },
    [api, navigate, updateSignInState],
  )

  const signInSSOHandler = useCallback(
    (data: SignInDto, redirectPath: string, teamToken?: string) => {
      return api.postSignIn(data).then((response) => {
        if (response.hasOwnProperty('user')) {
          updateSignInState(true)
          navigate(redirectPath, { replace: true })
        } else {
          navigate(PATH_SIGN_UP_SSO, {
            replace: true,
            state: {
              ssoIdToken: data.ssoData?.ssoIdToken as string,
              userData: response,
              allowFreeTrial: data.ssoData?.allowFreeTrial,
              teamToken,
            },
          })
        }
      })
    },
    [api, navigate, updateSignInState],
  )

  const signOutHandler = useCallback(() => {
    return api.postSignOut().then(() => {
      clearAll(true)
      navigate(PATH_ROOT, { state: { isSignOutAction: true }, replace: true })
    })
  }, [api, clearAll, navigate])

  const resetPasswordHandler = useCallback(
    (data: ResetPasswordRequest) => {
      return api.postResetPassword(data).then(() => {
        updateSignInState(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, updateSignInState],
  )

  const signUpHandler = useCallback(
    (data: SignUpRequest) => {
      return api.postSignUp(data).then(() => {
        updateSignInState(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, updateSignInState],
  )

  const signUpCheckTokenHandler = useCallback(
    (data: CheckInviteTokenReqDto) => {
      return api.postSignUpCheckToken(data)
    },
    [api],
  )

  const signUpFreeTrialHandler = useCallback(
    (data: SignUpFreeTrialReqDto) => {
      return api.postSignUpFreeTrial(data).then(() => {
        updateSignInState(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, updateSignInState],
  )

  const requestFreeTrialHandler = useCallback(
    (data: RequestFreeTrialReqDto) => {
      return api.postRequestFreeTrial(data)
    },
    [api],
  )

  const contextValue: AuthContextInterface = {
    isSignedIn: isSignedIn,
    check: checkHandler,
    signIn: signInHandler,
    signInSSO: signInSSOHandler,
    signOut: signOutHandler,
    signUp: signUpHandler,
    signUpCheckToken: signUpCheckTokenHandler,
    signUpFreeTrial: signUpFreeTrialHandler,
    requestFreeTrial: requestFreeTrialHandler,
    resetPassword: resetPasswordHandler,
  }
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_WEBSDK_CLIENT_ID!}>
      <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
    </GoogleOAuthProvider>
  )
}
