import { makeAutoObservable, observable } from 'mobx'
import i18next from 'i18next'

import { Api } from 'api/Api'
import {
  FrameTimestampDto,
  TracePageParams,
  TraceVideoMetadataDto,
  VideoMetadataDto,
  VideoProcessingStateDto,
} from 'api/models'
import { getProgress } from 'utils/getProgress'
import { AxiosProgressEvent } from 'axios'

export enum VideoDataStoreStatus {
  INITIALIZATION = 'INITIALIZATION',
  DOWNLOADING = 'DOWNLOADING',
  UPLOADING = 'UPLOADING',
  DELETING = 'DELETING',
  PROCESSING = 'PROCESSING',
  HAS_VIDEO_AND_READY = 'HAS_VIDEO_AND_READY',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
}

const VIDEO_BE_PROGRESS_RECHECK_TIMEOUT = 10_000

export class VideoDataStore {
  private readonly api: Api
  private readonly tracePageParams: TracePageParams

  private scheduledRecheckId: number | null = null
  tag: HTMLVideoElement | null = null
  srcBlobUrl = ''
  isDeletable = false
  status = VideoDataStoreStatus.INITIALIZATION
  errorReason = ''
  progress = 0
  videoLengthMicros = 0
  videoTimeMicrosByFrameId: ReadonlyMap<number, number> | null = null
  fileSize = 0

  constructor(api: Api, tracePageParams: TracePageParams) {
    makeAutoObservable<VideoDataStore, 'api' | 'tracePageParams' | 'scheduledRecheckId'>(this, {
      api: false,
      tracePageParams: false,
      scheduledRecheckId: false,
      tag: observable.ref,
      videoTimeMicrosByFrameId: observable.ref,
    })
    this.tracePageParams = tracePageParams
    this.api = api
  }

  load(): Promise<void> {
    return this.checkVideo()
  }

  private setIsDeletable(values: boolean) {
    this.isDeletable = values
  }

  private setStatus(status: Exclude<VideoDataStoreStatus, VideoDataStoreStatus.ERROR>) {
    this.status = status
  }

  private setError(reason: string) {
    this.status = VideoDataStoreStatus.ERROR
    this.errorReason = reason
  }

  private cancelVideoProgressRecheck() {
    if (this.scheduledRecheckId != null) {
      clearTimeout(this.scheduledRecheckId)
    }
  }

  private clearProgress() {
    this.progress = 0
  }

  private checkVideo(
    onVideoProcessingError?: (error: string | null) => void,
    onVideoSuccessfullyUploaded?: () => void,
  ): Promise<void> {
    this.cancelVideoProgressRecheck()
    return this.api
      .getTraceVideoMetadata(this.tracePageParams)
      .then((videoMeta) => {
        if (videoMeta == null) {
          this.setStatus(VideoDataStoreStatus.EMPTY)
          return
        }
        return this.updateVideoMetaAndDownloadVideo(
          videoMeta,
          onVideoProcessingError,
          onVideoSuccessfullyUploaded,
        )
      })
      .catch((reason) => {
        this.setError(String(reason))
        return Promise.reject(reason)
      })
      .finally(() => this.clearProgress())
  }

  private updateVideoMetaAndDownloadVideo(
    videoMeta: TraceVideoMetadataDto,
    onVideoProcessingError?: (error: string | null) => void,
    onVideoSuccessfullyUploaded?: () => void,
  ): Promise<void> {
    this.setIsDeletable(true)
    if (videoMeta.state === VideoProcessingStateDto.IN_PROGRESS) {
      this.setProcessingStatusAndScheduleUpdate(onVideoProcessingError, onVideoSuccessfullyUploaded)
      return Promise.resolve()
    }

    const originalVideo = videoMeta.originalVideo
    const processingError = VideoDataStore.getProcessingError(videoMeta, originalVideo)
    if (processingError != null) {
      this.setError(processingError)
      if (onVideoProcessingError != null) {
        onVideoProcessingError(processingError)
      }
      return Promise.resolve()
    }
    if (originalVideo == null) {
      return Promise.resolve()
    }

    this.setStatus(VideoDataStoreStatus.DOWNLOADING)
    this.setFrames(originalVideo.frameToTime)
    this.videoLengthMicros = originalVideo.durationMicros

    const onDownloadProgress = (progressEvent: AxiosProgressEvent) => {
      this.fileSize = progressEvent.total!
      this.setProgress(getProgress(progressEvent))
    }

    return this.api.getTraceVideo(originalVideo.videoUrl, onDownloadProgress).then((videoBlob) => {
      this.setSrcBlobUrl(URL.createObjectURL(videoBlob))
      this.setStatus(VideoDataStoreStatus.HAS_VIDEO_AND_READY)
      if (onVideoSuccessfullyUploaded != null) {
        onVideoSuccessfullyUploaded()
      }
    })
  }

  private setProcessingStatusAndScheduleUpdate(
    onVideoProcessingError: ((error: string | null) => void) | undefined,
    onVideoSuccessfullyUploaded: (() => void) | undefined,
  ) {
    this.setStatus(VideoDataStoreStatus.PROCESSING)
    this.scheduledRecheckId = window.setTimeout(
      () => this.checkVideo(onVideoProcessingError, onVideoSuccessfullyUploaded),
      VIDEO_BE_PROGRESS_RECHECK_TIMEOUT,
    )
  }

  deleteVideo(): Promise<void> {
    const prevStatus = this.status
    this.setStatus(VideoDataStoreStatus.DELETING)
    this.setIsDeletable(false)
    this.cancelVideoProgressRecheck()
    return this.api
      .deleteTraceVideo(this.tracePageParams)
      .then(() => this.dropVideo())
      .catch((reason) => {
        console.error(reason)
        if (prevStatus === VideoDataStoreStatus.ERROR) {
          this.setError(String(reason))
        } else {
          this.setStatus(prevStatus)
        }
        this.setIsDeletable(true)
        return Promise.reject(reason)
      })
  }

  private dropVideo() {
    this.setStatus(VideoDataStoreStatus.EMPTY)
    this.setIsDeletable(false)
    this.videoLengthMicros = 0
    this.videoTimeMicrosByFrameId = null
    this.progress = 0
    this.srcBlobUrl = ''
  }

  uploadVideo(
    file: File,
    onVideoProcessingError?: (error: string | null) => void,
    onVideoSuccessfullyUploaded?: () => void,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    this.setStatus(VideoDataStoreStatus.UPLOADING)
    this.setIsDeletable(false)
    this.fileSize = file.size

    const formData = new FormData()
    formData.append('file', file)
    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      this.setProgress(getProgress(progressEvent))
    }

    return this.api
      .postTraceVideo(this.tracePageParams, formData, onUploadProgress, abortSignal)
      .then((videoMeta) =>
        this.updateVideoMetaAndDownloadVideo(
          videoMeta,
          onVideoProcessingError,
          onVideoSuccessfullyUploaded,
        ),
      )
      .catch((reason) => {
        console.error(reason)
        if (this.isDeletable) {
          this.setError(String(reason))
        } else {
          this.setIsDeletable(true)
          this.setStatus(VideoDataStoreStatus.EMPTY)
        }
        return Promise.reject(reason)
      })
      .finally(() => this.clearProgress())
  }

  private setProgress(percentage: number) {
    this.progress = percentage
  }

  private setFrames(frameToTime: FrameTimestampDto[]) {
    const videoTimeMicrosByFrameId = new Map()
    frameToTime.forEach((item) => {
      videoTimeMicrosByFrameId.set(item.id, item.videoTime)
    })
    this.videoTimeMicrosByFrameId = videoTimeMicrosByFrameId
  }

  private static getProcessingError(
    videoMeta: TraceVideoMetadataDto,
    originalVideo?: VideoMetadataDto,
  ): string | null {
    if (videoMeta.state === VideoProcessingStateDto.FAILED) {
      return videoMeta.processingErrorCode != null
        ? i18next.t(`psChart.error.video.serverSide.${videoMeta.processingErrorCode}`)
        : 'Unknown server-side error'
    } else if (originalVideo == null) {
      return i18next.t('psChart.error.video.noOriginalVideo')
    } else if (originalVideo.frameToTime.length === 0) {
      return i18next.t('psChart.error.video.emptyFrameToTime')
    }
    return null
  }

  private setSrcBlobUrl(srcBlobUrl: string) {
    this.srcBlobUrl = srcBlobUrl
    this.setTag(this.srcBlobUrl)
  }

  private setTag(srcBlobUrl: string) {
    const videoTag = document.createElement('video')
    videoTag.muted = true

    const source = document.createElement('source')
    source.type = 'video/mp4'
    source.src = srcBlobUrl

    videoTag.appendChild(source)
    this.tag = videoTag
  }

  get isLoaded(): boolean {
    return this.status !== VideoDataStoreStatus.INITIALIZATION
  }

  get hasVideo(): boolean {
    return this.isLoaded
      ? ![VideoDataStoreStatus.EMPTY, VideoDataStoreStatus.ERROR].includes(this.status)
      : false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancelled(reason: any): boolean {
    return this.api.isCancelled(reason)
  }
}
