import { Slice } from '../models/Slice'
import { getSliceTitleWithArgs } from '../utils/slice'
import { GLOBAL_ALPHA_DEFAULT_VALUE, RenderEngineSettings } from '../models/settings'
import { Thread } from '../models/Thread'

import { BasicRenderer, RenderTextParams } from './BasicRenderer'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import { VerticalState } from 'components/ps-chart/models/VerticalState'
import { HorizontalState } from 'components/ps-chart/models/HorizontalState'
import {
  DepthByThreadId,
  DepthsByThreadId,
  TopBottomByThreadId,
} from 'components/ps-chart/stores/TraceAnalyzeStore'
import {
  ConnectionCurves,
  ConnectionPaths,
  SliceBordersPaths,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import { FlagsState } from 'components/ps-chart/stores/FlagsStore'
import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'

import { nanoToMeasurementString } from 'components/ps-chart/utils/nanoToString'
import { getSliceDimmedColor } from 'components/ps-chart/utils/getSliceDimmedColor'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { AllBorderTypes, AllLineTypes } from 'components/ps-chart/connections-render/NodeRenderData'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { AnnotationsState, AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationPinBindingDto } from 'api/models'

export type ThreadsTopMap = Map<number, number>

export interface Delay {
  start: number
  end: number
}

export type MeasurementPoints = {
  startX: number
  endX: number
  startY: number
} | null

export interface RenderEngineData {
  psChartStore: PsChartStore
  threads: Thread[]
  isFavorites: boolean
  shouldRenderMeasurement: boolean
  /**
   * Map which gives for specified thread id - thread's levels/depths used by slices from execution path
   */
  activeDepthsByThreadId: DepthsByThreadId
  /**
   * Map which gives for specified thread id - thread's maximum level/depth which should be rendered
   */
  maxDepthByThreadId: DepthByThreadId
  hState: HorizontalState
  vState: VerticalState
  flagsState: FlagsState
  videoState: VideoPlayerState
  annotationsState: AnnotationsState
  measurementPoints: MeasurementPoints
  isTransparentConnectionEnabled: boolean
  /**
   * View mode which should render only slices which belongs to depth/level used by slices from execution path.
   * And also render only zero depth/level for favorite/pinned threads not used in execution path.
   */
  isThreadShrunkModeEnabled: boolean
  connectionCurves?: ConnectionCurves
  linkModeSlice?: Slice
  delays?: Delay[]
}

/**
 * Gets the data and renders timeline, slices, etc
 */
export class RenderEngine {
  private readonly context: CanvasRenderingContext2D

  private readonly renderer: BasicRenderer

  private readonly measurementLineTextMeasurer: TextMeasurer

  private readonly fontStyle: string

  private readonly textMeasurer: TextMeasurer

  readonly measurementFontStyle = `${RenderEngine.textMeasurerFontSize}px Manrope`

  private readonly settings: RenderEngineSettings

  static readonly textMeasurerFontSize = 10

  constructor(
    context: CanvasRenderingContext2D,
    settings: RenderEngineSettings,
    fontStyle: string,
    textMeasurer: TextMeasurer,
  ) {
    this.settings = settings
    this.fontStyle = fontStyle
    this.textMeasurer = textMeasurer
    this.measurementLineTextMeasurer = new TextMeasurer(this.measurementFontStyle)
    this.context = context
    this.renderer = new BasicRenderer(this.context, settings.basicRenderer)
  }

  render(data: RenderEngineData) {
    RenderEngine.log(data)

    const start = performance.now()

    this.context.save()
    this.context.translate(0, this.settings.headerHeight)
    this.fillBackground(data.hState.width, data.vState.height)
    if (data.delays) {
      this.renderDelays(data.hState, data.vState.height, data.delays)
    }
    this.renderGridLines(data.hState.xGridLines, data.isFavorites, data.hState, data.vState)

    this.renderThreads(data)
    if (data.connectionCurves != null) {
      // We should render connection lines first so slice border won't be broken by close to border connection line
      this.renderConnectionsLines(
        data.connectionCurves.localCurvePaths,
        data.isTransparentConnectionEnabled,
      )
      this.renderSlicesBorders(data.connectionCurves.sliceBordersPaths)
    }

    this.context.restore()
    this.renderHeader(data)

    this.context.save()
    this.context.translate(0, this.settings.headerHeight)

    if (data.connectionCurves != null) {
      this.renderConnectionsLines(
        data.connectionCurves.crossCurvePaths,
        data.isTransparentConnectionEnabled,
      )
    }

    this.context.restore()

    this.renderHover(data.flagsState, data.videoState, data.hState, data.vState)
    this.renderAnnotationsConnections(data.annotationsState, data.psChartStore)
    if (data.flagsState.enabled) {
      this.renderFlags(data.flagsState, data.hState, data.vState)
    }
    this.renderVideoPointer(data.hState, data.vState, data.videoState)
    if (data.shouldRenderMeasurement) {
      this.renderMeasurement(data.psChartStore, data.measurementPoints)
    }

    console.log('rendering took', (performance.now() - start).toFixed(1), 'ms')
  }

  renderEmpty(data: RenderEngineData) {
    const maxHeightWithHeader = data.isFavorites
      ? data.vState.favCanvasHeight
      : data.vState.mainCanvasHeight + this.settings.headerHeight
    this.fillBackground(data.hState.width, maxHeightWithHeader)
  }

  private renderSlicesBorders(sliceBordersPaths: SliceBordersPaths) {
    for (const borderType of AllBorderTypes) {
      sliceBordersPaths[borderType].forEach((slicesPath: Path2D, layerIndex: number) => {
        this.renderer.strokePath(
          slicesPath,
          this.settings.palette.slice.borders[borderType][layerIndex],
          this.settings.threads.sliceBorderWidths[layerIndex],
        )
      })
    }
  }

  private renderConnectionsLines(
    connectionPaths: ConnectionPaths,
    isTransparentConnectionEnabled: boolean,
  ) {
    if (isTransparentConnectionEnabled) {
      this.context.globalAlpha = this.settings.connectionsTransparentModeOpacity
    }
    for (const lineType of AllLineTypes) {
      this.renderer.strokePath(
        connectionPaths[lineType],
        this.settings.palette.connectionLines.background,
        this.settings.connectionCurveBackgroundWidth,
      )
      this.renderer.strokePath(
        connectionPaths[lineType],
        this.settings.palette.connectionLines[lineType],
        this.settings.connectionCurveWidth,
        this.settings.connectionCurveDashes[lineType],
      )
    }
    if (isTransparentConnectionEnabled) {
      this.context.globalAlpha = GLOBAL_ALPHA_DEFAULT_VALUE
    }
  }

  private renderHeader(data: RenderEngineData) {
    const headerPath = new Path2D()
    headerPath.rect(0, 0, data.hState.width, this.settings.headerHeight)
    this.renderer.fillPath(headerPath, this.settings.palette.headerColor)
  }

  private static log(data: RenderEngineData) {
    console.log(
      `zoom=${data.hState.zoom}, timePerPx=${data.hState.timePerPx}, ` +
        `x=[${Math.round(data.hState.xStart / 1_000_000)}, ${Math.round(
          data.hState.xEnd / 1_000_000,
        )}] ms, ` +
        `width=${Math.round(data.hState.xWidth / 1_000_000)}, ` +
        `y=[${data.vState.yStart}, ${data.vState.yEndMain}] ms, height=${
          data.vState.yEndMain - data.vState.yStart
        }`,
    )
  }

  private fillBackground(width: number, height: number) {
    this.renderer.fillBackground(0, 0, width, height)
  }

  private renderDelays(hState: HorizontalState, height: number, delays: Delay[]) {
    delays.forEach((item) => {
      let start = RenderEngine.timeToPosition(item.start, hState.xStart, hState.timePerPx)
      let end = RenderEngine.timeToPosition(item.end, hState.xStart, hState.timePerPx)

      if (start < 0) {
        start = 0
      }
      if (end > hState.width) {
        end = hState.width
      }

      const width = end - start
      this.renderer.fillRect(start, 0, width, height, this.settings.palette.delay)
    })
  }

  static timeToPosition(time: number, xStart: number, timePerPx: number): number {
    return Math.round((time - xStart) / timePerPx)
  }

  private static heightToPosition(y: number, posY: number): number {
    return y - posY
  }

  private static isSliceOutByX(slice: Slice, xStart: number, xEnd: number) {
    return slice.end < xStart || slice.start > xEnd
  }

  private isSliceOutByY(slice: Slice, threadTopY: number, maxVisibleHeight: number) {
    const sliceTopY = threadTopY + slice.level * this.settings.threads.blockHeight
    const sliceBottomY = sliceTopY + this.settings.threads.blockHeight

    return sliceBottomY < 0 || sliceTopY > maxVisibleHeight
  }

  private renderThreads(data: RenderEngineData) {
    this.renderThreadsDividers(
      data.threads,
      data.isFavorites ? data.vState.favThreadsTopBottomMap : data.vState.mainThreadsTopBottomMap,
      data.hState.width,
      data.isFavorites ? data.vState.yStartFav : data.vState.yStart,
    )
    const sliceTextsToRender: RenderTextParams[] = []
    for (const thread of data.threads) {
      if (thread.isExpanded) {
        const topBottomMap = data.isFavorites
          ? data.vState.favThreadsTopBottomMap
          : data.vState.mainThreadsTopBottomMap
        const [threadTop] = topBottomMap.get(thread.id)!
        const threadTopY = RenderEngine.heightToPosition(
          threadTop,
          data.isFavorites ? data.vState.yStartFav : data.vState.yStart,
        )
        const maxHeightWithHeader = data.isFavorites
          ? data.vState.favCanvasHeight
          : data.vState.mainCanvasHeight + this.settings.headerHeight

        const threadActiveDepthsFromChain = data.activeDepthsByThreadId.get(thread.id) ?? []
        const threadMaxDepth = data.maxDepthByThreadId.get(thread.id)! - 1
        const threadSliceByDepth = thread.slicesByDepth

        for (let depth = 0; depth <= threadMaxDepth; depth++) {
          if (data.isThreadShrunkModeEnabled) {
            if (
              threadActiveDepthsFromChain.length &&
              !threadActiveDepthsFromChain.includes(depth)
            ) {
              continue
            }
            if (data.isFavorites && !threadActiveDepthsFromChain.length) {
              if (depth > threadMaxDepth) {
                continue
              }
            }
          }
          const slices = threadSliceByDepth.get(depth) ?? []

          for (const slice of slices) {
            const isOutOfXorY =
              RenderEngine.isSliceOutByX(slice, data.hState.xStart, data.hState.xEnd) ||
              this.isSliceOutByY(slice, threadTopY, maxHeightWithHeader)
            if (isOutOfXorY) {
              continue
            }

            let isDimmed = false
            if (data.linkModeSlice != null) {
              if (slice.isCluster) {
                isDimmed = true
              } else {
                isDimmed = Boolean(
                  getConnectionError(
                    data.linkModeSlice,
                    slice,
                    data.psChartStore.sliceById,
                    data.psChartStore.traceAnalyzeStore.sliceLinksBySliceId,
                  ),
                )
              }
            }
            // It is not "else" case because the slice can be dimmed by "link mode" or by "search results" simultaneously
            if (data.psChartStore.searchState.searchResultsSet.size > 0) {
              isDimmed = !data.psChartStore.searchState.searchResultsSet.has(slice.id)
            }

            // dim all slices that are not connected to the links tree if Dim Disconnected Slices is enabled
            if (
              data.psChartStore.traceAnalyzeStore.selectedSlice &&
              data.psChartStore.shouldDimDisconnectedSlices
            ) {
              // if all paths are visible then do not dim non-MEP slices
              if (data.psChartStore.traceAnalyzeStore.shouldShowAllPaths) {
                isDimmed = !data.psChartStore.traceAnalyzeStore.allChainsIds.has(slice.id)
              } else {
                // if only MEP, dim non-MEP slices
                isDimmed = !data.psChartStore.traceAnalyzeStore.mainChainIds.has(slice.id)
              }
            }
            this.prepareForRendering(
              thread,
              threadActiveDepthsFromChain,
              slice,
              data.psChartStore,
              sliceTextsToRender,
              isDimmed,
            )
          }
        }
      }
    }
    this.renderSliceTexts(sliceTextsToRender)
  }

  private prepareForRendering(
    thread: Thread,
    threadActiveDepthsFromChain: number[],
    slice: Slice,
    psChartStore: PsChartStore,
    textsToRender: RenderTextParams[],
    isDimmed: boolean,
  ) {
    const { x, y, w, h } = getSliceVisibleRect(
      thread,
      threadActiveDepthsFromChain,
      slice,
      psChartStore,
    )

    if (w > this.settings.basicRenderer.minLengthForText) {
      textsToRender.push({
        text: getSliceTitleWithArgs(slice),
        x,
        y: y + Math.floor(h / 2),
        width: w,
        textMeasurer: this.textMeasurer,
        active: !isDimmed,
      })
    }

    const palette = psChartStore.chartSettings.renderEngine.palette.slice
    const color = isDimmed ? getSliceDimmedColor(slice.color, palette) : slice.color

    this.renderer.fillRect(x, y, w, h, color)
  }

  private renderSliceTexts(textsToRender: RenderTextParams[]) {
    if (textsToRender.length === 0) {
      return null
    }
    textsToRender.forEach((textParams) => {
      this.renderer.setSliceTextDrawingSettings(
        textParams.active
          ? this.settings.palette.slice.text
          : this.settings.palette.slice.textInactive,
        this.fontStyle,
      )
      this.renderer.drawEllipsizedText(textParams)
    })
  }

  private renderThreadsDividers(
    threads: Thread[],
    topBottomByThreadId: TopBottomByThreadId,
    canvasWidth: number,
    posY: number,
  ) {
    const threadsPath = new Path2D()
    threads.forEach((thread, key) => {
      if (key === threads.length - 1) {
        return null
      }
      const [, threadBottom] = topBottomByThreadId.get(thread.id)!
      threadsPath.rect(
        0,
        RenderEngine.heightToPosition(threadBottom - this.settings.threads.dividerHeight, posY),
        canvasWidth,
        this.settings.threads.dividerHeight,
      )
    })
    this.renderer.fillPath(threadsPath, this.settings.palette.thread.delimiter)
  }

  renderVideoPointer(hState: HorizontalState, vState: VerticalState, videoState: VideoPlayerState) {
    if (videoState.hasFullData) {
      const time = videoState.traceVideoPointerTimeNanos
      if (time >= hState.xEnd) {
        // don't show video pointer if it is out of the view
        return
      } else if (hState.xStart <= time && time <= hState.xEnd) {
        const path = new Path2D()
        let x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
        if (x === hState.width) {
          // To sync with global timeline limiter line
          x = x - 0.5
        }
        path.moveTo(x, 0)
        path.lineTo(x, vState.height)
        const color = this.settings.videoPointer.lineColor
        this.renderer.strokePath(path, color, this.settings.videoPointer.lineWidth)
      }
    }
  }

  private renderFlags(flagsState: FlagsState, hState: HorizontalState, vState: VerticalState) {
    if (flagsState.enabled && !flagsState.showLabels) {
      return
    }

    // Need to sort to put selected flag always higher in z-order when drawing
    const sortedFlags = [...flagsState.flags].sort((a) =>
      a.id === flagsState.selectedFlagId ? 1 : -1,
    )
    sortedFlags.forEach((flag) => {
      /** Could be improved with grouping and stroking by color,
       * but considering small amount of flags, it won't
       * bring any performance boost.
       */
      if (hState.xStart <= flag.time && flag.time <= hState.xEnd) {
        const path = new Path2D()
        const x = RenderEngine.timeToPosition(flag.time, hState.xStart, hState.timePerPx)
        path.moveTo(x, 0)
        path.lineTo(x, vState.height)
        const isSelected = flag.id === flagsState.selectedFlagId
        const color = isSelected
          ? this.settings.flags.selectedColor
          : this.settings.flags.colors[flag.color]
        this.renderer.strokePath(path, color, this.settings.flags.strokeWidth)
      }
    })
  }

  private renderMeasurement(psChartStore: PsChartStore, points: MeasurementPoints) {
    const blockHeight = 14
    const borderRadius = 2
    const arrowWidth = 5
    const arrowHeight = 3
    const textBlockPaddingX = 8
    const textColor = '#000000'

    if (points) {
      const lineWidth = points.endX - points.startX

      if (lineWidth < arrowWidth * 2) {
        return
      }

      const center = points.startX + lineWidth / 2
      const text = nanoToMeasurementString(lineWidth * psChartStore.hState.timePerPx)
      const textWidth = this.measurementLineTextMeasurer.getTextWidth(text)

      const linePath = new Path2D()
      linePath.moveTo(points.startX, points.startY)
      linePath.lineTo(points.endX, points.startY)
      this.renderer.strokePath(
        linePath,
        this.settings.measurementColor,
        this.settings.measurementStrokeWidth,
      )

      const leftArrowPath = new Path2D()
      leftArrowPath.moveTo(points.startX, points.startY)
      leftArrowPath.lineTo(points.startX + arrowWidth, points.startY - arrowHeight)
      leftArrowPath.lineTo(points.startX + arrowWidth, points.startY + arrowHeight)
      this.renderer.fillPath(leftArrowPath, this.settings.measurementColor)

      const rightArrowPath = new Path2D()
      rightArrowPath.moveTo(points.endX, points.startY)
      rightArrowPath.lineTo(points.endX - arrowWidth, points.startY - arrowHeight)
      rightArrowPath.lineTo(points.endX - arrowWidth, points.startY + arrowHeight)
      this.renderer.fillPath(rightArrowPath, this.settings.measurementColor)

      if (lineWidth > textWidth + textBlockPaddingX * 2 + arrowWidth * 2) {
        const blockWidth = textWidth + textBlockPaddingX * 2
        const blockX = center - blockWidth / 2
        const blockY = points.startY - blockHeight / 2
        const blockPath = new Path2D()

        blockPath.moveTo(blockX + borderRadius, blockY)
        blockPath.lineTo(blockX + blockWidth - borderRadius, blockY)
        blockPath.quadraticCurveTo(
          blockX + blockWidth,
          blockY,
          blockX + blockWidth,
          blockY + borderRadius,
        )
        blockPath.lineTo(blockX + blockWidth, blockY + blockHeight - borderRadius)
        blockPath.quadraticCurveTo(
          blockX + blockWidth,
          blockY + blockHeight,
          blockX + blockWidth - borderRadius,
          blockY + blockHeight,
        )
        blockPath.lineTo(blockX + borderRadius, blockY + blockHeight)
        blockPath.quadraticCurveTo(
          blockX,
          blockY + blockHeight,
          blockX,
          blockY + blockHeight - borderRadius,
        )
        blockPath.lineTo(blockX, blockY + borderRadius)
        blockPath.quadraticCurveTo(blockX, blockY, blockX + borderRadius, blockY)
        this.renderer.fillPath(blockPath, this.settings.measurementColor)
        this.renderer.setSliceTextDrawingSettings(textColor, this.measurementFontStyle)
        this.renderer.drawEllipsizedText({
          text,
          width: blockWidth,
          x: blockX,
          y: blockY + blockHeight / 2 - 1,
          textMeasurer: this.measurementLineTextMeasurer,
          active: true,
        })
      }
    }
  }

  private renderGridLines(
    gridLines: number[],
    isFavorites: boolean,
    hState: HorizontalState,
    vState: VerticalState,
  ) {
    const path = new Path2D()
    const maxHeightWithHeader = isFavorites
      ? vState.favCanvasHeight
      : vState.mainCanvasHeight + this.settings.headerHeight
    gridLines.forEach((time) => {
      const x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
      path.moveTo(x, 0)
      path.lineTo(x, maxHeightWithHeader)
    })
    this.renderer.strokePath(
      path,
      this.settings.palette.timeline.delimiterLine,
      this.settings.commonTimeline.scaleWidth,
    )
  }

  private renderHover(
    flagsState: FlagsState,
    videoState: VideoPlayerState,
    hState: HorizontalState,
    vState: VerticalState,
  ) {
    const hoverTime = flagsState.hoverTime ? flagsState.hoverTime : videoState.hoverTime
    if (hoverTime && hState.xStart <= hoverTime && hoverTime <= hState.xEnd) {
      const path = new Path2D()
      const x = RenderEngine.timeToPosition(hoverTime, hState.xStart, hState.timePerPx)
      path.moveTo(x, 0)
      path.lineTo(x, vState.height)

      const { flags, ghostIndicator } = this.settings

      if (videoState.hoverTime) {
        this.renderer.strokePath(path, ghostIndicator.color, ghostIndicator.strokeWidth)
      } else if (flagsState.enabled) {
        this.renderer.strokePath(path, flags.hoverFlagColor, flags.strokeWidth)
      }
    }
  }

  private renderAnnotationsConnections(
    annotationsState: AnnotationsState,
    psChartStore: PsChartStore,
  ) {
    if (!annotationsState.featureState.enabled) {
      return
    }

    annotationsState.annotationsWithConnectedSlices.forEach((annotation) => {
      const actionBinding = annotation.action.binding
      if (actionBinding !== undefined && actionBinding.sliceId !== undefined) {
        const hasDelay = AnnotationsStore.hasDelay(annotation.delay)
        this.renderPinBinding(hasDelay, actionBinding, annotationsState, psChartStore)
      }
      const reactionBinding = annotation.reaction.binding
      if (reactionBinding !== undefined && reactionBinding.sliceId !== undefined) {
        const hasDelay = AnnotationsStore.hasDelay(annotation.delay)
        this.renderPinBinding(hasDelay, reactionBinding, annotationsState, psChartStore)
      }
    })
  }

  private renderPinBinding(
    hasDelay: boolean,
    pinBinding: AnnotationPinBindingDto,
    state: AnnotationsState,
    psChartStore: PsChartStore,
  ) {
    const active = state.selectedPinBinding === pinBinding || state.hoveredPinBinding === pinBinding
    const sliceId = pinBinding.sliceId!
    const time = pinBinding.time
    this.renderAnnotationToSliceConnection(psChartStore, active, hasDelay, sliceId, time)
  }

  private renderAnnotationToSliceConnection(
    psChartStore: PsChartStore,
    active: boolean,
    hasDelay: boolean,
    sliceId: number,
    time: number,
  ) {
    const { hState } = psChartStore
    const { annotation } = this.settings

    const slice = psChartStore.sliceById.get(sliceId)!
    const thread = psChartStore.traceDataState.threadsById.get(slice.threadId)!
    const threadActiveDepths =
      psChartStore.traceAnalyzeStore.activeThreadsDepthsFromChain.get(slice.threadId) ?? []

    const baseColor = hasDelay ? annotation.delayColor : annotation.pinColor
    const activeColor = hasDelay ? annotation.delayColor : annotation.pinHoveredColor

    const x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
    const { y } = getSliceVisibleRect(thread, threadActiveDepths, slice, psChartStore)
    let top = y + this.settings.headerHeight + 0.5 * this.settings.threads.blockHeight
    if (top < this.settings.headerHeight) {
      top = this.settings.headerHeight
    }

    const circle = new Path2D()
    const dotSize = active ? annotation.connectionActiveDotSize : annotation.connectionDotSize
    circle.ellipse(x, top, dotSize, dotSize, 0, 0, 360)
    this.renderer.fillRect(
      x - annotation.strokeWidth / 2,
      0,
      annotation.strokeWidth,
      top,
      active ? activeColor : baseColor,
    )
    this.renderer.fillPath(circle, annotation.pinColor)
    if (active) {
      const activeCircle = new Path2D()
      activeCircle.ellipse(
        x,
        top,
        annotation.connectionActiveDotSize,
        annotation.connectionActiveDotSize,
        0,
        0,
        360,
      )
      this.renderer.strokePath(activeCircle, activeColor, annotation.strokeWidth)
    }
  }
}
