import React, { useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { ApiError, AuthenticatedUserDto, ProjectRole, ProjectSummaryItem } from 'api/models'
import { Userpic } from 'components/Userpic'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { getFlowName } from 'utils/getFlowName'
import { Icon } from 'components/Icon'
import { getOsName } from 'utils/getOsName'

import { PATH_PROJECT_CONTRIBUTORS } from 'pages/ProjectContributorsPage'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { PATH_FLOW } from 'pages/FlowPage'
import { queryKeys, useUserQuery } from 'hooks/useApiQuery'
import { ProjectSettings } from './ProjectSettings'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'

interface ProjectCardProps extends ProjectSummaryItem {
  isShowTeamList?: boolean
  isUserFavorite?: boolean
  isSuperAdminOrTeamAdmin: boolean
}

export const ProjectCard = ({
  project,
  flows,
  numUnassignedTraces,
  isShowTeamList,
  isUserFavorite,
  isSuperAdminOrTeamAdmin,
}: ProjectCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: user } = useUserQuery()
  const api = useApi()
  const queryClient = useQueryClient()

  const postProjectFavoriteMutation = useMutation(
    () => api.postProjectFavorite({ id: project.id }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<AuthenticatedUserDto | undefined>(queryKeys.user, (oldData) => {
          if (oldData) {
            return { ...oldData, settings: data }
          }
        })
      },
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const deleteProjectFavoriteMutation = useMutation(
    () => api.deleteProjectFavorite({ id: project.id }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<AuthenticatedUserDto | undefined>(queryKeys.user, (oldData) => {
          if (oldData) {
            return { ...oldData, settings: data }
          }
        })
      },
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const [isFavorite, setFavorite] = useState(isUserFavorite)

  const handleFavoriteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setFavorite(!isFavorite)
    if (isUserFavorite) {
      deleteProjectFavoriteMutation.mutate()
    } else {
      postProjectFavoriteMutation.mutate()
    }
  }

  const isSuperOrTeamOrProjectAdmin =
    isSuperAdminOrTeamAdmin || user?.roles.projects[Number(project.id)] === ProjectRole.ADMIN

  const handleProjectContributorsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(generatePath(PATH_PROJECT_CONTRIBUTORS, { projectUrlName: project.urlName }))
  }

  const handleFlowClick =
    (flowProjectLocalId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      navigate(generatePath(PATH_FLOW, { projectUrlName: project.urlName, flowProjectLocalId }))
    }

  return (
    <Link
      to={generatePath(PATH_FLOWS, { projectUrlName: project.urlName })}
      className="group flex flex-col justify-between min-h-[220px] p-[16px] pb-[8px] rounded-[8px] bg-dark-dark2 hover:bg-dark-dark1 transition-colors"
      data-tid="project-card"
    >
      <div className="flex items-center mb-[22px]">
        <div className="flex items-center flex-1 pr-[20px]">
          {project.image && (
            <img
              src={project.image?.tq.url}
              alt=""
              aria-hidden="true"
              className="w-[28px] h-[28px] mr-[8px] rounded-[7px] object-cover"
            />
          )}
          <div className="text-big tracking-wide font-medium">
            <span className="mr-[4px]">{project.name}</span>
            <span className="mr-[5px] text-gray-normal">{getOsName(project.os)}</span>
            <Icon
              className={classNames(
                'relative top-[-1px] inline-block align-middle text-icon transition focus-visible:opacity-100',
                isFavorite
                  ? 'text-electro hover:text-sky'
                  : 'opacity-0 group-hover:opacity-100 text-gray-normal hover:text-white',
              )}
              as="button"
              icon={isFavorite ? 'favorite' : 'favorite-outline'}
              aria-label={
                isFavorite
                  ? t('projects.projectCard.deleteFavoritesAriaLabel')
                  : t('projects.projectCard.addFavoritesAriaLabel')
              }
              onClick={handleFavoriteClick}
            />
          </div>
        </div>
        {isSuperAdminOrTeamAdmin && user && (
          <ProjectSettings className="mr-[8px]" user={user} project={project} isProjectCard />
        )}
        {isSuperOrTeamOrProjectAdmin && (
          <Button
            onClick={handleProjectContributorsClick}
            variant={ButtonVariantEnum.Text}
            textColorVariant={ButtonTextColorVariantEnum.Muted}
            withIcon
          >
            {t('projects.projectCard.projectContributors')} <ButtonIcon icon="user" />
          </Button>
        )}
      </div>
      <div className="flex items-end">
        <div className="w-[264px] pr-[20px] pb-[9px] text-gray-normal text-small tracking-wide">
          {t('projects.projectCard.flows', { count: flows.length })}{' '}
          {numUnassignedTraces > 0 && (
            <span className="ml-[4px] text-state-attention">
              {t('projects.projectCard.unassignedTraces', { count: numUnassignedTraces })}
            </span>
          )}
        </div>
        <div className="flex flex-1 gap-x-[8px] mr-[-8px]">
          {flows.slice(0, 5).map((item) => (
            <FlowItem
              onClick={handleFlowClick(String(item.projectLocalId))}
              isShowTeamList={isShowTeamList}
              data-tid="project-card-flow-card"
              key={item.projectLocalId}
            >
              <div className="text-normal tracking-wide font-medium text-white/90 mb-[20px]">
                {getFlowName(item.name, item.projectLocalId)}
              </div>
              <Userpic {...item.author} />
            </FlowItem>
          ))}
        </div>
      </div>
    </Link>
  )
}

const FlowItem = styled.button<{ isShowTeamList?: boolean }>`
  ${tw`
      flex flex-col justify-between
      w-1/3 min-h-[144px] p-[12px 16px 16px]
      border border-white/[.06] rounded-sm
      text-left transition-colors
      hover:(border-transparent bg-white/[.05])
      down-1680:[&:nth-child(1n+4)]:hidden
      1680:w-1/4
      down-1920:[&:nth-child(1n+5)]:hidden
      1920:w-1/5
    `}

  ${({ isShowTeamList }) => isShowTeamList && tw`down-1280:(w-1/2 [&:nth-child(1n+3)]:hidden)`}
`
