import React, { DragEvent, useCallback, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Icon } from 'components/Icon'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { useController } from 'react-hook-form'
import { FieldPath, FieldValues } from 'react-hook-form/dist/types'

export function FileField<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  fieldName,
  disabled = false,
  className,
}: {
  fieldName: TName
  disabled: boolean
  className?: string
}) {
  const { t } = useTranslation()
  const [drag, setDrag] = useState(false)
  const dragCounterRef = useRef<number>(0)
  const { field } = useController<TFieldValues, TName>({ name: fieldName })
  const isFileEmpty = field.value == null

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (isFileEmpty) {
      dragCounterRef.current++
      if (event.dataTransfer.items.length > 0) {
        setDrag(true)
      }
    }
  }

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (isFileEmpty) {
      dragCounterRef.current--
      if (dragCounterRef.current === 0) {
        setDrag(false)
      }
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (isFileEmpty) {
      const droppedFile = event.dataTransfer.files[0]
      if (droppedFile) {
        field.onChange(droppedFile)
      }
      setDrag(false)
      dragCounterRef.current = 0
      event.dataTransfer.clearData()
    }
  }

  const onDelete = useCallback(() => {
    field.onChange(null)
  }, [field])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => field.onChange((event.target.files ?? [])[0]),
    [field],
  )

  return (
    <div
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={classNames(
        'bg-gray-inputBG h-[104px] flex items-center justify-center rounded-sm border-dashed',
        isFileEmpty ? 'h-[152px]' : 'border-[2px]',
        drag ? 'border-electro' : 'border-gray-faded',
        className,
      )}
    >
      {field.value == null ? (
        <div className="flex items-center justify-center">
          <Icon className="text-[32px] text-gray-normal mr-[8px]" icon="upload" />
          <div className="text-small tracking-wide text-gray-normal">
            <Trans
              i18nKey="uploader.dropHereOr"
              components={{
                choose: (
                  <label className="cursor-pointer transition-colors text-electro hover:text-sky" />
                ),
                input: (
                  <input
                    name={field.name}
                    type="file"
                    accept="application/vnd.android.package-archive"
                    ref={field.ref}
                    hidden={true}
                    onChange={onChange}
                    disabled={disabled}
                  />
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col">
          <Icon icon="check" className="text-[32px] mb-[-5px]" />
          <span className="mb-[4px] text-small font-medium text-white">
            {t('uploader.uploaded', { fileName: field.value.name })}
          </span>
          <Button
            withIcon
            variant={ButtonVariantEnum.Text}
            textColorVariant={ButtonTextColorVariantEnum.Muted}
            onClick={onDelete}
            disabled={disabled}
          >
            {t('uploader.delete')}
          </Button>
        </div>
      )}
    </div>
  )
}
