import { useEffect, useState } from 'react'
import { isNodeEnvDev } from 'utils/env'
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react'
import { usePostHogContext } from 'utils/posthog'
import { devOverride, UseFeatureFlag } from 'utils/feature-flags/constants'

const FLAGS_REQUEST_TIMEOUT_MS = 10 * 1000

/**
 * Check if the feature flag is enabled.
 *
 * ```javascript
 * import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'
 * ...
 *
 * export const MyComponent = () => {
 *   const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)
 *   ...
 *   return <div>Annotations enabled: {annotationsEnabled ? 'Yeah' : 'Nope'}</div>
 * }
 * ```
 *
 * You can locally override some feature flags on the dev environment. You need to
 * add override in the `devOverride` object in `utils/feature-flags/constants.ts`
 */
// @ts-expect-error
export const useFeatureFlag: UseFeatureFlag = (flag) => {
  const { isError } = usePostHogContext()
  const [isTimeout, setIsTimeout] = useState(false)
  const flagValue = useFeatureFlagEnabled(flag)
  const flagPayload = useFeatureFlagPayload(flag)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    if (flagValue !== undefined) {
      setIsTimeout(false)
    } else if (!isError && !isTimeout) {
      timeoutId = setTimeout(() => {
        setIsTimeout(true)
        throw new Error(`PostHog timeout for '${flag}' flag`)
      }, FLAGS_REQUEST_TIMEOUT_MS)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isError, flagValue, isTimeout, flag])

  if (isError || isTimeout) {
    // If we fail to load the feature flags, then the flag is false by default
    return false
  }

  // Return dev override only if the flag has some value, i.e. it was fetched and passed the `undefined`
  // state. This is needed to fully mimic the actual flag behavior.
  if (isNodeEnvDev() && devOverride[flag] !== undefined && flagValue !== undefined) {
    return devOverride[flag]
  }

  return flagPayload || flagValue
}
