import { HavingTroubles } from 'components/guide/HavingTroubles'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { AppInstrumentStatus } from 'api/models'

export const AppBuildError = observer(function AppBuildError() {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  if (guideStore.appBuildAndRunStatus?.buildStatus?.status === AppInstrumentStatus.FAILED) {
    return (
      <HavingTroubles>
        <p className="mb-[5px] mt-[24px] text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%]">
          {t('guidePage.buildError')}
        </p>
        <p className="mb-[24px] text-[14px] font-[400] leading-[21px] tracking-[2%] text-state-bad">
          {guideStore.appBuildAndRunStatus?.buildStatus?.message ??
            t('guidePage.defaultBuildError')}
        </p>
        <p className="text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%] mb-[5px]">
          {t('guidePage.toTroubleShoot')}
        </p>
        <p className="text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%]">
          {t('guidePage.otherQuestions')}
        </p>
      </HavingTroubles>
    )
  }
  return null
})
