import React from 'react'
import classNames from 'classnames'

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClassName?: string
  inModal?: boolean
  children?: React.ReactNode
  error?: string
  showCounter?: boolean
  ref?: React.Ref<HTMLInputElement>
}

export const Input = React.forwardRef(
  (
    {
      containerClassName,
      className,
      inModal,
      children,
      error,
      showCounter = true,
      ...props
    }: InputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={classNames('mb-4', inModal && 'w-[320px]', containerClassName)}>
        <div className="relative">
          <input
            {...props}
            ref={ref}
            className={classNames(
              'peer w-full h-[32px] px-[16px] py-0 text-small tracking-wide bg-dark-dark1 border-0 placeholder:text-gray-normal text-white focus:ring-0 focus-visible:outline-0',
              props.maxLength && 'pr-[40px]',
              className,
            )}
          />
          <div className="absolute bottom-0 inset-x-[12px] h-px transition-colors bg-transparent peer-focus:bg-electro" />
          {props.maxLength && typeof props.value === 'string' && showCounter && (
            <div
              className={classNames(
                'absolute top-1/2 -translate-y-1/2 right-[8px] text-small text-gray-faded',
                props.maxLength - props.value.length === 0 && 'text-state-bad',
              )}
            >
              {props.maxLength - props.value.length}
            </div>
          )}
          {children}
        </div>
        {error && <div className="text-small mt-[4px] text-state-bad">{error}</div>}
      </div>
    )
  },
)
