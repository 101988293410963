export interface MarkdownConfig {
  headerLevelsToCollapse: HeaderLevel[]
  styles: MdStyles
}

export type HeaderLevel = 1 | 2 | 3 | 4 | 5 | 6

export const MD_STYLES_DEFAULT: MdStyles = {
  header: {
    h1: {
      marginClassName: 'mt-8 mb-6',
      otherClassName: 'text-header-normal font-medium text-white',
    },
    h2: {
      marginClassName: 'mt-6 mb-4',
      otherClassName: 'text-header-small font-medium text-white',
    },
    h3: {
      marginClassName: 'mt-4 mb-4',
      otherClassName: 'text-header-tiny font-medium text-white leading-[26px]',
    },
    h4: {
      marginClassName: 'my-4',
      otherClassName: 'text-big text-white',
    },
    h5: {
      marginClassName: 'my-4',
      otherClassName: 'text-normal text-white',
    },
    h6: {
      marginClassName: 'my-4',
      otherClassName: 'text-normal',
    },
  },
  thematicBreak: {
    className: 'my-8',
  },
  para: {
    className: 'mb-4 text-[14px] font-[400] leading-[150%] text-gray-normal my-[16px]',
  },
  anchor: {
    className: 'text-electro',
  },
  code: {
    wrapperClassName: 'mb-4 p-4 bg-dark-dark1 rounded text-normal text-white',
    titleClassName: 'text-gray-normal mb-4',
    iconClassName: 'text-gray-normal hover:text-white text-icon-small hover:cursor-pointer',
  },
  inlineCode: {
    className: 'bg-dark-dark2 rounded text-white px-1',
  },
  list: {
    className: 'mb-4 pl-2',
  },
  listItem: {
    className: 'mb-2',
  },
  image: {
    className: 'my-4 m-auto',
    imageWrapper: {
      className: 'w-full',
    },
  },
}

export interface MdStyles {
  header: {
    h1: MdHeaderStyle
    h2: MdHeaderStyle
    h3: MdHeaderStyle
    h4: MdHeaderStyle
    h5: MdHeaderStyle
    h6: MdHeaderStyle
  }
  para: {
    className: string
  }
  anchor: {
    className: string
  }
  list: {
    className: string
  }
  listItem: {
    className: string
  }
  thematicBreak: {
    className: string
  }
  image: {
    className: string
    imageWrapper?: {
      className?: string
    }
  }
  code: MdCodeStyle
  inlineCode: {
    className: string
  }
}

export interface MdHeaderStyle {
  marginClassName: string
  otherClassName: string
}

export interface MdCodeStyle {
  wrapperClassName: string
  titleClassName: string
  iconClassName: string
}

export function headerStylesByLevel(styles: MdStyles, level: HeaderLevel): MdHeaderStyle {
  switch (level) {
    case 1:
      return styles.header.h1
      break
    case 2:
      return styles.header.h2
      break
    case 3:
      return styles.header.h3
      break
    case 4:
      return styles.header.h4
      break
    case 5:
      return styles.header.h5
      break
    case 6:
      return styles.header.h6
      break
  }
}
