import { generatePath } from 'react-router-dom'
import { makeAutoObservable, runInAction } from 'mobx'
import { t } from 'i18next'

import { SelectOption } from 'components/Select'
import { RA_RECORDS } from 'api/contentful'
import { fetchContentfulEntry } from 'hooks/useContentfulEntry'
import { TypeRegressionAnalysisData } from 'hooks/__generated__/contentful'
import { PATH_CHART } from 'pages/PsChartPage'

import { chartSettings } from './commits-chart/CommitsChartSettings'
import { ChartScale, DelayInfo, DelayRec, RADataDto } from './data-models'
import { HostType, getHostType } from 'utils/getHostType'

export enum RaSortType {
  CHRONOLOGICAL,
  DIFF_ABS,
}

export class RAStore {
  private raw: RADataDto | null = null
  error: string | null = null
  sortType: RaSortType = RaSortType.CHRONOLOGICAL
  isSortReversed = false

  get delaysList(): DelayRec[] {
    if (this.raw == null) {
      return []
    }
    const delaysList: DelayRec[] = []

    this.raw.commonSlices.forEach((upRawRec, upRawRecID) => {
      const upLeftDur = upRawRec.duration[0]
      const upRightDur = upRawRec.duration[1]
      const upDurDiffMs = upRightDur - upLeftDur
      delaysList.push({
        titles: [upRawRec.title],
        srcSliceIds: [upRawRec.id[1]],
        durations: [...upRawRec.duration],
        diff: {
          abs: upDurDiffMs,
          rel: Math.round((upDurDiffMs * 100) / upLeftDur),
        },
      })
      if (
        upRawRec.delayBetweenPrevious.length === 0 ||
        upRawRecID === this.raw!.commonSlices.length - 1
      ) {
        return
      }
      const downRawRecID = upRawRecID + 1
      const downRawRec = this.raw!.commonSlices[downRawRecID]
      const leftDelay = upRawRec.delayBetweenPrevious[0]
      const rightDelay = upRawRec.delayBetweenPrevious[1]
      const delayDiffMs = rightDelay - leftDelay
      delaysList.push({
        titles: [upRawRec.title, downRawRec.title],
        srcSliceIds: [upRawRec.id[1], downRawRec.id[1]],
        durations: [...upRawRec.delayBetweenPrevious],
        diff: {
          abs: delayDiffMs,
          rel: Math.round((delayDiffMs * 100) / leftDelay),
        },
      })
    })

    return RAStore.sortRecords(delaysList, this.sortType, this.isSortReversed)
  }

  get sourcePath() {
    if (this.raw == null) {
      return null
    }
    const source = this.raw.sources[1]

    return generatePath(PATH_CHART, {
      projectUrlName: source.project,
      flowProjectLocalId: source.flow,
      traceProjectLocalId: source.trace,
    })
  }

  changeSortType(sortType: RaSortType) {
    if (sortType === this.sortType) {
      this.isSortReversed = !this.isSortReversed
    } else {
      this.sortType = sortType
      this.isSortReversed = false
    }
  }

  get delaySum(): DelayInfo | null {
    if (this.delaysList.length === 0) {
      return null
    }

    const leftDur = this.delaysList.reduce((sum, el) => sum + el.durations[0], 0)
    const rightDur = this.delaysList.reduce((sum, el) => sum + el.durations[1], 0)
    const durDiffMs = this.delaysList.reduce((sum, el) => sum + el.diff.abs, 0)

    return {
      durations: [leftDur, rightDur],
      diff: {
        abs: durDiffMs,
        rel: Math.round((durDiffMs * 100) / leftDur),
      },
    }
  }

  get commitsOptions(): SelectOption[] {
    if (this.raw == null) {
      return []
    }

    return this.raw.commits.map((commitTitle) => ({
      value: commitTitle,
      label: commitTitle,
    }))
  }

  get chartScale(): ChartScale | null {
    if (this.delaySum == null) {
      return null
    }
    const maxValue = Math.max(...this.delaySum.durations)
    const minValue = Math.min(...this.delaySum.durations)
    const mainDiff = maxValue - minValue
    const rawOptimalStep = Math.round(mainDiff / chartSettings.dispersionMultiplayer)
    const optimalStepNumberLength = rawOptimalStep.toString().length
    const optimalStepZeros = Math.pow(10, optimalStepNumberLength - 1)
    const scaleStep = Math.max(
      Math.round(rawOptimalStep / optimalStepZeros) * optimalStepZeros,
      chartSettings.minScaleStep,
    )
    const maxDuration = Math.ceil(maxValue / optimalStepZeros) * optimalStepZeros
    return {
      maxDuration,
      scaleStep,
    }
  }

  constructor() {
    makeAutoObservable(this)
  }

  fetch() {
    const isProd = getHostType() === HostType.PROD
    const dataKey = isProd ? RA_RECORDS.prod : RA_RECORDS.test
    fetchContentfulEntry(dataKey)()
      .then((docData: TypeRegressionAnalysisData) => {
        runInAction(() => {
          this.raw = docData.fields.data
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.error = t('ra.loadingError')
        })
      })
  }

  clean() {
    this.raw = null
  }

  private static sortRecords(recList: DelayRec[], sortType: RaSortType, isSortReversed: boolean) {
    switch (sortType) {
      case RaSortType.DIFF_ABS:
        if (isSortReversed) {
          return recList.sort((recA, recB) => recB.diff.abs - recA.diff.abs)
        }
        return recList.sort((recA, recB) => recA.diff.abs - recB.diff.abs)
      case RaSortType.CHRONOLOGICAL:
      default:
        if (isSortReversed) {
          return recList.reverse()
        }
        return recList
    }
  }
}
