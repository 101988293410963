import React from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'components/tooltip/Tooltip'
import { PlainActionsTooltip } from 'components/ActionTooltip'

import { PsChartStore } from 'components/ps-chart/PsChartStore'

export const FlameTooltip = observer(function FlameTooltip({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const tooltip = psChartStore.tooltip

  if (!tooltip) {
    return null
  }

  return (
    <Tooltip
      animated={tooltip.settings?.animated}
      offsetY={20}
      childrenRect={{
        top: tooltip.point.y,
        right: tooltip.point.x,
        left: tooltip.point.x,
        bottom: tooltip.point.y,
      }}
      content={<PlainActionsTooltip title={tooltip.title} />}
      visible={tooltip.visible}
      {...tooltip.settings}
    />
  )
})
