import { OBJECT_ID_EMPTY_VALUE, Slice } from '../models/Slice'
import { Thread } from '../models/Thread'
import { SliceDto, ThreadDto, TraceDataDto, UtilityThreadDto } from 'api/models'
import { SlicePalette } from 'components/ps-chart/models/settings'
import { getAsyncSliceColor } from 'components/ps-chart/utils/getSliceDimmedColor'

interface ParseResult {
  depth: number
  slices: Slice[]
}

type SliceFreqByTitle = Map<string, number>
type SliceColorsByTitle = Map<string, string>

const isValidThread = (thread?: ThreadDto | UtilityThreadDto): thread is ThreadDto => {
  return !!thread?.slices?.length
}

export function parseSlicesToThreadsFromApi(
  traceData: TraceDataDto,
  palette: SlicePalette,
): Thread[] {
  const threads: ThreadDto[] = [...traceData.threads]
  if (traceData.utilityThreads && isValidThread(traceData.utilityThreads.frames)) {
    threads.splice(1, 0, traceData.utilityThreads.frames)
  }

  const sliceFreq: SliceFreqByTitle = calcColorFreq(threads)
  const sliceColors: SliceColorsByTitle = calcSliceColors(sliceFreq, palette)

  return threads.map((thread) => {
    const { slices, depth } = toSlicesFromApi(
      thread.slices,
      0,
      sliceColors,
      thread.id,
      thread.isAsync,
      palette,
      null,
      null,
    )
    return new Thread(thread.id, thread.name, slices, depth, thread.isAsync)
  })
}

function calcSliceColors(
  sliceFreq: SliceFreqByTitle,
  slicePalette: SlicePalette,
): SliceColorsByTitle {
  const result: SliceColorsByTitle = new Map()
  const entriesArr = [...sliceFreq.entries()]
  const threshold = slicePalette.frequentColors.length
  for (let i = 0; i < threshold && i < entriesArr.length; i++) {
    const sliceTitle = entriesArr[i][0]
    const color = slicePalette.frequentColors[i % threshold]
    result.set(sliceTitle, color)
  }
  for (let i = threshold; i < entriesArr.length; i++) {
    const sliceTitle = entriesArr[i][0]
    const color = slicePalette.normalColors[(i - threshold) % slicePalette.normalColors.length]
    result.set(sliceTitle, color)
  }
  return result
}

function calcColorFreq(threads: ThreadDto[]): SliceFreqByTitle {
  const notSorted: SliceFreqByTitle = new Map()
  walkSlicesDto(threads, (slice) => {
    const count = notSorted.get(slice.name) ?? 0
    notSorted.set(slice.name, count + 1)
  })
  // noinspection UnnecessaryLocalVariableJS
  const sorted = new Map([...notSorted.entries()].sort((a, b) => b[1] - a[1]))
  return sorted
}

function toSlicesFromApi(
  apiSlices: SliceDto[],
  level: number,
  sliceColors: SliceColorsByTitle,
  threadId: number,
  isAsync: boolean,
  palette: SlicePalette,
  parent: Slice | null,
  root: Slice | null,
  parentPositionIndex?: number,
): ParseResult {
  const slices: Slice[] = []
  let depth = level + 1
  apiSlices.forEach((sliceDto, positionIndex) => {
    const rootPositionIndex = parentPositionIndex ?? positionIndex
    const initialColor = sliceColors.get(sliceDto.name)!
    const color = isAsync ? getAsyncSliceColor(initialColor, level) : initialColor
    const slice: Slice = {
      id: sliceDto.id,
      title: sliceDto.name.trim(),
      start: sliceDto.start,
      end: sliceDto.end,
      color: color,
      level: level,
      closureId: isSliceFrame(sliceDto.name) ? null : sliceDto.closureId,
      threadId: threadId,
      objectId: sliceDto.objectId ?? OBJECT_ID_EMPTY_VALUE,
      rootPositionIndex,
      args: sliceDto.arguments ?? [],
      children: [],
      extra: sliceDto.extra,
      root: root ?? null,
      parent: parent ?? null,
    }
    if (slice.start > slice.end) {
      console.warn('Illegal slice size', slice)
    }
    if (sliceDto.children && sliceDto.children.length > 0) {
      const parsed = toSlicesFromApi(
        sliceDto.children,
        level + 1,
        sliceColors,
        threadId,
        isAsync,
        palette,
        slice,
        root ?? slice,
        rootPositionIndex,
      )
      slice.children = parsed.slices
      depth = Math.max(parsed.depth, depth)
    }
    slices.push(slice)
  })
  return { slices, depth }
}

function walkSlicesDto(
  threads: ThreadDto[],
  callback: (slice: SliceDto, thread: ThreadDto, level: number, parentId: number | null) => void,
) {
  threads.map((apiThread) => {
    const walkOverSlices = (slices: SliceDto[], level: number, parentId: number | null) => {
      for (const slice of slices) {
        callback(slice, apiThread, level, parentId)

        if (slice.children && slice.children.length > 0) {
          walkOverSlices(slice.children, level + 1, slice.id)
        }
      }
    }

    walkOverSlices(apiThread.slices, 0, null)
  })
}

const isSliceFrame = (sliceTitle: string) => /^FrameCounter.+doFrame$/.test(sliceTitle)
