import { Api } from 'api/Api'
import { QueryClient } from 'react-query'
import { Analytics, AnalyticsImpl } from 'utils/analytics'
import axios, { Axios } from 'axios'
import { ExceptionHandler } from 'utils/ExceptionHandler'
import { getServerHost } from 'utils/getServerHost'
import { getHostType, HostType } from 'utils/getHostType'

export interface CompositionRoot {
  api: Api
  queryClient: QueryClient
  axios: Axios
  isProdOrBeta: boolean
  analytics: Analytics
  exceptionHandler: ExceptionHandler
  subscribeToAxiosError: (listener: (error: unknown) => void) => void
}

export class CompositionRootImpl implements CompositionRoot {
  private _axios?: Axios

  private _api?: Api

  private _queryClient?: QueryClient

  private _analytics?: Analytics

  private onAxiosError: (error: unknown) => void

  readonly exceptionHandler: ExceptionHandler

  constructor() {
    this.onAxiosError = () => {}
    this.exceptionHandler = new ExceptionHandler()
  }

  subscribeToAxiosError = (listener: (error: unknown) => void) => {
    this.onAxiosError = listener
  }

  get isProdOrBeta(): boolean {
    const hostType = getHostType()
    return hostType === HostType.PROD || hostType === HostType.BETA
  }

  get axios(): Axios {
    if (!this._axios) {
      this._axios = axios.create({
        withCredentials: true,
      })
      this._axios.interceptors.response.use(
        (response) => response,
        (error) => {
          this.onAxiosError(error)
          return Promise.reject(error)
        },
      )
    }
    return this._axios
  }

  get api(): Api {
    if (!this._api) {
      const apiHostWithScheme = 'https://' + getServerHost()
      this._api = new Api(apiHostWithScheme, this.axios)
    }
    return this._api
  }

  get queryClient(): QueryClient {
    if (!this._queryClient) {
      this._queryClient = new QueryClient()
    }
    return this._queryClient
  }

  get analytics(): Analytics {
    if (!this._analytics) {
      this._analytics = new AnalyticsImpl()
    }
    return this._analytics
  }
}
