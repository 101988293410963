import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FieldErrors } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import NProgress from 'nprogress'

import { Modal } from 'components/Modal'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { Icon } from 'components/Icon'
import { FileField } from 'components/FileField'
import { useToaster } from 'hooks/useToaster'
import { ApkSupportIssueReq, SupportIssueCategory } from 'api/models'
import { useApi } from 'contexts/di-context'
import { showGotItToaster } from 'components/ToastGotIt'
import { Select } from 'components/Select'
import { Input } from 'components/Input'
import { Textarea } from 'components/Textarea'

const categoryOptions: SupportIssueCategory[] = Object.values(SupportIssueCategory)

interface Props {
  isVisible: boolean
  onClose: () => void
}

export const CustomerSupportModal = ({ isVisible, onClose }: Props) => {
  const toaster = useToaster()
  const { projectUrlName } = useParams()
  const [isEmptyApkAllowed, setIsEmptyApkAllowed] = useState(false)
  const formContext = useForm<ApkSupportIssueReq>({
    validationSchema: customerSupportFormSchema,
    formProps: {
      defaultValues: {
        subject: '',
        description: '',
      },
    },
  })
  const category = formContext.watch('category')

  const { t } = useTranslation()
  const api = useApi()
  const [isInProgress, setIsInProgress] = useState(false)

  const handleSubmitSuccess = useCallback(
    async (data: ApkSupportIssueReq) => {
      if (shouldShowApkSection(data.category) && data.apkFile == null && !isEmptyApkAllowed) {
        toaster.info('customerSupport.errors.apkRequired')
        setIsEmptyApkAllowed(true)
        return
      }
      try {
        const reqData = projectUrlName != null ? { ...data, projectName: projectUrlName } : data
        NProgress.start()
        setIsInProgress(true)
        const res = await api.postSupportIssue(reqData)
        console.info(`The support ticket id: ${res.id} has been created`)
        formContext.reset()
        onClose()
        showGotItToaster(t('customerSupport.submitted'), 'customerSupport.submitted')
      } catch (err) {
        console.error(err)
        toaster.error(err, t('customerSupport.errors.submitFailed'))
      } finally {
        NProgress.done()
        setIsInProgress(false)
        setIsEmptyApkAllowed(false)
      }
    },
    [api, formContext, onClose, projectUrlName, t, toaster, isEmptyApkAllowed],
  )
  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<ApkSupportIssueReq>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toaster.error(errorMsg)
      })
    },
    [toaster],
  )

  const handleActionButtonPress = useMemo(() => {
    if (isInProgress) {
      return
    }
    return formContext.handleSubmit(handleSubmitSuccess, handleSubmitInvalid)
  }, [isInProgress, formContext, handleSubmitSuccess, handleSubmitInvalid])

  const onModalClose = useCallback(() => {
    if (isInProgress) {
      return
    }
    onClose()
  }, [onClose, isInProgress])

  return (
    <Modal
      hiddenCloseButton={true}
      title={t('customerSupport.title')}
      isOpen={isVisible}
      onClose={onModalClose}
      actionButton={{
        children: t('submit'),
        textColorVariant: isInProgress
          ? ButtonTextColorVariantEnum.Muted
          : ButtonTextColorVariantEnum.Primary,
        onClick: handleActionButtonPress,
      }}
    >
      <Form
        formContext={formContext}
        onSubmit={handleSubmitSuccess}
        onSubmitInvalid={handleSubmitInvalid}
      >
        <div>
          <p className="whitespace-pre-line text-small mt-[25px] mb-[45px]">
            {t('customerSupport.description')}
          </p>
        </div>
        <SubTitle>{t('customerSupport.helpCategory')}</SubTitle>
        <Controller
          name="category"
          render={({ field: { onChange, value } }) => (
            <Select
              className="mb-[25px]"
              buttonClassName="bg-gray-inputBG"
              options={categoryOptions.map((categoryOption) => ({
                value: categoryOption,
                label: t('customerSupport.categoryOptions.' + categoryOption),
              }))}
              placeholder={t('customerSupport.helpCategoryPlaceholder')}
              disabled={isInProgress}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <div className="mb-[25px]">
          <SubTitle>{t('customerSupport.subject')}</SubTitle>
          <Input
            {...formContext.register('subject')}
            showCounter={false}
            maxLength={35}
            className="bg-gray-inputBG rounded-sm"
            placeholder={t('customerSupport.subjectPlaceholder')}
            disabled={isInProgress}
          />
        </div>
        <SubTitle>{t('customerSupport.howHelp')}</SubTitle>
        <Textarea
          {...formContext.register('description')}
          maxLength={1000}
          className="bg-gray-inputBG min-h-[104px] mb-[33px] rounded-sm"
          placeholder={t('customerSupport.howHelpPlaceholder')}
          disabled={isInProgress}
        />
        {shouldShowApkSection(category) && (
          <>
            <SubTitle>{t('customerSupport.uploadApk')}</SubTitle>
            <FileField<ApkSupportIssueReq, 'apkFile'>
              fieldName="apkFile"
              className="mb-[33px]"
              disabled={isInProgress}
            />
          </>
        )}
        {shouldShowCodeSection(category) && (
          <>
            <SubTitle>{t('customerSupport.executionLog')}</SubTitle>
            <Textarea
              {...formContext.register('executionLog')}
              className="bg-gray-inputBG min-h-[104px] rounded-sm"
              placeholder={t('customerSupport.executionLogPlaceholder')}
              disabled={isInProgress}
            />
          </>
        )}
        <Button
          type="button"
          variant={ButtonVariantEnum.Text}
          className="absolute p-[5px] top-[14px] right-[14px] text-white group"
          onClick={onClose}
          disabled={isInProgress}
        >
          <Icon icon="cross-icon" className="text-gray-normal group-hover:text-white" />
        </Button>
      </Form>
    </Modal>
  )
}

function SubTitle({ children }: { children: React.ReactNode }) {
  return <h3 className="text-normal tracking-wide font-medium mb-[11px]">{children}</h3>
}

const categoriesToShowApkSections: SupportIssueCategory[] = [
  SupportIssueCategory.RUN_APP,
  SupportIssueCategory.RECORD_TRACE,
  SupportIssueCategory.RECORD_VIDEO,
  SupportIssueCategory.MISSING_FRAME_COUNTER,
  SupportIssueCategory.UPLOAD_TRACE,
  SupportIssueCategory.UPLOAD_VIDEO,
  SupportIssueCategory.TRACE_QUALITY,
]

const shouldShowApkSection = (category?: SupportIssueCategory) =>
  Boolean(category && categoriesToShowApkSections.includes(category))

const shouldShowCodeSection = (category?: SupportIssueCategory) =>
  Boolean(category === SupportIssueCategory.RUN_APP)

export const customerSupportFormSchema = yup.object<ValidationSchema<ApkSupportIssueReq>>({
  category: yup.string().required('customerSupport.errors.categoryRequired'),
  subject: yup.string().required('customerSupport.errors.subjectRequired'),
  description: yup
    .string()
    .required('customerSupport.errors.descriptionRequired')
    .min(10, 'customerSupport.errors.descriptionMin10'),
  executionLog: yup.string().when(['category', 'apkFile'], ([category, apkFile], schema) => {
    if (shouldShowCodeSection(category) && apkFile == null) {
      return schema.required('customerSupport.errors.logRequired')
    }
    return schema
  }),
})
