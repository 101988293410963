import { ProjectRole } from 'api/models'
import { TFunction } from 'react-i18next'

export const getProjectRoleName = (
  role?: ProjectRole | null,
  t?: TFunction<'translation', undefined>,
) => {
  if (t) {
    switch (role) {
      case ProjectRole.ADMIN:
        return t('user.projectRole.admin')
      case ProjectRole.CONTRIBUTOR:
        return t('user.projectRole.contributor')
      default:
        return ''
    }
  }
  return ''
}
