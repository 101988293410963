/**
 * https://app.contentful.com/spaces/tab8wfn9nvlu/entries/<entryId>
 */
export const CONTENTFUL_DOCS = Object.freeze({
  gradleMainDoc: '6wA6MeAVPAl0Vh7foYeu8N',
  gradleBuildStepDoc: '5Y617G6Xhqo2rO0tbukC20',
})

export const QUICKSTART_DOCS = Object.freeze({
  intro: '2S32hubqpg4WRkmhMeWs03',
  introToFlowFlibrary: '3CMaBBLKuNgYiNdlP16oOz',
  introToAnnotations: '4F9mBS3twUQ5CGOm1hpNfE',
  introToPxA: '3txt91nNK7948dCjlAVrKd',
  introToTraceViewer: '6cbouK4dm38mT1vgrJd6yi',
  introToInsights: '58WKxIP1L13DdgrxbYXVJj',
})

/**
 * https://app.contentful.com/spaces/tab8wfn9nvlu/content_types/<contentTypeId>
 */
export const CONTENTFUL_TYPES = Object.freeze({
  markdownDocument: 'markdownDocument',
  quickstartPage: 'quickstartPage',
})

/**
 * https://app.contentful.com/spaces/tab8wfn9nvlu/assets/<assetId>
 */
export const QUICKSTART_ASSETS = Object.freeze({
  video: '6WxLASPaUqXhl7HTxGMah1',
  videoThumnail: '6ehRGb0PpNA2MpxzqPNkvF',
})

export const RA_RECORDS = Object.freeze({
  // https://app.contentful.com/spaces/tab8wfn9nvlu/entries/1edaLmgOgf3SzkTy13oZXw
  test: '1edaLmgOgf3SzkTy13oZXw',
  // https://app.contentful.com/spaces/tab8wfn9nvlu/entries/3o8jotB03juqi4BFtPlgqk
  prod: '3o8jotB03juqi4BFtPlgqk',
})
