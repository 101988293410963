import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { useToaster } from 'hooks/useToaster'

interface OutPathsControlsProps {
  psChartStore: PsChartStore
}

type OutpathIconType = 'unselectPath' | 'updateSelectedPath' | 'selectThisPath'

export const OutPathsControls = observer(function OutPathsControls({
  psChartStore,
}: OutPathsControlsProps) {
  const toaster = useToaster()
  const totalCount = psChartStore.traceAnalyzeStore.selectedCycleSliceTotalVariants
  const currentIndex =
    totalCount > 0 ? psChartStore.traceAnalyzeStore.selectedCycleSliceVariantIndex + 1 : 0
  const isDisabled = totalCount < 2

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.code === 'Comma') {
        // <
        event.stopPropagation()
        psChartStore.traceAnalyzeStore.selectCycleSlicePreviousVariant()
      } else if (event.shiftKey && event.code === 'Period') {
        // >
        event.stopPropagation()
        psChartStore.traceAnalyzeStore.selectCycleSliceNextVariant()
      }
    }
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [psChartStore])

  const handlePrevPress = useCallback(() => {
    psChartStore.traceAnalyzeStore.selectCycleSlicePreviousVariant()
  }, [psChartStore])

  const handleNextPress = useCallback(() => {
    psChartStore.traceAnalyzeStore.selectCycleSliceNextVariant()
  }, [psChartStore])

  const handleTogglePress = useCallback(() => {
    psChartStore.traceAnalyzeStore
      .togglePinCycleSliceVariantIndex()
      .catch(() => toaster.error('psChart.error.traceViewer.failedToUpdateChoreographerPaths'))
  }, [psChartStore, toaster])

  const pinState = psChartStore.traceAnalyzeStore.selectedCycleSlicePinState

  return (
    <div className="flex font-medium text-small text-gray-normal tracking-widest pt-[3px] ">
      <div className="flex">
        <span className="min-w-[75px]">
          <p>
            Path {currentIndex}/{totalCount}
          </p>
        </span>
        <ActionTooltip tooltipId={pinState.tooltip as OutpathIconType} place="top">
          <IconColored
            className="mr-[5px]"
            icon={pinState.icon}
            size={16}
            isActive={psChartStore.traceAnalyzeStore.isSelectedCyclePinnedVariantActive}
            isDisabled={false}
            onClick={handleTogglePress}
          />
        </ActionTooltip>
      </div>
      <div className="ml-[10px]">
        <ActionTooltip tooltipId="prevPath" place="top">
          <IconColored
            icon="arrow-drop-left"
            size={12}
            isDisabled={isDisabled}
            onClick={handlePrevPress}
          />
        </ActionTooltip>
        <ActionTooltip tooltipId="nextPath" place="top">
          <IconColored
            className="ml-[15px] scale-x-[-1]"
            icon="arrow-drop-left"
            size={12}
            isDisabled={isDisabled}
            onClick={handleNextPress}
          />
        </ActionTooltip>
      </div>
    </div>
  )
})
