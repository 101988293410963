import { Outlet, useLocation, useParams } from 'react-router-dom'
import { autorun, when } from 'mobx'
import { ChartPageParams, NamedLinkDto } from 'api/models'
import { useEffect, useMemo, useState } from 'react'
import { useToaster } from 'hooks/useToaster'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { observer } from 'mobx-react-lite'
import { Layout } from 'components/Layout'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'
import { ChartDataStore, chartDataStoreContext } from 'components/ps-chart/stores/ChartDataStore'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { PsChartStore, psChartStoreContext } from 'components/ps-chart/PsChartStore'
import { useApi } from 'contexts/di-context'

import { useIsReadOnlyProject } from 'utils/feature-flags'

export const ChartRootPage = observer(function ChartRootPage() {
  const api = useApi()

  const isReadOnlyProject = useIsReadOnlyProject()

  if (isReadOnlyProject) {
    api.overrideMethod('postFlag', (_, flag) => Promise.resolve(flag))
    api.overrideMethod('deleteFlag', () => Promise.resolve())
    api.overrideMethod('putFlag', (_, flag) => Promise.resolve(flag))
    api.overrideMethod('postAnnotation', (_, annotation) => Promise.resolve(annotation))
    api.overrideMethod('deleteAnnotation', () => Promise.resolve())
    api.overrideMethod('putAnnotation', (_, annotation) => Promise.resolve(annotation))
    api.overrideMethod('postNamedLink', (_, data) => Promise.resolve(data as NamedLinkDto))
    api.overrideMethod('deleteNamedLink', () => Promise.resolve())
  } else {
    api.resetOverrideMethod('postFlag')
    api.resetOverrideMethod('deleteFlag')
    api.resetOverrideMethod('putFlag')
    api.resetOverrideMethod('postAnnotation')
    api.resetOverrideMethod('deleteAnnotation')
    api.resetOverrideMethod('putAnnotation')
    api.resetOverrideMethod('postNamedLink')
    api.resetOverrideMethod('deleteNamedLink')
  }

  const toaster = useToaster()

  const { projectUrlName, traceProjectLocalId, flowProjectLocalId } = useParams<ChartPageParams>()
  if (projectUrlName == null || traceProjectLocalId == null || flowProjectLocalId == null) {
    throw new Error(
      'One of the required chart page params (projectUrlName, traceProjectLocalId) was not set',
    )
  }

  // useParams can change the returned object without changing the inner value
  const chartPageParams: ChartPageParams = useMemo(
    () => ({ projectUrlName, traceProjectLocalId, flowProjectLocalId }),
    [projectUrlName, traceProjectLocalId, flowProjectLocalId],
  )

  const chartCommonStore: ChartDataStore = useMemo(
    () => new ChartDataStore(api, chartPageParams, new PsChartSettings()),
    [api, chartPageParams],
  )
  const location = useLocation()

  const psChartStore: PsChartStore = useMemo(() => {
    return new PsChartStore(
      chartCommonStore.settings,
      api,
      chartPageParams,
      toaster,
      chartCommonStore.traceDataStore,
      chartCommonStore.hStateStore,
      chartCommonStore.videoDataStore,
      chartCommonStore.annotationsDataStore,
      chartCommonStore.flagsDataStore,
      false,
      false,
    )
  }, [api, chartCommonStore, chartPageParams, toaster])

  useEffect(
    () =>
      when(
        () => psChartStore.isLoaded,
        () => {
          const navState = location.state
          if (navState == null || navState.sliceId == null || navState.title == null) {
            return null
          }
          const sliceId = Number(navState.sliceId)
          const slice = psChartStore.sliceById.get(sliceId)
          if (slice == null) {
            console.warn(`The slice with ID ${sliceId} has not been found!`)
            return null
          }
          if (!navState.title.includes(slice.title)) {
            console.warn(
              `The slice with ID ${sliceId} has a wrong title. Expected to include: "${navState.title}" / Actual: "${slice.title}"`,
            )
            return null
          }
          psChartStore.setSelectedSlice(slice)
        },
      ),
    [psChartStore, location],
  )

  useEffect(() => {
    const disposeAutorun = autorun(
      () => {
        const url = psChartStore.videoPlayerStore.srcBlobUrl
        if (!url) {
          psChartStore.videoTimelineStore.reset()
          return
        }
        const videoLeftLimitSec = psChartStore.videoPlayerStore.videoLeftLimitSec
        const videoRightLimitSec = psChartStore.videoPlayerStore.videoRightLimitSec
        const pxEnd = psChartStore.videoPlayerStore.videoRightLimitPx
        const pxStart = psChartStore.videoPlayerStore.videoLeftLimitPx
        const widthOrStartPxChanged =
          pxStart !== psChartStore.videoTimelineStore.startXPx ||
          pxEnd - pxStart !== psChartStore.videoTimelineStore.width
        if (
          url &&
          videoLeftLimitSec != null &&
          videoRightLimitSec != null &&
          (!psChartStore.videoTimelineStore.canvas || widthOrStartPxChanged)
        ) {
          psChartStore.videoTimelineStore.extractPreview(
            url,
            videoLeftLimitSec,
            videoRightLimitSec,
            psChartStore.videoPlayerStore.videoLeftLimitPx,
            psChartStore.videoPlayerStore.videoRightLimitPx,
          )
        }
      },
      {
        delay: 500, // to debounce changes when closing/opening details panel
        name: 'autorun @ ChartRootPage -> extractPreview',
      },
    )
    return () => {
      psChartStore.dispose()
      disposeAutorun()
    }
  }, [psChartStore])

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    chartCommonStore
      .load()
      .then(() => {
        psChartStore.load(chartCommonStore.namedLinks, chartCommonStore.choreographerPaths)
      })
      .catch((reason) => {
        setError(reason)
        return Promise.reject(reason)
      })
  }, [chartCommonStore, chartPageParams, psChartStore, toaster])

  if (error) {
    return <ErrorPageAuthorized fullReload={true} layoutProps={{}} />
  }

  if (chartCommonStore.isLoading) {
    return (
      <Layout pageConfig={{ withoutStyledContent: true }}>
        <main className="flex flex-grow relative">
          <ModuleProgressCircle />
        </main>
      </Layout>
    )
  }

  return (
    <chartDataStoreContext.Provider value={chartCommonStore}>
      <psChartStoreContext.Provider value={psChartStore}>
        <Outlet />
      </psChartStoreContext.Provider>
    </chartDataStoreContext.Provider>
  )
})
