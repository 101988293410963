import React, { useCallback, useRef, useState } from 'react'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Thread } from 'components/ps-chart/models/Thread'
import { observer } from 'mobx-react-lite'
import { IconColored } from 'components/common/IconColored'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { ActionTooltip } from 'components/ActionTooltip'

interface ThreadItemProps {
  psChartStore: PsChartStore
  thread: Thread
  height: number
  isLast: boolean
  isFavorite: boolean
}

export const ThreadItem = observer(function ThreadItem({
  psChartStore,
  thread,
  height,
  isLast,
  isFavorite,
}: ThreadItemProps) {
  const settings = psChartStore.chartSettings.renderEngine.threads
  const [textTruncated, setTextTruncated] = useState(false)

  const onFavoriteClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.toggleFavoriteThread(thread)
  }, [psChartStore, thread])

  const onSearchClick = useCallback(() => {
    psChartStore.searchState.setSearchContextThreadId(thread.id)
  }, [psChartStore, thread])

  const onArrowDownClick = useCallback(() => {
    if (!isFavorite) {
      psChartStore.traceAnalyzeStore.deprioritizeThread(thread.id)
    }
  }, [psChartStore, thread.id, isFavorite])

  const handleMouseEnter = useCallback(() => {
    setTextTruncated(
      Boolean(
        titleRef && titleRef.current && titleRef.current.scrollWidth > titleRef.current.clientWidth,
      ),
    )
  }, [setTextTruncated])

  const titleRef = useRef<HTMLDivElement>(null)

  return (
    <ThreadItemWrapper
      className="group"
      dividerHeight={isLast ? 0 : settings.dividerHeight}
      style={{ height: `${height}px` }}
    >
      <div className="flex items-center h-full py-[3px]">
        <div className={'flex items-center sticky top-0 w-full pl-[24px] h-[24px] pr-[15px]'}>
          <ActionTooltip childrenRef={titleRef} text={textTruncated ? thread.title : undefined}>
            <div
              className="w-full text-normal text-gray-normal truncate tracking-wide"
              onMouseEnter={handleMouseEnter}
            >
              {thread.title}
            </div>
          </ActionTooltip>
          <div className="hidden group-hover:flex group-hover:bg-dark-dark2 rounded pl-[3px]">
            <ActionTooltip tooltipId="threadSearch">
              <IconColored
                size={13}
                icon="small-search-icon"
                onClick={onSearchClick}
                className="px-[7px]"
              />
            </ActionTooltip>
            <ActionTooltip tooltipId={!isFavorite ? 'threadLockBottom' : undefined}>
              <IconColored
                size={12}
                icon="arrow-small-deprioritize-icon"
                onClick={onArrowDownClick}
                isDisabled={isFavorite}
                className="px-[7px]"
                data-tid="thread-deprioritize"
              />
            </ActionTooltip>
            <ActionTooltip tooltipId={!isFavorite ? 'threadLockTop' : undefined}>
              <IconColored
                size={13}
                icon="small-favorites-icon"
                onClick={onFavoriteClick}
                isActive={isFavorite}
                className="px-[7px]"
                data-tid="thread-favorite"
              />
            </ActionTooltip>
          </div>
        </div>
      </div>
    </ThreadItemWrapper>
  )
})

const ThreadItemWrapper = styled.div(({ dividerHeight }: { dividerHeight: number }) => [
  tw`border-solid border-gray-strokeLight`,
  `border-bottom-width: ${dividerHeight}px`,
])
