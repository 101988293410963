import React, { Fragment, useCallback, useEffect } from 'react'
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import { Tab } from '@headlessui/react'

import { Empty } from 'components/Empty'
import { Layout } from 'components/Layout'
import { FlowHead } from 'components/flows/FlowHead'
import { Table } from 'components/traces/Table'
// import { ButtonVariantEnum } from "components/Button"
import { useFlowQuery, useFlowTracesQuery, useProjectQuery, useUserQuery } from 'hooks/useApiQuery'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { PATH_TRACE_ADD } from 'pages/TraceAddPage'
import { TeamRole, Trace } from 'api/models'

import PhoneSVG from 'assets/img/phone.svg'
import { CompareCommits } from 'components/regression-analysis/CompareCommits'
import { useRAData } from 'components/regression-analysis/useRAData'

export const PATH_FLOW_START = '/projects/:projectUrlName/flows/:flowProjectLocalId/'
export const PATH_FLOW_END = 'traces'
const COMPARE_COMMITS_KEY = 'commits'
export const PATH_FLOW = PATH_FLOW_START + PATH_FLOW_END
export const PATH_FLOW_FULL_PATTERN = PATH_FLOW + `/${COMPARE_COMMITS_KEY}?`

interface Props {
  flowTracesData: Trace[]
  canWorkWithFlow: boolean
  projectName: string
}

export const TableTabs = ({ flowTracesData, canWorkWithFlow, projectName }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const selectedIndex = useMatch(`${PATH_FLOW}/${COMPARE_COMMITS_KEY}`) == null ? 0 : 1
  const { raStore, isRAEnabled } = useRAData(projectName)

  const onTabChange = useCallback(
    (tabIndex: number) => {
      switch (tabIndex) {
        case 1:
          navigate(COMPARE_COMMITS_KEY, {
            replace: true,
            relative: 'path',
          })
          break
        default:
          navigate('../', {
            replace: true,
            relative: 'path',
          })
          break
      }
    },
    [navigate],
  )

  if (!isRAEnabled) {
    return <Table traces={flowTracesData} canWorkWithFlow={canWorkWithFlow} />
  }

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <Tab.List className="pl-[107px] text-xs text-gray-normal mb-[24px]">
        {[t('ra.tabs.traces'), t('ra.tabs.commits')].map((title) => (
          <Tab
            key={`tab-key-${title}`}
            className={({ selected }) =>
              `mr-[16px] pb-[5px] ${
                selected
                  ? 'text-white border-b-[3px] border-electro'
                  : 'hover:text-white hover:border-b-[3px] hover:border-gray-normal'
              }`
            }
          >
            {title}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels as={Fragment}>
        <Tab.Panel className="flex flex-grow">
          <Table traces={flowTracesData} canWorkWithFlow={canWorkWithFlow} />
        </Tab.Panel>
        <Tab.Panel className="flex flex-grow pl-[107px]">
          <CompareCommits raStore={raStore} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}

export const FlowPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  const { isSuccess: isFlowTracesSuccess, data: flowTracesData } = useFlowTracesQuery({
    projectUrlName,
    flowProjectLocalId,
  })

  const isLoaded = isFlowSuccess && isFlowTracesSuccess && isUserSuccess && isProjectSuccess

  const teamRole = user?.roles.teams[project?.team.id as number]
  const projectRole = user?.roles.projects[project?.id as number]
  const canWorkWithFlow = Boolean(
    !user?.roles.isSuperAdmin || (teamRole && teamRole !== TeamRole.NONE) || projectRole,
  )

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const handleAddNewTraceClick = () =>
    navigate(generatePath(PATH_TRACE_ADD, { projectUrlName, flowProjectLocalId }))

  return (
    <Layout headerProps={{ screenTitleKey: 'library' }}>
      {isLoaded && (
        <>
          {flowData && (
            <FlowHead
              {...flowData}
              backLink={generatePath(PATH_FLOWS, { projectUrlName })}
              showMyTracesOnlyButton={
                !!flowTracesData?.find((trace) => trace?.createdBy === user?.name)
              }
              canWorkWithFlow={canWorkWithFlow}
            />
          )}
          {project != null &&
          isFlowTracesSuccess &&
          flowTracesData &&
          flowTracesData?.length > 0 ? (
            <TableTabs
              flowTracesData={flowTracesData}
              canWorkWithFlow={canWorkWithFlow}
              projectName={project.name}
            />
          ) : (
            <Empty
              image={PhoneSVG}
              title={t('flows.flowPage.empty.title')}
              text={t('flows.flowPage.empty.text')}
              buttons={
                canWorkWithFlow
                  ? [
                      // {
                      //   name: flowsText("flowPage.empty.buttons.annotateFlow"),
                      //   variant: ButtonVariantEnum.Outlined
                      // },
                      {
                        name: t('flows.flowPage.empty.buttons.addNewTrace'),
                        onClick: handleAddNewTraceClick,
                      },
                    ]
                  : []
              }
            />
          )}
        </>
      )}
    </Layout>
  )
}
