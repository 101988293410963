import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { getDurationString } from 'components/regression-analysis/getDurationString'
import { RAStore } from 'components/regression-analysis/RAStore'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'

import { CommitsCanvas } from './CommitsCanvas'
import { chartSettings } from './CommitsChartSettings'

dayjs.extend(duration)

export const CommitsChart = observer(function CommitsChart({ raStore }: { raStore: RAStore }) {
  const { t } = useTranslation()
  if (
    raStore.commitsOptions.length === 0 ||
    raStore.delaySum == null ||
    raStore.chartScale == null
  ) {
    return <ModuleProgressCircle />
  }

  return (
    <div className="flex flex-col bg-dark-dark3 px-[16px] relative mb-[16px]">
      <div className="flex flex-row h-[80px]">
        <div className="typography-big-accent mt-[12px] w-[75px]">{t('ra.chart.timeHeader')}</div>
        <div className="flex flex-grow">
          {raStore.delaySum.durations.map((durationSum, key) => (
            <div key={key} className="flex flex-grow flex-col items-center">
              <div className="typography-small-accent text-gray-normal mt-[17px]">
                {raStore.commitsOptions[key].label}
              </div>
              <div className="typography-small text-gray-normal mt-[3px]">
                {getDurationString(durationSum)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {Array.from(Array(chartSettings.stepsCount), (_, i) => (
          <div
            className="h-[60px] typography-small text-gray-faded pt-[4px] border-t border-gray-strokeLight"
            key={i}
          >
            {getDurationString(
              raStore.chartScale!.maxDuration - i * raStore.chartScale!.scaleStep,
              true,
            )}
          </div>
        ))}
      </div>
      <div className="absolute left-[91px] right-[16px] top-[80px] bottom-0">
        <CommitsCanvas raStore={raStore} />
      </div>
    </div>
  )
})
