import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { CardLine } from 'components/ps-chart/details-view/CardLine'
import { ConnectIcon } from 'components/ps-chart/details-view/ConnectIcon'
import { CardTitleBar } from 'components/ps-chart/details-view/CardTitleBar'
import { useConnectionHeader } from 'components/ps-chart/details-view/UseConnectionHeader'
import { nanoToString } from 'components/ps-chart/utils/nanoToString'
import { isLinkIdTemp } from 'components/ps-chart/stores/connections-store/createNamedLink'
import { Slice } from 'components/ps-chart/models/Slice'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useToaster } from 'hooks/useToaster'
import { moveToSlice } from 'components/ps-chart/utils/moveTo'
import { useDi } from 'contexts/di-context'
import { Analytics } from 'utils/analytics'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import {
  checkIfNestedArguments,
  SliceNestedArgument,
} from 'components/ps-chart/details-view/SliceNestedArgument'

enum LinkEvent {
  HotKey = 'HotKey',
  Click = 'Click',
}

interface SliceBoxProps {
  psChartStore: PsChartStore
  chain: Slice[]
  sliceIndex: number
  isDetached?: boolean
}

export const SliceDetailsCard = observer(function SliceDetailsCard({
  psChartStore,
  chain,
  sliceIndex,
  isDetached = false,
}: SliceBoxProps) {
  const { t } = useTranslation()
  const toaster = useToaster()

  const analytics = useDi().compositionRoot.analytics
  useSelectSliceEvent(psChartStore, analytics)

  const slice = chain[sliceIndex]
  const nextSlice = chain[sliceIndex + 1]
  const sliceLinks = psChartStore.traceAnalyzeStore.sliceLinksBySliceId.get(slice.id)
  const usedSliceLink =
    nextSlice != null ? sliceLinks?.find((link) => link.toSliceId === nextSlice.id) : null
  const manualLink = sliceLinks?.find((link) => link.connectionType === ConnectionType.MANUAL)
  const thread = psChartStore.traceDataState.threadsById.get(slice.threadId)!
  const isLast = sliceIndex === chain.length - 1
  const isNestedArguments = checkIfNestedArguments(slice.args)
  const isLinkModeActive = slice.id === psChartStore.linkModeSliceId
  const isConnectingInProgress = manualLink != null && isLinkIdTemp(manualLink.sourceId!)
  const isDisabled = manualLink != null && !manualLink.isEditable

  const connectedHeader = useConnectionHeader(isLast, usedSliceLink?.connectionType)

  const onLinkEvent = (event: LinkEvent) => {
    if (isDisabled) {
      return null
    }
    if (isLinkModeActive) {
      psChartStore.disableLinkMode()
      return null
    }
    if (manualLink != null) {
      psChartStore
        .disconnectSlice(manualLink)
        .catch((reason) => toaster.error(reason, 'psChart.error.connection.deleteConnection'))
      return null
    }
    if (event === LinkEvent.HotKey) {
      analytics.track('create-new-connection-hotkey')
    }
    psChartStore.enableLinkMode(slice.id)
  }

  const onSelect = () => {
    moveToSlice(slice.id, psChartStore)
  }

  useHotKeys(['KeyC'], () => onLinkEvent(LinkEvent.HotKey), isLast)

  /** Added for {@link PsChartStore.isStaticPageMode} */
  const staticModeExtraRows = slice.extra
    ? Object.entries(slice.extra ?? {}).map((value) => {
        return (
          <CardLine label={value[0]}>
            {Array.isArray(value[1]) ? value[1].join(', ') : value[1]}
          </CardLine>
        )
      })
    : []

  return (
    <>
      {isDetached && (
        <div className="mb-[10px] flex">
          <div className="font-medium text-small text-gray-normal flex-grow tracking-widest pt-[3px]">
            {t('psChart.details.sliceHeader')}
          </div>
          <div>
            <ConnectIcon
              isActive={isLinkModeActive}
              onClick={() => onLinkEvent(LinkEvent.Click)}
              isConnected={manualLink != null}
              isInProgress={isConnectingInProgress}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}
      <div className="relative text-small rounded font-medium text-gray-normal bg-dark-dark1 px-[8px] pt-[13px] pb-[6px]">
        <CardTitleBar color={slice.color} title={slice.title} onClick={onSelect} />
        {thread && <CardLine label={t('psChart.details.thread')}>{thread.title}</CardLine>}
        <CardLine label={t('psChart.details.sliceId')}>{slice.id}</CardLine>
        {slice.objectId !== -1 && (
          <CardLine label={t('psChart.details.objectId')}>{slice.objectId}</CardLine>
        )}
        <CardLine label={t('psChart.details.startTime')}>{nanoToString(slice.start)}</CardLine>
        <CardLine label={t('psChart.details.duration')}>
          {getSliceDurationString(slice.start, slice.end)}
        </CardLine>
        {!isNestedArguments && slice.args.length > 0 && (
          <CardLine label={t('psChart.details.arguments')}>
            <>
              {slice.args.map((arg, index) => (
                <div key={index}>{arg.value}</div>
              ))}
            </>
          </CardLine>
        )}
        {isNestedArguments && <SliceNestedArgument args={slice.args} />}
        {psChartStore.isStaticPageMode && staticModeExtraRows}
      </div>
      <div className="flex flex-col items-center min-h-[48px] relative">
        {connectedHeader && (
          <>
            <div className="h-[16px] w-[1px] bg-white opacity-20" />
            <div
              className={
                'px-[18px] border-[1px] rounded-sm leading-[14px] text-small text-gray-normal ' +
                connectedHeader.className
              }
            >
              {connectedHeader.title}
            </div>
            <div className="h-[16px] w-[1px] bg-white opacity-20" />
          </>
        )}
        {!isDetached && (
          <div className="absolute top-0 right-0 bottom-0 flex items-center">
            <ConnectIcon
              isActive={isLinkModeActive}
              onClick={() => onLinkEvent(LinkEvent.Click)}
              isConnected={manualLink != null}
              isInProgress={isConnectingInProgress}
              isDisabled={isDisabled}
            />
          </div>
        )}
      </div>
    </>
  )
})

const getSliceDurationString = (start: number, end: number) => {
  return nanoToString(end - start)
}

let prevSelectedSlice: number | undefined = undefined

function useSelectSliceEvent(psChartStore: PsChartStore, analytics: Analytics) {
  useEffect(() => {
    if (prevSelectedSlice !== psChartStore.traceAnalyzeStore.selectedSlice?.id) {
      analytics.track('select-slice')
      prevSelectedSlice = psChartStore.traceAnalyzeStore.selectedSlice?.id
    }
  }, [psChartStore.traceAnalyzeStore.selectedSlice, analytics])
}
