import React, { useCallback, useEffect, useRef, useState } from 'react'
import { autorun, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { FlameChartRender } from 'components/ps-chart/flame-chart/FlameChartRender'
import { FlameTooltip } from 'components/ps-chart/flame-chart/FlameTooltip'
import { useToaster } from 'hooks/useToaster'
import { useDi } from 'contexts/di-context'

const Loading = observer(function FlameChartLoading({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  return psChartStore.isLoading ? <ModuleProgressCircle /> : null
})

export const FlameChart = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const mainCanvasRef = useRef<HTMLCanvasElement>(null)
  const favoritesCanvasRef = useRef<HTMLCanvasElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const [flameChartRender, setFlameChart] = useState<FlameChartRender>()
  const toaster = useToaster()
  const analytics = useDi().compositionRoot.analytics

  useEffect(() => {
    if (
      mainCanvasRef.current == null ||
      favoritesCanvasRef.current == null ||
      chartWrapperRef.current == null
    ) {
      throw new Error('Canvas has not been initialized')
    }
    const wrapperEl = chartWrapperRef.current
    const newFlameChartRender = new FlameChartRender(
      mainCanvasRef.current,
      favoritesCanvasRef.current,
      psChartStore,
      toaster,
      analytics,
    )
    setFlameChart(newFlameChartRender)
    newFlameChartRender.addEventListeners(wrapperEl)
    if (psChartStore.isLoaded) {
      newFlameChartRender.setCanvasSizeParams()
      newFlameChartRender.prepareDataAndRender()
    }

    return () => newFlameChartRender.removeEventListeners(wrapperEl)
  }, [psChartStore, toaster, analytics])

  useEffect(
    () =>
      autorun(
        () => {
          if (
            psChartStore.traceDataState.threads.length > 0 &&
            psChartStore.isEmpty &&
            flameChartRender != null
          ) {
            runInAction(() => {
              psChartStore.setIsLoading()
            })
            flameChartRender.setCanvasSizeParams()
            runInAction(() => {
              psChartStore.setIsLoaded()
            })
            flameChartRender.prepareDataAndRender()
          }
        },
        { name: 'autorun @ FlameChart & psChartStore.isEmpty -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore],
  )
  /**
   * Canvas redraw reaction to changes in favorites thread list.
   * Thread's height be dynamic because of different viewing modes
   **/
  useEffect(
    () =>
      reaction(
        () => psChartStore.vState.favCanvasHeight,
        () => {
          if (psChartStore.isLoaded) {
            flameChartRender?.setCanvasSizeParams()
            flameChartRender?.prepareDataAndRender()
          }
        },
        { name: 'reaction @ FlameChart & favThreads -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore],
  )

  useEffect(
    () =>
      reaction(
        () => [
          psChartStore.hState.width,
          psChartStore.hState.xWidthTotal,
          psChartStore.hState.xMin,
          psChartStore.hState.xMax,
          psChartStore.hState.xStart,
          psChartStore.hState.xEnd,
          psChartStore.hState.xWidth,
          psChartStore.hState.zoom,
          psChartStore.hState.timePerPx,
          psChartStore.hState.xGridLines,

          psChartStore.vState.height,
          psChartStore.vState.yStart,
          psChartStore.vState.yEndMain,
          psChartStore.vState.yStartFav,
          psChartStore.vState.yEndFav,
          psChartStore.vState.mainTotalHeight,
          psChartStore.vState.mainCanvasHeight,
          psChartStore.vState.scrollableHeight,
          psChartStore.vState.mainScrollableHeight,
          psChartStore.vState.favTotalHeight,
          psChartStore.vState.favCanvasHeight,
          psChartStore.vState.mainThreadsTopMap,
          psChartStore.vState.favThreadsTopMap,
          psChartStore.vState.mainThreadsTopBottomMap,
          psChartStore.vState.favThreadsTopBottomMap,

          psChartStore.isLoaded,
          psChartStore.traceAnalyzeStore.selectedCycleSliceVariantIndex,
          psChartStore.traceAnalyzeStore.detailsChainType,
          psChartStore.traceAnalyzeStore.linksTree,
          psChartStore.traceAnalyzeStore.mainChainSlices,
          psChartStore.traceAnalyzeStore.chainExists,
          psChartStore.traceAnalyzeStore.favIdSet,
          psChartStore.traceAnalyzeStore.mainThreads,
          psChartStore.traceAnalyzeStore.depthByThreadId,
          psChartStore.traceAnalyzeStore.heightByThreadId,
          psChartStore.traceAnalyzeStore.showExPathModeFullDepth,
          psChartStore.traceAnalyzeStore.showNormalModeFullDepth,
          psChartStore.traceAnalyzeStore.showShrunkModeDepth,
          psChartStore.isLinkModeActive,
          psChartStore.flagsStore.flags.slice(),
          psChartStore.flagsStore.selectedFlagId,
          psChartStore.flagsStore.hoverTime,
          psChartStore.flagsStore.showLabels,
          psChartStore.traceDataState.threads,
          psChartStore.searchState.searchResults,
          psChartStore.videoPlayerStore.status,
          psChartStore.videoPlayerStore.traceVideoPointerTimeNanos,
          psChartStore.videoPlayerStore.hoverTime,

          psChartStore.isTransparentConnectionEnabled,
          psChartStore.rendererStore.renderType,

          psChartStore.shouldDimDisconnectedSlices,

          psChartStore.annotationsStore.annotationsWithConnectedSlices,
        ],
        () => psChartStore.isLoaded && flameChartRender?.prepareDataAndRender(),
        { name: 'reaction @ FlameChart & list -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore],
  )

  useEffect(() => {
    if (chartWrapperRef.current != null) {
      const resizeObserver = new ResizeObserver(([chartWrapperEntry]) => {
        psChartStore.hState.setWidth(chartWrapperEntry.target.clientWidth)
        psChartStore.vState.setHeight(chartWrapperEntry.target.clientHeight)
        flameChartRender?.onResize()
      })
      resizeObserver.observe(chartWrapperRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [flameChartRender, psChartStore])

  useEffect(() => {
    if (chartWrapperRef.current != null) {
      psChartStore.hState.setWidth(chartWrapperRef.current.clientWidth)
      psChartStore.vState.setHeight(chartWrapperRef.current.clientHeight)
    }
  }, [psChartStore])

  useEffect(
    () =>
      reaction(
        () => psChartStore.traceAnalyzeStore.selectedSlice,
        (selectedSlice) => {
          if (selectedSlice != null) {
            if (psChartStore.traceDataState.hasReactQueueThread) {
              if (
                psChartStore.traceDataState.reactJSThreadIds.has(selectedSlice.threadId) &&
                psChartStore.traceAnalyzeStore.nativeChainExists
              ) {
                psChartStore.traceAnalyzeStore.switchDetailsToNativeChain()
              } else {
                psChartStore.traceAnalyzeStore.switchDetailsToRegularChain()
              }
            }
          }
        },
        { name: 'reaction @ FlameChart & selectedSlice -> moveToSlice' },
      ),
    [psChartStore],
  )

  // Rendering performance measuring
  const runPerfMeasure = useCallback(() => {
    flameChartRender?.prepareDataAndRender()
    if (psChartStore.renderingMeasuring.isStarted) {
      setTimeout(() => {
        runPerfMeasure()
      }, 1000)
    }
  }, [flameChartRender, psChartStore])

  // Rendering performance measuring
  useEffect(
    () =>
      reaction(
        () => psChartStore.renderingMeasuring.isStarted,
        (isStarted) => {
          if (flameChartRender != null && isStarted) {
            runPerfMeasure()
          }
        },
        { name: 'reaction @ FlameChart & renderingMeasuring -> runPerfMeasure' },
      ),
    [flameChartRender, psChartStore, runPerfMeasure],
  )

  return (
    <div className="flex-grow relative overflow-hidden" ref={chartWrapperRef}>
      <canvas ref={favoritesCanvasRef} className="absolute" />
      <canvas ref={mainCanvasRef} className="absolute" />
      <FlameTooltip psChartStore={psChartStore} />
      <Loading psChartStore={psChartStore} />
    </div>
  )
}
