import { Point } from 'components/ps-chart/models/helper-types'
import { getSliceTitleWithArgs } from 'components/ps-chart/utils/slice'
import { moveToThread } from 'components/ps-chart/utils/moveTo'
import { Thread } from 'components/ps-chart/models/Thread'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { isSlice, Slice } from 'components/ps-chart/models/Slice'
import { PsChartFeatures, PsChartStore } from 'components/ps-chart/PsChartStore'

import { Delay, MeasurementPoints, RenderEngine, RenderEngineData } from './RenderEngine'
import { Pointer } from './Pointer'
import { ConnectionsRender } from 'components/ps-chart/connections-render/ConnectionsRender'
import { getBorderRect } from 'components/ps-chart/utils/getBorderRect'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import {
  ConnectionCurves,
  getEmptyConnectionCurves,
} from 'components/ps-chart/connections-render/ConnectionCurves'

import { throttle } from 'throttle-debounce'
import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'
import { Toaster } from 'hooks/useToaster'
import { Analytics } from 'utils/analytics'
import { PsChartEventsHandler } from 'components/ps-chart/PsChartEventsHandler'
import { walkOverTree } from 'components/ps-chart/stores/connections-store/LinksTree/walkOverTree'

/**
 * Flame-chart drawing root class
 *
 * Responsibilities:
 * - handles user events (clicks, hover, zoom, etc)
 * - delegates state to {@link PsChartStore}
 * - delegates rendering to {@link RenderEngine}
 */
export class FlameChartRender {
  readonly mainCanvas: HTMLCanvasElement
  readonly mainContext: CanvasRenderingContext2D
  readonly mainRenderEngine: RenderEngine
  readonly baseTextMeasurer: TextMeasurer

  readonly baseFontStyle: string

  readonly favCanvas: HTMLCanvasElement
  readonly favContext: CanvasRenderingContext2D
  readonly favRenderEngine: RenderEngine

  private readonly settings: PsChartSettings
  private readonly features: PsChartFeatures
  private readonly blockHeight: number

  private startDragPoint: Point | null = null
  private startMeasurementPoint: Point | null = null

  private lastAnimationSlice: number | null = null

  private readonly psChartStore: PsChartStore

  private readonly pointer: Pointer

  private readonly connectionsRender: ConnectionsRender

  private pressedKey: null | string = null

  private readonly toaster: Toaster
  private analytics: Analytics
  private readonly psChartEventsHandler: PsChartEventsHandler

  constructor(
    canvas: HTMLCanvasElement,
    favoritesCanvas: HTMLCanvasElement,
    psChartStore: PsChartStore,
    toaster: Toaster,
    analytics: Analytics,
  ) {
    this.psChartStore = psChartStore
    this.toaster = toaster
    this.analytics = analytics
    this.settings = psChartStore.chartSettings
    this.features = psChartStore.chartFeatures
    this.baseFontStyle = `${this.settings.renderEngine.basicRenderer.fontSize}px ${this.settings.renderEngine.basicRenderer.fontFamily}`
    this.baseTextMeasurer = new TextMeasurer(this.baseFontStyle)
    this.blockHeight = this.settings.renderEngine.threads.blockHeight
    this.connectionsRender = new ConnectionsRender(psChartStore)

    const mainContext = canvas.getContext('2d', { alpha: false })
    if (mainContext == null) {
      throw new Error('canvas.getContext failed!')
    }
    this.mainCanvas = canvas
    this.mainContext = mainContext
    this.mainRenderEngine = new RenderEngine(
      mainContext,
      this.settings.renderEngine,
      this.baseFontStyle,
      this.baseTextMeasurer,
    )

    const favContext = favoritesCanvas.getContext('2d', { alpha: false })
    if (favContext == null) {
      throw new Error('favoritesCanvas.getContext failed!')
    }

    this.favCanvas = favoritesCanvas
    this.favContext = favContext
    this.favRenderEngine = new RenderEngine(
      favContext,
      this.settings.renderEngine,
      this.baseFontStyle,
      this.baseTextMeasurer,
    )

    this.pointer = new Pointer(this.mainCanvas, this.favCanvas)
    this.psChartEventsHandler = new PsChartEventsHandler(psChartStore, this.mainCanvas)

    this.setCanvasSizeParams()
  }

  addEventListeners(wrapperEl: HTMLElement) {
    wrapperEl.addEventListener('wheel', this.onWheel)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)

    wrapperEl.addEventListener('mousedown', this.onMouseDown)
    wrapperEl.addEventListener('mousemove', this.onMouseMove)
    wrapperEl.addEventListener('mouseup', this.onMouseUp)
    wrapperEl.addEventListener('mouseout', this.onMouseOut)

    this.psChartEventsHandler.addEventListeners(wrapperEl)
  }

  removeEventListeners(wrapperEl: HTMLElement) {
    wrapperEl.removeEventListener('wheel', this.onWheel)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)

    wrapperEl.removeEventListener('mousedown', this.onMouseDown)
    wrapperEl.removeEventListener('mousemove', this.onMouseMove)
    wrapperEl.removeEventListener('mouseup', this.onMouseUp)
    wrapperEl.removeEventListener('mouseout', this.onMouseOut)

    this.psChartEventsHandler.removeEventListeners(wrapperEl)
  }

  private readonly onKeyDown = (event: KeyboardEvent) => {
    if (['KeyD', 'KeyA', 'KeyW', 'KeyS'].includes(event.code)) {
      this.hideTooltip()
    }

    if (event.code === 'Escape') {
      if (this.psChartStore.isLinkModeActive) {
        this.psChartStore.disableLinkMode()
      }
    }

    if (event.code === 'KeyK') {
      this.psChartStore.traceAnalyzeStore.toggleShowExPathFullDepth()
    }

    if (event.code === 'KeyT') {
      this.psChartStore.enableTransparentConnection()
    }

    if (event.code === 'KeyP') {
      this.psChartStore.toggleIsDimDisconnectedSlicesEnabled()
    }

    if (this.psChartStore.chartFeatures.measurementTool) {
      if (event.code === 'ShiftLeft' && this.pointer.isMain()) {
        const point = this.pointer.getRelativePoint()
        if (point) {
          const rect = this.mainCanvas.getBoundingClientRect()
          this.startMeasurementPoint = {
            x: point.x + rect.left,
            y: point.y + rect.top,
          }
        }
        this.psChartStore.enableMeasurementMode()
      }
    }

    this.pressedKey = event.code
  }

  private readonly onKeyUp = (event: KeyboardEvent) => {
    this.pressedKey = null

    if (event.code === 'ShiftLeft') {
      this.hideMeasurement()
    }

    if (event.code === 'KeyT') {
      this.psChartStore.disableTransparentConnection()
    }
  }

  private readonly onWheel = (event: WheelEvent) => {
    event.preventDefault()
    this.hideTooltip()
    if (event.deltaY !== 0 && event.ctrlKey) {
      if (event.deltaY < 0) {
        this.psChartStore.hState.increaseZoom(this.pointer.getRelativeX(), event.deltaY)
      } else {
        this.psChartStore.hState.decreaseZoom(this.pointer.getRelativeX(), event.deltaY)
      }
    }
    return false
  }

  private readonly onMouseDown = (event: MouseEvent) => {
    if (!event.ctrlKey) {
      this.startDragPoint = { x: event.x, y: event.y }
    }
  }

  private readonly onMouseMove = (event: MouseEvent) => {
    this.pointer.onMouseMove(event)
    if (this.psChartStore.chartFeatures.sliceNameTooltip) {
      this.onHover()
    }
    if (!event.ctrlKey && this.startDragPoint != null) {
      const mouseMoveDelta = this.startDragPoint.x - event.x
      const delta = this.calcChangeXStartDelta(
        mouseMoveDelta * 2 * this.psChartStore.hState.timePerPx,
      )
      if (this.pointer.isMain()) {
        this.psChartStore.vState.scrollY(
          this.calcChangeXStartDelta(this.startDragPoint.y - event.y),
        )
      }
      this.psChartStore.hState.changeXStart(delta)

      this.startDragPoint = { x: event.x, y: event.y }
    }
    if (
      this.psChartStore.isLinkModeActive ||
      (this.psChartStore.isMeasurementModeActive && this.startMeasurementPoint)
    ) {
      this.prepareDataAndRender()
    }
  }

  private readonly onMouseUp = (event: MouseEvent) => {
    if (
      !event.ctrlKey &&
      this.startDragPoint != null &&
      this.pointer.isEqualTo({
        x: this.startDragPoint.x,
        y: this.startDragPoint.y,
      })
    ) {
      this.onClick()
    }
    this.startDragPoint = null

    if (this.psChartStore.isMeasurementModeActive) {
      this.prepareDataAndRender()
    }
  }

  private readonly onMouseOut = () => {
    this.startDragPoint = null
    this.pointer.onMouseOut()
    this.hideMeasurement()
  }

  onResize() {
    this.setCanvasSizeParams()
    if (this.psChartStore.isLoaded) {
      this.prepareDataAndRender()
    }
  }

  private calcChangeXStartDelta(delta: number): number {
    return (
      Math.sign(delta) *
      Math.max(1, Math.abs(Math.round(delta * this.settings.changePosCoefficient)))
    )
  }

  private getMeasurementPoints = (): MeasurementPoints => {
    let result = {
      startX: 0,
      endX: 0,
      startY: 0,
    }

    if (!this.psChartStore.isMeasurementModeActive || !this.startMeasurementPoint) {
      return result
    }

    const end = this.pointer.getRelativePoint()
    if (this.startMeasurementPoint && end) {
      const rect = this.mainCanvas.getBoundingClientRect()
      result = {
        startX: this.startMeasurementPoint.x - rect.left,
        endX: end.x,
        startY: this.startMeasurementPoint.y - rect.top,
      }
      if (result.endX < result.startX) {
        result = {
          startX: result.endX,
          endX: result.startX,
          startY: result.startY,
        }
      }
      return result
    }
    return null
  }

  prepareDataAndRender() {
    if (!this.lastAnimationSlice) {
      this.lastAnimationSlice = requestAnimationFrame(() => {
        const all = performance.now()
        const processedMain = this.psChartStore.rendererStore.processedMainThreads
        const processedFav = this.psChartStore.rendererStore.processedFavoriteThreads
        const elementInChart = this.findElementInChart()
        const hoveredSlice = isSlice(elementInChart) ? elementInChart : null

        const canvasCurves = this.connectionsRender.getCanvasCurves(
          this.psChartStore.vState.mainThreadsTopMap,
          this.psChartStore.vState.favThreadsTopMap,
          () => ({
            hoveredSlice,
            cursorPoint: this.pointer.getRelativePoint(this.settings.renderEngine.headerHeight),
            isCursorOnFav: this.pointer.isFav(),
          }),
        )

        this.mainRenderEngine.render(
          this.getRenderData(
            this.psChartStore,
            processedMain,
            false,
            this.psChartStore.isMeasurementModeActive,
            this.getMeasurementPoints(),
            canvasCurves.main,
          ),
        )
        this.favRenderEngine.render(
          this.getRenderData(
            this.psChartStore,
            processedFav,
            true,
            false,
            this.getMeasurementPoints(),
            canvasCurves.fav,
          ),
        )

        this.lastAnimationSlice = null
        const allRenderingTookTime = performance.now() - all
        console.log('all took', allRenderingTookTime.toFixed(2))

        if (this.psChartStore.renderingMeasuring.isStarted) {
          this.psChartStore.renderingMeasuring.addMeasure(allRenderingTookTime)
        }
      })
    }
  }

  prepareDelaysData = (): Delay[] => {
    const delays: Delay[] = []
    walkOverTree(this.psChartStore.traceAnalyzeStore.linksTree, (item) => {
      const links = item.fromLinks
      const { xStart, xEnd } = this.psChartStore.hState
      links.forEach((fromLink) => {
        const sliceStart = this.psChartStore.sliceById.get(fromLink.toTreeNode.sliceId)
        const sliceEnd = this.psChartStore.sliceById.get(fromLink.fromTreeNode.sliceId)
        if (!sliceStart || !sliceEnd) {
          return
        }

        const start = sliceStart.end
        const end = sliceEnd.start
        // Only include delays that are visible within the viewing area.
        if (
          end > start &&
          ((start >= xStart && start <= xEnd) ||
            (end <= xEnd && end >= xStart) ||
            (start <= xStart && end >= xEnd))
        ) {
          delays.push({
            start,
            end,
          })
        }
      })
    })
    return delays
  }

  getRenderData(
    psChartStore: PsChartStore,
    threads: Thread[],
    isFavorites: boolean,
    shouldRenderMeasurement: boolean,
    measurementPoints: MeasurementPoints,
    connectionCurves?: ConnectionCurves,
  ): RenderEngineData {
    return {
      psChartStore: psChartStore,
      threads: threads,
      isFavorites: isFavorites,
      shouldRenderMeasurement: shouldRenderMeasurement,
      activeDepthsByThreadId: psChartStore.traceAnalyzeStore.activeThreadsDepthsFromChain,
      maxDepthByThreadId: psChartStore.traceAnalyzeStore.depthByThreadId,
      hState: psChartStore.hState,
      vState: psChartStore.vState,
      flagsState: psChartStore.flagsStore,
      videoState: psChartStore.videoPlayerStore,
      annotationsState: psChartStore.annotationsStore,
      connectionCurves,
      isTransparentConnectionEnabled: psChartStore.isTransparentConnectionEnabled,
      isThreadShrunkModeEnabled:
        psChartStore.traceAnalyzeStore.chainExists &&
        psChartStore.traceAnalyzeStore.showShrunkModeDepth,
      measurementPoints,
      linkModeSlice: psChartStore.linkModeSliceId
        ? this.psChartStore.sliceById.get(psChartStore.linkModeSliceId)
        : undefined,
      delays: this.prepareDelaysData(),
    }
  }

  private onClick() {
    this.psChartStore.flagsStore.clearSelected()
    const result = this.findElementInChart()
    if (this.psChartStore.isLinkModeActive) {
      if (result != null && this.psChartStore.linkModeSliceId != null && isSlice(result)) {
        const fromSlice = this.psChartStore.sliceById.get(this.psChartStore.linkModeSliceId)!
        this.psChartStore
          .connectSlices(fromSlice, result)
          .then(() => this.analytics.track('connect-slices'))
          .catch((reason) =>
            this.toaster.error(reason, 'psChart.error.connection.cantConnectSlices'),
          )
      }
    } else if (isSlice(result)) {
      const thread = this.psChartStore.traceDataState.threadsById.get(result.threadId)!
      const threadsTopBottomMap = this.psChartStore.traceAnalyzeStore.favIdSet.has(result.threadId)
        ? this.psChartStore.vState.favThreadsTopBottomMap
        : this.psChartStore.vState.mainThreadsTopBottomMap
      const [threadTop] = threadsTopBottomMap.get(result.threadId)!
      const relativePointY = this.pointer.getRelativePoint(
        this.settings.renderEngine.headerHeight,
      )!.y
      const offset = relativePointY - (this.psChartStore.vState.yStart + relativePointY - threadTop)

      this.psChartStore.setSelectedSlice(result)
      moveToThread(thread, this.psChartStore, offset)
    } else if (result != null && this.psChartStore.traceAnalyzeStore.selectedSlice != null) {
      const threadsTopBottomMap = this.psChartStore.traceAnalyzeStore.favIdSet.has(result.id)
        ? this.psChartStore.vState.favThreadsTopBottomMap
        : this.psChartStore.vState.mainThreadsTopBottomMap
      this.psChartStore.setSelectedSlice(null)
      const [threadTop] = threadsTopBottomMap.get(result.id)!
      const relativePoint = this.pointer.getRelativePoint(this.settings.renderEngine.headerHeight)
      if (relativePoint !== null) {
        const offset =
          relativePoint.y - (this.psChartStore.vState.yStart + relativePoint.y - threadTop)
        moveToThread(result, this.psChartStore, offset)
      }
    } else {
      return null
    }
  }

  private onHover = throttle(16, () => {
    const result = this.findElementInChart()
    if (isSlice(result)) {
      const title = this.isTextShrink(result) ? getSliceTitleWithArgs(result) : null
      const point = this.pointer.absolutePointer

      if (title && point) {
        this.psChartStore.setTooltip({
          point,
          title,
        })
      }
    } else {
      this.hideTooltip()
    }
  })

  private hideTooltip() {
    this.psChartStore.setTooltip(null)
  }

  getSliceRectAndThread = (slice: Slice) => {
    const thread = this.psChartStore.traceDataState.threadsById.get(slice.threadId)!
    const threadActiveDepths =
      this.psChartStore.traceAnalyzeStore.activeThreadsDepthsFromChain.get(slice.threadId) ?? []

    return {
      rect: getBorderRect(
        getSliceVisibleRect(
          thread,
          threadActiveDepths,
          slice,
          this.psChartStore,
          this.settings.sliceRectMinWidth,
        ),
        this.settings.renderEngine.foundSliceBorderWidth,
      ),
      thread,
    }
  }

  isTextShrink = (slice: Slice) => {
    const {
      rect: { w },
    } = this.getSliceRectAndThread(slice)
    const title = getSliceTitleWithArgs(slice)
    const maxWidth = w - this.settings.renderEngine.basicRenderer.blockPaddingX * 2
    const textToDraw = this.baseTextMeasurer.getEllipsizedText(title, maxWidth)
    return textToDraw !== title
  }

  findElementInChart(): Slice | Thread | null {
    const point = this.pointer.getRelativePoint()
    const isMain = this.pointer.isMain()
    const isFav = !isMain && this.pointer.isFav()
    if (point == null || !(isMain || isFav)) {
      return null
    }

    const threads = isMain
      ? this.psChartStore.traceAnalyzeStore.mainThreads
      : this.psChartStore.traceAnalyzeStore.favThreads

    const time = point.x * this.psChartStore.hState.timePerPx + this.psChartStore.hState.xStart
    const posY = isMain ? this.psChartStore.vState.yStart : this.psChartStore.vState.yStartFav
    const y = posY + point.y - this.settings.renderEngine.headerHeight
    const threadsTopBottomMap = isFav
      ? this.psChartStore.vState.favThreadsTopBottomMap
      : this.psChartStore.vState.mainThreadsTopBottomMap
    const thread = threads.find((curThread) => {
      const [curThreadTop, curThreadBottom] = threadsTopBottomMap.get(curThread.id)!
      return y >= curThreadTop && y <= curThreadBottom
    })
    if (thread) {
      const [threadTop] = threadsTopBottomMap.get(thread.id)!
      const threadDepthUnderPointer = Math.floor((y - threadTop) / this.blockHeight)
      const sliceVariations = thread.slicesByDepth.get(threadDepthUnderPointer)
      if (sliceVariations === undefined) {
        return thread
      }
      const slice = sliceVariations.find((sliceVariant) => {
        return sliceVariant.start <= time && time <= sliceVariant.end
      })
      return slice ?? thread
    }
    return null
  }

  setCanvasSizeParams() {
    const favCanvasHeight = this.psChartStore.vState.favCanvasHeight

    // TODO: can be fractional! What should we do in this case?
    const pixelRatio = window.devicePixelRatio
    const fullChartWidth = this.psChartStore.hState.width

    this.favCanvas.style.width = fullChartWidth + 'px'
    this.favCanvas.style.height = favCanvasHeight + 'px'
    this.favCanvas.width = Math.floor(fullChartWidth * pixelRatio)
    this.favCanvas.height = Math.floor(favCanvasHeight * pixelRatio)

    const mainCanvasHeight = this.psChartStore.vState.mainCanvasHeight
    this.mainCanvas.style.width = fullChartWidth + 'px'
    this.mainCanvas.style.height = mainCanvasHeight + 'px'
    this.mainCanvas.style.top = favCanvasHeight + 'px'
    this.mainCanvas.width = Math.floor(fullChartWidth * pixelRatio)
    this.mainCanvas.height = Math.floor(mainCanvasHeight * pixelRatio)

    this.favContext.scale(pixelRatio, pixelRatio)
    this.mainContext.scale(pixelRatio, pixelRatio)

    this.mainRenderEngine.renderEmpty(
      this.getRenderData(
        this.psChartStore,
        [],
        false,
        false,
        this.getMeasurementPoints(),
        getEmptyConnectionCurves(),
      ),
    )
    this.favRenderEngine.renderEmpty(
      this.getRenderData(
        this.psChartStore,
        [],
        true,
        false,
        this.getMeasurementPoints(),
        getEmptyConnectionCurves(),
      ),
    )
  }

  private hideMeasurement() {
    if (!this.psChartStore.isMeasurementModeActive) {
      return
    }
    this.startMeasurementPoint = null
    this.psChartStore.disableMeasurementMode()
    this.prepareDataAndRender()
  }
}
