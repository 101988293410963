import React, { useContext } from 'react'
import 'locale/i18n'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CompositionRoot } from 'contexts/CompositionRoot'
import { Axios } from 'axios'
import { Api } from 'api/Api'
import { ExceptionHandler } from 'utils/ExceptionHandler'

export const DiContext = React.createContext<DiContextInterface | null>(null)

export interface DiContextInterface {
  compositionRoot: CompositionRoot
}

export function useDi(): DiContextInterface {
  const context = useContext(DiContext)
  if (context == null) {
    throw new Error('DI context is not initialized')
  }
  return context
}

export function useAxios(): Axios {
  return useDi().compositionRoot.axios
}

export function useApi(): Api {
  return useDi().compositionRoot.api
}

export function useExceptionHandler(): ExceptionHandler {
  return useDi().compositionRoot.exceptionHandler
}

interface DiContextProviderProps {
  children: React.ReactNode
  compositionRoot: CompositionRoot
}

export const DiContextProvider = ({ compositionRoot: cr, children }: DiContextProviderProps) => {
  return (
    <DiContext.Provider value={{ compositionRoot: cr }}>
      <QueryClientProvider client={cr.queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </DiContext.Provider>
  )
}
