import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import NProgress from 'nprogress'
import { Trans, useTranslation } from 'react-i18next'

import { Layout } from 'components/Layout'
import { Icon } from 'components/Icon'
import { Button, ButtonVariantEnum } from 'components/Button'
import { PATH_TEAM } from 'pages/TeamPage'
import { ProjectUser } from 'components/projects/ProjectUser'
import { ProjectInviteModal } from 'components/projects/ProjectInviteModal'
import { TeamsList } from 'components/teams/TeamsList'
import { useProjectUsersQuery, useTeamsQuery, useUserQuery } from 'hooks/useApiQuery'
import { getOsName } from 'utils/getOsName'
import { getTechName } from 'utils/getTechName'
import { ProjectDto, ProjectRole, TeamRole } from 'api/models'
import { getTeamDomain } from 'utils/getTeamDomain'
import { ProjectSettings } from 'components/projects/ProjectSettings'

export const PATH_PROJECT_CONTRIBUTORS = '/projects/:projectUrlName/project-contributors'

export const ProjectContributorsPage = () => {
  const { t } = useTranslation()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess } = useTeamsQuery()
  const { isSuccess: isProjectUsersSuccess, data: projectUsers } = useProjectUsersQuery({
    projectUrlName,
  })
  const teamUrlName = projectUsers?.project.team.urlName as string

  const isSuperAdmin = user?.roles.isSuperAdmin
  const isTeamAdmin = user?.roles.teams[projectUsers?.project.team.id as number] === TeamRole.ADMIN
  const isProjectAdmin =
    user?.roles.projects[projectUsers?.project.id as number] === ProjectRole.ADMIN
  const isSuperAdminOrTeamAdmin = isSuperAdmin || isTeamAdmin
  const isSuperOrTeamOrProjectAdmin = isSuperAdminOrTeamAdmin || isProjectAdmin

  const [projectInviteModalOpened, setProjectInviteModalOpened] = useState(false)
  const handleInviteContributorClick = () => setProjectInviteModalOpened(true)
  const handleProjectInviteModalClose = () => setProjectInviteModalOpened(false)

  const isLoaded = isUserSuccess && isTeamsSuccess && isProjectUsersSuccess

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const isLastTeamOrProjectAdmin = useMemo(
    () =>
      projectUsers?.users.filter(
        (item) => item.teamRole === TeamRole.ADMIN || item.projectRole === ProjectRole.ADMIN,
      ).length === 1,
    [projectUsers?.users],
  )

  const sortedProjectUsers = useMemo(() => {
    const multiplier = 1
    return projectUsers?.users.sort((a, b) => {
      if (a.lastActive === null || a.lastActive === undefined) {
        return 1 * multiplier
      }
      if (b.lastActive === null || b.lastActive === undefined) {
        return -1 * multiplier
      }
      return (
        b.lastActive.localeCompare(a.lastActive, undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * multiplier
      )
    })
  }, [projectUsers?.users])

  return (
    <Layout
      headerProps={{ screenTitleKey: 'projectContributors', hiddenProjectSelect: true }}
      pageConfig={{
        withoutNavbar: true,
        withoutStyledContent: true,
      }}
    >
      {isUserSuccess && isTeamsSuccess && (
        <main className="flex flex-1">
          <TeamsList activeTeamUrlName={teamUrlName} />
          {isProjectUsersSuccess && (
            <div className="flex-1 overflow-hidden">
              <div className="pr-[24px] pl-[109px] 1920:pl-[162px]">
                <div className="relative flex items-center py-[28px]">
                  <Icon
                    as={Link}
                    to={generatePath(PATH_TEAM, { teamUrlName })}
                    icon="arrow-round-l"
                    aria-label={t('goToBack')}
                    className="text-icon transition-colors absolute left-[-85px] mt-[3px] text-gray-normal hover:text-white 1920:left-[-138px]"
                  />
                  <div className="flex-1">
                    <h1 className="inline-block align-middle text-header-small mr-[16px]">
                      {projectUsers?.project.name}
                    </h1>
                    <div className="inline-block mr-[16px] align-middle shrink-0 text-small tracking-wide text-gray-normal">
                      {t('projects.projectContributorsPage.created')}{' '}
                      {dayjs(projectUsers?.project.dateCreated).format('MMMM DD YYYY')}
                    </div>
                  </div>
                  <div className="flex items-center shrink-0">
                    {isSuperAdminOrTeamAdmin && (
                      <ProjectSettings
                        className="mr-[16px]"
                        user={user}
                        project={projectUsers?.project as ProjectDto}
                      />
                    )}
                    {isSuperOrTeamOrProjectAdmin && (
                      <Button
                        variant={ButtonVariantEnum.Outlined}
                        onClick={handleInviteContributorClick}
                        isSmall
                      >
                        {t('projects.projectContributorsPage.inviteContributor')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="pb-[68px] overflow-x-auto">
                <div className="flex pl-[109px] 1920:pl-[162px]">
                  <div className="shrink-0 w-[200px] pr-[8px] 1920:w-[308px]">
                    {projectUsers?.project.image && (
                      <img
                        src={projectUsers?.project.image?.tq.url}
                        alt=""
                        aria-hidden="true"
                        className="w-[108px] h-[108px] mb-[10px] rounded-[28px] object-cover"
                      />
                    )}
                    <div className="text-gray-normal text-normal tracking-wide">
                      <div className="text-white">{getOsName(projectUsers?.project.os)}</div>
                      <div>{getTechName(projectUsers?.project.tech)}</div>
                      <div className="break-words">{projectUsers?.project.appId}</div>
                    </div>
                    <div className="mt-[15px] pt-[10px] border-t border-t-white/10 text-normal tracking-wide">
                      {projectUsers?.project.team.domain && (
                        <div>{getTeamDomain(projectUsers?.project.team.domain)}</div>
                      )}
                      <div className="text-small text-gray-normal max-w-[150px]">
                        <Trans
                          i18nKey="domainJoin.text"
                          components={{
                            joinStateComponent: <span className="text-white" />,
                          }}
                          values={{
                            joinState: t('domainJoin.doWhitelist', {
                              context: String(projectUsers?.project.doWhitelist || false),
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 pr-[24px]">
                    <div className="grid grid-cols-project-users py-[8px] rounded-sm bg-dark-dark3">
                      {[
                        t('projects.projectContributorsPage.columns.name'),
                        t('projects.projectContributorsPage.columns.role'),
                        t('projects.projectContributorsPage.columns.lastActive'),
                      ].map((item, index) => (
                        <div
                          className="px-[16px] text-small tracking-wide text-gray-normal"
                          key={String(index)}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                    {sortedProjectUsers?.map((item, index) => (
                      <ProjectUser
                        {...item}
                        isSuperAdmin={isSuperAdmin}
                        isSuperOrTeamOrProjectAdmin={isSuperOrTeamOrProjectAdmin}
                        authUserId={user?.id}
                        teamUrlName={teamUrlName}
                        isLastTeamOrProjectAdmin={isLastTeamOrProjectAdmin}
                        key={String(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      )}
      <ProjectInviteModal
        isOpen={projectInviteModalOpened}
        onClose={handleProjectInviteModalClose}
      />
    </Layout>
  )
}
