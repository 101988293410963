import { observer } from 'mobx-react-lite'
import { GlobalTimeline } from 'components/global-timeline/GlobalTimeline'
import { FlameChart } from './flame-chart/FlameChart'
import { Threads } from 'components/ps-chart/threads-panel/Threads'
import { useScrollListener } from './hooks/useScrollListener'
import { DetailsView } from './details-view/DetailsView'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ActionsPanel } from 'components/ps-chart/actions-panel/ActionsPanel'
import { ChartScroll } from 'components/ps-chart/ChartScroll'
import { VideoTimeline } from 'components/video-timeline/VideoTimeline'
import { VideoStatus } from 'components/video-timeline/VideoStatus'
import { ChartExtensions } from './PsChartExtensions'

export const PsChart = observer(function PsChart(props: { psChartStore: PsChartStore }) {
  const { psChartStore } = props

  return (
    <main className="flex-grow flex relative overflow-hidden">
      <div className="flex flex-grow flex-col overflow-hidden">
        <ChartExtensions
          psChartStore={psChartStore}
          tracePageParams={psChartStore.chartPageParams}
        />
        <ScrolledChart psChartStore={psChartStore} />
      </div>
      <DetailsView psChartStore={psChartStore} />
    </main>
  )
})

// This component is detached to be NOT observable
export const ScrolledChart = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const scrolledContainerRef = useScrollListener(psChartStore)

  return (
    <div className="flex flex-grow">
      <div className="flex flex-grow relative" ref={scrolledContainerRef}>
        <Threads psChartStore={psChartStore} />
        <FlameChart psChartStore={psChartStore} />
        <ActionsPanel psChartStore={psChartStore} />
        <ChartScroll psChartStore={psChartStore} />
      </div>
    </div>
  )
}

export interface OverviewTimelineProps {
  psChartStore: PsChartStore
  isVideoPreviewOnly: boolean
}

export const OverviewTimeline = observer(function OverviewTimeline(props: OverviewTimelineProps) {
  const { psChartStore, isVideoPreviewOnly } = props
  return isVideoPreviewOnly || psChartStore.isVideoPreviewInGlobalTimelineEnabled ? (
    <div className="relative">
      <VideoStatus psChartStore={psChartStore} />
      <VideoTimeline psChartStore={psChartStore} />
    </div>
  ) : (
    <GlobalTimeline psChartStore={psChartStore} />
  )
})
