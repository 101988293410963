import i18n from 'i18next'
import common from './en/en-common.json'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

export const COMMON_NS = 'common'

export const resources = { en: { common } } as const

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: 'en',
    ns: [COMMON_NS],
    defaultNS: COMMON_NS,
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
