import React from 'react'
import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { Popover, Transition } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'

import { Icon } from 'components/Icon'
import { Count } from 'components/Count'
import { Filter, FilterProps } from './Filter'
import { DateFilter, TraceTimesType } from './DateFilter'
import { useOrderStorage, OrderSortingType, OrderStorageKey } from 'hooks/useOrderStorage'

interface TableHeadColumnProps {
  param: string
  name: string
  count?: number
  showOrder?: boolean
  filter?: FilterProps
  dateFilter?: boolean
  traceTimes: TraceTimesType
  getActiveOrder: () => string
  isOrderDesc: () => boolean
}

export const TableHeadColumn = ({
  param,
  name,
  count,
  showOrder,
  filter,
  dateFilter,
  traceTimes,
  getActiveOrder,
  isOrderDesc,
}: TableHeadColumnProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { writeSortingToStorage } = useOrderStorage(OrderStorageKey.TRACE_SORTING_KEY)
  const isOrder = getActiveOrder() === param

  const handleOrderButtonClick = () => {
    const newSorting: OrderSortingType = {
      columnOrder: param,
    }

    searchParams.set('columnOrder', param)
    if (isOrderDesc()) {
      delete newSorting.order
      searchParams.delete('order')
    } else {
      newSorting.order = 'desc'
      searchParams.set('order', 'desc')
    }

    writeSortingToStorage(newSorting)
    setSearchParams(searchParams)
  }

  return (
    <HeadColumn>
      <Content>
        <LeftSide>
          {name} {count && <CustomCount>{count}</CustomCount>}
          {showOrder && (
            <OrderIcon
              isOpen={isOrder}
              isOrderDesc={isOrderDesc()}
              as="button"
              icon="arrow-drop-d"
              onClick={handleOrderButtonClick}
            />
          )}
        </LeftSide>
        {(dateFilter || (filter?.options && filter?.options?.length > 0)) && (
          <Popover as={React.Fragment}>
            {({ open }) => (
              <>
                <Popover.Button as={FilterButton} className={open ? 'is-open' : ''}>
                  <Icon icon="filters" className="text-icon" />
                </Popover.Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <FilterView>
                    {dateFilter ? (
                      <DateFilter param={param} traceTimes={traceTimes} />
                    ) : (
                      <Filter {...filter} param={param} />
                    )}
                  </FilterView>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </Content>
    </HeadColumn>
  )
}

export const HeadColumn = styled.div`
  ${tw`text-small tracking-wide`}
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.gray.normal};
  outline: none;
`

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-left: -8px;
  padding-right: 5px;
  padding-left: 8px;

  ${({ theme }) => theme.notTouchScreen} {
    ${HeadColumn}:hover & {
      background-color: ${({ theme }) => theme.colors.rollover.dark};
    }
  }
`

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`

const CustomCount = styled(Count)`
  margin-left: 8px;
`

export const OrderIcon = styled(Icon)<{ isOpen: boolean; isOrderDesc: boolean }>`
  ${tw`transition text-icon`}
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  ${({ isOrderDesc }) =>
    isOrderDesc &&
    css`
      transform: scaleY(-1);
    `}

  ${({ theme }) => theme.notTouchScreen} {
    ${HeadColumn}:hover & {
      opacity: 1;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const FilterButton = styled.button`
  ${tw`transition`}
  opacity: 0;
  outline: none;

  &.is-open {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.notTouchScreen} {
    ${HeadColumn}:hover & {
      opacity: 1;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const FilterView = styled.div`
  ${tw`tracking-normal`}
  position: absolute;
  z-index: 1;
  top: 100%;
  right: -50px;
  left: 0;
  width: auto;
  margin-top: 1px;
`
