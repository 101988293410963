export interface SliceArgument {
  key?: string
  value: string
}

export interface Slice {
  id: number
  title: string
  /**
   * Nanoseconds
   */
  start: number
  /**
   * Nanoseconds
   */
  end: number
  color: string
  level: number

  parent: Slice | null
  root: Slice | null

  rootPositionIndex: number
  children?: Slice[]

  threadId: number
  closureId: number | null
  objectId: number
  // arguments is a reserved word in JS
  args: SliceArgument[]
  isCluster?: boolean
  /** Added for {@link PsChartStore.isStaticPageMode} */
  extra?: Extra
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSlice = (object: Record<string, any> | null): object is Slice => {
  return object !== null && 'threadId' in object
}

export const OBJECT_ID_EMPTY_VALUE = -1

/** Added for {@link PsChartStore.isStaticPageMode} */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Extra = { [key: string]: any }
