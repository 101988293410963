import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Tooltip, TooltipChildrenRef } from 'components/tooltip/Tooltip'
import { TooltipPlace } from 'components/tooltip/types'
import commonTexts from 'locale/en/en-common.json'

export type TooltipId = keyof typeof commonTexts.tooltips
export type ShortcutId = keyof typeof commonTexts.shortcuts

type ActionsTooltipProps = {
  text?: string
  tooltipId?: TooltipId
  children: ReactElement
  childrenRef?: TooltipChildrenRef
  offsetY?: number
  offsetX?: number
  place?: TooltipPlace
  disabled?: boolean
}

export const ActionTooltip = (props: ActionsTooltipProps) => {
  const { t } = useTranslation()
  const { disabled, text, children, tooltipId, ...otherProps } = props
  const title = tooltipId ? t('tooltips.' + tooltipId, tooltipId) : text
  let shortcuts: ShortcutId[] | undefined
  if (tooltipId) {
    shortcuts = globalShortcuts.get(tooltipId)
  }

  return disabled ? (
    <>{children}</>
  ) : (
    <Tooltip
      animated={true}
      showAnimationTime={400}
      showDelay={500}
      content={
        title ? (
          <PlainActionsTooltip
            title={title}
            actions={shortcuts && shortcuts.map((shortcut) => t('shortcuts.' + shortcut))}
          />
        ) : null
      }
      {...otherProps}
    >
      {children}
    </Tooltip>
  )
}

const globalShortcuts = new Map<TooltipId, ShortcutId[]>([
  ['measurementTool', ['shift', 'moveCursor']],
  ['flagDetailsHide', ['tbd']],
  ['search', ['f']],
  ['flagDelete', ['delete']],
  ['connectionCreate', ['c']],
  ['filter', ['k']],
  ['searchExit', ['esc']],
  ['transparentMode', ['t']],
  ['dimDisconnectedSlices', ['p']],
  ['prevPath', ['<']],
  ['nextPath', ['>']],
  ['renderType', ['tbd']],
])

export const PlainActionsTooltip = (props: { title: string; actions?: string[] }) => {
  const { title, actions } = props

  return (
    <div
      className={classNames(
        'flex items-center h-[24px] px-[8px] bg-dark-dark5 rounded-sm',
        actions && 'pl-[12px]',
      )}
    >
      <p className="text-small text-gray-normal">{title}</p>
      {actions && (
        <span className="flex pl-[10px] mr-[-4px]">
          {actions.map((action, index) => (
            <span
              className="text-[11px] h-[16px] px-[4px] mx-[1px] text-dark-dark5 bg-gray-normal rounded-sm"
              key={String(index)}
            >
              {action}
            </span>
          ))}
        </span>
      )}
    </div>
  )
}
