import React, { useContext, useEffect, useRef } from 'react'
import classNames from 'classnames'

import { GuidesHeader, Header, HeaderProps } from './Header'
import { Navbar } from './Navbar'
// import { Hints } from "./Hints"
import { BottomButton } from 'components/BottomButton'
import { LayoutContext } from 'contexts/layout-context'
import { Footer } from './Footer'

export interface LayoutProps {
  children?: React.ReactNode
  className?: string
  headerProps?: HeaderProps
  headerComponent?: React.ReactNode
  teamUrlName?: string
  pageConfig?: {
    withBottomButton?: boolean
    withoutStyledContent?: boolean
    withoutNavbar?: boolean
  }
}

export const Layout = ({
  children,
  className,
  headerProps,
  pageConfig,
  headerComponent,
  teamUrlName,
}: LayoutProps) => {
  const contentEl = useRef<HTMLDivElement>(null)

  const { withBottomButton, withoutStyledContent, withoutNavbar } = pageConfig || {}

  const {
    isWithoutNavbar,
    setWithoutNavbar,
    hintsIsOpened,
    // setHintsOpened
  } = useContext(LayoutContext)

  useEffect(() => {
    if (Boolean(withoutNavbar) !== isWithoutNavbar) {
      setWithoutNavbar(Boolean(withoutNavbar))
    }
  }, [isWithoutNavbar, withoutNavbar, setWithoutNavbar])

  return (
    <div className="flex h-full flex-col items-stretch">
      {headerComponent ? (
        headerComponent
      ) : headerProps?.isGuidesPage ? (
        <GuidesHeader teamUrlName={teamUrlName} />
      ) : (
        <Header {...headerProps} />
      )}
      <div
        className={classNames('flex-1 overflow-y-auto scrolling-touch flex flex-col', className)}
        id="__CONTENT__"
      >
        {withoutStyledContent ? (
          children
        ) : (
          <main className="flex flex-1">
            {/* TODO: uncomment when hints will be ready */}
            {/* <Hints isOpened={hintsIsOpened} setOpened={setHintsOpened} /> */}
            <div className="overflow-hidden flex flex-1 flex-col" ref={contentEl}>
              {children}
            </div>
          </main>
        )}
        {withBottomButton && <BottomButton contentEl={contentEl} hintsOpened={hintsIsOpened} />}
      </div>
      {!withoutNavbar && <Navbar />}
      {headerProps?.isGuidesPage && <Footer />}
    </div>
  )
}
