import React, { useState } from 'react'

export interface LayoutContextProps {
  isWithoutNavbar?: boolean
  setWithoutNavbar: React.Dispatch<React.SetStateAction<boolean>>
  hintsIsOpened: boolean
  setHintsOpened: React.Dispatch<React.SetStateAction<boolean>>
}

interface LayoutContextProviderProps {
  children: React.ReactNode
}

export const LayoutContext = React.createContext<LayoutContextProps>({
  isWithoutNavbar: false,
  setWithoutNavbar: () => {},
  hintsIsOpened: false,
  setHintsOpened: () => {},
})

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {
  const [hintsIsOpened, setHintsOpened] = useState(false)
  const [isWithoutNavbar, setWithoutNavbar] = useState(false)

  return (
    <LayoutContext.Provider
      value={{ isWithoutNavbar, setWithoutNavbar, hintsIsOpened, setHintsOpened }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
