import React, { useContext } from 'react'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { colors } from 'utils/styles/colors'
import { LayoutContext } from 'contexts/layout-context'
import { IconColored } from './common/IconColored'

export const StyledToaster = () => {
  const { isWithoutNavbar } = useContext(LayoutContext)
  return (
    <View data-testid="toast-root">
      <Toaster
        position="bottom-center"
        containerStyle={{ inset: isWithoutNavbar ? 24 : 72 }}
        toastOptions={{
          className: '!text-small !tracking-wide !shadow-base',
          style: {
            maxWidth: 'none',
            padding: '8px 8px 8px 16px',
            borderRadius: '2px',
            color: colors.white,
            background: colors.dark.dark5,
          },
          duration: 7500,
          success: { style: { background: colors.sky } },
          error: { style: { color: colors.state.bad, background: colors.dark.dark5 } },
        }}
      >
        {(t) => (
          <ToastBar toast={t} position="bottom-center">
            {({ message }) => (
              <>
                {message}
                {t.type !== 'loading' &&
                  (t.icon || (
                    <IconColored
                      className={classNames(' ml-[10px]', t.type === 'error' && 'text-gray-normal')}
                      onClick={() => toast.dismiss(t.id)}
                      icon="cross"
                      size={24}
                    />
                  ))}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </View>
  )
}

export function toastWithTitle(title: string, message: string, isError: boolean) {
  return (
    <>
      <div className="mr-4">
        {title && (
          <h4
            className={`${isError ? 'text-state-bad' : ''} font-medium text-small leading-relaxed`}
          >
            {title}
          </h4>
        )}
        <p
          className={`${
            title ? 'text-gray-normal' : 'text-state-bad'
          } font-normal text-small leading-relaxed`}
        >
          {message}
        </p>
      </div>
    </>
  )
}

const View = styled.div`
  [role='status'],
  [aria-live='polite'] {
    margin: 0;
  }
`
