import { Layout } from 'components/Layout'
import { FreeTrialQuestionnaire } from 'components/free-trial/FreeTrialQuestionnaire'

export const PATH_FREE_TRIAL_QUESTIONNAIRE = '/free-trial-questionnaire'

export const FreeTrialQuestionnairePage = () => {
  return (
    <Layout
      pageConfig={{ withoutStyledContent: true, withoutNavbar: true }}
      headerProps={{ isGuidesPage: true, hiddenProjectSelect: true }}
    >
      <FreeTrialQuestionnaire />
    </Layout>
  )
}
