import { QUICKSTART_DOCS } from 'api/contentful'
import { QuickstartPages } from './models'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'

export const QUICKSTART = 'quickstart'

export const QUICKSTART_PAGE_TO_CONTENTFUL_ENTRIES = {
  [QuickstartPages.Contents]: QUICKSTART_DOCS.intro,
  [QuickstartPages.FlowLibrary]: QUICKSTART_DOCS.introToFlowFlibrary,
  [QuickstartPages.Annotations]: QUICKSTART_DOCS.introToAnnotations,
  [QuickstartPages.PxA]: QUICKSTART_DOCS.introToPxA,
  [QuickstartPages.TraceViewer]: QUICKSTART_DOCS.introToTraceViewer,
  [QuickstartPages.Insights]: QUICKSTART_DOCS.introToInsights,
}

export const QUICKSTART_PAGES = [
  QuickstartPages.Contents,
  QuickstartPages.FlowLibrary,
  QuickstartPages.Annotations,
  QuickstartPages.PxA,
  QuickstartPages.TraceViewer,
  QuickstartPages.Insights,
]

export const QUICKSTART_MARKDOWN_STYLES = {
  ...MD_STYLES_DEFAULT,
  para: {
    className: 'mb-0 text-[14px] font-[400] leading-[150%] text-gray-normal my-[16px]',
  },
  image: {
    ...MD_STYLES_DEFAULT.image,
    imageWrapper: {
      className: 'bg-dark-default',
    },
  },
}

export const QUICKSTART_MARKDOWN_CONFIG: MarkdownConfig = {
  headerLevelsToCollapse: [2],
  styles: QUICKSTART_MARKDOWN_STYLES,
}

export const QUICKSTART_MARKDOWN_TRY_BLOCK_STYLES = {
  ...MD_STYLES_DEFAULT,
  header: {
    ...MD_STYLES_DEFAULT.header,
    h1: {
      marginClassName: 'mt-8 mb-6',
      otherClassName: 'text-header-normal font-medium text-state-attention',
    },
    h2: {
      marginClassName: 'mt-6 mb-4',
      otherClassName: 'text-header-small font-medium text-state-attention',
    },
    h3: {
      marginClassName: 'mt-4 mb-4',
      otherClassName: 'text-header-tiny font-medium text-state-attention',
    },
    h4: {
      marginClassName: 'my-4',
      otherClassName: 'text-big text-white hover:cursor-pointer',
    },
  },
  para: {
    className: 'mb-0 text-[14px] font-[400] leading-[150%] text-gray-normal my-[16px]',
  },
  image: {
    ...MD_STYLES_DEFAULT.image,
    imageWrapper: {
      className: 'bg-dark-default',
    },
  },
}

export const QUICKSTART_MARKDOWN_TRY_BLOCK_CONFIG: MarkdownConfig = {
  headerLevelsToCollapse: [2, 4],
  styles: QUICKSTART_MARKDOWN_TRY_BLOCK_STYLES,
}
