import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Icon'
import { PATH_ROOT } from 'pages/MainPage'
import { UserMenu } from 'components/UserMenu'

export const LayoutHeader = ({ shouldShowUserMenu = false }) => {
  const { t } = useTranslation()

  return (
    <header
      className={
        'bg-dark-dark5 border-none z-header items-center justify-between h-[var(--topBarHeight)] px-[16px] flex'
      }
    >
      <Link to={PATH_ROOT} aria-label={t('appName')} data-tid="logo" className="mr-[20px]">
        <Icon icon="logo-bold" className="text-gray-normal" />
      </Link>
      {shouldShowUserMenu && <UserMenu />}
    </header>
  )
}
