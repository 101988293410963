import { makeAutoObservable, observable } from 'mobx'

import { Api } from 'api/Api'
import { TracePageParams } from 'api/models'
import { Thread } from 'components/ps-chart/models/Thread'
import { parseSlicesToThreadsFromApi } from 'components/ps-chart/utils/parser'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { Slice } from 'components/ps-chart/models/Slice'
import { getSliceSize, walkSlices } from 'components/ps-chart/utils/slice'

export type ReadonlySliceById = ReadonlyMap<number, Slice>
export type SliceById = Map<number, Slice>

export interface TraceDataState {
  threads: ReadonlyArray<Thread>
  threadsById: ReadonlyMap<number, Thread>
  sliceById: ReadonlySliceById
  reactQueueThread: Thread | null
  hasReactQueueThread: boolean
  reactJSThreadIds: Set<number>
  minSliceLengthNs: number
}

export class TraceDataStore implements TraceDataState {
  private readonly api: Api
  private readonly tracePageParams: TracePageParams
  private readonly settings: PsChartSettings

  private _threads: Thread[] = []
  private _threadsById = new Map<number, Thread>()
  private _sliceById: SliceById = new Map()
  private _minSliceLengthNs = 0

  constructor(api: Api, tracePageParams: TracePageParams, settings: PsChartSettings) {
    makeAutoObservable<
      TraceDataStore,
      'api' | 'tracePageParams' | 'settings' | '_threads' | '_threadsById' | '_sliceById'
    >(this, {
      api: false,
      settings: false,
      tracePageParams: false,
      _threads: observable.ref,
      _threadsById: false,
      _sliceById: false,
    })
    this.tracePageParams = tracePageParams
    this.api = api
    this.settings = settings
  }

  load(): Promise<void> {
    return this.api.getTrace(this.tracePageParams).then((traceData) => {
      const threads = parseSlicesToThreadsFromApi(
        traceData,
        this.settings.renderEngine.palette.slice,
      )
      this.setThreads(threads)
    })
  }

  get threads(): ReadonlyArray<Thread> {
    return this._threads
  }

  get threadsById(): ReadonlyMap<number, Thread> {
    return this._threadsById
  }

  get sliceById(): ReadonlySliceById {
    return this._sliceById
  }

  get minSliceLengthNs(): number {
    return this._minSliceLengthNs
  }

  public setThreads(threads: Thread[]) {
    this.clear()
    this._threads = threads

    let minSlice: Slice | null = null
    let minSize: number = Number.MAX_SAFE_INTEGER

    threads.forEach((thread) => {
      this._threadsById.set(thread.id, thread)

      walkSlices(thread.slices, (slice) => {
        this._sliceById.set(slice.id, slice)

        const currentSize = getSliceSize(slice)
        if (currentSize > 0 && currentSize < minSize) {
          minSize = currentSize
          minSlice = slice
        }
      })
    })
    console.log('smallest slice', minSlice)
    this._minSliceLengthNs = minSize
  }

  get reactQueueThread(): Thread | null {
    return this.threads.find((thread) => thread.title.startsWith('mqt_js')) ?? null
  }

  get hasReactQueueThread(): boolean {
    return this.reactQueueThread !== null
  }

  get reactJSThreadIds(): Set<number> {
    return new Set<number>(
      this.threads
        .filter((thread) => thread.title !== undefined && thread.title.startsWith('js-thread'))
        .map((thread) => thread.id),
    )
  }

  private clear() {
    this._sliceById.clear()
    this._threadsById.clear()
    this._threads = []
    this._minSliceLengthNs = 0
  }
}
