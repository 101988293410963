import { makeAutoObservable, runInAction } from 'mobx'
import i18next from 'i18next'

import { PsChartSettings } from './models/settings'
import { Slice } from './models/Slice'
import { GlobalTimelineContent } from 'components/common/models/Segment'
import { calcThreadsPreviewContent } from 'components/ps-chart/utils/calcPreviewContent'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Api } from 'api/Api'
import { ChartPageParams, ChoreographerPathsDto, NamedLinkDto } from 'api/models'
import { HorizontalStateStore } from 'components/ps-chart/stores/HorizontalStateStore'
import { VerticalStateStore } from 'components/ps-chart/stores/VerticalStateStore'
import { RenderingMeasuringStore } from 'components/ps-chart/stores/RenderingMeasuringStore'
import {
  ConnectionErrorCode,
  getAncestorsConnectionError,
  getConnectedSlicesPositionError,
  getConnectionError,
} from 'components/ps-chart/stores/connections-store/getConnectionError'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { Point } from 'components/ps-chart/models/helper-types'
import { SearchState } from 'components/ps-chart/stores/SearchState'
import {
  createNamedLink,
  removeLinksBySourceId,
} from 'components/ps-chart/stores/connections-store/createNamedLink'
import { fillNamedLinks } from 'components/ps-chart/stores/connections-store/fillNamedLinks'
import { moveToSlice } from 'components/ps-chart/utils/moveTo'
import { ChartRendererStore } from 'components/ps-chart/stores/ChartRendererStore'
import { walkSlices } from 'components/ps-chart/utils/slice'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { cluster } from 'components/ps-chart/flame-chart/logic/clustering'
import { Toaster } from 'hooks/useToaster'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { VideoTimelineStore } from 'components/ps-chart/stores/VideoTimelineStore'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { TooltipAnimationSettings } from 'components/tooltip/Tooltip'
import { VideoDataStore } from 'components/ps-chart/stores/VideoDataStore'
import { AnnotationsDataStore } from 'components/ps-chart/stores/AnnotationsDataStore'
import React from 'react'
import { FlagsDataStore } from 'components/ps-chart/stores/FlagsDataStore'
import { ReadonlySliceById, TraceDataState } from 'components/ps-chart/stores/TraceDataStore'
import { TraceAnalyzeStore } from 'components/ps-chart/stores/TraceAnalyzeStore'

enum DataLoadingState {
  EMPTY,
  LOADING,
  LOADED,
}

export interface Error {
  id: number
  msg: string
}

export interface Tooltip {
  title: string
  point: Point
  visible?: boolean
  settings?: TooltipAnimationSettings
}

export interface PsChartFeatures {
  flags: boolean
  annotations: AnnotationsFeatureState
  measurementTool: boolean
  //TODO: remove sliceNameTooltip after refactoring annotations page and removing PsChartRenderer from there
  // https://linear.app/productscience/issue/PST-872/refactor-annotations-page-to-not-use-pschartrenderer-there
  sliceNameTooltip: boolean
}

export interface AnnotationsFeatureState {
  enabled: boolean
  draggable: boolean
  clickable: boolean
}

export const psChartStoreContext = React.createContext<PsChartStore | null>(null)

export class PsChartStore {
  readonly chartSettings: PsChartSettings
  readonly chartFeatures: PsChartFeatures = {
    flags: true,
    measurementTool: true,
    annotations: { enabled: true, draggable: false, clickable: true },
    sliceNameTooltip: true,
  }

  private dataLoadingState = DataLoadingState.EMPTY

  readonly traceDataState: TraceDataState
  readonly traceAnalyzeStore: TraceAnalyzeStore
  readonly hState: HorizontalStateStore
  readonly vState: VerticalStateStore

  readonly flagsStore: FlagsStore

  readonly annotationsStore: AnnotationsStore

  isLinkModeActive = false

  isMeasurementModeActive = false

  linkModeSliceId: number | null = null

  private readonly api: Api

  readonly chartPageParams: Required<ChartPageParams>

  tooltip: Tooltip | null = null

  readonly renderingMeasuring: RenderingMeasuringStore = new RenderingMeasuringStore()

  readonly searchState: SearchState

  readonly rendererStore: ChartRendererStore

  private isDimDisconnectedSlicesEnabled = false

  isTransparentConnectionEnabled = false

  private readonly toaster: Toaster

  isVideoPreviewInGlobalTimelineEnabled = false

  /**
   * Temporarily. Added to weaken connections constraints for a static page (Eugeny Malutin's work)
   */
  readonly isStaticPageMode: boolean

  readonly videoPlayerStore: VideoPlayerStore

  readonly videoTimelineStore: VideoTimelineStore

  private readonly videoDataStore: VideoDataStore
  private readonly annotationsDataStore: AnnotationsDataStore

  constructor(
    chartSettings: PsChartSettings,
    api: Api,
    chartPageParams: ChartPageParams,
    toaster: Toaster,
    threadsDataState: TraceDataState,
    hStateStore: HorizontalStateStore,
    videoDataStore: VideoDataStore,
    annotationsDataStore: AnnotationsDataStore,
    flagsDataStore: FlagsDataStore,
    isVideoModeEnabled?: boolean,
    isStaticPageMode?: boolean,
  ) {
    makeAutoObservable<PsChartStore, 'api' | 'chartPageParams' | 'videoDataStore' | 'toaster'>(
      this,
      {
        chartSettings: false,
        toaster: false,
        videoDataStore: false,
        api: false,
        chartPageParams: false,
        sliceById: false,
      },
    )
    this.api = api
    this.chartPageParams = chartPageParams
    this.toaster = toaster
    this.isVideoPreviewInGlobalTimelineEnabled = isVideoModeEnabled || false
    this.videoDataStore = videoDataStore
    this.annotationsDataStore = annotationsDataStore
    this.chartSettings = chartSettings
    this.isStaticPageMode = isStaticPageMode ?? false
    this.traceDataState = threadsDataState
    this.traceAnalyzeStore = new TraceAnalyzeStore(
      this.api,
      this.toaster,
      this.traceDataState,
      this.chartSettings.renderEngine.threads,
      this.chartPageParams,
    )
    this.hState = hStateStore
    this.vState = new VerticalStateStore(this.traceAnalyzeStore, chartSettings)
    this.flagsStore = new FlagsStore(
      this.api,
      chartPageParams,
      flagsDataStore,
      this.chartSettings.renderEngine.flags,
      this.chartFeatures,
    )
    this.searchState = new SearchState()
    this.rendererStore = new ChartRendererStore(
      this.traceAnalyzeStore,
      this.hState,
      this.vState,
      this.chartSettings,
    )
    this.videoPlayerStore = new VideoPlayerStore({
      threadsStore: this.traceDataState,
      hState: this.hState,
      videoDataStore,
    })
    this.annotationsStore = new AnnotationsStore(
      this.api,
      chartPageParams,
      this.videoPlayerStore,
      this.chartFeatures.annotations,
      this.annotationsDataStore,
      this.chartSettings.renderEngine.annotation,
    )
    this.videoTimelineStore = new VideoTimelineStore(toaster)
  }

  get sliceById(): ReadonlySliceById {
    return this.traceDataState.sliceById
  }

  setIsVideoModeEnabled(value: boolean) {
    this.isVideoPreviewInGlobalTimelineEnabled = value
  }

  load(
    namedLinks: NamedLinkDto[] | null,
    choreographerPaths: ChoreographerPathsDto | null,
  ): Promise<void> {
    this.fillAutoConnections()
    if (namedLinks) {
      this.setNamedLinks(namedLinks)
    }
    if (choreographerPaths) {
      this.traceAnalyzeStore.pinCycleVariants(choreographerPaths)
    }
    return Promise.all([this.videoPlayerStore.load()]).then()
  }

  dispose() {
    this.videoPlayerStore.dispose()
  }

  get shouldDimDisconnectedSlices() {
    return this.traceAnalyzeStore.chainExists && this.isDimDisconnectedSlicesEnabled
  }

  get isLoading() {
    return this.dataLoadingState === DataLoadingState.LOADING
  }

  get isLoaded() {
    return this.dataLoadingState === DataLoadingState.LOADED
  }

  get isEmpty() {
    return this.dataLoadingState === DataLoadingState.EMPTY
  }

  setIsEmpty() {
    this.dataLoadingState = DataLoadingState.EMPTY
  }

  setIsLoading() {
    this.dataLoadingState = DataLoadingState.LOADING
  }

  setIsLoaded() {
    this.dataLoadingState = DataLoadingState.LOADED
  }

  setTooltip(tooltip: Tooltip | null) {
    this.tooltip = tooltip
  }

  get globalTimelineContent(): GlobalTimelineContent {
    return calcThreadsPreviewContent(
      this.sortedThreads,
      HorizontalStateStore.timePerPx(this.hState.xWidthTotal, this.hState.width),
      this.chartSettings.clusteringMinSliceSizePx,
      this.chartSettings.clusteringStickSizePx,
    )
  }

  moveTo(x: number, y: number) {
    const runInActionMoveTo = () => {
      this.hState.setXStartAndZoom(x)
      this.vState.setYStart(y)
    }
    runInAction(runInActionMoveTo)
  }

  private reset() {
    this.setIsEmpty()
    this.setSelectedSlice(null)
    this.traceAnalyzeStore.sliceLinksBySliceId.clear()
  }

  setSelectedSlice(slice: Slice | null) {
    console.info('setSelectedSlice->slice', slice)
    this.traceAnalyzeStore.setSelectedSlice(slice)

    if (slice) {
      this.flagsStore.clearSelected()
    }
  }

  enableLinkMode(sliceId: number) {
    this.isLinkModeActive = true
    this.linkModeSliceId = sliceId
  }

  disableLinkMode() {
    this.isLinkModeActive = false
    this.linkModeSliceId = null
  }

  enableMeasurementMode() {
    this.isMeasurementModeActive = true
  }

  disableMeasurementMode() {
    this.isMeasurementModeActive = false
  }

  connectSlices(fromSlice: Slice, toSlice: Slice): Promise<void> {
    const conErrorMsg = getConnectionError(
      fromSlice,
      toSlice,
      this.sliceById,
      this.traceAnalyzeStore.sliceLinksBySliceId,
    )
    if (conErrorMsg != null) {
      return Promise.reject(i18next.t(conErrorMsg))
    }

    const newNamedLink = createNamedLink(fromSlice, toSlice, true)

    const previousMainChain = this.traceAnalyzeStore.mainChainSlices

    fillNamedLinks(
      this.sliceById,
      this.traceAnalyzeStore.sliceLinksBySliceId,
      this.traceAnalyzeStore.namedLinksByLinkId,
      [newNamedLink],
    )

    const currentMainChain = this.traceAnalyzeStore.mainChainSlices

    this.setShouldShowAllPathsFilter(previousMainChain, currentMainChain)

    const createdLinks = this.traceAnalyzeStore.sliceLinksBySliceId.get(fromSlice.id) ?? []
    const displayedLink = createdLinks.find((link) => link.toSliceId === toSlice.id)

    if (displayedLink == null) {
      this.toaster.info('psChart.connections.closerLinkWithSameFunc')
    }

    this.disableLinkMode()
    moveToSlice(toSlice.id, this)

    return this.api
      .postNamedLink(this.chartPageParams.projectUrlName, {
        fromName: newNamedLink.fromName,
        toName: newNamedLink.toName,
        type: newNamedLink.type,
        isEditable: true,
      })
      .then((namedLinkDto: NamedLinkDto) => {
        runInAction(() => {
          removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, newNamedLink.id)
          fillNamedLinks(
            this.sliceById,
            this.traceAnalyzeStore.sliceLinksBySliceId,
            this.traceAnalyzeStore.namedLinksByLinkId,
            [namedLinkDto],
          )
        })
      })
      .catch((reason) => {
        runInAction(() => {
          removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, newNamedLink.id)
        })
        return Promise.reject(reason)
      })
  }

  private setShouldShowAllPathsFilter(previousMainChain: Slice[], currentMainChain: Slice[]) {
    if (!this.traceAnalyzeStore.shouldShowAllPaths) {
      const previous = previousMainChain.map(({ id }) => id).join(':')
      const current = currentMainChain.map(({ id }) => id).join(':')

      if (current.includes(previous) && current !== previous) {
        return null
      }

      this.toggleShouldShowAllPaths()

      if (current !== previous) {
        this.toaster.info('psChart.connections.mepChanged')
      } else {
        this.toaster.info('psChart.connections.newLinkOutsideMep')
      }
    }
  }

  disconnectSlice(sliceLink: SliceLink): Promise<void> {
    const sliceId = sliceLink.fromSliceId
    const sourceId = sliceLink.sourceId
    if (sourceId == null) {
      throw new Error("This links can't be deleted because the sourceId is not defined.")
    }

    removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, sourceId)

    const namedLinkDto = this.traceAnalyzeStore.namedLinksByLinkId.get(sourceId)!
    this.traceAnalyzeStore.namedLinksByLinkId.delete(sourceId)
    moveToSlice(sliceId, this)

    if (this.isLinkModeActive) {
      this.linkModeSliceId = sliceId
    }
    return this.api
      .deleteNamedLink(this.chartPageParams.projectUrlName, sourceId)
      .catch((reason) => {
        runInAction(() => {
          fillNamedLinks(
            this.sliceById,
            this.traceAnalyzeStore.sliceLinksBySliceId,
            this.traceAnalyzeStore.namedLinksByLinkId,
            [namedLinkDto],
          )
        })
        return Promise.reject(reason)
      })
  }

  toggleShouldShowAllPaths() {
    this.traceAnalyzeStore.shouldShowAllPaths = !this.traceAnalyzeStore.shouldShowAllPaths
  }

  toggleIsDimDisconnectedSlicesEnabled() {
    this.isDimDisconnectedSlicesEnabled = !this.isDimDisconnectedSlicesEnabled
  }

  toggleRenderTypeMode() {
    this.rendererStore.switchRenderTypeMode()
  }

  enableTransparentConnection() {
    this.isTransparentConnectionEnabled = true
  }

  disableTransparentConnection() {
    this.isTransparentConnectionEnabled = false
  }

  get globalTimelineSearchHighlightsContent(): GlobalTimelineContent {
    const threadLineIndexById = new Map<number, number>()
    this.sortedThreads.forEach((thread, index) => {
      threadLineIndexById.set(thread.id, index)
    })

    const result: GlobalTimelineContent = []

    if (this.searchState.searchResults.length > 0) {
      const rows: Array<Slice>[] = []
      this.searchState.searchResults.forEach((sliceId) => {
        const slice = this.sliceById.get(sliceId)!
        const threadLineIndex = threadLineIndexById.get(slice.threadId)!
        const row = rows[threadLineIndex]
        if (row) {
          row.push(slice)
        } else {
          rows[threadLineIndex] = [slice]
        }
      })

      rows.forEach((row, rowIndex) => {
        const sortedRowSlices = row.sort((a, b) => a.start - b.start)
        result[rowIndex] = cluster(
          sortedRowSlices,
          this.rendererStore.minSliceSize,
          this.rendererStore.stickSize,
          'red',
        )
      })
    }

    return result
  }

  get sortedThreads() {
    return this.traceAnalyzeStore.favThreads.concat(this.traceAnalyzeStore.mainThreads)
  }

  selectFlag(id: number, cid: number | undefined) {
    this.flagsStore.select(id, cid)
  }

  /*
   * It's public only for tests in executionPathFilter.test.ts
   */
  setNamedLinks(namedLinks: NamedLinkDto[]) {
    if (namedLinks.length > 0) {
      fillNamedLinks(
        this.sliceById,
        this.traceAnalyzeStore.sliceLinksBySliceId,
        this.traceAnalyzeStore.namedLinksByLinkId,
        namedLinks,
      )
    }
  }

  /**
   * fillAutoConnections: check for auto links looking for slice closure id.
   * Currently, fillAutoConnections doesn't check if there were any connections before it was called,
   * so it should be called before any manipulation with sliceLinksBySliceId
   */
  private fillAutoConnections() {
    const errorsTable = new Map<ConnectionErrorCode, number>()
    this.traceDataState.threads.forEach((thread) => {
      walkSlices(thread.slices, (slice) => {
        if (slice.closureId == null) {
          return null
        }
        const toSlice = this.sliceById.get(slice.closureId)!
        const conErrorMsg =
          getConnectedSlicesPositionError(slice, toSlice) ||
          getAncestorsConnectionError(slice, toSlice)
        if (!this.isStaticPageMode && conErrorMsg != null) {
          if (!errorsTable.has(conErrorMsg)) {
            errorsTable.set(conErrorMsg, 0)
          }
          errorsTable.set(conErrorMsg, errorsTable.get(conErrorMsg)! + 1)
          return null
        }
        this.traceAnalyzeStore.sliceLinksBySliceId.set(slice.id, [
          {
            fromSliceId: slice.id,
            toSliceId: slice.closureId,
            connectionType: ConnectionType.CLOSURE,
            isEditable: false,
          },
        ])
      })
    })
    errorsTable.forEach((count, errorCode) => {
      console.warn(
        `${count} auto-connections have been filtered out. ErrorMsg: ${i18next.t(errorCode)}`,
      )
    })
  }

  setChartFeatures(features: PsChartFeatures) {
    this.chartFeatures.flags = features.flags
    this.chartFeatures.measurementTool = features.measurementTool
    this.chartFeatures.sliceNameTooltip = features.sliceNameTooltip
    this.chartFeatures.annotations.enabled = features.annotations.enabled
    this.chartFeatures.annotations.draggable = features.annotations.draggable
  }
}
