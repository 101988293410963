import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { FieldErrors, useController } from 'react-hook-form'
import * as yup from 'yup'

import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { FormInput } from 'components/Form/FormInput'
import { Button } from 'components/Button'
import { LinkExpired } from './LinkExpired'
import { useToaster } from 'hooks/useToaster'
import NProgress from 'nprogress'
import { useAuth } from 'contexts/auth-context'
import { isPasswordStrong } from 'utils/validation'
import { TermsAndConditions } from 'components/free-trial/TermsAndConditions'

export const PasswordForm = (props: { verificationToken: string }) => {
  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null)
  const [visibleName, setVisibleName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const { verificationToken } = props
  const { t } = useTranslation()
  const toaster = useToaster()
  const auth = useAuth()
  const formContext = useForm<PasswordFormFields>({
    validationSchema: passwordFormSchema,
  })

  const handleSubmit = useCallback(
    (values: PasswordFormFields) => {
      NProgress.start()
      auth
        .signUpFreeTrial({
          data: {
            inviteToken: verificationToken,
            password: values.password,
          },
        })
        .catch((error) => toaster.error(error))
        .finally(() => NProgress.done())
    },
    [verificationToken, auth, toaster],
  )

  useEffect(() => {
    NProgress.start()
    auth
      .signUpCheckToken({ inviteToken: verificationToken, allowFreeTrial: true, returnData: true })
      .then((data) => {
        setUserEmail(data.email ?? '')
        setVisibleName(data.name || data.email || '')
        setTokenIsValid(true)
      })
      .catch(() => setTokenIsValid(false))
      .finally(() => NProgress.done())
  }, [verificationToken, auth, toaster])

  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<PasswordFormFields>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toaster.error(errorMsg)
      })
    },
    [toaster],
  )

  if (tokenIsValid === null) {
    return null
  } else if (!tokenIsValid) {
    return <LinkExpired inviteToken={verificationToken} />
  }

  return (
    <div>
      <div className="text-center mb-[48px]">
        <h2 className="text-header-small mb-[8px]">{t('auth.freeTrial.passwordForm.title')}</h2>
        {visibleName && (
          <h3 className="text-normal text-gray-normal">
            {t('auth.freeTrial.passwordForm.subTitle', { name: visibleName })}
          </h3>
        )}
      </div>
      <div className="max-w-[320px] mx-auto">
        <Form<PasswordFormFields>
          formContext={formContext}
          onSubmit={handleSubmit}
          onSubmitInvalid={handleSubmitInvalid}
        >
          <PasswordInputsGroup visibleName={visibleName} userEmail={userEmail} />
        </Form>
        <TermsAndConditions />
      </div>
    </div>
  )
}

interface PasswordInputsGroupProps {
  visibleName: string
  userEmail: string
}

const PasswordInputsGroup = ({ visibleName, userEmail }: PasswordInputsGroupProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { t } = useTranslation()
  const passwordController = useController<PasswordFormFields>({ name: 'password' })
  const confirmPasswordController = useController<PasswordFormFields>({
    name: 'confirmPassword',
  })

  const isFormValid = confirmPasswordController.formState.isValid
  const isFormFilled = Boolean(
    passwordController.field.value && confirmPasswordController.field.value,
  )

  const handleVisibleClick = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <>
      <input
        hidden={true}
        name="name"
        value={visibleName}
        type="text"
        readOnly={true}
        autoComplete="username"
      />
      <input
        hidden={true}
        name="email"
        value={userEmail}
        type="text"
        readOnly={true}
        autoComplete="user email"
      />
      <FormInput<PasswordFormFields>
        visible={passwordVisible}
        onMakeVisibleButtonClick={handleVisibleClick}
        name="password"
        type="password"
        checked={isFormValid}
        containerClassName="mb-[24px]"
        className="bg-gray-inputBG"
        placeholder={t('auth.freeTrial.passwordForm.password')}
      />
      <FormInput<PasswordFormFields>
        visible={passwordVisible}
        onMakeVisibleButtonClick={handleVisibleClick}
        name="confirmPassword"
        type="password"
        checked={isFormValid}
        containerClassName="mb-[24px]"
        className="bg-gray-inputBG"
        placeholder={t('auth.freeTrial.passwordForm.confirmPassword')}
      />
      <Button className="w-full" isSmall type="submit" disabled={!isFormFilled}>
        {t('auth.freeTrial.passwordForm.button')}
      </Button>
    </>
  )
}

interface PasswordFormFields {
  password: string
  confirmPassword: string
}

export const passwordFormSchema = yup.object<ValidationSchema<PasswordFormFields>>({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .test('isPasswordTheSame', 'auth.common.isNotSamePasswords', function (value) {
      return this.parent.password === value
    })
    .test('isPasswordStrong', 'auth.common.strongPassword', isPasswordStrong),
})
