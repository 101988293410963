import { Fragment } from 'react'
import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Icon } from 'components/Icon'
import { transitionProps } from './ListBox'
import { SectionType } from './models'

interface PopoverProps<T> {
  disabled?: boolean
  className?: string
  popoverClassName?: string
  buttonClass?: (open?: boolean, value?: T, disabled?: boolean) => string
  buttonChildren?: (open?: boolean, value?: T, disabled?: boolean) => JSX.Element | string
  buttonLabel?: (open?: boolean, value?: T, disabled?: boolean) => string
  menuSections: SectionType[]
  show?: (open?: boolean) => boolean | undefined | boolean
  withSelect?: boolean
  inModal?: boolean
}

/**
 * Use Popover when you need a menu that cares about its position.
 * Use this primarily in tables or when the dropdown does not have a fixed width
 * and the menun can render in different places depending on the length of the selected content.
 * Try to avoid this component when possible because it is the least accessible.
 */
export const Popover = ({
  disabled,
  className,
  popoverClassName,
  buttonClass,
  buttonChildren,
  buttonLabel,
  menuSections,
  withSelect,
  show,
  inModal,
}: PopoverProps<string | number>) => {
  return (
    <HeadlessPopover className={classNames('group ml-[-8px]', popoverClassName)}>
      {({ open }) => (
        <>
          {buttonChildren ? (
            <HeadlessPopover.Button
              as="button"
              disabled={disabled}
              className={classNames(buttonClass && buttonClass(open), 'focus:outline-electro z-0')}
              aria-label={buttonLabel && buttonLabel(open)}
            >
              {buttonChildren && buttonChildren(open)}
            </HeadlessPopover.Button>
          ) : null}
          <Transition as={Fragment} {...transitionProps} show={show && show(open)}>
            <HeadlessPopover.Panel
              className={classNames(
                inModal && 'border border-white/[.06] py-[3px] mr-[12px]',
                !inModal && 'pt-[11px] pb-[8px] shadow-base',
                'absolute right-0 rounded-sm w-[200px] bg-dark-dark5 z-50',
                className,
              )}
            >
              {menuSections.map((section, sectionIndex) => (
                <div
                  className="text-small tracking-wide mt-[22px] first:mt-0"
                  key={String(sectionIndex)}
                >
                  {section.name && (
                    <div className="px-[16px] pb-[8px] text-left text-gray-faded cursor-default break-words">
                      {section.name}
                    </div>
                  )}
                  <div>
                    {section.options.map((option, optionIndex) => (
                      <HeadlessPopover.Button
                        className="text-white group flex justify-between items-center w-full mt-[5px] first:mt-0 py-[4px] px-[16px] text-left transition-colors enabled:hover:bg-white/[.05] disabled:cursor-not-allowed"
                        disabled={option.isDisabled}
                        onClick={option.onClick}
                        data-tid={option.dataTid}
                        key={String(optionIndex)}
                      >
                        <div className="pr-[15px] group-disabled:text-gray-dark">{option.name}</div>
                        {(withSelect || section.withSelect) && (
                          <div
                            className={classNames(
                              'w-[20px] h-[20px] mr-[-6px] flex justify-center items-center transition-opacity',
                              option.isSelect ? 'opacity-100' : 'opacity-0',
                            )}
                          >
                            <Icon icon="check" className="absolute text-icon text-electro" />
                          </div>
                        )}
                      </HeadlessPopover.Button>
                    ))}
                  </div>
                </div>
              ))}
            </HeadlessPopover.Panel>
          </Transition>
        </>
      )}
    </HeadlessPopover>
  )
}
