import { PasswordForm } from 'components/free-trial/PasswordForm'
import { useLocation, useSearchParams } from 'react-router-dom'
import { RequestFreeTrialForm } from 'components/free-trial/RequestFreeTrialForm'
import { useToaster } from 'hooks/useToaster'
import NProgress from 'nprogress'
import { useAuth } from 'contexts/auth-context'
import { PATH_ROOT } from 'pages/MainPage'
import { Layout } from 'components/Layout'
import { LayoutHeader } from 'components/free-trial/LayoutHeader'
import { useCallback } from 'react'

export const PATH_SIGN_UP_TRIAL = '/sign-up-trial'

export const SignUpTrialPage = () => {
  const [searchParams] = useSearchParams()
  const { signInSSO } = useAuth()
  const verificationToken = searchParams.get('ver-token')
  const teamToken = searchParams.get('team-token') ?? undefined
  const location = useLocation()
  const toaster = useToaster()

  const handleGoogleSuccessAuth = useCallback(
    (ssoToken: string) => {
      NProgress.start()
      signInSSO(
        { ssoData: { ssoIdToken: ssoToken, allowFreeTrial: true } },
        location.state?.from?.pathname ?? PATH_ROOT,
        teamToken,
      )
        .catch((error) => {
          toaster.error(error)
        })
        .finally(() => {
          NProgress.done()
        })
    },
    [location, signInSSO, toaster, teamToken],
  )

  return (
    <Layout
      pageConfig={{ withoutNavbar: true, withoutStyledContent: true }}
      headerProps={{ isGuidesPage: true, hiddenProjectSelect: true }}
      headerComponent={<LayoutHeader />}
    >
      <div className="flex min-h-full items-center justify-center bg-dark-dark3">
        <div className="w-full">
          {verificationToken ? (
            <PasswordForm verificationToken={verificationToken} />
          ) : (
            <RequestFreeTrialForm onGoogleSuccess={handleGoogleSuccessAuth} teamToken={teamToken} />
          )}
        </div>
      </div>
    </Layout>
  )
}
