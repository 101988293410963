import { makeAutoObservable, observable, runInAction } from 'mobx'
import { Api } from 'api/Api'

import { ChartPageParams } from 'api/models'
import { Flag } from 'components/ps-chart/models/Flag'
import { Flags } from 'components/ps-chart/stores/FlagsStore'

export class FlagsDataStore {
  private readonly api: Api
  private readonly chartPageParams: ChartPageParams

  private _flags: Flag[] = []
  private _isLoaded = false

  constructor(api: Api, chartPageParams: ChartPageParams) {
    makeAutoObservable<FlagsDataStore, 'api' | 'tracePageParams' | '_flags'>(this, {
      api: false,
      tracePageParams: false,
      _flags: observable.shallow,
    })
    this.api = api
    this.chartPageParams = chartPageParams
  }

  load(): Promise<void> {
    return this.api.getFlags(this.chartPageParams).then((flags) => {
      const fetchFlags = () => {
        this.setIsLoaded(true)
        this._flags = this._flags.filter((flag) => flag.id <= 0) // filter not to delete locally added before fetch request came
        flags.forEach((flag) => this._flags.push(flag))
      }
      runInAction(fetchFlags)
    })
  }

  get flags(): Flags {
    return this._flags
  }

  get isLoaded(): boolean {
    return this._isLoaded
  }

  private setIsLoaded(isLoaded: boolean) {
    this._isLoaded = isLoaded
  }

  push(flag: Flag) {
    this._flags.push(flag)
  }

  findByTime(time: number): Flag | undefined {
    return this._flags.find((flag) => flag.time === time)
  }

  getByIdOrCid(id: number, cid: number | undefined): Flag | undefined {
    return this._flags.find((flag) => (cid ? cid === flag.cid : id === flag.id))
  }

  getById(id: number): Flag | undefined {
    return this._flags.find((flag) => flag.id === id)
  }

  delete(id: number, cid?: number) {
    const flagToDelete = this.getByIdOrCid(id, cid)!
    const indexToDelete = this.flags.indexOf(flagToDelete)
    if (indexToDelete > -1) {
      this._flags.splice(indexToDelete, 1)
    }
  }

  update(updated: Flag, id: number, cid?: number) {
    const old = this.getByIdOrCid(id, cid)!
    const index = this.flags.indexOf(old)
    this._flags.splice(index, 1, updated)
  }
}
