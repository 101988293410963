import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'

import { Layout } from 'components/Layout'
import { PageHead } from 'components/PageHead'
import { Table } from 'components/traces/Table'
import { Empty } from 'components/Empty'
import { ButtonVariantEnum } from 'components/Button'

import { useProjectQuery, useUnassignedTracesQuery, useUserQuery } from 'hooks/useApiQuery'
import { PATH_FLOWS } from 'pages/FlowsPage'

import PhoneSVG from 'assets/img/phone.svg'
import { TeamRole } from 'api/models'

export const PATH_UNASSIGNED = '/projects/:projectUrlName/traces'

export const UnassignedPage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isUnassignedTracesSuccess, data: unassignedTracesData } =
    useUnassignedTracesQuery({
      projectUrlName,
    })

  const isLoaded = isUnassignedTracesSuccess && isUserSuccess && isProjectSuccess

  const teamRole = user?.roles.teams[project?.team.id as number]
  const projectRole = user?.roles.projects[project?.id as number]
  const canWorkWithTraces =
    !user?.roles.isSuperAdmin || (teamRole && teamRole !== TeamRole.NONE) || projectRole

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const handleGoToLibraryClick = () => navigate(generatePath(PATH_FLOWS, { projectUrlName }))

  return (
    <Layout headerProps={{ screenTitleKey: 'library' }}>
      {isLoaded &&
        (!canWorkWithTraces ? (
          <Navigate to={generatePath(PATH_FLOWS, { projectUrlName })} replace={true} />
        ) : (
          <>
            <PageHead
              title={t('unassigned.title')}
              backLink={generatePath(PATH_FLOWS, { projectUrlName })}
            />
            {unassignedTracesData && unassignedTracesData?.length > 0 ? (
              <Table traces={unassignedTracesData} withSelect withAssign />
            ) : (
              <Empty
                image={PhoneSVG}
                title={t('unassigned.emptyUnassigned.text')}
                text={t('unassigned.emptyUnassigned.notice')}
                buttons={[
                  {
                    name: t('unassigned.emptyUnassigned.buttonTitle'),
                    variant: ButtonVariantEnum.Outlined,
                    onClick: handleGoToLibraryClick,
                  },
                ]}
              />
            )}
          </>
        ))}
    </Layout>
  )
}
