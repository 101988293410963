import { colors } from 'utils/styles/colors'

export const chartSettings = Object.freeze({
  dispersionMultiplayer: 2,
  stepsCount: 3,
  minScaleStep: 1_000_000,

  line: Object.freeze({
    width: 2,
    color: colors.state.good,
  }),
})
