import { colors } from 'utils/styles/colors'

export class FlagsSettings {
  readonly flagBottomPadding = 3
  readonly flagHeight = 10
  readonly flagWidth = 10
  readonly flagCenterLeftPadding = 6
  readonly strokeWidth = 1
  readonly dragPadding = 5
  readonly flagTitleWidth = 85
  readonly flagTitlePadding = 5
  readonly titleFrameCornerRadius = 2
  readonly titleFrameStrokeWidth = 1
  readonly flagTitleVerticalMargin = 4

  readonly cursorDefault = 'default'
  readonly cursorResize = 'ew-resize'

  readonly hoverColor = colors.globalTimeline.dark76

  readonly fontSize = 10
  readonly fontFamily = 'Manrope'

  readonly titleMaxRows = 3
  readonly titleMaxLength = 120

  readonly color = colors.electro
  readonly selectedColor = colors.white
  readonly titleColor = colors.white
  readonly hoverFlagColor = colors.gray.iconStatus
  readonly titleBgColor = colors.dark.dark5

  /**
   * Flag color options
   */
  readonly colors: string[] = [
    '#A3375E',
    '#713175',
    '#533B97',
    '#3F5997',
    '#3791BF',
    '#2F8C85',
    '#78A361',
    '#E9B959',
    '#DD7653',
    '#8D6C61',
  ]
}

export const flagsSettings = new FlagsSettings()
