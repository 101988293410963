import React from 'react'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'

import { Icon } from 'components/Icon'

export interface SelectProps {
  options: SelectOption[]
  value: string | string[] | null
  onChange: (value: string) => void
  placeholder?: string
  theme?: SelectTheme
  className?: string
  buttonClassName?: string
  optionsClassName?: string
  disabled?: boolean
  multiple?: boolean
}

export enum SelectTheme {
  DARK = 'DARK',
  OVERLAY = 'OVERLAY',
}

export interface SelectOption {
  label: string | React.ReactNode
  value: string
  disabled?: boolean
}

export const Select = ({
  options,
  value,
  onChange,
  placeholder,
  theme,
  className,
  buttonClassName,
  optionsClassName,
  disabled,
  multiple,
}: SelectProps) => {
  let label = placeholder
  if (Array.isArray(value) && value.length > 0) {
    label = options
      .filter((item) => value?.includes(item.value))
      .map((item) => item.label)
      .join(', ')
  } else if (value && typeof value === 'string') {
    label = options.find((item) => item.value === value)?.label as string
  }

  return (
    <Listbox value={value} onChange={onChange} multiple={multiple} disabled={disabled}>
      <div className={classNames('relative', className)}>
        <Listbox.Button
          className={({ open }) =>
            classNames(
              'relative w-full h-[32px] pl-[16px] pr-[42px] text-left text-small tracking-wide bg-dark-dark1 whitespace-nowrap text-ellipsis overflow-hidden',
              theme === SelectTheme.DARK && '!bg-dark-dark3',
              open ? 'rounded-t-sm' : 'rounded-sm',
              (Array.isArray(value) ? value.length > 0 : value) ? 'text-white' : 'text-gray-normal',
              buttonClassName,
              disabled && 'opacity-40',
            )
          }
        >
          {({ open }) => (
            <>
              {label}
              <Icon
                icon="arrow-drop-d"
                className={classNames(
                  'absolute top-0 right-[2px] text-gray-normal text-icon transition-opacity',
                  open ? 'opacity-0' : 'opacity-100',
                )}
              />
            </>
          )}
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={classNames(
              'absolute top-full z-50 left-0 w-full py-[4px] text-white bg-dark-dark3 overflow-y-auto scrolling-touch rounded-b-sm text-small tracking-wide',
              theme === SelectTheme.OVERLAY &&
                '!top-0 !bg-dark-dark1 rounded-sm shadow-base !py-[8px]',
              optionsClassName,
            )}
          >
            {options.map((option, index) => (
              <Listbox.Option
                className={({ active, disabled: optionDisabled }) =>
                  classNames(
                    'flex justify-between items-center px-[15px] py-[5px]',
                    active && 'bg-white/[.05]',
                    optionDisabled ? 'cursor-default' : 'cursor-pointer',
                  )
                }
                value={option.value}
                disabled={option.disabled}
                key={String(index)}
              >
                {({ selected }) => (
                  <>
                    <span className="break-words">{option.label}</span>
                    <span
                      className={classNames(
                        'w-[20px] h-[20px] ml-[4px] mr-[-6px] flex justify-center items-center transition-opacity',
                        selected ? 'opacity-100' : 'opacity-0',
                      )}
                    >
                      <Icon icon="check" className="absolute text-icon text-electro" />
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
