import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  QUICKSTART,
  QUICKSTART_MARKDOWN_CONFIG,
  QUICKSTART_MARKDOWN_TRY_BLOCK_CONFIG,
} from 'components/quickstart/constants'
import {
  PageToContentMap,
  QuickstartContentWithoutVideo,
  QuickstartPages,
} from 'components/quickstart/models'
import { Markdown } from 'components/guide/markdown/Markdown'
import { isTypeQuickstartPage } from 'hooks/__generated__/contentful/TypeQuickstartPage'
import { useModal } from 'hooks/useModal'

export const Content = ({ pageToContentMap, linksToPages }: QuickstartContentWithoutVideo) => {
  const { paramValue } = useModal(QUICKSTART)

  switch (paramValue) {
    case QuickstartPages.Contents: {
      return (
        <ul>
          {linksToPages.map(({ urlQueryParam, linkText }) => (
            <li key={urlQueryParam} className="my-4 hover:text-electro hover:cursor-pointer w-fit">
              <Link
                to={{
                  pathname: location.pathname,
                  search: `?${QUICKSTART}=${urlQueryParam}`,
                }}
              >
                {linkText}
              </Link>
            </li>
          ))}
        </ul>
      )
    }

    default:
      return <MainContent pageToContentMap={pageToContentMap} />
  }
}

interface MainContentProps {
  pageToContentMap: PageToContentMap
}

export const MainContent = ({ pageToContentMap }: MainContentProps) => {
  const { t } = useTranslation()
  const { paramValue } = useModal(QUICKSTART)

  if (!pageToContentMap) {
    return null
  }

  const pageContent = pageToContentMap[paramValue as QuickstartPages]

  if (!pageContent || !isTypeQuickstartPage(pageContent)) {
    return <div>{t('quickstart.pageNotFound')}</div>
  }

  const templateValues = new Map()

  return (
    <div className="h-full flex flex-col justify-between">
      <Markdown
        config={QUICKSTART_MARKDOWN_CONFIG}
        markdown={pageContent.fields.body}
        templateValues={templateValues}
      />
      {pageContent.fields?.tryBlock !== undefined && (
        <div className="bg-dark-dark1 w-5/6 md:w-[628px] m-auto px-4 pb-4 pt-1 mt-6 rounded-md">
          <Markdown
            config={QUICKSTART_MARKDOWN_TRY_BLOCK_CONFIG}
            markdown={pageContent.fields.tryBlock!}
            templateValues={templateValues}
          />
        </div>
      )}
    </div>
  )
}
