import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'

import { Modal } from 'components/Modal'
import { Icon } from 'components/Icon'
import { getFlowName } from 'utils/getFlowName'

import { queryKeys, useFlowsQuery } from 'hooks/useApiQuery'
import { useMutation, useQueryClient } from 'react-query'
import { ApiError, Trace, Traces } from 'api/models'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'

export interface AssignTraceModalProps {
  isOpen: boolean
  name?: string
  tracesIds?: number[]
  activeFlowsIds?: number[]
  prevFlowsIds?: number[]
}

export interface AssignTraceModalStateContext {
  setState: ({
    isOpen,
    name,
    tracesIds,
    activeFlowsIds,
    prevFlowsIds,
  }: AssignTraceModalProps) => void
}

export const AssignTraceModalContext = React.createContext<AssignTraceModalStateContext | null>(
  null,
)

export const AssignTraceModal = ({
  isOpen,
  name,
  tracesIds,
  activeFlowsIds,
  prevFlowsIds,
  onClose,
  onDone,
}: AssignTraceModalProps & { onClose: () => void; onDone: () => void }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const [checkedIds, setCheckedIds] = useState<number[]>(activeFlowsIds || [])

  useEffect(() => {
    if (activeFlowsIds) {
      setCheckedIds(activeFlowsIds)
    }
  }, [activeFlowsIds])

  const { isSuccess: isFlowsSuccess, data: flowsData } = useFlowsQuery({ projectUrlName })

  const handleClick = (projectLocalId: number) => () => {
    const newCheckedIds = [...checkedIds]
    const findIndex = newCheckedIds.indexOf(projectLocalId)
    if (findIndex > -1) {
      newCheckedIds.splice(findIndex, 1)
    } else {
      newCheckedIds.push(projectLocalId)
    }
    setCheckedIds(newCheckedIds)
  }

  const postAssignTraceMutation = useMutation(
    (variables: { checkedIds: number[]; withoutToast?: boolean }) =>
      Promise.all([
        ...(tracesIds?.map((trace) =>
          api.postAssignTrace(
            { projectUrlName, traceProjectLocalId: String(trace) },
            variables.checkedIds,
          ),
        ) || []),
      ]),
    {
      onSuccess: (_, variables: { checkedIds: number[]; withoutToast?: boolean }) => {
        NProgress.done()

        const getTraceWithNewFlows = (item: Trace) => {
          const newFlows = flowsData
            ?.filter((flowItem) => variables?.checkedIds.indexOf(flowItem.projectLocalId) !== -1)
            .map((flowItem) => ({
              flowProjectLocalId: flowItem.projectLocalId,
              flowName: flowItem.name,
            }))
          return tracesIds?.indexOf(item.projectLocalId) !== -1
            ? Object.assign({}, item, { flows: newFlows })
            : item
        }

        queryClient.setQueryData<Traces | undefined>(
          queryKeys.unassignedTraces({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData.map((item) => getTraceWithNewFlows(item))
            }
          },
        )

        if (flowProjectLocalId) {
          queryClient.setQueryData<Traces | undefined>(
            queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
            (oldData) => {
              if (oldData) {
                return oldData.map((item) => getTraceWithNewFlows(item))
              }
            },
          )
        }

        const getFirstFlow = flowsData?.find((flow) => flow?.projectLocalId === checkedIds[0])
        const toastTextTrace =
          tracesIds &&
          t('traces.assignTraceModal.toastTextTrace', {
            count: tracesIds?.length,
          })
        const toastTextFlow = t('traces.assignTraceModal.toastTextFlow', {
          count: checkedIds.length,
          name: getFirstFlow?.name,
        })
        const toastTest = t('traces.assignTraceModal.toastTest_interval', {
          postProcess: 'interval',
          count: checkedIds.length,
          toastTextTrace: toastTextTrace,
          toastTextFlow: toastTextFlow,
        })
        if (!variables.withoutToast) {
          toast.success(
            <>
              {toastTest}
              <button className="ml-4" onClick={handleUndoClick}>
                {t('undo')}
              </button>
            </>,
          )
        }

        onDone()
        onClose()
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handeDoneClick = () => {
    if (prevFlowsIds?.sort().toString() !== checkedIds.sort().toString()) {
      NProgress.start()
      postAssignTraceMutation.mutate({ checkedIds })
    } else {
      onClose()
    }
  }

  const handleUndoClick = () => {
    NProgress.start()
    toast.dismiss()
    postAssignTraceMutation.mutate({ checkedIds: activeFlowsIds || [], withoutToast: true })
  }

  const getSortedFlowsData = flowsData
    ?.sort(
      (a, b) =>
        String(a.dateUpdated).localeCompare(String(b.dateUpdated), undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * -1,
    )
    .sort((a) => (a.projectLocalId === Number(flowProjectLocalId) ? -1 : 0))

  return (
    <Modal
      title={t('traces.assign')}
      secondaryTitle={name || ''}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        children: t('traces.assignTraceModal.actionDone'),
        onClick: handeDoneClick,
        disabled: postAssignTraceMutation.isLoading,
      }}
    >
      {isFlowsSuccess && flowsData && (
        <Content>
          <div className="mb-[16px] text-small tracking-wide text-gray-normal">
            {t('traces.assignTraceModal.toAssign')}
          </div>
          {getSortedFlowsData?.map((item) => (
            <div
              className="my-[8px] flex items-center justify-between w-full cursor-pointer text-small tracking-wide"
              key={String(item.projectLocalId)}
              onClick={handleClick(item.projectLocalId)}
            >
              <div>{getFlowName(item.name, item.projectLocalId)}</div>
              <div className="flex items-center">
                {item.projectLocalId === Number(flowProjectLocalId) && (
                  <div className="font-medium text-gray-normal">
                    {t('traces.assignTraceModal.thisFlow')}
                  </div>
                )}
                <Icon
                  icon="check"
                  className={classNames(
                    'text-icon text-electro',
                    checkedIds.indexOf(item.projectLocalId) !== -1 ? 'opacity-100' : 'opacity-0',
                  )}
                />
              </div>
            </div>
          ))}
        </Content>
      )}
    </Modal>
  )
}

const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 435px;
  margin-top: 10px;
  margin-right: -16px;
  padding-right: 11px;
`
