import { colors } from 'utils/styles/colors'

export class CommonTimelineSettings {
  readonly palette = new CommonTimelinePalette()

  readonly scaleWidth = 1

  readonly fontSize = 10
  readonly fontFamily = 'Manrope'

  readonly scaleTextTopPadding = 4
  readonly scaleTextLeftPadding = 4
}

export class CommonTimelinePalette {
  readonly text = colors.gray.normal
  readonly timeScaleLine = colors.globalTimeline.white10
  readonly dragLine = colors.white
  readonly bgOutOfSelectedTime = colors.globalTimeline.dark76
  readonly previewDataColor = colors.electro + '80'
}

export const commonTimelineSettings = new CommonTimelineSettings()
