import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import GoogleSVG from 'assets/img/google.svg'
import { useGoogleLogin } from '@react-oauth/google'
import NProgress from 'nprogress'
import { getAuth, GoogleAuthProvider, signInWithCredential } from 'firebase/auth'
import { useToaster } from 'hooks/useToaster'
import { initializeApp } from 'firebase/app'

export type GoogleButtonSuccessHandler = (fbIdToken: string) => void

interface GoogleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onSuccess: GoogleButtonSuccessHandler
}

export const GoogleButton = (props: GoogleButtonProps) => {
  const { onSuccess, children, className, ...otherProps } = props
  const toaster = useToaster()
  const { t } = useTranslation()

  useEffect(() => {
    initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    })
  }, [])

  const signInViaGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      NProgress.start()
      const gAuth = getAuth()
      signInWithCredential(gAuth, GoogleAuthProvider.credential(null, tokenResponse.access_token))
        .then(async ({ user }) => {
          const idToken = await user.getIdToken()
          NProgress.done()
          if (onSuccess) {
            onSuccess(idToken)
          }
        })
        .catch(() => {
          toaster.error('errorMessage')
          NProgress.done()
        })
    },
    onError: (errorResponse) => {
      toaster.error(errorResponse.error_description)
    },
  })

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      signInViaGoogle()
    },
    [signInViaGoogle],
  )

  return (
    <button
      className={classNames(
        'h-[32px] flex justify-center items-center w-full mt-[52px] border-[1px] border-gray-strokeLight hover:border-white text-small font-medium transition-colors',
        className,
      )}
      onClick={handleButtonClick}
      {...otherProps}
    >
      <img src={GoogleSVG} alt="" aria-hidden="true" className="w-[32px] h-[32px] mr-[6px]" />
      {children || t('auth.common.signInWithGoogle')}
    </button>
  )
}
