import { HTMLProps } from 'react'

export interface MdParaProps extends HTMLProps<HTMLParagraphElement> {
  inline: boolean
}

export const MdPara = ({ inline, className, children, ...rest }: MdParaProps) => {
  return (
    <p {...rest} className={`${inline ? 'inline' : ''} ${className ?? ''}`}>
      {children}
    </p>
  )
}
