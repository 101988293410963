import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Icon } from './Icon'
import { useProjectQuery, useProjectsSummaryQuery } from 'hooks/useApiQuery'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { OptionType } from './dropdowns/models'
import { ListBox } from './dropdowns/ListBox'

export const ProjectSelect = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isProjectsSummarySuccess, data: projectsSummary } = useProjectsSummaryQuery(
    { teamUrlName: project?.team.urlName },
    isProjectSuccess,
  )

  if (!isProjectsSummarySuccess || projectsSummary?.projects?.length === 0) {
    return null
  }

  if (projectsSummary?.projects?.length === 1) {
    return (
      <div className="text-small text-gray-normal mr-[16px]">
        {t('components.projectSelect.project')}
        <span className="ml-[5px] text-small text-white">{projectUrlName}</span>
      </div>
    )
  }

  const getProjectOptions: OptionType[] =
    projectsSummary?.projects?.map((item) => {
      const isSelect: boolean = item.project.urlName === projectUrlName
      return {
        name: item.project.name,
        image: item.project.image,
        isSelect,
      }
    }) || []

  return (
    <ListBox
      menuSections={[{ name: 'Projects', options: getProjectOptions }]}
      menuClass="h-[400px] overflow-y-scroll right-[10px]"
      onSelect={(index: number) => {
        const lbProjectUrlName: string | undefined =
          projectsSummary?.projects[index]?.project.urlName
        navigate(generatePath(PATH_FLOWS, { projectUrlName: lbProjectUrlName }))
      }}
      buttonChildren={() => (
        <div className="flex items-center justify-end">
          <div className="hover:text-white flex items-center">
            <p>Project</p>&nbsp;
            <p className="text-white ml-1">
              {getProjectOptions.find((item) => item.isSelect)?.name}
            </p>
          </div>
          <Icon icon="arrow-drop-d" className="text-icon relative" />
        </div>
      )}
      buttonClass={(open) =>
        classNames(
          'flex items-center text-small tracking-wide transition mr-4',
          open ? 'text-white' : 'text-gray-normal',
        )
      }
      withSelect
    />
  )
}
