import React, { useContext } from 'react'
import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'

import { Layout } from 'components/Layout'
import { PsChart } from 'components/ps-chart/PsChart'
import { HeaderProps, ScreenTitleKey } from 'components/Header'
import {
  AnnotationsFeatureState,
  PsChartFeatures,
  psChartStoreContext,
} from 'components/ps-chart/PsChartStore'
import { HeaderTraceBreadcrumbs } from 'components/HeaderTraceBreadcrumbs'
import { useNavigationRules } from 'hooks/useNavigationRules'

export const PATH_CHART_FOLDER = 'traceviewer'
export const PATH_CHART = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_CHART_FOLDER}`
export const PATH_CHART_DIRECT = '/projects/:projectUrlName/traces/:traceProjectLocalId'

const defaultChartFeatures = (annotationsEnabled?: boolean): PsChartFeatures => ({
  flags: true,
  measurementTool: true,
  annotations: { enabled: !!annotationsEnabled, draggable: false } as AnnotationsFeatureState,
  sliceNameTooltip: true,
})

export interface PsChartPageProps {
  screenTitleKey: ScreenTitleKey
}

export const PsChartPage = ({ screenTitleKey }: PsChartPageProps) => {
  const headerProps: HeaderProps = { screenTitleKey, leftText: <HeaderTraceBreadcrumbs /> }
  const psChartStore = useContext(psChartStoreContext)!

  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)

  useNavigationRules()

  if (annotationsEnabled === undefined) {
    return null
  }
  psChartStore.setChartFeatures(defaultChartFeatures(annotationsEnabled))

  return (
    <Layout
      headerProps={headerProps}
      pageConfig={{
        withoutStyledContent: true,
      }}
    >
      <PsChart psChartStore={psChartStore} />
    </Layout>
  )
}
