import { Trans } from 'react-i18next'
import { useCustomerSupportModal } from 'contexts/customer-support-modal-context'
import { useAuth } from 'contexts/auth-context'
import { Icon } from './Icon'
import { PRIVACY_LINK, TERMS_LINK } from 'utils/links'

export const Footer = () => {
  const { openModal } = useCustomerSupportModal()
  const { isSignedIn } = useAuth()

  return (
    <footer className="bg-dark-dark2 px-4 w-full h-[40px] flex justify-between items-center text-[12px] leading-[16.8px] tracking-[3%] font-[400] whitespace-nowrap min-w-[1000px]">
      <div className="text-gray-faded">
        <Trans
          i18nKey="footer.allRightsReserved"
          values={{
            year: new Date().getFullYear(),
          }}
        />
      </div>
      <div className="ml-4">
        <ul className="flex text-gray-normal">
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.customerSupport"
              components={{
                a: isSignedIn ? (
                  <span role="button" onClick={openModal} />
                ) : (
                  <a target="_blank" href="https://www.productscience.ai/customer-support" />
                ),
                icon: <Icon icon="support" className="mr-2" />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.documentation"
              components={{
                a: <a target="_blank" href="https://product-science.github.io/" />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.privacy"
              components={{
                a: <a target="_blank" href={PRIVACY_LINK} />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.terms"
              components={{
                a: <a target="_blank" href={TERMS_LINK} />,
              }}
            />
          </li>
        </ul>
      </div>
    </footer>
  )
}
