import React, { useCallback, useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useFlowQuery, useTraceQuery } from 'hooks/useApiQuery'
import { Icon } from 'components/Icon'
import { TraceEditModal } from 'components/TraceEditModal'
import { PATH_FLOW } from 'pages/FlowPage'
import { useIsReadOnlyProject } from 'utils/feature-flags'

export const HeaderTraceBreadcrumbs = () => {
  const { projectUrlName, flowProjectLocalId, traceProjectLocalId } = useParams()
  const [modalVisible, setModalVisible] = useState(false)
  const isReadOnlyProject = useIsReadOnlyProject()

  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  const { isSuccess: isTraceSuccess, data: trace } = useTraceQuery({
    projectUrlName,
    traceProjectLocalId,
  })

  const handleOpenModal = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  if (!isFlowSuccess || !isTraceSuccess) {
    return null
  }

  return (
    <div className="flex break-all items-center">
      <Link
        to={generatePath(PATH_FLOW, { projectUrlName, flowProjectLocalId })}
        className="text-small text-gray-normal hover:text-white cursor-pointer max-w-[50%] line-clamp-1"
      >
        {flowData?.name}
      </Link>
      <Icon icon="arrow-drop-r" className="text-[30px] text-gray-faded mx-[-3px]" />
      <span className="group flex-1 flex items-center text-gray-normal cursor-default">
        <span className="text-small relative line-clamp-1 mr-[6px]">{trace?.name}</span>
        {!isReadOnlyProject && (
          <Icon
            icon="small-edit"
            onClick={handleOpenModal}
            className="text-[16px] opacity-0 group-hover:opacity-100 hover:text-white cursor-pointer"
          />
        )}
      </span>
      <TraceEditModal
        visible={modalVisible}
        title={trace?.name}
        onClose={handleCloseModal}
        traceProjectLocalId={traceProjectLocalId}
        projectUrlName={projectUrlName}
      />
    </div>
  )
}
