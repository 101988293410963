import React, { useCallback, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { CompleteStepWarning } from 'components/guide/CompleteStepWarning'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { GuideStepType } from 'components/guide/models'
import { NextActionButton } from 'components/guide/NextActionButton'
import { HavingTroubles } from 'components/guide/HavingTroubles'
import { FreeTrialStage, ProcessingErrorCodeEnum, TraceProcessingStateEnum } from 'api/models'
import { ProgressCircle } from 'components/common/util/ProgressCircle'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'

export const ViewTrace = observer(function ViewTrace() {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const isRecordTraceCompleted = Boolean(
    guideStore.steps.find((step) => step.type === GuideStepType.RecordTrace)?.completed,
  )
  const isTraceProcessing =
    guideStore.bestTrace?.processingState === TraceProcessingStateEnum.IN_PROGRESS

  const getErrorMessage = (errorCode?: ProcessingErrorCodeEnum) => {
    switch (errorCode) {
      case ProcessingErrorCodeEnum.APP_ID_NOT_FOUND:
        return t('traces.processingError.appIdNotFound')
      case ProcessingErrorCodeEnum.UNSUPPORTED_FILE_FORMAT:
        return t('traces.processingError.unsupportedFileFormat')
      case ProcessingErrorCodeEnum.INTERNAL_SERVER_ERROR:
        return t('traces.processingError.internalServerError')
      default:
        return t('guidePage.viewTrace.errors.general')
    }
  }

  return (
    <div className="pt-[40px]">
      {!isRecordTraceCompleted && (
        <CompleteStepWarning>{t('guidePage.completeAllStepsWarning')}</CompleteStepWarning>
      )}

      {guideStore.traceUrlPath != null ? (
        <>
          <h1 className="font-[500] text-[26px] leading-[39px] mb-[32px]">
            {t('guidePage.congratulations')}
          </h1>
          <p className="font-[400] text-[12px] leading-[21px]">{t('guidePage.viewTrace1')}</p>
          <p className="font-[400] text-[12px] leading-[21px]">{t('guidePage.viewTrace2')}</p>

          <OpenTraceButton
            traceUrlPath={guideStore.traceUrlPath}
            projectIdOrUrlName={guideStore.freeTrialProjectSummary!.project.urlName}
            freeTrialStage={guideStore.freeTrialTeam?.freeTrial?.stage}
          />
        </>
      ) : (
        <>
          {guideStore.bestTrace != null && (
            <div className="flex flex-col justify-center items-center bg-[#191919] mb-[56px]">
              <div className="text-[14px] text-gray-normal font-[500] leading-[21px] tracking-[2%] pt-[8.5px] pb-[7.5px] flex justify-center">
                {isTraceProcessing ? (
                  <>
                    <div className="h-[16px] w-[16px] mr-[8px]">
                      <ProgressCircle />
                    </div>
                    <div>{t('guidePage.viewTrace.traceInProcessing')}</div>
                  </>
                ) : (
                  <div className="text-state-bad">
                    {getErrorMessage(guideStore.bestTrace.processingErrorCode)}
                  </div>
                )}
              </div>
            </div>
          )}
          <HavingTroubles>
            <p className="text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%] mt-[24px]">
              {t('guidePage.submitRequests')}
            </p>
          </HavingTroubles>
        </>
      )}
    </div>
  )
})

const OpenTraceButton = ({
  traceUrlPath,
  projectIdOrUrlName,
  freeTrialStage,
}: {
  traceUrlPath: string
  projectIdOrUrlName: string
  freeTrialStage?: FreeTrialStage
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const api = useApi()
  const toaster = useToaster()
  const [isLoading, setIsLoading] = useState(false)

  const openTrace = useCallback(() => {
    if (freeTrialStage === FreeTrialStage.FLOW_LIBRARY) {
      return navigate(traceUrlPath)
    }
    setIsLoading(true)
    api
      .postFinishInstructions(projectIdOrUrlName)
      .then(() => {
        navigate(traceUrlPath)
      })
      .catch((error) => toaster.error(error))
      .finally(() => {
        setIsLoading(false)
      })
  }, [freeTrialStage, api, projectIdOrUrlName, navigate, traceUrlPath, toaster])

  return (
    <NextActionButton
      onClick={openTrace}
      label={t('guidePage.openTraceViewer')}
      disabled={isLoading}
      className="min-w-[186px]"
    >
      {isLoading && <ProgressCircle className="inline mr-[8px]" />}
      {t('guidePage.openTraceViewer')}
    </NextActionButton>
  )
}
