import { SliceArgument } from 'components/ps-chart/models/Slice'
import { CardLine, lineColors } from 'components/ps-chart/details-view/CardLine'
import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon } from 'components/Icon'
import tw from 'twin.macro'
import classNames from 'classnames'

export function checkIfNestedArguments(array: SliceArgument[]): boolean {
  const regex = /^\d{2}-[a-zA-Z\d]+(\.(\d{2}-)?[a-zA-Z\d]+)+$/

  for (const object of array) {
    if (regex.test(object.key!)) {
      return true
    }
  }
  return false
}

function groupNestedArgumentsByLevel(list: SliceArgument[], level = 0) {
  const map = new Map()
  const sortedList = list.sort((current, next) => {
    const currentSplit = current.key!.split('.')
    const nextSplit = next.key!.split('.')
    return currentSplit[level].localeCompare(nextSplit[level])
  })
  for (const item of sortedList) {
    const split = item.key!.split('.')
    const key = split[level]
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  }
  map.forEach((value, key, childMap) => {
    if (Array.isArray(value) && value.length > 1) {
      childMap.set(key, groupNestedArgumentsByLevel(value, level + 1))
    }
  })
  return map
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function arrayFromMap(map: Map<any, any>): string[][] {
  return Array.from(map.entries(), ([key, value]) =>
    value instanceof Map ? [key, arrayFromMap(value)] : [key, value],
  )
}

function parseTitleFromSliceArgument(sliceArgumentKey: string) {
  const titleWords = sliceArgumentKey
    .replace(/(\d+|\[\d+\])-/g, '')
    .match(/[a-z]+|[A-Z][a-z]+|\b[A-Z]+\b/g)!
    .map((word) => word.toLowerCase())
  const titleFirstWord = titleWords.shift()!
  return [
    titleFirstWord.charAt(0).toLocaleUpperCase() + titleFirstWord.slice(1),
    ...titleWords,
  ].join(' ')
}

const CardNestedLine = ({
  input,
  collapsed,
  handleClick,
  level = 1,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any[][]
  collapsed: Map<string, boolean>
  handleClick: (title: string) => () => void
  level?: number
}) => {
  return (
    <>
      {input.map(([title, value]) => {
        const isCollapsed = collapsed.has(title) ? collapsed.get(title)! : true
        const hasBranch = value.length > 1

        return (
          <div className={classNames(level > 1 && 'pl-[4px]')} key={title}>
            <CardLine
              label={parseTitleFromSliceArgument(title)}
              level={level}
              nested={hasBranch}
              isCollapsed={isCollapsed}
              onClick={hasBranch ? handleClick(title) : undefined}
            >
              {!hasBranch ? (
                <div className="pl-1" key={title}>
                  {value[0].value}
                </div>
              ) : (
                <CollapseButton isCollapsed={isCollapsed} as="button" icon="arrow-collapse-d" />
              )}
            </CardLine>
            {value.length > 1 && (
              <Border
                level={level}
                className={classNames(
                  `pl-[3px] border-l-[1px]`,
                  isCollapsed && 'hidden',
                  `argument-level-${level}`,
                )}
              >
                <CardNestedLine
                  input={value}
                  collapsed={collapsed}
                  handleClick={handleClick}
                  level={level + 1}
                />
              </Border>
            )}
          </div>
        )
      })}
    </>
  )
}

export const SliceNestedArgument = ({ args }: { args: SliceArgument[] }) => {
  const [collapsed, setCollapsed] = useState(new Map())
  const groupedArguments = arrayFromMap(groupNestedArgumentsByLevel(args))

  const handleClickCollapse = (title: string) => () => {
    setCollapsed((prev) => new Map(prev).set(title, prev.has(title) ? !prev.get(title) : false))
  }

  return (
    <CardNestedLine
      input={groupedArguments}
      collapsed={collapsed}
      handleClick={handleClickCollapse}
    />
  )
}

const CollapseButton = styled(Icon)<{ isCollapsed: boolean }>`
  ${tw`transition text-[12px] mr-[2px]`}
  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      transform: scaleY(-1);
    `}

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const Border = styled.div<{ level: number }>`
  border-color: ${({ level }) => level && lineColors.get(level)};
`
