import { AnnotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'
import Konva from 'konva'
import { AnnotationDelayDto } from 'api/models'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'

export class KonvaPin {
  private readonly annotationSettings: AnnotationsSettings
  private parentWidth: number
  private parentHeight: number
  private _time: number | null = null
  private prevX = 0
  private _draggable = true

  readonly pinGroup: Konva.Group

  private readonly pinBgRect: Konva.Rect
  private readonly pinDrop: Konva.Path
  private readonly pinLeg: Konva.Line
  private readonly pinText: Konva.Text
  private readonly pinTextFrame: Konva.Rect

  constructor(parentWidth: number, parentHeight: number, annotationSettings: AnnotationsSettings) {
    this.parentWidth = parentWidth
    this.parentHeight = parentHeight
    this.annotationSettings = annotationSettings

    const { bgRect, drop, leg, text, textFrame } = this.createPin()
    this.pinBgRect = bgRect
    this.pinDrop = drop
    this.pinLeg = leg
    this.pinText = text
    this.pinTextFrame = textFrame

    this.pinGroup = new Konva.Group({ draggable: true })

    this.pinGroup.add(this.pinBgRect)
    this.pinGroup.add(this.pinDrop)
    this.pinGroup.add(this.pinLeg)
    this.pinGroup.add(this.pinTextFrame)
    this.pinGroup.add(this.pinText)

    this.pinGroup.addEventListener('dragstart', () => {
      if (!this.draggable) {
        this.pinGroup.draggable(false)
      }
    })
    this.pinGroup.addEventListener('dragend', () => {
      this.pinGroup.draggable(true)
    })

    this.pinGroup.addEventListener('dragmove', () => {
      if (this.x < 0) {
        this.x = 0
      }
      if (this.x > this.parentWidth) {
        this.x = this.parentWidth
      }
      this.y = 0
    })
  }

  private createPin(): CreatePinResult {
    const settings = this.annotationSettings
    const parentH = this.parentHeight
    const pinH = settings.pinHeight
    const pinW = settings.pinWidth
    const pinHmiddle = parentH - pinH
    const pinWmiddle = Math.round(pinW / 2)
    const textW = settings.pinTextWidth
    const textMiddle = Math.round(textW / 2)

    const bgRect = new Konva.Rect({
      x: -pinWmiddle - settings.dragPadding,
      y: parentH - pinH - settings.pinLegHeight - settings.pinTextVerticalMargin,
      width: pinW + settings.dragPadding * 2,
      height: parentH,
    })

    const drop = new Konva.Path({
      x: -pinWmiddle,
      y: pinHmiddle - settings.pinLegHeight,
      data: 'M11.1805 10.2563L8 13.4639L4.81952 10.2563C4.40145 9.83467 4.0696 9.33389 3.84311 8.78244C3.61663 8.23099 3.5 7.6398 3.5 7.04266C3.5 6.44553 3.61663 5.85433 3.84311 5.30288C4.0696 4.75143 4.40145 4.25065 4.81952 3.82902C5.23758 3.40739 5.73366 3.07316 6.27932 2.84521C6.82497 2.61727 7.40965 2.5 8 2.5C8.59036 2.5 9.17504 2.61727 9.72069 2.84521C10.2664 3.07316 10.7624 3.40738 11.1805 3.82901C12.0249 4.6806 12.5 5.83651 12.5 7.04266C12.5 8.24881 12.0249 9.40471 11.1805 10.2563Z',
      stroke: settings.pinColor,
      fill: settings.bgColor,
      strokeWidth: settings.strokeWidth,
    })

    const leg = new Konva.Line({
      points: [0, parentH - settings.pinLegHeight, 0, parentH],
      stroke: settings.pinColor,
      strokeWidth: settings.strokeWidth,
      fill: settings.pinColor,
      closed: true,
    })

    const titleTop = parentH - pinH - settings.pinTextVerticalMargin - settings.fontSize - 1
    const text = new Konva.Text({
      x: -textMiddle,
      width: textW,
      y: titleTop - Math.round(settings.pinTextVerticalMargin / 2),
      text: this.text,
      fill: settings.textColor,
      fontSize: settings.fontSize,
      fontFamily: settings.fontFamily,
      wrap: 'none',
      ellipsis: true,
      align: 'center',
    })

    const textFrame = new Konva.Rect({
      x: -textMiddle - settings.pinTextPadding,
      y: titleTop - settings.pinTextPadding,
      width: textW + settings.pinTextPadding * 2,
      height: settings.pinTextPadding * 2 + settings.pinTextVerticalMargin + 1,
      stroke: settings.frameColor,
      strokeWidth: settings.strokeWidth,
      fill: settings.bgColor,
      cornerRadius: settings.pinTextFrameCornerRadius,
      visible: true,
    })
    return { bgRect, drop: drop, leg, text, textFrame }
  }

  private set text(value: string) {
    this.pinText.width(this.annotationSettings.pinTextWidth)
    this.pinText.text(value)
    const textW = this.pinText.getTextWidth()
    const width = textW + this.annotationSettings.pinTextPadding * 2 - 1
    const x = this.pinLeg.x() - Math.round(textW / 2) - this.annotationSettings.pinTextPadding
    this.pinTextFrame.width(textW + this.annotationSettings.pinTextPadding * 2)
    this.pinTextFrame.x(x)
    this.pinText.width(width)
    this.pinText.x(x)
  }

  set time(value: number | null) {
    this._time = value
    if (value !== null && value >= 0) {
      this.pinGroup.visible(true)
    } else {
      this.pinGroup.visible(false)
    }
  }

  get time(): number | null {
    return this._time
  }

  get x(): number {
    return this.pinGroup.x()
  }

  set x(value: number) {
    this.prevX = value
    this.pinGroup.x(value)
  }

  set y(value: number) {
    this.pinGroup.y(value)
  }

  updateWidth(parentWidth: number) {
    this.parentWidth = parentWidth
  }

  updateHeight(parentHeight: number) {
    this.parentHeight = parentHeight
  }

  updateState(
    text: string,
    delay: AnnotationDelayDto,
    time: number | null,
    linkedToSlice: boolean,
    hovered: boolean,
    selected: boolean,
    draggable: boolean,
  ) {
    this.text = text
    this.time = time

    this.updatePinColor(linkedToSlice, hovered, selected, delay)
    this.updateTextColor(hovered, selected, delay)

    if (hovered) {
      this.moveToTop()
    }
    this._draggable = draggable
  }

  setDraggable(draggable: boolean) {
    this._draggable = draggable
  }

  get draggable() {
    return this._draggable
  }

  private updatePinColor(
    linkedToSlice: boolean,
    hovered: boolean,
    selected: boolean,
    delay: AnnotationDelayDto,
  ) {
    if (hovered || selected) {
      this.pinDrop.stroke(this.annotationSettings.pinHoveredColor)
      this.pinLeg.stroke(this.annotationSettings.pinHoveredColor)
    } else {
      this.pinDrop.stroke(this.annotationSettings.pinColor)
      this.pinLeg.stroke(this.annotationSettings.pinColor)
    }
    if (linkedToSlice) {
      this.pinDrop.fill(this.annotationSettings.pinLinkedToSliceColor)
    } else {
      this.pinDrop.fill(this.annotationSettings.bgColor)
    }
    if (AnnotationsStore.hasDelay(delay)) {
      this.pinDrop.stroke(this.annotationSettings.delayColor)
      this.pinLeg.stroke(this.annotationSettings.delayColor)
    }
  }

  private updateTextColor(hovered: boolean, selected: boolean, delay: AnnotationDelayDto) {
    this.pinTextFrame.stroke(this.annotationSettings.frameColor)
    this.pinText.fill(this.annotationSettings.textColor)

    if (selected) {
      this.pinTextFrame.fill(this.annotationSettings.pinSelectedBgColor)
      this.pinText.fill(this.annotationSettings.textSelectedColor)
    } else {
      this.pinTextFrame.fill(this.annotationSettings.bgColor)
      this.pinTextFrame.stroke(this.annotationSettings.frameColor)
      this.pinText.fill(this.annotationSettings.textColor)
    }

    if (hovered) {
      this.pinTextFrame.stroke(this.annotationSettings.pinHoveredColor)
      if (selected) {
        this.pinText.fill(this.annotationSettings.textSelectedColor)
      } else {
        this.pinText.fill(this.annotationSettings.textHoveredColor)
      }
    }

    if (AnnotationsStore.hasDelay(delay)) {
      this.pinTextFrame.stroke(this.annotationSettings.delayColor)
    }
  }

  hasTimeAndIsInside(start: number, end: number): boolean {
    return this.time !== null && start <= this.time && this.time <= end
  }

  moveToTop() {
    this.pinGroup.moveToTop()
  }

  removeAndUnsubscribe() {
    this.pinGroup.off('dragstart dragend dragmove')
    this.pinGroup.remove()
  }
}

interface CreatePinResult {
  bgRect: Konva.Rect
  drop: Konva.Path
  leg: Konva.Line
  text: Konva.Text
  textFrame: Konva.Rect
}
