import React, { useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonProps,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'

export enum ModalSizeEnum {
  SMALL,
  NORMAL,
}

type ModalButtonType = ButtonProps & {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

export interface ModalProps {
  size?: ModalSizeEnum
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  secondaryTitle?: string
  additionalButton?: ModalButtonType
  actionButton?: ModalButtonType
  hiddenCloseButton?: boolean
  onRendered?: () => void
}

export const Modal = ({
  children,
  onClose,
  title,
  isOpen,
  secondaryTitle,
  size = ModalSizeEnum.NORMAL,
  additionalButton,
  actionButton,
  hiddenCloseButton,
  onRendered,
}: ModalProps) => {
  const { t } = useTranslation()

  const handleStopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }, [])

  return (
    <Transition appear show={isOpen} as={React.Fragment} beforeEnter={onRendered}>
      <Dialog as="div" className="relative z-modal" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-dark-default/60" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="fixed inset-0 p-6 overflow-y-auto scrolling-touch flex flex-col"
            onClick={handleStopPropagation}
          >
            <div className="flex flex-1 my-0 mx-auto">
              <div className="self-center justify-self-center">
                <Dialog.Panel
                  className={classNames(
                    { 'w-[480px]': size === ModalSizeEnum.SMALL },
                    { 'w-[560px]': size === ModalSizeEnum.NORMAL },
                  )}
                >
                  <div
                    className="px-[24px] py-[16px] rounded-[8px] transform bg-dark-dark5"
                    tabIndex={isOpen ? 0 : undefined}
                    onKeyDown={(event) => event.stopPropagation()}
                  >
                    <Dialog.Title
                      className="text-normal tracking-wide font-medium min-h-[40px] pb-[10px]"
                      as="h3"
                    >
                      {title}{' '}
                      {secondaryTitle && (
                        <span className="text-gray-normal break-all">{secondaryTitle}</span>
                      )}
                    </Dialog.Title>
                    {children}
                    <div className="flex justify-between min-h-[41px] items-end">
                      <div>
                        {additionalButton?.children && (
                          <Button
                            className="outline-offset-8"
                            variant={ButtonVariantEnum.Text}
                            textColorVariant={ButtonTextColorVariantEnum.Muted}
                            {...additionalButton}
                          />
                        )}
                      </div>
                      <div>
                        {!hiddenCloseButton && (
                          <Button
                            className="outline-offset-8 mr-[24px]"
                            variant={ButtonVariantEnum.Text}
                            textColorVariant={ButtonTextColorVariantEnum.Muted}
                            onClick={onClose}
                          >
                            {t('cancel')}
                          </Button>
                        )}
                        <Button
                          className="outline-offset-8 text-state-bad"
                          variant={ButtonVariantEnum.Text}
                          textColorVariant={ButtonTextColorVariantEnum.Primary}
                          {...actionButton}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
