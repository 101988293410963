import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { SliceDetailsCard } from 'components/ps-chart/details-view/SliceDetailsCard'
import { useTranslation } from 'react-i18next'
import { IconColored } from 'components/common/IconColored'
import { ActionTooltip } from 'components/ActionTooltip'
import { OutPathsControls } from 'components/ps-chart/details-view/OutPathsControls'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

interface SliceConnectionsProps {
  psChartStore: PsChartStore
}

export const SliceConnections = observer(function SliceConnections({
  psChartStore,
}: SliceConnectionsProps) {
  const { t } = useTranslation()

  const onShowAllPathsClick = useCallback(() => {
    psChartStore.toggleShouldShowAllPaths()
  }, [psChartStore])

  const onDimDisconnectedSlicesClick = useCallback(() => {
    psChartStore.toggleIsDimDisconnectedSlicesEnabled()
  }, [psChartStore])

  const onFilterIconClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.toggleShowExPathFullDepth()
  }, [psChartStore])

  const onShrinkIconClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.toggleThreadShrunkMode()
  }, [psChartStore])

  const onRegularButtonClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.switchDetailsToRegularChain()
  }, [psChartStore])

  const onNativeButtonClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.switchDetailsToNativeChain()
  }, [psChartStore])

  const isCycleSliceSelected =
    !psChartStore.isStaticPageMode && psChartStore.traceAnalyzeStore.isCycleSliceSelected

  const firstSlice = psChartStore.traceAnalyzeStore.mainDetailsSlices.at(0)!
  const chain = isCycleSliceSelected
    ? psChartStore.traceAnalyzeStore.mainDetailsSlices.slice(1)
    : psChartStore.traceAnalyzeStore.mainDetailsSlices

  const showReactNativeControl =
    psChartStore.traceDataState.hasReactQueueThread &&
    psChartStore.traceAnalyzeStore.nativeChainExists &&
    psChartStore.traceAnalyzeStore.regularChainExists
  const isNativeButtonActive = psChartStore.traceAnalyzeStore.isDetailsChainNative
  const isRegularButtonActive = psChartStore.traceAnalyzeStore.isDetailsChainRegular

  return (
    <div className="h-details-panel pt-[16px] pl-[16px] pr-[10px] overflow-y-scroll">
      {isCycleSliceSelected && (
        <>
          <SliceDetailsCard
            psChartStore={psChartStore}
            chain={[firstSlice]}
            sliceIndex={0}
            isDetached={true}
          />
          <div className="pb-[10px] mt-[-20px] mb-[15px] border-b-[2px] border-gray-divider">
            <div className="font-medium text-small text-gray-normal">
              {t('psChart.details.automaticConnectionsFrom')}
            </div>
            <div className="font-medium text-small text-white">
              {psChartStore.traceAnalyzeStore.isChoreographerSliceSelected
                ? t('psChart.details.choreographer')
                : t('psChart.details.iosViewLifecycle')}
            </div>
          </div>
        </>
      )}
      <div className="mb-[15px] flex">
        {isCycleSliceSelected ? (
          <div className="flex-grow">
            <OutPathsControls psChartStore={psChartStore} />
          </div>
        ) : (
          <div className="font-medium text-small text-gray-normal flex-grow tracking-widest pt-[3px]">
            {t('psChart.details.connectionHeader')}
          </div>
        )}
        <div>
          <ActionTooltip
            tooltipId={
              psChartStore.traceAnalyzeStore.shouldShowAllPaths
                ? 'showMainExecutionPath'
                : 'showAllPaths'
            }
          >
            <IconColored
              onClick={onShowAllPathsClick}
              isActive={psChartStore.traceAnalyzeStore.shouldShowAllPaths}
              icon="paths"
              size={16}
              isDisabled={!psChartStore.traceAnalyzeStore.hasAlternativeChains}
              data-tid="main-exec-path-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="filter">
            <IconColored
              className="ml-[10px]"
              onClick={onFilterIconClick}
              isActive={
                psChartStore.traceAnalyzeStore.chainExists &&
                !psChartStore.traceAnalyzeStore.showExPathModeFullDepth
              }
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
              icon="collapse"
              size={16}
              data-tid="thread-filter-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="collapseDisconnectedDepths">
            <IconColored
              className="ml-[10px]"
              onClick={onShrinkIconClick}
              isActive={
                psChartStore.traceAnalyzeStore.chainExists &&
                psChartStore.traceAnalyzeStore.showShrunkModeDepth
              }
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
              icon="shrink-threads"
              size={16}
              data-tid="thread-filter-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="dimDisconnectedSlices">
            <IconColored
              className="ml-[10px]"
              onClick={onDimDisconnectedSlicesClick}
              isActive={psChartStore.shouldDimDisconnectedSlices}
              icon="dim-disconnected-slices"
              size={16}
              data-tid="dim-disconnected-slices"
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
            />
          </ActionTooltip>
        </div>
      </div>
      {showReactNativeControl ? (
        <div className="mb-[15px] flex justify-around">
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={
              isNativeButtonActive
                ? ButtonTextColorVariantEnum.Primary
                : ButtonTextColorVariantEnum.Muted
            }
            onClick={onNativeButtonClick}
          >
            {t('psChart.details.reactJSTab')}
          </Button>
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={
              isRegularButtonActive
                ? ButtonTextColorVariantEnum.Primary
                : ButtonTextColorVariantEnum.Muted
            }
            onClick={onRegularButtonClick}
          >
            {t('psChart.details.regularTab')}
          </Button>
        </div>
      ) : null}
      {chain.map((slice, index) => (
        <SliceDetailsCard
          psChartStore={psChartStore}
          chain={chain}
          sliceIndex={index}
          key={slice.id}
        />
      ))}
    </div>
  )
})
