/**
 * Feature Flags that are available for use.
 * TODO: Make a codemod to sync with PostHog.
 */
export const FEATURE_FLAGS = {
  /** @deprecated For tests only */
  SAMPLE_FLAG: 'sample-flag-for-tests',
  SELF_SERVE: 'self-serve',
  ANNOTATIONS: 'annotations',
  REGRESSION_ANALYSIS: 'regression-analysis',
  SUITABLE_SLICES: 'suitable-slices',
  CHART_RENDER_TOGGLE: 'chart-render-toggle',
  READ_ONLY_PROJECTS: 'read-only-projects',
} as const

type FeatureFlagPayloads = {
  [FEATURE_FLAGS.SAMPLE_FLAG]: boolean
  [FEATURE_FLAGS.SELF_SERVE]: boolean
  [FEATURE_FLAGS.ANNOTATIONS]: boolean
  [FEATURE_FLAGS.REGRESSION_ANALYSIS]: boolean
  [FEATURE_FLAGS.SUITABLE_SLICES]: boolean
  [FEATURE_FLAGS.CHART_RENDER_TOGGLE]: boolean
  [FEATURE_FLAGS.READ_ONLY_PROJECTS]: string[]
}

export type FeatureFlags = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS]

export type UseFeatureFlag = <T extends FeatureFlags>(
  flag: T,
) => FeatureFlagPayloads[T] | undefined | boolean

/**
 * Override feature flags on development. Will work only for `process.env.NODE_ENV === 'developent'`.
 * You can use it to quickly override flags value to test them during development.
 *
 * Example:
 * ```
 * {
 *   ...
 *   [FEATURE_FLAGS.SELF_SERVE]: true,
 * }
 * ```
 */
export const devOverride: { [key in FeatureFlags]?: FeatureFlagPayloads[key] } = {
  // [FEATURE_FLAGS.SELF_SERVE]: true,
}
