import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Action, ScrollLeft, ScrollRight, ZoomInAction, ZoomOutAction } from './PerfActions'
import { makeAutoObservable } from 'mobx'
import { getQuantile } from './utils'

export enum PerfMeasurerSubType {
  ZOOM_SCROLL,
  ZOOM,
}

export class PerfDynamicMeasurerStore {
  private isMeasuringInProgress = false
  private readonly psChartStore: PsChartStore
  private rafId: number | null = null
  private rafIdMeasurer: number | null = null
  private timeoutId: number | null = null
  private curActionId = 0
  private rafTimeMarks: number[] = []
  private startTime = 0
  private stopTime = 0
  private MAX_RUN_TIME = 60 * 1000 * 2
  private _subType = PerfMeasurerSubType.ZOOM_SCROLL
  private actionsQueueByType = new Map<PerfMeasurerSubType, Action[]>()

  constructor(psChartStore: PsChartStore) {
    makeAutoObservable<PerfDynamicMeasurerStore, 'rafTimeMarks'>(this, {
      rafTimeMarks: false,
    })
    this.psChartStore = psChartStore
    this.actionsQueueByType.set(PerfMeasurerSubType.ZOOM_SCROLL, [
      new ZoomInAction(this.psChartStore),
      new ScrollLeft(this.psChartStore),
      new ScrollRight(this.psChartStore),
      new ZoomOutAction(this.psChartStore),
    ])
    this.actionsQueueByType.set(PerfMeasurerSubType.ZOOM, [
      new ZoomInAction(this.psChartStore),
      new ZoomOutAction(this.psChartStore),
    ])
  }

  private get actionsSequence() {
    return this.actionsQueueByType.get(this._subType)!
  }

  private prepareNextAction() {
    const potentialAction = this.actionsSequence[this.curActionId]
    if (potentialAction.isFinished) {
      this.curActionId = ++this.curActionId === this.actionsSequence.length ? 0 : this.curActionId
      const nextAction = this.actionsSequence[this.curActionId]
      nextAction.reset()
      return nextAction
    }
    return potentialAction
  }

  private runStep() {
    this.rafId = requestAnimationFrame(() => {
      this.timeoutId = window.setTimeout(() => {
        // Do the change
        const action = this.prepareNextAction()
        action.perform()

        const curTime = performance.now()
        if (curTime - this.startTime >= this.MAX_RUN_TIME) {
          this.stop()
          return null
        }
        // Schedule the next iteration
        if (this.isMeasuringInProgress) {
          this.runStep()
        }
      }, 0)
    })
  }

  private runMeasurer() {
    this.rafIdMeasurer = requestAnimationFrame(() => {
      if (this.isMeasuringInProgress) {
        this.rafTimeMarks.push(performance.now())
        this.runMeasurer()
      }
    })
  }

  get averageFps() {
    if (!this.isMeasuringInProgress && this.rafTimeMarks.length > 0) {
      return getFpsQuantile(this.rafTimeMarks)
    }

    return 0
  }

  get subType() {
    return this._subType
  }

  toggleSubType() {
    if (!this.isMeasuringInProgress) {
      switch (this._subType) {
        case PerfMeasurerSubType.ZOOM:
          return (this._subType = PerfMeasurerSubType.ZOOM_SCROLL)
        case PerfMeasurerSubType.ZOOM_SCROLL:
          return (this._subType = PerfMeasurerSubType.ZOOM)
      }
    }
  }

  getElapsedTime() {
    if (this.isMeasuringInProgress) {
      return 0
    }
    return Math.floor(this.stopTime - this.startTime)
  }

  run() {
    if (!this.isMeasuringInProgress) {
      this.startTime = performance.now()
      this.isMeasuringInProgress = true
      this.runStep()
      this.rafTimeMarks = []
      this.runMeasurer()
      this.startTime = performance.now()
    }
  }

  stop() {
    if (this.isMeasuringInProgress) {
      this.isMeasuringInProgress = false
      if (this.timeoutId != null) {
        clearTimeout(this.timeoutId)
      }
      if (this.rafId != null) {
        cancelAnimationFrame(this.rafId)
      }
      if (this.rafIdMeasurer != null) {
        cancelAnimationFrame(this.rafIdMeasurer)
      }
      this.stopTime = performance.now()
    }
  }
}

export const getFpsQuantile = (timeMarks: number[]) => {
  const second = 1000
  const precision = 100
  let leftIntBorder = null
  const fullIntervals = []
  let intervalFramesCount = 0
  for (const mark of timeMarks) {
    if (leftIntBorder === null) {
      leftIntBorder = mark
    } else if (mark - leftIntBorder >= second) {
      fullIntervals.push(intervalFramesCount)
      leftIntBorder += second
      intervalFramesCount = 0
    }
    intervalFramesCount++
  }
  return Math.round(getQuantile(fullIntervals, 0.9) * precision) / precision
}
