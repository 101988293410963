import { makeAutoObservable, runInAction, when } from 'mobx'
import * as Sentry from '@sentry/react'
import { generatePath, matchPath } from 'react-router-dom'
import { PATH_CHART } from 'pages/PsChartPage'
import { Api } from 'api/Api'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { Entry } from 'contentful'

import {
  AppBuildAndRunStatusDto,
  AppInstrumentStatus,
  CreateFreeTrialProjectReqDto,
  FreeTrialStage,
  OsType,
  ProjectSummaryItem,
  TeamDto,
  TechType,
  Trace,
  TraceProcessingStateEnum,
} from 'api/models'

import {
  BuildSystemOption,
  FrameworkOption,
  GuideStepType,
  LanguageOption,
  OsOption,
  PlatformType,
  SelectPlatformFormFields,
  Step,
} from 'components/guide/models'

import {
  PATH_GUIDE_INSTRUMENT_AND_BUILD,
  PATH_GUIDE_RECORD_TRACE,
  PATH_GUIDE_SELECT_PLATFORM,
  PATH_GUIDE_VIEW_TRACE,
} from 'pages/guide/GuideRootPage'

import { SubmitRequestStore } from './SubmitRequestStore'
import { fetchContentfulEntry, TypeMarkdownDocumentFields } from 'hooks/useContentfulEntry'
import { CONTENTFUL_DOCS } from 'api/contentful'
import { suppressMobXWhenAbort } from 'utils/suppressMobXWhenAbort'
import { suppressCanceled } from 'utils/suppressCanceled'

const PULL_TIMEOUT = 10_000

export class GuideStore {
  freeTrialProjectSummary: Readonly<ProjectSummaryItem> | null = null

  freeTrialTeam: Readonly<TeamDto> | null = null

  isReady = false

  private readonly api: Api

  private userId: Readonly<number> | null = null

  private preselectedTechStack: string[] = []

  private scheduledRecheckId: ReturnType<typeof setInterval> | null = null

  buildProperties: string | null = null

  appBuildAndRunStatus: AppBuildAndRunStatusDto | null = null

  mdContent: string | null = null

  androidBuild: string | null = null

  submitRequestStore?: SubmitRequestStore

  private readonly abortController: AbortController

  private traces: Trace[] = []

  constructor(api: Api) {
    makeAutoObservable<GuideStore, 'api' | 'abortController' | 'scheduledRecheckId'>(this, {
      api: false,
      abortController: false,
      scheduledRecheckId: false,
    })
    this.api = api
    this.abortController = new AbortController()
  }

  destroy() {
    this.abortController.abort()
    this.cancelPollBuildAndRunStatus()
  }

  get steps(): Step[] {
    const freeTrialProjectExist = Boolean(this.freeTrialProjectSummary)
    return [
      { type: GuideStepType.SelectPlatform, completed: freeTrialProjectExist, available: true },
      {
        type: GuideStepType.InstrumentAndBuild,
        completed:
          this.appBuildAndRunStatus?.buildStatus?.status === AppInstrumentStatus.SUCCESS &&
          this.appBuildAndRunStatus.appRunStatus?.status === AppInstrumentStatus.SUCCESS,
        available: freeTrialProjectExist,
      },
      {
        type: GuideStepType.RecordTrace,
        completed: this.bestTrace != null,
        available: freeTrialProjectExist,
      },
      { type: GuideStepType.ViewTrace, completed: false, available: freeTrialProjectExist },
    ]
  }

  get selectedTechStack() {
    if (this.freeTrialProjectSummary) {
      const values = this.selectPlatformDefaultValues
      return [values.os, values.devPlatform, values.languages.join('/'), values.buildSystem]
    }
    return this.preselectedTechStack
  }

  setPreselectedTechStack = (values: string[]) => {
    this.preselectedTechStack = values
  }

  private fetchUser() {
    return this.api.getUser(this.abortController.signal).then((user) => {
      runInAction(() => {
        if (user != null) {
          this.userId = user.id
        }
      })
    })
  }

  private fetchTeam() {
    return this.api
      .getTeams(this.abortController.signal)
      .then((teams) => {
        const firstFreeTrialTeam = teams.find((team) => team.freeTrial != null)
        if (firstFreeTrialTeam != null) {
          runInAction(() => {
            this.freeTrialTeam = firstFreeTrialTeam
          })
        }
      })
      .catch(suppressCanceled)
  }

  private initSubmitRequestStore() {
    return when(() => this.freeTrialTeam != null, { signal: this.abortController.signal })
      .then(() => {
        return runInAction(() => {
          this.submitRequestStore = new SubmitRequestStore({
            api: this.api,
            teamUrlName: this.freeTrialTeam!.urlName,
          })
          return this.submitRequestStore.fetchData()
        })
      })
      .catch(suppressMobXWhenAbort)
  }

  private startPullingProject() {
    return when(() => this.freeTrialTeam != null, { signal: this.abortController.signal })
      .then(() => this.pullProjects(this.freeTrialTeam!.urlName))
      .catch(suppressMobXWhenAbort)
  }

  private pullProjects(teamUrlName: string, isSingleCall = false) {
    return this.api
      .getProjectsSummary(
        {
          teamUrlName,
        },
        this.abortController.signal,
      )
      .then((freeTrialTeamProjects) => {
        const firstFreeTrialProjectSummary = freeTrialTeamProjects.projects.at(0)
        if (firstFreeTrialProjectSummary != null) {
          this.freeTrialProjectSummary = firstFreeTrialProjectSummary
        }
      })
      .catch(suppressCanceled)
      .finally(() => {
        if (!this.abortController.signal.aborted && !isSingleCall) {
          setTimeout(() => this.pullProjects(teamUrlName), PULL_TIMEOUT)
        }
      })
  }

  get freeTrialFlow() {
    return (
      this.freeTrialProjectSummary?.flows.filter((flow) => flow.author.id === this.userId)?.at(0) ??
      null
    )
  }

  private fetchDocs() {
    return Promise.all([
      fetchContentfulEntry(CONTENTFUL_DOCS.gradleMainDoc)().then(
        (docData: Entry<TypeMarkdownDocumentFields>) => {
          this.mdContent = docData.fields.markdown
        },
      ),
      fetchContentfulEntry(CONTENTFUL_DOCS.gradleBuildStepDoc)().then(
        (docData: Entry<TypeMarkdownDocumentFields>) => {
          this.androidBuild = docData.fields.markdown
        },
      ),
    ])
  }

  private fetchBuildProperties() {
    return when(() => this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() => {
        return this.api
          .postBuildProperties(this.freeTrialProjectSummary!.project.urlName)
          .then((buildProperties) => {
            runInAction(() => {
              this.buildProperties = buildProperties
            })
          })
      })
      .catch(suppressMobXWhenAbort)
  }

  private startPullingAppBuildAndRunStatus() {
    return when(() => this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() => this.pullAppBuildAndRunStatus(this.freeTrialProjectSummary!.project.urlName))
      .catch(suppressMobXWhenAbort)
  }

  private pullAppBuildAndRunStatus(projectUrlName: string) {
    this.api
      .getAppBuildAndRunStatus(projectUrlName)
      .then((appState) => {
        runInAction(() => {
          this.appBuildAndRunStatus = appState
        })
      })
      .finally(() => {
        if (!this.abortController.signal.aborted) {
          setTimeout(() => this.pullAppBuildAndRunStatus(projectUrlName), PULL_TIMEOUT)
        }
      })
  }

  private startPullingTraces() {
    return when(() => this.freeTrialFlow != null && this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() =>
        this.pullTraces(
          this.freeTrialProjectSummary!.project.urlName,
          this.freeTrialFlow!.projectLocalId.toString(),
        ),
      )
      .catch(suppressMobXWhenAbort)
  }

  private pullTraces(projectUrlName: string, flowProjectLocalId: string) {
    return this.api
      .getFlowTraces({
        projectUrlName,
        flowProjectLocalId,
      })
      .then((traces) => {
        runInAction(() => {
          this.traces = traces
        })
      })
      .finally(() => {
        if (!this.abortController.signal.aborted) {
          setTimeout(() => this.pullTraces(projectUrlName, flowProjectLocalId), PULL_TIMEOUT)
        }
      })
  }

  get bestTrace() {
    return (
      this.traces.find((trace) => trace.processingState === TraceProcessingStateEnum.FINISHED) ??
      this.traces.at(0) ??
      null
    )
  }

  get traceUrlPath() {
    if (
      this.bestTrace == null ||
      this.bestTrace.processingState !== TraceProcessingStateEnum.FINISHED ||
      this.freeTrialProjectSummary == null ||
      this.freeTrialFlow == null
    ) {
      return null
    }

    return generatePath(PATH_CHART, {
      projectUrlName: this.freeTrialProjectSummary.project.urlName,
      flowProjectLocalId: this.freeTrialFlow.projectLocalId.toString(),
      traceProjectLocalId: this.bestTrace.projectLocalId.toString(),
    })
  }

  fetchData() {
    this.fetchBuildProperties()

    return Promise.all([
      this.fetchUser(),
      this.fetchTeam().then(() => {
        const fetchDocsPromise = this.fetchDocs()
        if (this.freeTrialTeam?.freeTrial?.stage === FreeTrialStage.INSTRUCTIONS) {
          return fetchDocsPromise
        }
      }),
      this.initSubmitRequestStore(),
      this.startPullingProject().then(() => {
        const blockingRequests = []
        const pullBuildRunStatusPromise = this.startPullingAppBuildAndRunStatus()
        const pullTracesPromise = this.startPullingTraces()
        if (this.freeTrialProjectSummary != null) {
          blockingRequests.push(pullBuildRunStatusPromise)
          if (this.freeTrialFlow != null) {
            blockingRequests.push(pullTracesPromise)
          }
        }
        return Promise.all(blockingRequests)
      }),
    ])
      .catch((error) => {
        if (!(error as AxiosError).isAxiosError) {
          Sentry.captureException(error)
        }
        throw error
      })
      .finally(() => {
        runInAction(() => {
          this.isReady = true
        })
      })
  }

  createFreeTrialProject(teamUrlName: string, data: CreateFreeTrialProjectReqDto) {
    return this.api.createFreeTrialProject(teamUrlName, data).then(() => {
      return this.pullProjects(teamUrlName, true)
    })
  }

  get selectPlatformDefaultValues(): SelectPlatformFormFields {
    const project = this.freeTrialProjectSummary?.project
    return {
      os: project ? GuideStore.getOsByOsType(project.os) : '',
      devPlatform: project ? GuideStore.getDevPlatformByTechType(project.tech) : '',
      languages: project
        ? project.languages.map((lang) => GuideStore.getLocalValueByType(LanguageOption, lang))
        : [],
      buildSystem:
        project && project.buildSystem
          ? GuideStore.getLocalValueByType(BuildSystemOption, project.buildSystem)
          : '',
    }
  }

  private cancelPollBuildAndRunStatus() {
    if (this.scheduledRecheckId != null) {
      clearInterval(this.scheduledRecheckId)
    }
  }

  createFlow = async (flowName: string): Promise<string | Error | void> => {
    const projectUrlName = this.freeTrialProjectSummary?.project.urlName

    if (projectUrlName) {
      const projectObject = { projectUrlName }
      const flowObject = { name: flowName, description: '' }

      try {
        const newFlow = await this.api.postFlow(projectObject, flowObject)

        if (newFlow && newFlow.projectLocalId) {
          runInAction(() => {
            this.freeTrialProjectSummary?.flows.unshift(newFlow)
          })

          return newFlow.name ?? ''
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }
        throw new Error(t('guidePage.createFlow.couldNotCreate'))
      }
    } else {
      throw new Error(t('guidePage.createFlow.noProjectPost'))
    }
  }

  public static getStepByPathName(pathName: string): GuideStepType | null {
    if (matchPath(PATH_GUIDE_SELECT_PLATFORM, pathName)) {
      return GuideStepType.SelectPlatform
    } else if (matchPath(PATH_GUIDE_INSTRUMENT_AND_BUILD, pathName)) {
      return GuideStepType.InstrumentAndBuild
    } else if (matchPath(PATH_GUIDE_RECORD_TRACE, pathName)) {
      return GuideStepType.RecordTrace
    } else if (matchPath(PATH_GUIDE_VIEW_TRACE, pathName)) {
      return GuideStepType.ViewTrace
    }
    return null
  }

  public static getPagePathByStep(step: GuideStepType): string {
    switch (step) {
      case GuideStepType.SelectPlatform: {
        return PATH_GUIDE_SELECT_PLATFORM
      }
      case GuideStepType.InstrumentAndBuild: {
        return PATH_GUIDE_INSTRUMENT_AND_BUILD
      }
      case GuideStepType.RecordTrace: {
        return PATH_GUIDE_RECORD_TRACE
      }
      case GuideStepType.ViewTrace: {
        return PATH_GUIDE_VIEW_TRACE
      }
    }
  }

  public static getPageUrl(step: GuideStepType, platform: PlatformType): string {
    return generatePath(GuideStore.getPagePathByStep(step), { platform })
  }

  public static getPlatformByString(platform: string): PlatformType | null {
    if (!Object.values(PlatformType).includes(platform as PlatformType)) {
      return null
    }
    return platform as PlatformType
  }

  public static getOsByOsType(osType: OsType) {
    switch (osType) {
      case OsType.IOS:
        return OsOption.IOS
      case OsType.ANDROID:
        return OsOption.ANDROID
    }
  }

  public static getDevPlatformByTechType(techType: TechType) {
    switch (techType) {
      case TechType.JVM:
      case TechType.SWIFT:
        return FrameworkOption.NATIVE
      case TechType.REACT_NATIVE:
        return FrameworkOption.REACT_NATIVE
    }
  }

  public static getLocalValueByType(type: object, value: string) {
    return Object.values(type).find((item) => item.toLowerCase() === value.toLowerCase()) || value
  }
}
