import { makeAutoObservable, observable } from 'mobx'
import { Api } from 'api/Api'
import { Toaster } from 'hooks/useToaster'
import { Thread } from 'components/ps-chart/models/Thread'
import { ThreadsRenderSettings } from 'components/ps-chart/models/settings'
import {
  linkTreeNodes,
  mergeTreeNodes,
  TreeNode,
} from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { walkOverTree } from 'components/ps-chart/stores/connections-store/LinksTree/walkOverTree'
import { Slice } from 'components/ps-chart/models/Slice'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById, TraceDataState } from 'components/ps-chart/stores/TraceDataStore'
import { ChartPageParams, ChoreographerPathsDto, NamedLinkDto } from 'api/models'
import { getNonVisibleConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { getMainChainFromTree } from 'components/ps-chart/stores/connections-store/LinksTree/getMainChainFromTree'
import { getLinksTree } from 'components/ps-chart/stores/connections-store/LinksTree/getLinksTree'
import { ConnectionType } from '../models/ConnectionType'
import { findSlice, walkSlices } from 'components/ps-chart/utils/slice'
import { removeLinksBySourceId } from 'components/ps-chart/stores/connections-store/createNamedLink'

export interface Error {
  id: number
  msg: string
}

const CHOREOGRAPHER_PATTERN = 'Choreographer#doFrame'

const IOS_PATH_LOOKUP_PATTERNS = [
  ' viewDidLoad(',
  ' viewDidAppear(',
  ' viewDidLayoutSubviews(',
  ' viewDidDisappear(',
]

/**
 * For Cycle slice/function (Android/iOS) we are searching for execution path which leads into selected "cycle".
 * So for specific Cycle we are searching for Variants (slices/functions) with execution path.
 * Variants are split into two types based on their execution time and ancestor slice.
 *
 * SIBLING Variant: {@link TraceAnalyzeStore.getCycleSliceSiblingVariants}
 * Slices/Functions which are executed between selected cycle and closest previous cycle
 *
 * CHILD Variant: {@link TraceAnalyzeStore.getCycleSliceChildVariants}
 * Slices/Functions which are called by selected Cycle
 */
enum CycleVariantType {
  SIBLING = 'SIBLING',
  CHILD = 'CHILD',
}

/**
 * Array of all cycle variants should contain variant type, so it wouldn't need to re-check which connection type will be used
 * to create link between variant slice and cycle slice in {@link TraceAnalyzeStore.setCycleLink}
 */
interface CycleVariant {
  sliceId: number
  type: CycleVariantType
}

enum DetailsChainType {
  REGULAR,
  NATIVE,
}

export type DepthByThreadId = Map<number, number>
export type DepthsByThreadId = Map<number, number[]>
export type HeightByThreadId = Map<number, number>
export type TopBottom = [number, number]
export type TopBottomByThreadId = Map<number, TopBottom>

export interface SlicesState {
  sliceById: ReadonlySliceById
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>
  namedLinksByLinkId: Map<string, NamedLinkDto>
}

export interface ThreadsState {
  threads: ReadonlyArray<Thread>
  threadsById: ReadonlyMap<number, Thread>
  mainThreads: Thread[]
  favThreads: Thread[]
  favIdSet: Set<number>
  depthByThreadId: DepthByThreadId
}

export class TraceAnalyzeStore implements ThreadsState, SlicesState {
  /*
   * THREADS
   */
  private readonly traceDataState: TraceDataState
  readonly threadSettings: ThreadsRenderSettings

  favThreads: Thread[] = []

  customDepthByThreadId: DepthByThreadId = new Map()

  showNormalModeFullDepth = false
  showExPathModeFullDepth = false
  showShrunkModeDepth = false

  deprioritizedThreadIds: Array<number> = []

  private readonly api: Api
  private readonly toaster: Toaster
  private readonly chartPageParams: Required<ChartPageParams>

  /*
   * SLICES & CHAINS
   */
  shouldShowAllPaths = false
  detailsChainType: DetailsChainType = DetailsChainType.REGULAR

  selectedCycleSliceVariantIndex = 0
  private cycleSelectedVariantIndexLookup = new Map<number, number>()
  private cyclePinnedVariantIndexLookup = new Map<number, number>()
  selectedSlice: Slice | null = null
  sliceLinksBySliceId = new Map<number, ReadonlyArray<SliceLink>>()
  namedLinksByLinkId = new Map<string, NamedLinkDto>()

  constructor(
    api: Api,
    toaster: Toaster,
    threadsDataState: TraceDataState,
    threadSettings: ThreadsRenderSettings,
    chartPageParams: Required<ChartPageParams>,
  ) {
    makeAutoObservable<TraceAnalyzeStore, 'api' | 'toaster' | 'chartPageParams'>(this, {
      api: false,
      toaster: false,
      chartPageParams: false,
      threadSettings: false,
      sliceById: false,
      threads: false,
      threadsById: false,
      favThreads: observable.shallow,
      selectedSlice: observable.ref,
      sliceLinksBySliceId: observable.shallow,
    })

    this.api = api
    this.toaster = toaster
    this.chartPageParams = chartPageParams
    this.threadSettings = threadSettings
    this.traceDataState = threadsDataState
  }

  get threads(): ReadonlyArray<Thread> {
    return this.traceDataState.threads
  }

  get threadsById(): ReadonlyMap<number, Thread> {
    return this.traceDataState.threadsById
  }

  get mainThreads(): Thread[] {
    let mainThreads = this.calcMainThreads()

    if (this.chainExists && this.showShrunkModeDepth) {
      mainThreads = mainThreads.filter((thread) => this.activeThreadsFromChain.has(thread.id))
    }

    //WHY: No need to check for thread's weight if there is only one active thread
    if (this.activeThreadsFromChain.size < 2) {
      return mainThreads
    }

    const weightByThreadId: Map<number, number> = new Map()
    let curWeight = 0
    weightByThreadId.set(this.threads[0].id, curWeight++)
    for (const slice of [...this.mainChainSlices].reverse()) {
      if (!weightByThreadId.has(slice.threadId)) {
        weightByThreadId.set(slice.threadId, curWeight++)
      }
    }
    walkOverTree(this.linksTree, (treeNode) => {
      const slice = this.sliceById.get(treeNode.sliceId)!
      if (!weightByThreadId.has(slice.threadId)) {
        weightByThreadId.set(slice.threadId, curWeight++)
      }
    })

    mainThreads.sort((aThread, bThread) => {
      const aWeight = weightByThreadId.get(aThread.id) ?? +Infinity
      const bWeight = weightByThreadId.get(bThread.id) ?? +Infinity
      const diff = aWeight - bWeight
      return isNaN(diff) ? 0 : diff
    })

    return mainThreads
  }

  calcMainThreads(): Thread[] {
    const favSet = new Set(this.favThreads)
    const deprioritizedThreads: Thread[] = []
    const filteredThreads: Thread[] = this.threads.filter((thread) => {
      if (this.deprioritizedThreadIds.includes(thread.id)) {
        deprioritizedThreads.push(thread)
        return false
      }
      return !favSet.has(thread)
    })
    filteredThreads.push(...deprioritizedThreads)
    return filteredThreads
  }

  get favIdSet(): Set<number> {
    return new Set(this.favThreads.map((thread) => thread.id))
  }

  /**
   * Returns depth for all the threads
   * For normal mode: {@link customDepthByThreadId} if contains or original depth otherwise.
   * For execution path mode: max depth according to the {@link mainChain} or original depth otherwise.
   */
  get depthByThreadId(): DepthByThreadId {
    const depthByThreadId: DepthByThreadId = new Map()

    const alreadyAdded = new Set<number>()
    if (this.chainExists) {
      if (!this.showExPathModeFullDepth) {
        this.calcChainThreadMaxDepths(depthByThreadId)
        for (const threadId of depthByThreadId.keys()) {
          alreadyAdded.add(threadId)
        }
      }
      if (this.showShrunkModeDepth) {
        for (const favoriteThread of this.favThreads) {
          if (!alreadyAdded.has(favoriteThread.id)) {
            depthByThreadId.set(favoriteThread.id, 1)
            alreadyAdded.add(favoriteThread.id)
          }
        }
      }
    } else if (!this.showNormalModeFullDepth) {
      for (const [threadId, depth] of this.customDepthByThreadId.entries()) {
        depthByThreadId.set(threadId, depth)
        alreadyAdded.add(threadId)
      }
    }

    this.threads.forEach((thread) => {
      if (!alreadyAdded.has(thread.id)) {
        depthByThreadId.set(thread.id, thread.depth)
      }
    })
    return depthByThreadId
  }

  get heightByThreadId(): HeightByThreadId {
    const heightByThreadId: DepthByThreadId = new Map()
    const threads = this.threads
    const settings = this.threadSettings
    for (let i = 0; i < threads.length; i++) {
      const thread = threads[i]
      const depth = this.depthByThreadId.get(thread.id)!
      const height = Math.max(
        settings.minHeight,
        depth * settings.blockHeight + settings.bottomPadding,
      )
      heightByThreadId.set(thread.id, height)
    }
    return heightByThreadId
  }

  private calcChainThreadMaxDepths(depthByThreadId: DepthByThreadId) {
    const activeDepth = this.activeThreadsDepthsFromChain
    if (this.showShrunkModeDepth) {
      activeDepth.forEach((depth, threadId) =>
        depthByThreadId.set(threadId, depth.indexOf(Math.max(...depth)) + 1),
      )
    } else {
      activeDepth.forEach((depth, threadId) =>
        depthByThreadId.set(threadId, Math.max(...depth) + 1),
      )
    }
  }

  toggleFavoriteThread(thread: Thread) {
    if (this.favThreads.includes(thread)) {
      this.favThreads = this.favThreads.filter((curThread) => curThread.id !== thread.id)
    } else {
      this.favThreads.push(thread)
    }
  }

  deprioritizeThread(threadId: number) {
    this.deprioritizedThreadIds.push(threadId)
  }

  toggleShowExPathFullDepth() {
    this.showExPathModeFullDepth = !this.showExPathModeFullDepth
  }

  toggleThreadShrunkMode() {
    this.showShrunkModeDepth = !this.showShrunkModeDepth
  }

  reset() {
    this.sliceLinksBySliceId.clear()
  }

  get sliceById(): ReadonlySliceById {
    return this.traceDataState.sliceById
  }

  setSelectedSlice(slice: Slice | null) {
    this.selectedSlice = slice
    this.selectedCycleSliceVariantIndex = 0

    if (slice !== null && this.isCycleSlice(slice)) {
      if (
        this.cycleSelectedVariantIndexLookup.has(slice.id) ||
        this.cyclePinnedVariantIndexLookup.has(slice.id)
      ) {
        this.selectedCycleSliceVariantIndex = this.getSelectedCycleSliceVariantIndex()
      } else {
        this.selectCycleSliceVariant(slice.id, 0)
      }
    }
  }

  setCycleLink(cycleSliceId: number, cycleVariantIndex: number) {
    const cycleSlice = this.sliceById.get(cycleSliceId)!
    const cycleVariant =
      this.selectedSlice !== null && this.selectedSlice.id === cycleSliceId
        ? this.selectedCycleSliceVariants[cycleVariantIndex]
        : this.cyclePathVariants(cycleSlice)[cycleVariantIndex]
    const cycleVariantSlice = this.sliceById.get(cycleVariant?.sliceId)
    if (cycleVariantSlice !== undefined) {
      const connectionType =
        cycleVariant.type === CycleVariantType.CHILD ? ConnectionType.ASYNC : ConnectionType.MANUAL
      const links = Array.from(this.sliceLinksBySliceId.get(cycleSlice.id) ?? [])
      links.push({
        sourceId: `cycle${cycleSlice.id}`,
        fromSliceId: cycleSlice.id,
        toSliceId: cycleVariantSlice.id,
        connectionType,
        isEditable: false,
      })
      this.sliceLinksBySliceId.set(cycleSlice.id, links)
    }
  }

  pinCycleVariant(cycleSliceId: number, cycleVariantIndex: number) {
    this.cyclePinnedVariantIndexLookup.set(cycleSliceId, cycleVariantIndex)
    if (!this.cycleSelectedVariantIndexLookup.has(cycleSliceId)) {
      this.setCycleLink(cycleSliceId, cycleVariantIndex)
    }
  }

  selectCycleSliceVariant(cycleSliceId: number, cycleVariantIndex: number) {
    const pinnedIndex = this.cyclePinnedVariantIndexLookup.get(cycleSliceId) ?? -1
    removeLinksBySourceId(this.sliceLinksBySliceId, `cycle${cycleSliceId}`)
    if (pinnedIndex === cycleVariantIndex) {
      this.cycleSelectedVariantIndexLookup.delete(cycleSliceId)
    } else {
      this.cycleSelectedVariantIndexLookup.set(cycleSliceId, cycleVariantIndex)
    }
    this.setCycleLink(cycleSliceId, cycleVariantIndex)
  }

  pinCycleVariants(cyclePinnedVariantIndexes: ChoreographerPathsDto) {
    cyclePinnedVariantIndexes.choreographerPath.forEach((path) => {
      this.pinCycleVariant(path.sliceId, path.pathId)
    })
  }

  private static isChoreographerSlice(slice: Slice) {
    return slice.level === 0 && slice.title.includes(CHOREOGRAPHER_PATTERN)
  }

  private static isIosPathLookupSlice(slice: Slice) {
    return (
      slice.level === 0 &&
      slice.title.endsWith(')') &&
      IOS_PATH_LOOKUP_PATTERNS.some((x) => slice.title.includes(x))
    )
  }

  private isCycleSlice(slice: Slice) {
    return (
      TraceAnalyzeStore.isChoreographerSlice(slice) || TraceAnalyzeStore.isIosPathLookupSlice(slice)
    )
  }

  get isChoreographerSliceSelected(): boolean {
    return this.selectedSlice != null && TraceAnalyzeStore.isChoreographerSlice(this.selectedSlice)
  }

  get isCycleSliceSelected(): boolean {
    return this.selectedSlice != null && this.isCycleSlice(this.selectedSlice)
  }

  private getSelectedCycleSliceVariantIndex() {
    if (this.isCycleSliceSelected) {
      const index =
        this.cycleSelectedVariantIndexLookup.get(this.selectedSlice!.id) ??
        this.cyclePinnedVariantIndexLookup.get(this.selectedSlice!.id)
      if (index) {
        return index
      }
    }
    return 0
  }

  private getCycleSliceChildVariants = (cycleSlice: Slice): CycleVariant[] => {
    const childrenSet = new Set<number>()
    walkSlices([cycleSlice], (curSlice) => childrenSet.add(curSlice.id))

    const variantSlices = new Set<number>()
    walkSlices([...cycleSlice.children!], (curSlice) => {
      const curLinks = this.sliceLinksBySliceId.get(curSlice.id)
      if (curLinks != null && curLinks.length > 0) {
        for (const curLink of curLinks) {
          if (childrenSet.has(curLink.toSliceId) || variantSlices.has(curLink.fromSliceId)) {
            continue
          }
          const fromSlice = this.sliceById.get(curLink.fromSliceId)!
          const toSlice = this.sliceById.get(curLink.toSliceId)!
          const nonVisibleError = getNonVisibleConnectionError(
            fromSlice,
            toSlice,
            this.sliceById,
            this.sliceLinksBySliceId,
          )
          if (nonVisibleError != null) {
            continue
          }
          variantSlices.add(curLink.fromSliceId)
        }
      }
    })
    return Array.from(variantSlices).map((sliceId) => ({
      sliceId: sliceId,
      type: CycleVariantType.CHILD,
    }))
  }

  private getCycleSliceSiblingVariants = (cycleSlice: Slice): CycleVariant[] => {
    const variantSlices = new Set<number>()
    const selectedThread = this.threadsById.get(cycleSlice.threadId)!
    for (let posIndex = cycleSlice.rootPositionIndex - 1; posIndex >= 0; posIndex--) {
      const curRootSlice = selectedThread.slices[posIndex]
      if (
        TraceAnalyzeStore.isChoreographerSlice(curRootSlice) ||
        TraceAnalyzeStore.isIosPathLookupSlice(curRootSlice)
      ) {
        break
      }
      const curLinks = this.sliceLinksBySliceId.get(curRootSlice.id)
      if (curLinks != null) {
        for (const curLink of curLinks) {
          if (variantSlices.has(curLink.fromSliceId)) {
            continue
          }

          const fromSlice = this.sliceById.get(curLink.fromSliceId)!
          const toSlice = this.sliceById.get(curLink.toSliceId)!
          const nonVisibleError = getNonVisibleConnectionError(
            fromSlice,
            toSlice,
            this.sliceById,
            this.sliceLinksBySliceId,
          )
          if (nonVisibleError != null) {
            continue
          }

          variantSlices.add(curLink.fromSliceId)
        }
      }
    }
    return Array.from(variantSlices).map((sliceId) => ({
      sliceId: sliceId,
      type: CycleVariantType.SIBLING,
    }))
  }

  private cyclePathVariants(cycleSlice: Slice): CycleVariant[] {
    return [
      ...this.getCycleSliceChildVariants(cycleSlice),
      ...this.getCycleSliceSiblingVariants(cycleSlice),
    ]
  }

  get selectedCycleSliceVariants(): CycleVariant[] {
    return this.cyclePathVariants(this.selectedSlice!)
  }

  get selectedCycleSliceTotalVariants() {
    return this.selectedCycleSliceVariants.length
  }

  private updateCycleSliceIndexes(previousState: {
    pinned: Map<number, number>
    selected: Map<number, number>
  }): Promise<void> {
    const projectUrlName = this.chartPageParams.projectUrlName
    const traceProjectLocalId = this.chartPageParams.traceProjectLocalId

    const reqBody = Array.from(this.cyclePinnedVariantIndexLookup.entries()).map(
      ([sliceId, pathId]) => ({
        sliceId,
        pathId,
      }),
    )

    return this.api
      .putChoreographerPaths(
        { projectUrlName, traceProjectLocalId },
        { choreographerPath: [...reqBody] },
      )
      .then((paths) => {
        this.pinCycleVariants(paths)
      })
      .catch((reason) => {
        if (previousState) {
          this.cyclePinnedVariantIndexLookup = previousState.pinned
          this.cycleSelectedVariantIndexLookup = previousState.selected
        }
        return Promise.reject(reason)
      })
  }

  get isSelectedCycleHasPinnedVariant() {
    return this.cyclePinnedVariantIndexLookup.has(this.selectedSlice!.id)
  }

  get isSelectedCyclePinnedVariantActive() {
    return (
      this.selectedCycleSliceVariantIndex ===
      this.cyclePinnedVariantIndexLookup.get(this.selectedSlice!.id)
    )
  }

  togglePinCycleSliceVariantIndex(): Promise<void> {
    const previousState = {
      pinned: this.cyclePinnedVariantIndexLookup,
      selected: this.cycleSelectedVariantIndexLookup,
    }

    // optimistic update
    if (this.isSelectedCyclePinnedVariantActive) {
      this.cyclePinnedVariantIndexLookup.delete(this.selectedSlice!.id)
      this.cycleSelectedVariantIndexLookup.set(
        this.selectedSlice!.id,
        this.selectedCycleSliceVariantIndex,
      )
    } else {
      this.cycleSelectedVariantIndexLookup.delete(this.selectedSlice!.id)
      this.cyclePinnedVariantIndexLookup.set(
        this.selectedSlice!.id,
        this.selectedCycleSliceVariantIndex,
      )
    }

    // update the server
    return this.updateCycleSliceIndexes(previousState)
  }

  get selectedCycleSlicePinState() {
    if (this.isSelectedCyclePinnedVariantActive) {
      return {
        tooltip: 'unselectPath',
        icon: 'star-active',
      }
    } else if (this.isSelectedCycleHasPinnedVariant) {
      return {
        tooltip: 'updateSelectedPath',
        icon: 'star-inactive',
      }
    } else {
      return {
        tooltip: 'selectThisPath',
        icon: 'star-inactive',
      }
    }
  }

  selectCycleSliceNextVariant() {
    const nextValue = this.selectedCycleSliceVariantIndex + 1
    if (nextValue >= this.selectedCycleSliceTotalVariants) {
      this.selectedCycleSliceVariantIndex = 0
    } else {
      this.selectedCycleSliceVariantIndex = nextValue
    }
    this.selectCycleSliceVariant(this.selectedSlice!.id, this.selectedCycleSliceVariantIndex)
  }

  selectCycleSlicePreviousVariant() {
    const nextValue = this.selectedCycleSliceVariantIndex - 1
    if (nextValue < 0) {
      this.selectedCycleSliceVariantIndex = Math.max(this.selectedCycleSliceTotalVariants - 1, 0)
    } else {
      this.selectedCycleSliceVariantIndex = nextValue
    }
    this.selectCycleSliceVariant(this.selectedSlice!.id, this.selectedCycleSliceVariantIndex)
  }

  getSlicesFromTree(inputTree: TreeNode | null): Slice[] {
    const slices: Slice[] = []

    walkOverTree(inputTree, (node: TreeNode) => {
      if (node.sliceId != null) {
        const slice = this.sliceById.get(node.sliceId)
        if (slice !== undefined) {
          slices.push(slice)
        }
      }
    })

    return slices
  }

  get allRegularSlices(): Slice[] {
    return this.getSlicesFromTree(this.allPathsLinksTree)
  }

  get mainRegularSlices(): Slice[] {
    return getMainChainFromTree(this.allPathsLinksTree, this.sliceById)
  }

  get allNativeSlices(): Slice[] {
    return this.getSlicesFromTree(this.allNativeLinksTree)
  }

  get mainNativeSlices(): Slice[] {
    return getMainChainFromTree(this.allNativeLinksTree, this.sliceById)
  }

  get allChainsSlices(): Slice[] {
    return [...this.allRegularSlices, ...this.allNativeSlices]
  }

  get mainChainSlices(): Slice[] {
    return [...this.mainRegularSlices, ...this.mainNativeSlices]
  }

  get mainDetailsSlices(): Slice[] {
    return this.isDetailsChainNative ? this.mainNativeSlices : this.mainRegularSlices
  }

  get isDetailsChainNative(): boolean {
    return this.detailsChainType === DetailsChainType.NATIVE
  }

  get isDetailsChainRegular(): boolean {
    return this.detailsChainType === DetailsChainType.REGULAR
  }

  switchDetailsToNativeChain() {
    this.detailsChainType = DetailsChainType.NATIVE
  }

  switchDetailsToRegularChain() {
    this.detailsChainType = DetailsChainType.REGULAR
  }

  get chainExists(): boolean {
    return this.mainChainSlices.length > 1
  }

  get regularChainExists(): boolean {
    return this.mainRegularSliceIds.size > 1
  }

  get nativeChainExists(): boolean {
    return this.mainNativeSlicesIds.size > 1
  }

  get linksTree(): TreeNode | null {
    if (this.shouldShowAllPaths) {
      return mergeTreeNodes(this.allPathsLinksTree, this.allNativeLinksTree)
    }
    return mergeTreeNodes(this.mainPathLinksTree, this.mainNativeLinksTree)
  }

  filterPathLinksTreeByChainIds(
    initialTree: TreeNode | null,
    chainIds: Set<number>,
  ): TreeNode | null {
    if (initialTree == null) {
      return initialTree
    }
    const nodesCopyMap = new Map<number, TreeNode>()
    walkOverTree(initialTree, (treeNode) => {
      if (!chainIds.has(treeNode.sliceId)) {
        return null
      }
      nodesCopyMap.set(treeNode.sliceId, { ...treeNode })
    })
    nodesCopyMap.forEach((treeNode) => {
      treeNode.fromLinks = treeNode.fromLinks
        .filter((link) => chainIds.has(link.toTreeNode.sliceId))
        .map((link) => ({
          connectionType: link.connectionType,
          fromTreeNode: nodesCopyMap.get(link.fromTreeNode.sliceId)!,
          toTreeNode: nodesCopyMap.get(link.toTreeNode.sliceId)!,
        }))
    })
    return nodesCopyMap.get(initialTree.sliceId)!
  }

  get mainPathLinksTree(): TreeNode | null {
    return this.filterPathLinksTreeByChainIds(this.allPathsLinksTree, this.mainChainIds)
  }

  get allPathsLinksTree(): TreeNode | null {
    if (this.selectedSlice === null) {
      return null
    }

    return getLinksTree(this.selectedSlice, this.sliceLinksBySliceId, this.sliceById)
  }

  get allNativeLinksTree(): TreeNode | null {
    if (this.selectedSlice === null) {
      return null
    }
    if (!this.traceDataState.reactJSThreadIds.has(this.selectedSlice.threadId)) {
      return null
    }
    if (this.isCycleSliceSelected) {
      return null
    }
    if (this.traceDataState.reactQueueThread !== null) {
      const treeNode = { sliceId: this.selectedSlice!.id, fromLinks: [], toLinks: [] }
      const queueThreadSlice = findSlice(
        this.traceDataState.reactQueueThread.slices,
        (queueSlice) => {
          return (
            queueSlice.level > 0 &&
            queueSlice.start <= this.selectedSlice!.end &&
            queueSlice.end >= this.selectedSlice!.end
          )
        },
      )
      if (queueThreadSlice !== null) {
        const mqtTreeNode = getLinksTree(
          queueThreadSlice,
          this.sliceLinksBySliceId,
          this.sliceById,
          true,
        )!
        linkTreeNodes(treeNode, mqtTreeNode, ConnectionType.CLOSURE)
      }
      return treeNode
    }
    return null
  }

  get mainNativeLinksTree(): TreeNode | null {
    return this.filterPathLinksTreeByChainIds(this.allNativeLinksTree, this.mainNativeSlicesIds)
  }

  get mainChainIds(): Set<number> {
    return new Set<number>(this.mainChainSlices.map(({ id }) => id))
  }

  get allChainsIds(): Set<number> {
    return new Set<number>(this.allChainsSlices.map(({ id }) => id))
  }

  get mainRegularSliceIds(): Set<number> {
    return new Set<number>(this.mainRegularSlices.map(({ id }) => id))
  }

  get mainNativeSlicesIds(): Set<number> {
    return new Set<number>(this.mainNativeSlices.map(({ id }) => id))
  }

  get hasAlternativeChains(): boolean {
    return this.allChainsIds.size !== this.mainChainIds.size
  }

  get mainRegularSliceIdIndexes(): Map<number, number> {
    const indexedSlice = new Map<number, number>()
    this.mainRegularSlices.map((slice, index) => indexedSlice.set(slice.id, index))
    return indexedSlice
  }

  get mainNativeSliceIdIndexes(): Map<number, number> {
    const indexedSlice = new Map<number, number>()
    this.mainNativeSlices.map((slice, index) => indexedSlice.set(slice.id, index))
    return indexedSlice
  }

  /**
   * It's reverse order function (from right to left). As "from" can be in both native and regular chain
   * we should check link order in chain (regular/native) based on "to" slice, because "from"
   */
  checkMainChainSliceOrder(fromId: number, toId: number) {
    if (this.mainChainIds.has(fromId)) {
      if (this.nativeChainExists && this.mainNativeSlicesIds.has(toId)) {
        return (
          this.mainNativeSliceIdIndexes.get(toId)! - this.mainNativeSliceIdIndexes.get(fromId)! !==
          1
        )
      }
      if (this.regularChainExists && this.mainRegularSliceIds.has(toId)) {
        return (
          this.mainRegularSliceIdIndexes.get(toId)! -
            this.mainRegularSliceIdIndexes.get(fromId)! !==
          1
        )
      }
    }
    return false
  }

  get activeThreadsFromChain(): Set<number> {
    let slices: Slice[]
    if (this.shouldShowAllPaths) {
      slices = this.allChainsSlices
    } else {
      slices = this.mainChainSlices
    }
    return new Set(slices.map((slice) => slice.threadId))
  }

  get activeThreadsDepthsFromChain(): DepthsByThreadId {
    const depthsByThreadId = new Map<number, number[]>()
    let slices: Slice[]
    if (this.shouldShowAllPaths) {
      slices = this.allChainsSlices
    } else {
      slices = this.mainChainSlices
    }
    const threads = [...this.activeThreadsFromChain]
    for (let i = 0; i <= threads.length; i++) {
      const id = threads[i]
      const depths = [
        ...new Set<number>(
          slices.filter((slice) => slice.threadId === id).map((slice) => slice.level),
        ),
      ].sort((prev, next) => prev - next)
      depthsByThreadId.set(id, depths)
    }
    return depthsByThreadId
  }
}
