import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  GlobalTimelineListener,
  OnTimeWindowChange,
} from 'components/global-timeline/GlobalTimelineBaseRenderer'
import { GlobalTimelineSettings } from './models/GlobalTimelineSettings'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { GlobalTimelineContentRenderer } from 'components/global-timeline/GlobalTimelineContentRenderer'
import { autorun, reaction } from 'mobx'
import { useDi } from 'contexts/di-context'

export interface GlobalTimelineProps {
  psChartStore: PsChartStore
}

export const GlobalTimeline = observer(function GlobalTimeline({
  psChartStore,
}: GlobalTimelineProps) {
  const containerId = 'globalTimeline'
  const containerRef = useRef<HTMLDivElement>(null)
  const [globalTimelineRender, setGlobalTimelineRender] = useState<GlobalTimelineContentRenderer>()
  const analytics = useDi().compositionRoot.analytics

  const onTimeWindowChange = useCallback<OnTimeWindowChange>(
    (start, end) => {
      psChartStore.hState.applyWindowChange(start, end)
      analytics.track('aggregation-view-used')
    },
    [psChartStore, analytics],
  )

  useEffect(() => {
    if (!psChartStore.isLoaded) {
      return
    }
    const settings = new GlobalTimelineSettings()
    const listener: GlobalTimelineListener = {
      onTimeWindowChange: onTimeWindowChange,
    }
    const globalTimeline = new GlobalTimelineContentRenderer(
      containerId,
      containerRef,
      psChartStore.hState.xMin,
      psChartStore.hState.xMax,
      settings,
      listener,
      psChartStore.globalTimelineContent,
      psChartStore.globalTimelineSearchHighlightsContent,
    )
    globalTimeline.addEventListeners()
    setGlobalTimelineRender(globalTimeline)
    return () => {
      globalTimeline.removeEventListeners()
    }
  }, [onTimeWindowChange, psChartStore, psChartStore.isLoaded])

  useEffect(
    () =>
      autorun(
        () => {
          const isLoaded = psChartStore.isLoaded
          const width = psChartStore.hState.width
          const xStart = psChartStore.hState.xStart
          const xEnd = psChartStore.hState.xEnd
          if (isLoaded) {
            globalTimelineRender?.updateState(width, xStart, xEnd)
          }
        },
        { name: 'autorun @ GlobalTimeline -> updateState' },
      ),
    [globalTimelineRender, psChartStore],
  )

  useEffect(
    () =>
      reaction(
        () => [psChartStore.sortedThreads],
        () => {
          if (psChartStore.isLoaded) {
            globalTimelineRender?.updateContent(
              psChartStore.globalTimelineContent,
              psChartStore.globalTimelineSearchHighlightsContent,
            )
          }
        },
        { name: 'reaction @ GlobalTimeline & sortedThreads -> updateContent' },
      ),
    [globalTimelineRender, psChartStore],
  )

  useEffect(
    () =>
      reaction(
        () => [psChartStore.searchState.searchResults],
        () => {
          if (psChartStore.isLoaded) {
            globalTimelineRender?.updateSearchHighlights(
              psChartStore.globalTimelineSearchHighlightsContent,
            )
          }
        },
        { name: 'reaction @ GlobalTimeline & searchResults -> updateSearchHighlights' },
      ),
    [globalTimelineRender, psChartStore],
  )

  return <div id={containerId} className="h-[80px]" ref={containerRef} />
})
