import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

import { Icon } from 'components/Icon'

type PlayVideoButtonProps = {
  isPlaying: boolean
  onPress: () => void
}

export const PlayVideoButton = memo(function ({ isPlaying, onPress }: PlayVideoButtonProps) {
  const { t } = useTranslation()

  return (
    <PlayButton
      as="button"
      onClick={onPress}
      icon={isPlaying ? 'video-pause' : 'video-play'}
      aria-label={t(`psChart.video.${isPlaying ? 'Pause' : 'Play'}`)}
    />
  )
})

const PlayButton = styled(Icon)`
  ${tw`transition`}
  position: relative;
  width: 64px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray.normal};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.dark.dark1};
  font-size: 32px;
  line-height: 1;

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      color: ${({ theme }) => theme.colors.electro};
    }
  }
`
