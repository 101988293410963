import { useEffect, useRef } from 'react'

import { CommitsRender } from './CommitsRender'
import { RAStore } from 'components/regression-analysis/RAStore'

export const CommitsCanvas = ({ raStore }: { raStore: RAStore }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current == null) {
      return
    }

    const commitsRender = new CommitsRender(canvasRef.current)
    commitsRender.init(raStore)

    return () => {
      commitsRender.destruct()
    }
  }, [canvasRef, raStore])

  return <canvas className="h-full w-full" ref={canvasRef} />
}
