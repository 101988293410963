export enum GuideStepType {
  SelectPlatform = 'select-platform',
  InstrumentAndBuild = 'instrument-and-build',
  RecordTrace = 'record-trace',
  ViewTrace = 'view-trace',
}

export type Step = {
  type: GuideStepType
  completed: boolean
  available: boolean
}

export enum PlatformType {
  iOS = 'ios',
  Android = 'android',
}

export enum OsOption {
  ANDROID = 'Android',
  IOS = 'iOS',
}

export enum FrameworkOption {
  NATIVE = 'Native',
  REACT_NATIVE = 'React Native',
  FLUTTER = 'Flutter',
  OTHERS = 'Others',
}

export enum BuildSystemOption {
  GRADLE = 'Gradle',
  BAZEL = 'Bazel',
  BUCK = 'Buck',
  OTHER = 'Other',
  NONE = 'None',
}

export enum LanguageOption {
  JAVA = 'Java',
  KOTLIN = 'Kotlin',
  TS_JS = 'TypeScript / JavaScript',
  NATIVE_JAVA_KOTLIN = 'For Native: Java / Kotlin',
  DART = 'Dart',
  SWIFT = 'Swift',
  OBJ_C = 'Objective-C',
  NATIVE_SWIFT_OBJ_C = 'For Native: Swift / Objective-C',
  OTHERS = 'Others',
}

export interface SelectPlatformFormFields {
  os: string
  devPlatform: string
  languages: string[]
  buildSystem: string
}
