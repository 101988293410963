import React, { useState } from 'react'
import { generatePath, Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'

import { Icon } from 'components/Icon'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { Userpic } from 'components/Userpic'
import { DescriptionCollapse } from './DescriptionCollapse'

import { useMutation } from 'react-query'
import { ApiError, FlowDto } from 'api/models'
import { PATH_TRACE_ADD } from 'pages/TraceAddPage'
import { getFlowName } from 'utils/getFlowName'
import { FlowFormModal } from './FlowFormModal'
import { ActionModal } from 'components/ActionModal'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { PATH_FLOW } from 'pages/FlowPage'
import { openSecondModal } from 'utils/openSecondModal'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'
import { useIsReadOnlyProject } from 'utils/feature-flags'

// import { queryKeys } from "hooks/useApiQuery"

interface FlowHeadProps extends FlowDto {
  backLink: string
  showMyTracesOnlyButton?: boolean
  canWorkWithFlow?: boolean
}

export const FlowHead = ({
  backLink,
  projectLocalId,
  name,
  description,
  appStartType,
  author,
  showMyTracesOnlyButton,
  canWorkWithFlow,
}: FlowHeadProps) => {
  const { t } = useTranslation()
  // const queryClient = useQueryClient()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [flowFormModalOpened, setFlowFormModalOpened] = useState(false)
  const handleFlowSettingsClick = () => setFlowFormModalOpened(true)
  const handleFlowFormModalClose = () => setFlowFormModalOpened(false)

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [deleteWithTraces, setDeleteWithTraces] = useState(false)

  const isReadOnlyProject = useIsReadOnlyProject()

  const handleDeleteClick = () => openSecondModal(setFlowFormModalOpened, setDeleteModalOpened)
  const handleDeleteModalClose = () => setDeleteModalOpened(false)
  const handleDeleteWithTracesCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDeleteWithTraces(event.target.checked)

  const deleteFlowMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteFlow(
        { projectUrlName, flowProjectLocalId: String(projectLocalId) },
        deleteWithTraces,
      )
    },
    {
      onSuccess: () => {
        NProgress.done()
        navigate(generatePath(PATH_FLOWS, { projectUrlName }), { replace: true })
        toast.success(
          <>
            {t('flows.deleteFlow.success')}
            <button className="ml-4" onClick={handleUndoDeleteClick}>
              {t('undo')}
            </button>
          </>,
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleDeleteModalActionClick = () => {
    setDeleteModalOpened(false)
    deleteFlowMutation.mutate()
  }

  const postFlowsRestoreMutation = useMutation(
    () => api.postFlowsRestore({ projectUrlName }, projectLocalId),
    {
      onSuccess: (data) => {
        NProgress.done()
        navigate(
          generatePath(PATH_FLOW, {
            projectUrlName,
            flowProjectLocalId: String(data[0].projectLocalId),
          }),
          {
            replace: true,
          },
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleUndoDeleteClick = () => {
    NProgress.start()
    toast.dismiss()
    postFlowsRestoreMutation.mutate()
  }

  // const postFlowSubscribeMutation = useMutation(() => api.postFlowSubscribe({ projectUrlName, flowProjectLocalId }), {
  //   onSuccess: () => {
  //     queryClient.setQueryData<FlowDto | undefined>(queryKeys.flow({ projectUrlName, flowProjectLocalId }), oldData => {
  //       if (oldData) {
  //         return { ...oldData, flowUserInfo: { ...oldData.flowUserInfo, subscribed: true } }
  //       }
  //     })
  //   },
  //   onError: (err: AxiosError) => {
  //     toast.error(err.response?.data.message ?? t("errorMessage"))
  //   }
  // })
  // const deleteFlowSubscribeMutation = useMutation(
  //   () => api.deleteFlowSubscribe({ projectUrlName, flowProjectLocalId }),
  //   {
  //     onSuccess: () => {
  //       queryClient.setQueryData<FlowDto | undefined>(
  //         queryKeys.flow({ projectUrlName, flowProjectLocalId }),
  //         oldData => {
  //           if (oldData) {
  //             return { ...oldData, flowUserInfo: { ...oldData.flowUserInfo, subscribed: false } }
  //           }
  //         }
  //       )
  //     },
  //     onError: (err: AxiosError) => {
  //       toast.error(err.response?.data.message ?? t("errorMessage"))
  //     }
  //   }
  // )

  // const handleSubscribeClick = () => {
  //   if (flowUserInfo) {
  //     if (flowUserInfo.subscribed) {
  //       deleteFlowSubscribeMutation.mutate()
  //     } else {
  //       postFlowSubscribeMutation.mutate()
  //     }
  //   }
  // }

  const myTracesOnlyActive = searchParams.get('myTracesOnly') === 'true'
  const handleMyTracesOnlyButtonClick = () => {
    if (myTracesOnlyActive) {
      searchParams.delete('myTracesOnly')
    } else {
      searchParams.set('myTracesOnly', 'true')
    }
    setSearchParams(searchParams)
  }

  return (
    <>
      <div className="flex pt-[20px] pb-[30px] px-[24px]">
        <div className="shrink-0">
          <Icon
            as={Link}
            to={backLink}
            icon="arrow-round-l"
            aria-label={t('goToBack')}
            className="block transition-colors text-icon text-gray-normal hover:text-white"
          />
        </div>
        <div className="flex flex-1 pl-[50px] items-start">
          <div className="flex-1 pr-[20px]">
            <div className="flex items-start flex-wrap">
              <h1 className="break-words text-header-small font-medium mr-[16px]">
                {getFlowName(name, projectLocalId)}
              </h1>
              <div className="flex items-center mt-[2px] shrink-0">
                <Userpic {...author} />
                <div className="text-small tracking-wide text-gray-normal">
                  {t('by')} {author.name}
                </div>
              </div>
            </div>
            <div className="relative max-w-[520px] mt-[25px]">
              <DescriptionCollapse value={description || ''} />
            </div>
          </div>
          <div className="flex items-center shrink-0">
            {showMyTracesOnlyButton && (
              <Button
                className={classNames('group', myTracesOnlyActive && '!text-white')}
                onClick={handleMyTracesOnlyButtonClick}
                variant={ButtonVariantEnum.Text}
                textColorVariant={ButtonTextColorVariantEnum.Muted}
                data-tid="my-traces-only"
                withIcon
              >
                {t('flows.flowHead.myTracesOnly')}
                <ButtonIcon
                  icon={myTracesOnlyActive ? 'user-filled' : 'user-outline'}
                  className={classNames(myTracesOnlyActive && 'text-electro group-hover:text-sky')}
                />
              </Button>
            )}
            {/* <Button
              className={classNames("group ml-[10px]", flowUserInfo?.subscribed && "!text-white")}
              variant={ButtonVariantEnum.Text}
              textColorVariant={ButtonTextColorVariantEnum.Muted}
              onClick={handleSubscribeClick}
              withIcon>
              {t("flows.common.subscribe")}
              <ButtonIcon
                icon={flowUserInfo?.subscribed ? "subscribe-on" : "subscribe"}
                className={classNames(flowUserInfo?.subscribed && "text-electro group-hover:text-sky")}
              />
            </Button> */}
            {canWorkWithFlow && isReadOnlyProject !== undefined && (
              <>
                <Button
                  onClick={handleFlowSettingsClick}
                  className="ml-[10px]"
                  variant={ButtonVariantEnum.Text}
                  textColorVariant={ButtonTextColorVariantEnum.Muted}
                  withIcon
                  disabled={!!isReadOnlyProject}
                >
                  {t('flows.flowHead.flowSettings')} <ButtonIcon icon="filters" />
                </Button>
                <Button
                  className="ml-[18px]"
                  variant={
                    isReadOnlyProject ? ButtonVariantEnum.Disabled : ButtonVariantEnum.Outlined
                  }
                  as={Link}
                  to={generatePath(PATH_TRACE_ADD, { projectUrlName, flowProjectLocalId })}
                  data-tid="add-new-trace"
                  isSmall
                >
                  {t('flows.flowHead.addTrace')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <FlowFormModal
        flowModel={{ name, description, appStartType }}
        isOpen={flowFormModalOpened}
        onClose={handleFlowFormModalClose}
        onDeleteFlowClick={handleDeleteClick}
        isEdit
      />
      <ActionModal
        isOpen={deleteModalOpened}
        title={t('flows.deleteFlow.title')}
        secondaryTitle={name as string}
        text={t('flows.deleteFlow.text')}
        checkboxProps={{
          id: 'delete-all',
          name: 'delete-all',
          checked: deleteWithTraces,
          onChange: handleDeleteWithTracesCheckboxChange,
          children: t('flows.deleteFlow.checkbox'),
        }}
        onClose={handleDeleteModalClose}
        actionButton={{
          onClick: handleDeleteModalActionClick,
          disabled: deleteFlowMutation.isLoading,
        }}
      />
    </>
  )
}
