import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Input } from 'components/Input'
import { EmailDomain } from './EmailDomain'

import { ApiError, ProjectSummaryDto, TeamDto, TeamsArray } from 'api/models'
import { PATH_TEAM } from 'pages/TeamPage'
import { queryKeys } from 'hooks/useApiQuery'
import { AxiosError } from 'axios'
import { getDomainValid, getNameValid } from 'utils/validation'
import { useApi } from 'contexts/di-context'

interface TeamFormModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  isEdit?: boolean
  onlyDomainEdit?: boolean
  onDeleteTeamClick?: () => void
  teamModel?: TeamDto
}

const NAME_VALUE_MIN_LENGTH = 0
const NAME_VALUE_MAX_LENGTH = 30

export const TeamFormModal = ({
  isOpen,
  onClose,
  isEdit,
  onlyDomainEdit,
  onDeleteTeamClick,
  teamModel,
}: TeamFormModalProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { teamUrlName } = useParams() as { teamUrlName: string }

  const getValues = useCallback(() => {
    return {
      name: teamModel?.name || '',
      domain:
        (teamModel?.domain && teamModel?.domain.charAt(0) === '@'
          ? teamModel?.domain.substring(1)
          : teamModel?.domain) || '',
      doWhitelist: teamModel ? Boolean(teamModel?.doWhitelist) : true,
    }
  }, [teamModel])

  const [nameValue, setNameValue] = useState(getValues().name)
  const [domainValue, setDomainValue] = useState(getValues().domain)
  const [doWhitelistValue, setDoWhitelistValue] = useState<boolean>(getValues().doWhitelist)
  const [showError, setShowError] = useState({ nameValue: false, domainValue: false })

  const isNameValid = useMemo(() => getNameValid(nameValue), [nameValue])
  const isDomainValid = useMemo(() => getDomainValid(domainValue), [domainValue])

  useEffect(() => {
    setNameValue(getValues().name)
    setDomainValue(getValues().domain)
    setDoWhitelistValue(getValues().doWhitelist)
  }, [getValues, isOpen])

  const handleNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(event.target.value)
    if (showError.nameValue) {
      setShowError((prev) => ({ ...prev, nameValue: !getNameValid(event.target.value) }))
    }
  }
  const handleNameValueBlur = () => setShowError((prev) => ({ ...prev, nameValue: !isNameValid }))
  const handleDomainValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomainValue(event.target.value)
    if (showError.domainValue) {
      setShowError((prev) => ({ ...prev, domainValue: !getDomainValid(event.target.value) }))
    }
  }
  const handleDomainValueBlur = () =>
    setShowError((prev) => ({ ...prev, domainValue: !isDomainValid }))
  const handleDoWhitelistValueChange = (bool: boolean) => setDoWhitelistValue(bool)

  const getDataValues = () => ({
    name: nameValue,
    domain: domainValue,
    doWhitelist: doWhitelistValue,
  })

  const postTeamMutation = useMutation(() => api.postTeam(getDataValues()), {
    onSuccess: (data) => {
      NProgress.done()
      queryClient.setQueryData<TeamsArray | undefined>(queryKeys.teams, (oldData) => {
        if (oldData) {
          return oldData.concat(data)
        }
      })
      navigate(generatePath(PATH_TEAM, { teamUrlName: data.urlName }))
      toast.success(t('teams.teamFormModal.addSuccess', { name: data.name }))
      onClose()
    },
    onError: (err: AxiosError<ApiError>) => {
      NProgress.done()
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
  })

  const putTeamMutation = useMutation(() => api.putTeam({ teamUrlName }, getDataValues()), {
    onSuccess: (data) => {
      NProgress.done()
      queryClient.setQueryData<TeamsArray | undefined>(queryKeys.teams, (oldData) => {
        if (oldData) {
          return oldData.map((item) => (item.id === data.id ? { ...item, ...data } : item))
        }
      })
      if (data.urlName === teamUrlName) {
        queryClient.setQueryData<ProjectSummaryDto | undefined>(
          queryKeys.projectsSummary({ teamUrlName }),
          (oldData) => {
            if (oldData) {
              return { ...oldData, team: data }
            }
          },
        )
      } else {
        navigate(generatePath(PATH_TEAM, { teamUrlName: data.urlName }), { replace: true })
      }
      toast.success(t('teams.teamFormModal.editSuccess'))
      onClose()
    },
    onError: (err: AxiosError<ApiError>) => {
      NProgress.done()
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
  })

  const handleActionClick = () => {
    NProgress.start()
    if (isEdit) {
      putTeamMutation.mutate()
    } else {
      postTeamMutation.mutate()
    }
  }

  const isValid = useMemo(() => {
    return (
      nameValue.length > NAME_VALUE_MIN_LENGTH &&
      nameValue.length <= NAME_VALUE_MAX_LENGTH &&
      isNameValid &&
      isDomainValid
    )
  }, [nameValue, isNameValid, isDomainValid])

  return (
    <Modal
      title={isEdit ? t('teams.teamFormModal.editTitle') : t('teams.teamFormModal.addTitle')}
      isOpen={isOpen}
      onClose={onClose}
      additionalButton={
        isEdit && !onlyDomainEdit
          ? { children: t('teams.teamFormModal.deleteButton'), onClick: onDeleteTeamClick }
          : {}
      }
      actionButton={{
        children: isEdit ? t('teams.teamFormModal.editButton') : t('teams.teamFormModal.addButton'),
        onClick: handleActionClick,
        disabled: (isEdit ? putTeamMutation.isLoading : putTeamMutation.isLoading) || !isValid,
      }}
    >
      <div className="my-[16px]">
        {!onlyDomainEdit && (
          <Input
            placeholder={t('teams.teamFormModal.namePlaceholder')}
            value={nameValue}
            onChange={handleNameValueChange}
            onBlur={handleNameValueBlur}
            maxLength={NAME_VALUE_MAX_LENGTH}
            error={showError.nameValue ? t('teams.teamFormModal.nameValid') : undefined}
            inModal
          />
        )}
        <EmailDomain
          domainValue={domainValue}
          onDomainValueChange={handleDomainValueChange}
          onDomainValueBlur={handleDomainValueBlur}
          domainValueError={
            showError.domainValue ? t('teams.teamFormModal.domainValid') : undefined
          }
          doWhitelistValue={doWhitelistValue}
          onDoWhitelistValueChange={handleDoWhitelistValueChange}
        />
      </div>
    </Modal>
  )
}
