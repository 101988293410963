import { Outlet, ScrollRestoration } from 'react-router-dom'

import { StyledToaster } from 'components/StyledToaster'
import { TooltipsPortal } from 'components/tooltip/TooltipsPortal'
import { ContentScrollRestoration } from 'components/ContentScrollRestoration'
import { DiContextProvider } from 'contexts/di-context'
import { AuthContextProvider } from 'contexts/auth-context'
import { CompositionRootImpl } from 'contexts/CompositionRoot'
import { LayoutContextProvider } from 'contexts/layout-context'
import { CustomerSupportModalContextProvider } from 'contexts/customer-support-modal-context'
import { PostHogProvider } from 'utils/posthog'
import { RootThemeProvider } from 'utils/styles'

export const Root = () => {
  return (
    <DiContextProvider compositionRoot={new CompositionRootImpl()}>
      <AuthContextProvider>
        <PostHogProvider>
          <RootThemeProvider>
            <LayoutContextProvider>
              <CustomerSupportModalContextProvider>
                <ScrollRestoration />
                <ContentScrollRestoration />
                <Outlet />
                <TooltipsPortal />
                <StyledToaster />
              </CustomerSupportModalContextProvider>
            </LayoutContextProvider>
          </RootThemeProvider>
        </PostHogProvider>
      </AuthContextProvider>
    </DiContextProvider>
  )
}
