import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Input } from 'components/Input'
import { ProjectImage } from './ProjectImage'
import { EmailDomain } from 'components/teams/EmailDomain'
import {
  ApiError,
  AuthenticatedUserDto,
  OsType,
  ProjectDto,
  ProjectDtoForm,
  ProjectSummaryDto,
  ProjectUsersResponse,
  TeamDto,
  TechType,
} from 'api/models'
import { getOsName } from 'utils/getOsName'
import { getTechName } from 'utils/getTechName'
import { PATH_PROJECT_CONTRIBUTORS } from 'pages/ProjectContributorsPage'
import { queryKeys } from 'hooks/useApiQuery'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { AxiosError } from 'axios'
import { getNameValid } from 'utils/validation'
import { Select, SelectTheme } from 'components/Select'
import { useApi } from 'contexts/di-context'

interface ProjectFormModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  projectModel?: ProjectDto
  isEdit?: boolean
  isProjectCard?: boolean
  isFlowPage?: boolean
  onDeleteProjectClick?: () => void
  teamDomain?: TeamDto['domain']
  user: AuthenticatedUserDto
}

const EDIT_AIRTABLE_CLIENT_ID = 'EDIT_AIRTABLE_CLIENT_ID'

export const ProjectFormModal = ({
  isOpen,
  onClose,
  isEdit,
  isProjectCard,
  isFlowPage,
  onDeleteProjectClick,
  projectModel,
  teamDomain,
  user,
}: ProjectFormModalProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { teamUrlName, projectUrlName } = useParams() as {
    teamUrlName: string
    projectUrlName: string
  }

  const getValues = useCallback(() => {
    return {
      imageId: (projectModel?.image && projectModel?.image.id) || null,
      name: projectModel?.name || '',
      os: projectModel?.os || OsType.ANDROID,
      tech: projectModel?.tech || null,
      appId: projectModel?.appId || '',
      airtableClientId: projectModel?.airtableClientId || '',
      doWhitelist: projectModel ? Boolean(projectModel?.doWhitelist) : false,
    }
  }, [projectModel])

  const [imageIdValue, setImageIdValue] = useState(getValues().imageId)
  const [nameValue, setNameValue] = useState(getValues().name)
  const [osValue, setOsValue] = useState<OsType | null>(getValues().os)
  const [techValue, setTechValue] = useState<TechType | null>(getValues().tech)
  const [appIdValue, setAppIdValue] = useState(getValues().appId)
  const [airtableClientIdValue, setAirtableClientIdValue] = useState(getValues().airtableClientId)
  const [doWhitelistValue, setDoWhitelistValue] = useState<boolean>(getValues().doWhitelist)
  const [showError, setShowError] = useState({ nameValue: false })

  const isNameValid = useMemo(() => getNameValid(nameValue), [nameValue])

  useEffect(() => {
    setImageIdValue(getValues().imageId)
    setNameValue(getValues().name)
    setOsValue(getValues().os)
    setTechValue(getValues().tech)
    setAppIdValue(getValues().appId)
    setDoWhitelistValue(getValues().doWhitelist)
  }, [getValues, isOpen])

  const handleImageIdChange = (newImageId: number | null) => setImageIdValue(newImageId)

  const handleNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(event.target.value)
    if (showError.nameValue) {
      setShowError((prev) => ({ ...prev, nameValue: !getNameValid(event.target.value) }))
    }
  }
  const handleNameValueBlur = () => setShowError((prev) => ({ ...prev, nameValue: !isNameValid }))
  const handleAppIdValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAppIdValue(event.target.value)
  const handleAirtableClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAirtableClientIdValue(event.target.value)
  const handleDoWhitelistValueChange = (bool: boolean) => setDoWhitelistValue(bool)

  const handleOsSelectChange = (value: string) => {
    setOsValue(value as OsType)
    setTechValue(null)
  }
  const createOsOption = useCallback((os: OsType) => ({ label: getOsName(os), value: os }), [])

  const handleTechSelectChange = (value: string) => setTechValue(value as TechType)
  const createTechOption = useCallback(
    (tech: TechType) => ({ label: getTechName(tech), value: tech }),
    [],
  )

  const getDataValues = (): ProjectDtoForm => {
    const dataValues: ProjectDtoForm = {
      airtableClientId: airtableClientIdValue,
      name: nameValue,
      os: osValue,
      tech: techValue,
      appId: appIdValue,
      doWhitelist: doWhitelistValue,
      imageId: imageIdValue,
    }
    if (!dataValues.airtableClientId) {
      delete dataValues.airtableClientId
    }
    return dataValues
  }

  const postProjectMutation = useMutation(() => api.postProject({ teamUrlName }, getDataValues()), {
    onSuccess: (data) => {
      NProgress.done()
      queryClient.invalidateQueries(queryKeys.projectsSummary({ teamUrlName }))
      navigate(generatePath(PATH_PROJECT_CONTRIBUTORS, { projectUrlName: data.urlName }))
      toast.success(t('projects.projectFormModal.addSuccess', { name: data.name }))
      onClose()
    },
    onError: (err: AxiosError<ApiError>) => {
      NProgress.done()
      console.log()
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
  })

  const putProjectMutation = useMutation(
    () => api.putProject({ projectUrlName: projectModel?.urlName }, getDataValues()),
    {
      onSuccess: (data) => {
        NProgress.done()
        if (isProjectCard || isFlowPage) {
          queryClient.setQueryData<ProjectSummaryDto | undefined>(
            queryKeys.projectsSummary({ teamUrlName: projectModel?.team.urlName }),
            (oldData) => {
              if (oldData) {
                return {
                  ...oldData,
                  projects: oldData.projects.map((item) =>
                    item.project.id === projectModel?.id ? { ...item, project: data } : item,
                  ),
                }
              }
            },
          )
        } else if (data.urlName === projectUrlName) {
          queryClient.setQueryData<ProjectUsersResponse | undefined>(
            queryKeys.projectUsers({ projectUrlName }),
            (oldData) => {
              if (oldData) {
                return { ...oldData, project: data }
              }
            },
          )
        }
        if (data.urlName !== projectUrlName && !isProjectCard) {
          if (isFlowPage) {
            navigate(generatePath(PATH_FLOWS, { projectUrlName: data.urlName }), {
              replace: true,
            })
          } else {
            navigate(generatePath(PATH_PROJECT_CONTRIBUTORS, { projectUrlName: data.urlName }), {
              replace: true,
            })
          }
        }
        toast.success(t('projects.projectFormModal.editSuccess'))
        onClose()
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleActionClick = () => {
    NProgress.start()
    if (isEdit) {
      putProjectMutation.mutate()
    } else {
      postProjectMutation.mutate()
    }
  }

  const platformOptions = useMemo(() => {
    return osValue === OsType.ANDROID
      ? [createTechOption(TechType.JVM), createTechOption(TechType.REACT_NATIVE)]
      : [createTechOption(TechType.SWIFT)]
  }, [createTechOption, osValue])

  const isValid = useMemo(() => {
    return nameValue.length > 0 && appIdValue.length > 0 && techValue !== null && isNameValid
  }, [nameValue, appIdValue, techValue, isNameValid])

  return (
    <Modal
      title={
        isEdit ? t('projects.projectFormModal.editTitle') : t('projects.projectFormModal.addTitle')
      }
      isOpen={isOpen}
      onClose={onClose}
      additionalButton={
        isEdit
          ? { children: t('projects.projectFormModal.deleteButton'), onClick: onDeleteProjectClick }
          : {}
      }
      actionButton={{
        children: isEdit
          ? t('projects.projectFormModal.editButton')
          : t('projects.projectFormModal.addButton'),
        onClick: handleActionClick,
        disabled:
          (isEdit ? putProjectMutation.isLoading : postProjectMutation.isLoading) || !isValid,
      }}
    >
      <div className="my-[16px]">
        <div className="flex items-center mb-[32px]">
          <ProjectImage
            image={projectModel?.image}
            onImageIdChange={handleImageIdChange}
            className="shrink-0"
          />
          {isEdit && (
            <div className="text-normal tracking-wide pl-[16px]">
              {getOsName(projectModel?.os)}
              <div className="text-gray-normal">{getTechName(projectModel?.tech)}</div>
            </div>
          )}
        </div>
        <Input
          placeholder={t('projects.projectFormModal.projectName')}
          value={nameValue}
          onChange={handleNameValueChange}
          onBlur={handleNameValueBlur}
          error={showError.nameValue ? t('projects.projectFormModal.projectNameValid') : undefined}
          inModal
        />
        {!isEdit && (
          <>
            <Select
              options={[createOsOption(OsType.ANDROID), createOsOption(OsType.IOS)]}
              value={osValue}
              onChange={handleOsSelectChange}
              className="w-[320px] mb-[16px]"
              placeholder={t('projects.projectFormModal.os')}
              theme={SelectTheme.OVERLAY}
            />
            <Select
              options={platformOptions}
              value={techValue}
              onChange={handleTechSelectChange}
              className="w-[320px] mb-[16px]"
              placeholder={t('projects.projectFormModal.platform')}
              theme={SelectTheme.OVERLAY}
            />
          </>
        )}
        <Input
          placeholder={t('projects.projectFormModal.appBundleId')}
          value={appIdValue}
          onChange={handleAppIdValueChange}
          inModal
        />
        {user.features.includes(EDIT_AIRTABLE_CLIENT_ID) && (
          <Input
            placeholder={t('projects.projectFormModal.airtableClientId')}
            value={airtableClientIdValue}
            onChange={handleAirtableClientIdChange}
            inModal
          />
        )}
        {teamDomain && (
          <EmailDomain
            domainValue={teamDomain}
            doWhitelistValue={doWhitelistValue}
            onDoWhitelistValueChange={handleDoWhitelistValueChange}
            readonly
          />
        )}
      </div>
    </Modal>
  )
}
