import { useQuery } from 'react-query'

import {
  ComputationStatus,
  FlowComputationReq,
  FlowReq,
  ProjectReq,
  SliceSuggestionsReq,
  TeamReq,
  TraceProcessingStateEnum,
  TraceReq,
} from 'api/models'
import { useApi } from 'contexts/di-context'
import { ONE_DAY } from 'utils/oneDay'

export const queryKeys = {
  user: ['user'] as const,
  teams: ['teams'] as const,
  projectsSummary: ({ teamUrlName }: TeamReq) => ['projects-summary', teamUrlName] as const,
  teamUsers: ({ teamUrlName }: TeamReq) => ['team-users', teamUrlName] as const,
  projectUsers: ({ projectUrlName }: ProjectReq) => ['project-users', projectUrlName] as const,
  project: ({ projectUrlName }: ProjectReq) => ['project', projectUrlName] as const,
  flows: ({ projectUrlName }: ProjectReq) => ['flows', projectUrlName] as const,
  unassignedTraces: ({ projectUrlName }: ProjectReq) =>
    ['unassigned-traces', projectUrlName] as const,
  flow: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow', projectUrlName, flowProjectLocalId] as const,
  flowTraces: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-traces', projectUrlName, flowProjectLocalId] as const,
  trace: ({ projectUrlName, traceProjectLocalId }: TraceReq) =>
    ['trace', projectUrlName, traceProjectLocalId] as const,
  flowAnnotations: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-annotations', projectUrlName, flowProjectLocalId] as const,
  flowVideo: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-video', projectUrlName, flowProjectLocalId] as const,
  sliceSuggestions: ({
    projectUrlName,
    flowProjectLocalId,
    traceProjectLocalId,
  }: SliceSuggestionsReq) =>
    ['slice-suggestions', projectUrlName, flowProjectLocalId, traceProjectLocalId] as const,
  flowComputation: ({ projectUrlName, flowProjectLocalId }: FlowComputationReq) =>
    ['flow-computation', projectUrlName, flowProjectLocalId] as const,
}

export const useUserQuery = () => {
  const api = useApi()
  return useQuery(queryKeys.user, () => api.getUser(), {
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useTeamsQuery = (enabled?: boolean, useErrorBoundary = true) => {
  const api = useApi()
  return useQuery(queryKeys.teams, () => api.getTeams(), {
    enabled,
    useErrorBoundary,
    retry: false,
    refetchOnMount: false,
  })
}

export const useProjectsSummaryQuery = ({ teamUrlName }: TeamReq, enabled?: boolean) => {
  const api = useApi()
  return useQuery(
    queryKeys.projectsSummary({ teamUrlName }),
    () => api.getProjectsSummary({ teamUrlName }),
    {
      enabled,
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}

export const useTeamUsersQuery = ({ teamUrlName }: TeamReq) => {
  const api = useApi()
  return useQuery(queryKeys.teamUsers({ teamUrlName }), () => api.getTeamUsers({ teamUrlName }), {
    useErrorBoundary: true,
    retry: false,
  })
}

export const useProjectUsersQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.projectUsers({ projectUrlName }),
    () => api.getProjectUsers({ projectUrlName }),
    {
      useErrorBoundary: true,
      retry: false,
    },
  )
}

export const useProjectQuery = (
  { projectUrlName }: ProjectReq,
  enabled?: boolean,
  useErrorBoundary = true,
) => {
  const api = useApi()
  return useQuery(queryKeys.project({ projectUrlName }), () => api.getProject({ projectUrlName }), {
    enabled,
    useErrorBoundary,
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useFlowsQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(queryKeys.flows({ projectUrlName }), () => api.getFlows({ projectUrlName }), {
    useErrorBoundary: true,
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useUnassignedTracesQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.unassignedTraces({ projectUrlName }),
    () => api.getUnassignedTraces({ projectUrlName }),
    {
      useErrorBoundary: true,
      retry: false,
    },
  )
}

export const useFlowQuery = ({ projectUrlName, flowProjectLocalId }: FlowReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.flow({ projectUrlName, flowProjectLocalId }),
    () => api.getFlow({ projectUrlName, flowProjectLocalId }),
    {
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}

export const useFlowTracesQuery = ({ projectUrlName, flowProjectLocalId }: FlowReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
    () => api.getFlowTraces({ projectUrlName, flowProjectLocalId }),
    {
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      refetchInterval: (data) =>
        data?.find((item) => item.processingState === TraceProcessingStateEnum.IN_PROGRESS)
          ? 5000
          : false,
    },
  )
}

export const useTraceQuery = ({ projectUrlName, traceProjectLocalId }: TraceReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.trace({ projectUrlName, traceProjectLocalId }),
    () =>
      api.getSingleTrace({
        projectUrlName: projectUrlName!,
        traceProjectLocalId: traceProjectLocalId!,
      }),
    {
      useErrorBoundary: true,
      retry: false,
    },
  )
}

export const useSliceSuggestions = (request: SliceSuggestionsReq) => {
  const api = useApi()
  return useQuery(queryKeys.sliceSuggestions(request), () => api.getSliceSuggestions(request), {
    useErrorBoundary: true,
    retry: false,
    refetchInterval: 30000,
  })
}

export const useFlowComputation = (request: FlowComputationReq) => {
  const api = useApi()
  return useQuery(queryKeys.flowComputation(request), () => api.getFlowComputation(request), {
    useErrorBoundary: true,
    retry: false,
    refetchInterval: (data) =>
      data && data.computationStatus === ComputationStatus.IN_PROGRESS ? 5000 : 0,
  })
}
