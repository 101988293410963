import React, { useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'

// import { Count } from "components/Count"
import { Icon } from 'components/Icon'
import { ActionModal } from 'components/ActionModal'
import { Userpic } from 'components/Userpic'

import { useMutation, useQueryClient } from 'react-query'
import { ApiError, FlowDto, Flows } from 'api/models'
import { PATH_FLOW } from 'pages/FlowPage'
import { queryKeys } from 'hooks/useApiQuery'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'
import { Menu } from 'components/dropdowns/Menu'

export const FlowCard = ({
  projectLocalId,
  progress,
  name,
  author,
  flowUserInfo,
  index,
}: FlowDto & { index: number }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { projectUrlName } = useParams() as { projectUrlName: string }
  const api = useApi()

  // const isSubscribed = flowUserInfo && flowUserInfo.subscribed
  const isUserFavorite = flowUserInfo && flowUserInfo.favorite
  const [isFavorite, setFavorite] = useState(isUserFavorite)

  // const postFlowSubscribeMutation = useMutation(
  //   () => api.postFlowSubscribe({ projectUrlName, flowProjectLocalId: String(projectLocalId) }),
  //   {
  //     onSuccess: () => {
  //       queryClient.setQueryData<Flows | undefined>(queryKeys.flows({ projectUrlName }), oldData => {
  //         if (oldData) {
  //           return oldData.map(item =>
  //             item.projectLocalId === projectLocalId
  //               ? { ...item, flowUserInfo: Object.assign({}, item.flowUserInfo, { subscribed: true }) }
  //               : item
  //           )
  //         }
  //       })
  //     },
  //     onError: (err: AxiosError) => {
  //       toast.error(err.response?.data.message ?? t("errorMessage"))
  //     }
  //   }
  // )
  // const deleteFlowSubscribeMutation = useMutation(
  //   () => api.deleteFlowSubscribe({ projectUrlName, flowProjectLocalId: String(projectLocalId) }),
  //   {
  //     onSuccess: () => {
  //       queryClient.setQueryData<Flows | undefined>(queryKeys.flows({ projectUrlName }), oldData => {
  //         if (oldData) {
  //           return oldData.map(item =>
  //             item.projectLocalId === projectLocalId
  //               ? { ...item, flowUserInfo: Object.assign({}, item.flowUserInfo, { subscribed: false }) }
  //               : item
  //           )
  //         }
  //       })
  //     },
  //     onError: (err: AxiosError) => {
  //       toast.error(err.response?.data.message ?? t("errorMessage"))
  //     }
  //   }
  // )

  const postFlowFavoriteMutation = useMutation(
    () => api.postFlowFavorite({ projectUrlName, flowProjectLocalId: String(projectLocalId) }),
    {
      onSuccess: () => {
        queryClient.setQueryData<Flows | undefined>(
          queryKeys.flows({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData.map((item) =>
                item.projectLocalId === projectLocalId
                  ? {
                      ...item,
                      flowUserInfo: Object.assign({}, item.flowUserInfo, { favorite: true }),
                    }
                  : item,
              )
            }
          },
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )
  const deleteFlowFavoriteMutation = useMutation(
    () => api.deleteFlowFavorite({ projectUrlName, flowProjectLocalId: String(projectLocalId) }),
    {
      onSuccess: () => {
        queryClient.setQueryData<Flows | undefined>(
          queryKeys.flows({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData.map((item) =>
                item.projectLocalId === projectLocalId
                  ? {
                      ...item,
                      flowUserInfo: Object.assign({}, item.flowUserInfo, { favorite: false }),
                    }
                  : item,
              )
            }
          },
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  // const handleSubscribeClick = () => {
  //   if (flowUserInfo) {
  //     if (isSubscribed) {
  //       deleteFlowSubscribeMutation.mutate()
  //     } else {
  //       postFlowSubscribeMutation.mutate()
  //     }
  //   }
  // }

  const handleFavoriteButtonClick = () => {
    if (flowUserInfo) {
      setFavorite(!isFavorite)
      if (isUserFavorite) {
        deleteFlowFavoriteMutation.mutate()
      } else {
        postFlowFavoriteMutation.mutate()
      }
    }
  }

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [deleteWithTraces, setDeleteWithTraces] = useState(false)

  const handleDeleteClick = () => setDeleteModalOpened(true)
  const handleDeleteModalClose = () => setDeleteModalOpened(false)
  const handleDeleteWithTracesCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDeleteWithTraces(event.target.checked)

  const deleteFlowMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteFlow(
        { projectUrlName, flowProjectLocalId: String(projectLocalId) },
        deleteWithTraces,
      )
    },
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<Flows | undefined>(
          queryKeys.flows({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData?.filter((e) => e?.projectLocalId !== projectLocalId)
            }
          },
        )
        toast.success(
          <>
            {t('flows.deleteFlow.success')}
            <button className="ml-4" onClick={handleUndoDeleteClick}>
              {t('undo')}
            </button>
          </>,
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleDeleteModalActionClick = () => {
    setDeleteModalOpened(false)
    deleteFlowMutation.mutate()
  }

  const postFlowsRestoreMutation = useMutation(
    () => api.postFlowsRestore({ projectUrlName }, projectLocalId),
    {
      onSuccess: (data) => {
        NProgress.done()
        queryClient.setQueryData<Flows | undefined>(
          queryKeys.flows({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              const newData = [...oldData]
              newData.splice(index, 0, data[0])
              return newData
            }
          },
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleUndoDeleteClick = () => {
    NProgress.start()
    toast.dismiss()
    postFlowsRestoreMutation.mutate()
  }

  const dropdownOptions = [{ name: t('delete'), onClick: handleDeleteClick }]
  const dropdownSections = [{ name: t('flows.flowCard.flow'), options: dropdownOptions }]

  return (
    <div className="group h-full bg-dark-dark2 min-h-[234px] rounded-[8px] 1280:min-h-[284px] 1440:min-h-[334px] 1680:min-h-[364px] 1920:min-h-[275px] ">
      <div className="flex flex-1 flex-col h-full pt-[10px] px-[16px] pb-[16px] transition-colors group-hover:bg-rollover-dark">
        <Link
          to={generatePath(PATH_FLOW, {
            projectUrlName,
            flowProjectLocalId: String(projectLocalId),
          })}
          className="flex flex-1 flex-col justify-between"
          data-tid="flow-card"
        >
          <div className="-mt-[9px]">
            {['nav-annotation', 'nav-processes', 'nav-connections', 'nav-delays', 'nav-report']
              .slice(0, progress)
              .map((progressIcon, progressIndex) => (
                <Icon
                  icon={progressIcon}
                  className="text-icon text-gray-iconStatus"
                  key={String(progressIndex)}
                />
              ))}
          </div>
          <div>
            {/* <Count>99</Count> */}
            <div className="mt-[10px] text-big font-medium tracking-wide text-white/90">{name}</div>
          </div>
        </Link>
        <div className="flex mt-[15px] pt-[15px] border-t border-t-dark-dark4">
          <div className="flex items-center flex-1 text-small tracking-wide text-gray-normal">
            <Userpic {...author} />
            {t('by')} {author.name}
          </div>
          <div className="relative">
            <Menu
              onSelect={handleDeleteClick}
              menuClass="right-0 bottom-[34px]"
              menuSections={dropdownSections}
              buttonChildren={() => <Icon icon="more" />}
              buttonLabel={(open) =>
                t(`flows.flowCard.${open ? 'closeFlowMenuAriaLabel' : 'openFlowMenuAriaLabel'}`)
              }
              buttonClass={() =>
                'text-icon opacity-0 focus-visible:opacity-100 group-hover:opacity-100 transition text-gray-normal hover:text-white'
              }
            />
          </div>
          <ActionModal
            isOpen={deleteModalOpened}
            title={t('flows.deleteFlow.title')}
            secondaryTitle={name as string}
            text={t('flows.deleteFlow.text')}
            checkboxProps={{
              id: 'delete-all',
              name: 'delete-all',
              checked: deleteWithTraces,
              onChange: handleDeleteWithTracesCheckboxChange,
              children: t('flows.deleteFlow.checkbox'),
            }}
            onClose={handleDeleteModalClose}
            actionButton={{
              onClick: handleDeleteModalActionClick,
              disabled: deleteFlowMutation.isLoading,
            }}
          />
          <Icon
            as="button"
            icon={isFavorite ? 'favorite' : 'favorite-outline'}
            className={classNames(
              'text-icon opacity-0 focus-visible:opacity-100 group-hover:opacity-100 transition text-gray-normal hover:text-white',
              isFavorite && 'opacity-100 text-electro hover:text-sky',
            )}
            onClick={handleFavoriteButtonClick}
            aria-label={
              isFavorite
                ? t('flows.flowCard.deleteFromFavoritesAriaLabel')
                : t('flows.flowCard.addToFavoritesAriaLabel')
            }
          />
        </div>
      </div>
    </div>
  )
}
