import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { FilterTag } from './FilterTag'
import {
  AssignTraceModalContext,
  AssignTraceModalStateContext,
} from 'components/traces/AssignTraceModal'
import { Traces } from 'api/models'

interface AppliedFiltersProps {
  traces?: Traces
  selectedTraces: number[]
  withAssign?: boolean
  hasFilters: boolean
  activeFilters: Pick<{ [k: string]: string }, string>
}

export const AppliedFilters = ({
  traces,
  selectedTraces,
  withAssign,
  hasFilters,
  activeFilters,
}: AppliedFiltersProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const assignTraceModal = useContext<AssignTraceModalStateContext | null>(AssignTraceModalContext)

  const handleAssignButtonClick = () => {
    const findFirstTrace = traces?.find((trace) => trace?.projectLocalId === selectedTraces[0])
    const name = t('traces.filters.name_interval', {
      postProcess: 'interval',
      count: selectedTraces.length,
      name: findFirstTrace?.name,
    })
    const activeFlowsIds =
      selectedTraces.length === 1
        ? findFirstTrace?.flows.map((flow) => flow.flowProjectLocalId)
        : []
    const getPrevFlowsIds =
      traces?.map((trace) => trace?.flows.map((flow) => flow.flowProjectLocalId)).flat() || []
    const prevFlowsIds = getPrevFlowsIds.filter(
      (item, pos) => getPrevFlowsIds.indexOf(item) === pos,
    )
    assignTraceModal?.setState({
      isOpen: true,
      name,
      tracesIds: selectedTraces,
      activeFlowsIds,
      prevFlowsIds,
    })
  }

  const handleResetClick = () =>
    navigate(
      searchParams.get('myTracesOnly') === 'true'
        ? { pathname, search: 'myTracesOnly=true' }
        : pathname,
      {
        replace: true,
      },
    )

  const handleFilterTagClick = (param: string) => () => {
    searchParams.delete(param)
    if (param === 'dateCreated' || param === 'dateUpdated') {
      searchParams.delete(`${param}To`)
    }
    setSearchParams(searchParams)
  }

  return (
    <View>
      {withAssign && selectedTraces.length > 0 && (
        <AssignButton
          variant={ButtonVariantEnum.Text}
          textColorVariant={ButtonTextColorVariantEnum.Look}
          onClick={handleAssignButtonClick}
        >
          {t('traces.assign')}
          <AssignButtonIcon icon="arrow-small-r" />
        </AssignButton>
      )}
      {hasFilters && (
        <>
          <ResetButton variant={ButtonVariantEnum.Text} onClick={handleResetClick}>
            {t('traces.filters.reset')}
          </ResetButton>
          {Object.keys(activeFilters).map((item, index) => (
            <FilterTag
              name={t(`traces.filters.columnsBy.${item}`)}
              onClick={handleFilterTagClick(item)}
              key={String(index)}
            />
          ))}
        </>
      )}
    </View>
  )
}

const View = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
  padding: 17px 16px 18px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.dark.dark3};
`

const AssignButton = styled(Button)`
  height: 24px;
  margin-right: 48px;
  line-height: 24px;
`

const AssignButtonIcon = styled(ButtonIcon)`
  line-height: 24px;
`

const ResetButton = styled(Button)`
  height: 24px;
  margin-right: 4px;
  font-size: 14px;
  line-height: 1;
`
