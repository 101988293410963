import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { WheelBlocker } from 'utils/WheelBlocker'
import { Pointer } from 'components/ps-chart/flame-chart/Pointer'

export class PsChartEventsHandler {
  private pressedKey: null | string = null
  private readonly psChartStore: PsChartStore
  private readonly element: HTMLElement
  private readonly pointer: Pointer

  constructor(psChartStore: PsChartStore, element: HTMLElement) {
    this.psChartStore = psChartStore
    this.element = element
    this.pointer = new Pointer(this.element)
  }

  addEventListeners(element: HTMLElement) {
    element.addEventListener('wheel', this.onWheel)
    element.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  removeEventListeners(element: HTMLElement) {
    element.removeEventListener('wheel', this.onWheel)
    element.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  }

  private wheelBlockerY = new WheelBlocker('y')

  private readonly onWheel = (event: WheelEvent) => {
    event.preventDefault()
    if (event.deltaY !== 0 && event.ctrlKey) {
      if (event.deltaY < 0) {
        this.psChartStore.hState.increaseZoom(this.pointer.getRelativeX(), event.deltaY)
      } else {
        this.psChartStore.hState.decreaseZoom(this.pointer.getRelativeX(), event.deltaY)
      }
    }
    if (!event.ctrlKey) {
      this.wheelBlockerY.handleEvent(event, () => {
        const deltaX = this.calcChangeXStartDelta(
          (event.deltaX / 2) * this.psChartStore.hState.timePerPx,
        )
        this.psChartStore.hState.changeXStart(deltaX)
      })
    }
    return false
  }

  private readonly onMouseMove = (event: MouseEvent) => {
    this.pointer.onMouseMove(event)
  }

  private readonly onKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'KeyD' && this.pressedKey !== 'KeyD') {
      this.startXAnimation()
    }

    if (event.code === 'KeyA' && this.pressedKey !== 'KeyA') {
      this.startXAnimation(true)
    }

    if (event.code === 'KeyW' && this.pressedKey !== 'KeyW') {
      this.startZoomAnimation()
    }

    if (event.code === 'KeyS' && this.pressedKey !== 'KeyS') {
      this.startZoomAnimation(true)
    }

    this.pressedKey = event.code
  }

  private readonly onKeyUp = () => {
    this.pressedKey = null
  }

  private startXAnimation = (negative = false) => {
    const delta = this.calcChangeXStartDelta(
      (this.psChartStore.hState.xWidth * (negative ? -1 : 1)) /
        this.psChartStore.chartSettings.stepAnimationTime,
    )
    this.startAnimation((changeNum) => {
      this.psChartStore.hState.changeXStart(changeNum * delta)
    })
  }

  private startZoomAnimation = (negative = false) => {
    this.startAnimation((changeNum) => {
      const zoomFactor =
        1 +
        (negative ? -1 : 1) * (this.psChartStore.hState.chartSettings.zoomChangeFactor * changeNum)
      this.psChartStore.hState.changeZoom(zoomFactor, this.pointer.getRelativeX())
    })
  }

  private startAnimation = (draw: (change: number) => void) => {
    const start = Date.now()
    const animate = () => {
      const interval = Date.now() - start

      draw(interval / this.psChartStore.chartSettings.stepAnimationTime)

      if (this.pressedKey) {
        requestAnimationFrame(animate)
      }
    }
    requestAnimationFrame(animate)
  }

  private calcChangeXStartDelta(delta: number): number {
    return (
      Math.sign(delta) *
      Math.max(
        1,
        Math.abs(Math.round(delta * this.psChartStore.chartSettings.changePosCoefficient)),
      )
    )
  }
}
