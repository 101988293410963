import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BottomControls } from 'components/quickstart/modal/BottomControls'
import { Content } from 'components/quickstart/modal/Content'
import { Header } from 'components/quickstart/modal/Header'
import { QuickstartContent } from 'components/quickstart/models'
import { QUICKSTART } from 'components/quickstart/constants'
import { useResetScrollPosition } from 'components/quickstart/hooks/useResetScrollPosition'
import { useModal } from 'hooks/useModal'

export const QuickstartModal = ({
  pageToContentMap,
  linksToPages,
  video,
  videoThumbnail,
}: QuickstartContent) => {
  const { closeModal, isModalOpen } = useModal(QUICKSTART)

  const scrollContainerRef = useResetScrollPosition()
  const initialFocusRef = useRef<HTMLVideoElement>(null)

  const videoFileUrl = video?.data?.fields?.file?.url || ''
  const videoThumbnailFileUrl = videoThumbnail?.data?.fields?.file?.url || ''

  return (
    <Transition
      show={isModalOpen}
      as={Fragment}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog onClose={closeModal} initialFocus={initialFocusRef}>
        <div className="fixed inset-0 h-[100vh] bg-dark-dark4 opacity-70" />
        <div className="fixed z-[999] inset-0">
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Panel
              ref={scrollContainerRef}
              className="max-h-[88vh] w-[692px] rounded-md flex flex-col justify-between bg-dark-dark5 overflow-x-hidden"
            >
              <video
                ref={initialFocusRef}
                poster={videoThumbnailFileUrl}
                src={videoFileUrl}
                className="w-full h-fit m-0 p-0 rounded-t-md min-h-[390px]"
                controls
              />
              <div className="flex flex-col px-[32px]">
                <Header />
                <Content pageToContentMap={pageToContentMap} linksToPages={linksToPages} />
                <BottomControls />
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
