import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'

import { Logo } from 'components/Logo'
import { PATH_ROOT } from 'pages/MainPage'
import { LayoutContext } from 'contexts/layout-context'

interface AuthLayoutProps {
  children: React.ReactNode
  className?: string
  bottomInfo?: React.ReactNode[]
}

export function AuthLayout({ children, className, bottomInfo }: AuthLayoutProps): JSX.Element {
  const { t } = useTranslation()

  useEffect(() => {
    document.body.classList.add('bg-dark-dark5')
    return () => {
      document.body.classList.remove('bg-dark-dark5')
    }
  })

  const { setWithoutNavbar } = useContext(LayoutContext)

  useEffect(() => {
    setWithoutNavbar(true)
  }, [setWithoutNavbar])

  return (
    <div className="flex flex-col min-h-full px-[24px] py-[22px] bg-dark-dark5">
      <Link to={PATH_ROOT} className="self-center pt-[26px]">
        <Logo />
      </Link>

      <div className="flex flex-1 items-center justify-center py-10">
        <div className={classNames('w-[320px] self-center', className)}>{children}</div>
      </div>
      <Bottom className="grid grid-cols-3 items-end text-small tracking-wide">
        <div className="opacity-30">
          {t('copyright')} {new Date().getFullYear()}
        </div>
        {bottomInfo && bottomInfo?.length > 0 && (
          <div className="text-center">
            {bottomInfo?.map((info, index) => (
              <div className="mt-[20px] first:mt-0" key={String(index)}>
                {info}
              </div>
            ))}
          </div>
        )}
        <div className="text-right col-start-3">
          <a href="https://www.productscience.ai/privacy" target="_blank" rel="noopener">
            {t('privacyPolicy')}
          </a>
        </div>
      </Bottom>
    </div>
  )
}

const Bottom = styled.div`
  ${({ theme }) => theme.notTouchScreen} {
    a {
      ${tw`transition-colors`}
      outline-offset: 3px;

      &:hover {
        ${tw`text-gray-normal`}
      }
    }
  }
`
