import { useMemo, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from 'react-query'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { Userpic } from 'components/Userpic'
import { ButtonIcon, ButtonTextColorVariantEnum } from 'components/Button'
import { ActionModal } from 'components/ActionModal'
import {
  ApiError,
  AuthenticatedUserDto,
  ProjectDescriptorDto,
  TeamDto,
  TeamUserDto,
} from 'api/models'
import { queryKeys } from 'hooks/useApiQuery'

import { TeamUserRole } from './TeamUserRole'
import { TeamUserProjectRole } from './TeamUserProjectRole'
import { PATH_TEAM } from 'pages/TeamPage'
import { useApi } from 'contexts/di-context'
import { Menu } from 'components/dropdowns/Menu'

interface TeamUserProps extends TeamUserDto {
  projects: Array<ProjectDescriptorDto>
  authUserId?: AuthenticatedUserDto['id']
  teamId?: TeamDto['id']
  isSuperAdmin?: boolean
  getActiveOrder: string | null
  isLastTeamAdmin: boolean
}

export const TeamUser = ({
  id,
  email,
  lastActive,
  user,
  projects,
  teamRole,
  projectRoles,
  authUserId,
  teamId,
  isSuperAdmin,
  getActiveOrder,
  isLastTeamAdmin,
}: TeamUserProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { teamUrlName } = useParams() as { teamUrlName: string }
  const navigate = useNavigate()

  const [resendModalOpened, setResendModalOpened] = useState(false)
  const handleResendLink = () => setResendModalOpened(true)
  const handleResendModalClose = () => setResendModalOpened(false)

  const postTeamUserResendMutation = useMutation(
    () => {
      NProgress.start()
      return api.postTeamUserResend({ teamUrlName, userId: id })
    },
    {
      onSuccess: () => {
        NProgress.done()
        setResendModalOpened(false)
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const handleDeleteClick = () => setDeleteModalOpened(true)
  const handleDeleteModalClose = () => setDeleteModalOpened(false)

  const deleteTeamUserMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteTeamUser({ teamUrlName, userId: id })
    },
    {
      onSuccess: async () => {
        setDeleteModalOpened(false)
        await queryClient.refetchQueries(queryKeys.teamUsers({ teamUrlName }), { exact: true })
        NProgress.done()
        if (id === authUserId && !isSuperAdmin) {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(
          t('teams.teamUser.deleteModal.success', {
            user: user ? `${user.name} ${user.lastName}` : email,
          }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const getUserMenuOptions = useMemo(() => {
    const options = [{ name: t('teams.teamUser.deleteOption'), onClick: handleDeleteClick }]
    if (!user) {
      options.unshift({ name: t('teams.teamUser.resendOption'), onClick: handleResendLink })
    }
    return options
  }, [user, t])

  const deleteModalProps = useMemo(() => {
    if (id === authUserId) {
      if (isLastTeamAdmin) {
        return {
          title: t('teams.teamUser.deleteModal.himself_last.title'),
          text: t('teams.teamUser.deleteModal.himself_last.text'),
          hiddenCloseButton: true,
          actionButton: {
            children: t('teams.teamUser.deleteModal.himself_last.button'),
            textColorVariant: ButtonTextColorVariantEnum.Primary,
            onClick: handleDeleteModalClose,
          },
        }
      }
      return {
        title: t('teams.teamUser.deleteModal.himself.title'),
        text: t('teams.teamUser.deleteModal.himself.text'),
      }
    }
    return {
      title: t('teams.teamUser.deleteModal.general.title'),
      secondaryTitle: user ? `${user.name} ${user.lastName}` : email,
      text: t('teams.teamUser.deleteModal.general.text'),
    }
  }, [t, user, email, id, authUserId, isLastTeamAdmin])

  return (
    <View className="group w-fit min-w-full" projectsLength={projects.length}>
      <Column className="flex items-center">
        <div className="mr-[16px]">
          {user ? (
            <Userpic {...user} size={32} disabledMarginRight />
          ) : (
            <div className="w-[32px] h-[32px] rounded-2xl bg-white bg-opacity-[0.06]" />
          )}
        </div>
        <div>
          {user ? (
            <div className="text-white">
              {user.name} {user.lastName}
            </div>
          ) : (
            <div className="text-state-attention">{t('user.pending')}</div>
          )}
          <div>{email}</div>
        </div>
      </Column>
      <Column>
        <TeamUserRole
          id={id}
          email={email}
          user={user}
          teamRole={teamRole}
          teamId={teamId}
          authUserId={authUserId}
          isSuperAdmin={isSuperAdmin}
          getActiveOrder={getActiveOrder}
          isLastTeamAdmin={isLastTeamAdmin}
        />
      </Column>
      {projects.map((project, index) => (
        <Column key={String(index)}>
          <TeamUserProjectRole
            id={id}
            email={email}
            user={user}
            teamRole={teamRole}
            projectRole={projectRoles[project.id]}
            projectUrlName={project.urlName}
            projectId={project.id}
          />
        </Column>
      ))}
      <Column className={classNames(getActiveOrder === 'lastActive' && '!text-white')}>
        {lastActive && dayjs(lastActive).format('MMMM DD HH:mm:ss')}
      </Column>
      <div className="absolute z-10 top-1/2 right-[8px] -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100">
        <Menu
          onSelect={(index: number) => getUserMenuOptions[index].onClick()}
          menuSections={[{ options: getUserMenuOptions }]}
          menuClass="left-[-170px]"
          buttonLabel={(open) =>
            t(`teams.teamUser.${open ? 'closeUserMenuAriaLabel' : 'openUserMenuAriaLabel'}`)
          }
          buttonChildren={() => <ButtonIcon icon="more" />}
        />
      </div>
      <ActionModal
        isOpen={resendModalOpened}
        title={t('teams.teamUser.resendModal.title')}
        text={t('teams.teamUser.resendModal.text', { email })}
        onClose={handleResendModalClose}
        actionButton={{
          onClick: postTeamUserResendMutation.mutate,
          children: t('teams.teamUser.resendModal.button'),
          textColorVariant: ButtonTextColorVariantEnum.Primary,
          disabled: postTeamUserResendMutation.isLoading,
        }}
      />
      <ActionModal
        isOpen={deleteModalOpened}
        onClose={handleDeleteModalClose}
        actionButton={{
          onClick: deleteTeamUserMutation.mutate,
          disabled: deleteTeamUserMutation.isLoading,
        }}
        {...deleteModalProps}
      />
    </View>
  )
}

const View = styled.div<{ projectsLength?: number }>`
  ${tw`
    relative grid items-center
    py-[11px] rounded-sm
    transition-colors hover:bg-dark-dark6
    after:(absolute bottom-0 inset-x-[16px] h-px bg-black/30)
  `}

  grid-template-columns: 320px minmax(168px, 1fr) ${({ projectsLength }) =>
    'minmax(168px, 1fr) '.repeat(projectsLength || 0)} minmax(168px, 1fr)  50px;
`

const Column = tw.div`
  px-[16px] text-gray-normal text-small tracking-wide
`
