import { useTranslation } from 'react-i18next'
import { useController } from 'react-hook-form'
import * as yup from 'yup'

import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { Button } from 'components/Button'
import { Select } from 'components/Select'
import { queryKeys } from 'hooks/useApiQuery'
import { useToaster } from 'hooks/useToaster'
import { useApi } from 'contexts/di-context'
import { useCallback } from 'react'
import NProgress from 'nprogress'
import { PATH_ROOT } from 'pages/MainPage'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { AuthenticatedUserDto } from 'api/models'

export const FreeTrialQuestionnaire = () => {
  const { t } = useTranslation()
  const toaster = useToaster()
  const api = useApi()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const formContext = useForm<CompleteProfileFormFields>({
    validationSchema: tellUsMoreFormSchema,
  })

  const handleSubmit = useCallback(
    (values: CompleteProfileFormFields) => {
      NProgress.start()
      api
        .putUserQuestionnaire({
          userRole: t(values.role),
          customerDoings: t(values.customers),
          userTargetAchievement: t(values.achievement),
          howYouLearnedAboutPs: t(values.howLearned),
        })
        .then(() => {
          const oldUserData = queryClient.getQueryData(queryKeys.user) as AuthenticatedUserDto
          queryClient.setQueryData(queryKeys.user, {
            ...oldUserData,
            askQuestionnaire: false,
          })
          navigate(PATH_ROOT)
        })
        .catch((error) => toaster.error(error))
        .finally(() => NProgress.done())
    },
    [t, toaster, navigate, api, queryClient],
  )

  const handleSubmitInvalid = useCallback(() => {
    toaster.error('auth.freeTrial.signUp.errors.requiredFields')
  }, [toaster])

  return (
    <div className="flex min-h-full items-center justify-center bg-dark-dark3">
      <div className="w-full">
        <div className="text-center mb-[48px]">
          <h2 className="text-header-small text-center">
            {t('auth.freeTrial.tellUsMoreForm.title')}
          </h2>
        </div>
        <div className="max-w-[320px] mx-auto">
          <Form<CompleteProfileFormFields>
            formContext={formContext}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
          >
            <Questions />
            <Button className="w-full" isSmall type="submit">
              {t('auth.freeTrial.signUp.button')}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

const Questions = () => {
  const rolesController = useController<CompleteProfileFormFields>({ name: 'role' })
  const customersController = useController<CompleteProfileFormFields>({
    name: 'customers',
  })
  const achievementController = useController<CompleteProfileFormFields>({
    name: 'achievement',
  })
  const howLearnedController = useController<CompleteProfileFormFields>({
    name: 'howLearned',
  })
  const { t } = useTranslation()

  return (
    <>
      <div className="text-small text-gray-normal mb-[4px]">
        {t('auth.freeTrial.tellUsMoreForm.roles')}
      </div>
      <Select
        buttonClassName="bg-gray-inputBG"
        className="mb-[24px]"
        options={rolesOptionsKeys.map((key) => ({ label: t(key), value: key }))}
        value={rolesController.field.value}
        onChange={rolesController.field.onChange}
        placeholder={t('auth.freeTrial.tellUsMoreForm.selectPlaceholder')}
      />

      <div className="text-small text-gray-normal mb-[4px]">
        {t('auth.freeTrial.tellUsMoreForm.customers')}
      </div>
      <Select
        buttonClassName="bg-gray-inputBG"
        className="mb-[24px]"
        options={customersOptionsKeys.map((key) => ({ label: t(key), value: key }))}
        value={customersController.field.value}
        onChange={customersController.field.onChange}
        placeholder={t('auth.freeTrial.tellUsMoreForm.selectPlaceholder')}
      />

      <div className="text-small text-gray-normal mb-[4px]">
        {t('auth.freeTrial.tellUsMoreForm.achievement')}
      </div>
      <Select
        buttonClassName="bg-gray-inputBG"
        className="mb-[24px]"
        options={achieveOptionsKeys.map((key) => ({ label: t(key), value: key }))}
        value={achievementController.field.value}
        onChange={achievementController.field.onChange}
        placeholder={t('auth.freeTrial.tellUsMoreForm.selectPlaceholder')}
      />

      <div className="text-small text-gray-normal mb-[4px]">
        {t('auth.freeTrial.tellUsMoreForm.howLearned')}
      </div>
      <Select
        buttonClassName="bg-gray-inputBG"
        className="mb-[24px]"
        options={howLearnedOptionsKeys.map((key) => ({ label: t(key), value: key }))}
        value={howLearnedController.field.value}
        onChange={howLearnedController.field.onChange}
        placeholder={t('auth.freeTrial.tellUsMoreForm.selectPlaceholder')}
      />
    </>
  )
}

interface CompleteProfileFormFields {
  role: string
  customers: string
  achievement: string
  howLearned: string
}

export const tellUsMoreFormSchema = yup.object<ValidationSchema<CompleteProfileFormFields>>({
  role: yup.string().required(),
  customers: yup.string().required(),
  achievement: yup.string().required(),
  howLearned: yup.string().required(),
})

const rolesOptionsKeys = [
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option1',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option2',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option3',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option4',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option5',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option6',
  'auth.freeTrial.tellUsMoreForm.rolesOptions.option7',
]

const customersOptionsKeys = [
  'auth.freeTrial.tellUsMoreForm.customersOptions.option1',
  'auth.freeTrial.tellUsMoreForm.customersOptions.option2',
  'auth.freeTrial.tellUsMoreForm.customersOptions.option3',
  'auth.freeTrial.tellUsMoreForm.customersOptions.option4',
]

const achieveOptionsKeys = [
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option1',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option2',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option3',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option4',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option5',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option6',
  'auth.freeTrial.tellUsMoreForm.achievementOptions.option7',
]

const howLearnedOptionsKeys = [
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option1',
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option2',
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option3',
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option4',
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option5',
  'auth.freeTrial.tellUsMoreForm.howLearnedOptions.option6',
]
