import axios from 'axios'
import { Asset } from 'contentful'
import { UseQueryResult, useQuery } from 'react-query'
import { ContentfulEntry } from './__generated__/contentful'
import { ONE_DAY } from 'utils/oneDay'

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_TOKEN } = process.env

export const fetchContentfulAsset = (assetId: string) => async () => {
  const response = await axios.get(
    `https://cdn.contentful.com/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/assets/${assetId}?access_token=${REACT_APP_CONTENTFUL_TOKEN}`,
  )
  return response.data
}

export type UseQueryResultResponse<E = ContentfulEntry> = UseQueryResult<E>

export const useContentfulAsset = <E = Asset>(assetId: string): UseQueryResultResponse<E> => {
  const { data, ...rest } = useQuery(
    [`contentful-asset-${assetId}`, assetId],
    fetchContentfulAsset(assetId),
    {
      retry: false,
      retryOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
  return {
    data,
    ...rest,
  }
}
