import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, ButtonVariantEnum, ButtonTextColorVariantEnum } from 'components/Button'
import { QUICKSTART, QUICKSTART_PAGES } from 'components/quickstart/constants'
import { QuickstartPages } from 'components/quickstart/models'
import { useModal } from 'hooks/useModal'

export const BottomControls = () => {
  const { t } = useTranslation()
  const { closeModal, paramValue } = useModal(QUICKSTART)
  const [searchParams, setSearchParams] = useSearchParams()

  const currentStep = QUICKSTART_PAGES.indexOf(paramValue as QuickstartPages) ?? 0
  const nextStep = currentStep + 1
  const isFinalStep = nextStep === QUICKSTART_PAGES.length

  const onClickNext = () => {
    if (!isFinalStep) {
      const currentQueryParams = new URLSearchParams(searchParams)
      currentQueryParams.set(QUICKSTART, QUICKSTART_PAGES[nextStep])
      setSearchParams(currentQueryParams)
    } else {
      localStorage.removeItem(QUICKSTART)
      closeModal()
    }
  }

  return (
    <div className="h-[100px] mt-[20px] flex justify-between items-center">
      <Button
        variant={ButtonVariantEnum.Text}
        textColorVariant={ButtonTextColorVariantEnum.Muted}
        onClick={closeModal}
      >
        {t('quickstart.hideTools')}
      </Button>
      <Button
        variant={ButtonVariantEnum.Outlined}
        textColorVariant={ButtonTextColorVariantEnum.Primary}
        onClick={onClickNext}
      >
        {isFinalStep ? t('quickstart.completed') : t('quickstart.next')}
      </Button>
    </div>
  )
}
