export abstract class StorageItem<T> {
  readonly storageKey: string

  constructor(key: string) {
    this.storageKey = key
  }

  protected get localStorageValue(): string | null {
    return localStorage.getItem(this.storageKey)
  }

  protected set localStorageValue(newValue: string | null) {
    if (!newValue) {
      this.remove()
    } else {
      localStorage.setItem(this.storageKey, newValue)
    }
  }

  protected showParsingErrorAndResetData(error: unknown) {
    console.warn(
      `Error while parsing data from localStorage item with key "${this.storageKey}". Data was reset!`,
      error,
    )
    this.remove()
  }

  public remove() {
    localStorage.removeItem(this.storageKey)
  }

  abstract get value(): T
  abstract set value(newValue: T)
}
