import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { getDurationString } from 'components/regression-analysis/getDurationString'
import { Select } from 'components/Select'
import { RaSortType, RAStore } from 'components/regression-analysis/RAStore'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { OrderIcon } from 'components/traces/TableHeadColumn'

import DelaysPointerSVG from './delays_pointer.svg'
import './DelaysTable.css'
import { SliceTitle } from './SliceTitle'

const getRelString = (relNum: number) => `${relNum > 0 ? '+' : ''}${relNum}%`

const Rel = ({ relNum }: { relNum: number }) =>
  relNum === 0 ? null : (
    <span className={`${relNum < 0 ? 'text-state-good' : 'text-state-bad'}`}>
      {getRelString(relNum)}
    </span>
  )

export const DelaysTable = observer(function DelaysTable({ raStore }: { raStore: RAStore }) {
  const { t } = useTranslation()

  if (raStore.delaysList.length === 0 || raStore.delaySum == null) {
    return <ModuleProgressCircle />
  }

  return (
    <table className="mb-[24px] w-full text-left text-sm p-0">
      <thead className="bg-dark-dark3">
        <tr className="table-body-line">
          <th className="p-0">
            <div className="table-h1">{t('ra.table.recordsHeader')}</div>
          </th>
          <th className="p-0">
            <div className="table-h2 pr-[15px]">
              <Select
                options={raStore.commitsOptions}
                value={raStore.commitsOptions.at(0)!.value}
                onChange={() => null}
              />
            </div>
          </th>
          <th className="p-0">
            <div className="table-h2 pr-[15px]">
              <Select
                options={raStore.commitsOptions}
                value={raStore.commitsOptions.at(1)!.value}
                onChange={() => null}
              />
            </div>
          </th>
          <th className="p-0">
            <div className="table-h2">
              <div>{getDurationString(raStore.delaySum.diff.abs)}</div>
              <div className="text-header-small font-medium leading-[31px]">
                <Rel relNum={raStore.delaySum.diff.rel} />
              </div>
            </div>
          </th>
        </tr>
        <tr className="table-body-line">
          <th className="p-0">
            <div className="table-h3">
              <button
                className="hover:text-white flex group"
                onClick={() => raStore.changeSortType(RaSortType.CHRONOLOGICAL)}
              >
                {t('ra.table.recordsSubHeader')}
                <OrderIcon
                  isOpen={raStore.sortType === RaSortType.CHRONOLOGICAL}
                  isOrderDesc={
                    raStore.sortType === RaSortType.CHRONOLOGICAL && raStore.isSortReversed
                  }
                  className="sort-icon"
                  icon="arrow-drop-d"
                />
              </button>
            </div>
          </th>
          <th className="p-0">
            <div className="table-h3">{t('ra.table.durationHeader')}</div>
          </th>
          <th className="p-0">
            <div className="table-h3">{t('ra.table.durationHeader')}</div>
          </th>
          <th className="p-0">
            <div className="table-h3">
              <button
                className="hover:text-white flex group"
                onClick={() => raStore.changeSortType(RaSortType.DIFF_ABS)}
              >
                {t('ra.table.differenceHeader')}
                <OrderIcon
                  isOpen={raStore.sortType === RaSortType.DIFF_ABS}
                  isOrderDesc={raStore.sortType === RaSortType.DIFF_ABS && raStore.isSortReversed}
                  className="sort-icon"
                  icon="arrow-drop-d"
                />
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {raStore.delaysList.map((item, id) => (
          <tr className="table-body-line border-b border-gray-strokeLight" key={id}>
            <td className="p-0">
              <div className="table-body-cell typography-normal text-white flex flex-col">
                {item.titles.flatMap((title, index) =>
                  index === 0 ? (
                    <SliceTitle
                      title={title}
                      sourcePath={raStore.sourcePath}
                      sliceId={item.srcSliceIds[index]}
                      key={`SliceTitle-${index}`}
                    />
                  ) : (
                    <div className="flex mt-[2px]" key={`SliceTitle-${index}`}>
                      <img
                        className="mt-[2px] mr-[2px] h-[16px]"
                        alt="delay line"
                        src={DelaysPointerSVG}
                      />
                      <SliceTitle
                        title={title}
                        sourcePath={raStore.sourcePath}
                        sliceId={item.srcSliceIds[index]}
                      />
                    </div>
                  ),
                )}
              </div>
            </td>
            <td className="p-0">
              <div className="table-body-cell typography-normal-accent text-gray-normal flex">
                {getDurationString(item.durations[0])}
              </div>
            </td>
            <td className="p-0">
              <div className="table-body-cell typography-normal-accent text-gray-normal flex">
                {getDurationString(item.durations[1])}
              </div>
            </td>
            <td className="p-0">
              <div className="table-body-cell typography-normal-accent text-white flex">
                <div>{getDurationString(item.diff.abs)}</div>
                <div className="pl-[8px]">
                  <Rel relNum={item.diff.rel} />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
})
