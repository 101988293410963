import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'
import { VideoProgress } from './VideoProgress'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'
import { useToaster } from 'hooks/useToaster'
import { useDeleteVideo } from 'components/ps-chart/hooks/useDeleteVideo'
import { useCallback } from 'react'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { PATH_ANNOTATIONS } from 'pages/AnnotationsPage'
import { generatePath, Link, useParams } from 'react-router-dom'

const { UPLOADING } = VideoDataStoreStatus

const Message = ({ text }: { text: string }) => (
  <p className="px-[16px] text-gray-normal text-small text-center w-full">{text}</p>
)

export interface VideoStatusMsgProps {
  msg: string
  videoStore: VideoPlayerStore
  shouldShowProgress?: boolean
  shouldShowDelete?: boolean
  shouldShowCancelUpload?: boolean
  isFullScreen?: boolean
  shouldGoBackToAnnotations?: boolean
}

export const VideoStatusMsg = observer(function VideoStatusMsg(props: VideoStatusMsgProps) {
  const {
    msg,
    videoStore,
    shouldShowProgress = false,
    shouldShowCancelUpload = false,
    shouldShowDelete = true,
    shouldGoBackToAnnotations = false,
    isFullScreen,
  } = props
  const { t } = useTranslation()
  const toaster = useToaster()
  const deleteVideo = useDeleteVideo(videoStore, toaster)
  const onCancelUpload = useCallback(() => videoStore.cancelVideoUpload(), [videoStore])

  const { projectUrlName, flowProjectLocalId, traceProjectLocalId } = useParams()
  const link =
    projectUrlName &&
    flowProjectLocalId &&
    traceProjectLocalId &&
    generatePath(PATH_ANNOTATIONS, { projectUrlName, flowProjectLocalId, traceProjectLocalId })

  return (
    <div className="flex flex-col justify-center m-auto max-w-[400px]">
      {videoStore.status === UPLOADING && <Message text={msg} />}
      {shouldShowProgress && (
        <span className="flex flex-col justify-center m-auto">
          <span className="text-gray-normal block mx-[24px]">
            <VideoProgress
              status={videoStore.status}
              fileSize={videoStore.fileSize}
              progress={videoStore.progress}
            />
          </span>
          {videoStore.status === UPLOADING && (
            <span className="block pt-4">
              <Message text={t('psChart.video.uploadingWarning')} />
            </span>
          )}
        </span>
      )}
      {videoStore.status !== UPLOADING && <Message text={msg} />}
      {shouldShowDelete && (
        <div className="text-center pt-4">
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={ButtonTextColorVariantEnum.Muted}
            onClick={deleteVideo}
          >
            {t('delete')}
          </Button>
        </div>
      )}

      {shouldShowCancelUpload && !isFullScreen && (
        <div className="text-center pt-4">
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={ButtonTextColorVariantEnum.Muted}
            onClick={onCancelUpload}
          >
            {t('cancel')}
          </Button>
        </div>
      )}

      {shouldGoBackToAnnotations && link && (
        <div className="px-[16px] text-gray-normal text-small text-center w-full">
          <Trans
            i18nKey="psChart.video.goToAnnotationsToUpload"
            className="text-normal"
            components={{
              annotationsLink: <Link to={link} className="underline hover:text-white" />,
            }}
          />
        </div>
      )}
    </div>
  )
})
