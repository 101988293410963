import { AnnotationsStore, PinType } from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationDto } from 'api/models'
import { MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useArrows } from 'components/annotations/details-view/list/useArrows'
import { AnnotationListItemView } from 'components/annotations/details-view/list/AnnotationListItemView'
import { Arrow } from 'components/annotations/details-view/list/Arrow'
import { DeleteAnnotationModal } from 'components/annotations/details-view/list/DeleteAnnotationModal'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useToaster } from 'hooks/useToaster'
import { AnnotationListItemEdit } from 'components/annotations/details-view/list/AnnotationListItemEdit'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'

interface AnnotationProps {
  annotation: AnnotationDto
  type: PinType
  mapped: boolean
  elRefs: RefObject<HTMLLIElement>[]
  annotationsStore: AnnotationsStore
  videoPlayerState: VideoPlayerState
}

export const AnnotationListItem = observer(function AnnotationListItem(props: AnnotationProps) {
  const { annotation, type, mapped, elRefs, annotationsStore, videoPlayerState } = props
  const toaster = useToaster()

  const annotationRef = useRef<HTMLDivElement>(null)
  const pinIds = mapped
    ? annotationsStore.pinIdsSortedByTime
    : annotationsStore.pinIdsNotPlacedOnTimelineByCreatedDate
  const { calcArrowWidth, heightArrow } = useArrows(pinIds, elRefs, annotation.id)

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const hoveredId = annotationsStore.hoveredId
  const isHighlighted =
    hoveredId !== null && hoveredId.id === annotation.id && hoveredId.type === type

  useEffect(() => {
    if (isHighlighted && annotationRef.current && !isHovered) {
      annotationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isHighlighted, isHovered])

  const onHover = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (event.type === 'mouseenter') {
        setIsHovered(true)
        annotationsStore.setHoveredId({ id: annotation.id, type: type })
      }
      if (event.type === 'mouseleave') {
        setIsHovered(false)
        annotationsStore.setHoveredId(null)
      }
    },
    [annotation.id, annotationsStore, type],
  )

  const updateAnnotation = useCallback(() => {
    annotationsStore.setEditedId({ id: annotation.id, type: type })
  }, [annotation.id, annotationsStore, type])

  const deleteAnnotation = useCallback(() => {
    annotationsStore
      .delete(annotation.id)
      .catch((reason) => toaster.error(reason, 'annotation.error.delete'))
    setIsDeleteModalOpen(false) // close delete modal
  }, [annotation.id, annotationsStore, toaster])

  const placeOnTimeline = useCallback(() => {
    annotationsStore
      .updateTime(videoPlayerState.traceVideoPointerTimeNanos, { id: annotation.id, type: type })
      .catch((reason) => toaster.error(reason, 'annotation.error.placeOnTimeline'))
  }, [annotation.id, annotationsStore, toaster, type, videoPlayerState.traceVideoPointerTimeNanos])

  const openDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(true)
  }, [])

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const editedId = annotationsStore.editedId
  const isEdited = editedId && editedId.id === annotation.id && editedId.type === type

  return (
    <div
      ref={annotationRef}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      className={classNames(
        'relative flex flex-col justify-between transition text-small tracking-wide rounded-sm',
        isHovered || isHighlighted ? ' bg-dark-default' : 'bg-dark-dark1',
      )}
    >
      {isEdited ? (
        <AnnotationListItemEdit
          annotation={annotation}
          type={type}
          annotationStore={annotationsStore}
        />
      ) : (
        <AnnotationListItemView
          annotation={annotation}
          type={type}
          mapped={mapped}
          isActive={isHovered}
          onUpdateAnnotation={updateAnnotation}
          onDeleteAnnotation={openDeleteModal}
          onPlaceOnTimeline={placeOnTimeline}
          videoPlayerState={videoPlayerState}
        />
      )}
      {mapped && type === PinType.ACTION && (
        <Arrow
          setWidth={calcArrowWidth}
          setHeight={heightArrow}
          setColor={
            AnnotationsStore.hasDelay(annotation.delay)
              ? annotationsStore.settings.delayColor
              : annotationsStore.settings.connectionLineColor
          }
          isActive={isHovered}
        />
      )}
      <DeleteAnnotationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDelete={deleteAnnotation}
      />
    </div>
  )
})
