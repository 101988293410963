import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { FieldErrors, useController } from 'react-hook-form'
import NProgress from 'nprogress'
import * as Sentry from '@sentry/react'
import * as yup from 'yup'

import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { useToaster } from 'hooks/useToaster'
import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { Icon } from 'components/Icon'

interface FlowNameFields {
  flowName: string
}

// Please update validation on BE if you change validation here.
export const flowNameFormSchema = yup.object<ValidationSchema<FlowNameFields>>({
  flowName: yup
    .string()
    .trim()
    .min(1, 'guidePage.createFlow.validation.tooShort')
    .max(35, 'guidePage.createFlow.validation.tooLong')
    .required('guidePage.createFlow.validation.empty'),
})

export const CreateFlowForm = observer(() => {
  const { t } = useTranslation()
  const toaster = useToaster()

  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const predefinedFlow = guideStore.freeTrialFlow
  const predefinedFlowName = predefinedFlow && predefinedFlow.name

  const formContext = useForm<FlowNameFields>({
    validationSchema: flowNameFormSchema,
    formProps: {
      defaultValues: {
        flowName: predefinedFlowName ?? '',
      },
      mode: 'all',
    },
  })

  const handleSubmit = useCallback(
    (values: FlowNameFields) => {
      NProgress.start()
      guideStore
        .createFlow(values.flowName)
        .catch((error) => {
          Sentry.captureException(error)
          toaster.error(error?.message ?? t('guidePage.createFlow.couldNotCreate'))
        })
        .finally(() => NProgress.done())
    },
    [guideStore, t, toaster],
  )

  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<FlowNameFields>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toaster.error(errorMsg)
      })
    },
    [toaster],
  )

  return (
    <div className="relative mb-[16px]">
      <Form
        onSubmit={handleSubmit}
        onSubmitInvalid={handleSubmitInvalid}
        aria-label={t('guidePage.createFlow.createFlowForm')}
        formContext={formContext}
      >
        <FormChildren predefinedFlowName={predefinedFlowName} />
      </Form>
    </div>
  )
})

interface FormChildrenProps {
  predefinedFlowName: string | null
}

const FormChildren = ({ predefinedFlowName }: FormChildrenProps) => {
  const { t } = useTranslation()
  const flowNameController = useController<FlowNameFields>({ name: 'flowName' })
  const isSubmitDisabled =
    !!predefinedFlowName ||
    !flowNameController.fieldState.isDirty ||
    flowNameController.fieldState.invalid

  return (
    <div className="pt-[12px] relative w-[588px] h-[100px] bg-[#242424] border-[1px] border-[#616161] rounded-sm mb-[16px] text-[12px] m-0 placeholder:text-gray-normal text-white font-[500]">
      <input
        minLength={1}
        value={flowNameController.field.value}
        onChange={flowNameController.field.onChange}
        placeholder={flowNameController.field.value || t('guidePage.createFlow.placeholder')}
        className="focus:outline-none border-none bg-transparent w-full pl-[16px]"
        aria-required={true}
        aria-label={t('guidePage.createFlow.placeholder')}
        disabled={Boolean(predefinedFlowName)}
      />
      <Button
        disabled={isSubmitDisabled}
        variant={ButtonVariantEnum.Outlined}
        textColorVariant={ButtonTextColorVariantEnum.Primary}
        className="absolute left-[12px] bottom-[12px]"
        type="submit"
      >
        {t('guidePage.createFlow.submit')}
      </Button>
      {predefinedFlowName && (
        <div className="flex items-center absolute bottom-[15px] justify-end right-4">
          <span className="flex items-center">
            <Icon icon="check" className="text-state-good text-[30px] w-[30px]" />
            <p className="text-[12px]">{t('guidePage.createFlow.successMessage')}</p>
          </span>
        </div>
      )}
    </div>
  )
}
