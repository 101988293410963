import { StringStorageItem } from './items/StringStorageItem'
import { BooleanStorageItem } from './items/BooleanStorageItem'
import { ObjectStorageItem } from './items/ObjectStorageItem'
import { ArrayStorageItem } from './items/ArrayStorageItem'
import { StringsArrayStorageItem } from './items/StringsArrayStorageItem'

import { ProjectFilter } from 'pages/FlowsPage'
import { OrderStorageType } from 'hooks/useOrderStorage'

const TRACES_ORDER_STORAGE_KEY = 'psOrder'
const LAST_TEAM_STORAGE_KEY = 'psLastTeam'
const SIGNED_IN_STORAGE_KEY = 'isSignedIn'
const FLOWS_FILTERS_STORAGE_KEY = 'psFlowsFilters'
const GOT_IT_STORAGE_KEY = 'psShowedFirstAnnotationStickyToast'

class PsLocalStorage {
  private readonly lastTeamStorage = new StringStorageItem(LAST_TEAM_STORAGE_KEY)
  private readonly signedInStorage = new BooleanStorageItem(SIGNED_IN_STORAGE_KEY)
  private readonly flowsFiltersStorage = new ArrayStorageItem<ProjectFilter>(
    FLOWS_FILTERS_STORAGE_KEY,
  )

  private readonly tracesOrderStorage = new ObjectStorageItem<OrderStorageType>(
    TRACES_ORDER_STORAGE_KEY,
  )

  private readonly gotItStorage = new StringsArrayStorageItem(GOT_IT_STORAGE_KEY)

  hasGotIt(id: string): boolean {
    return this.gotItStorage.value.includes(id)
  }

  addGotIt(id: string) {
    this.gotItStorage.addItem(id)
  }

  getLastTeam(): string {
    return this.lastTeamStorage.value
  }

  setLastTeam(newValue: string) {
    this.lastTeamStorage.value = newValue
  }

  removeLastTeam() {
    this.lastTeamStorage.remove()
  }

  getSignedIn(): boolean {
    return this.signedInStorage.value
  }

  setSignedIn(isSignedIn: boolean) {
    this.signedInStorage.value = isSignedIn
  }

  getFlowsFilters(): ProjectFilter[] {
    return this.flowsFiltersStorage.value
  }

  setFlowsFilters(newValue: ProjectFilter[]) {
    this.flowsFiltersStorage.value = newValue
  }

  getTracesOrder(): OrderStorageType | null {
    return this.tracesOrderStorage.value
  }

  setTracesOrder(newValue: OrderStorageType) {
    this.tracesOrderStorage.value = newValue
  }
}

export const psLocalStorage = new PsLocalStorage()
