import { useCallback, useEffect, useRef } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { PsChartStore } from 'components/ps-chart/PsChartStore'

export const ChartScroll = observer(function ChartScroll({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const topMargin =
    psChartStore.chartSettings.renderEngine.headerHeight + psChartStore.vState.favCanvasHeight

  useEffect(() =>
    reaction(
      () => psChartStore.vState.yStart,
      (yStart: number) => {
        if (scrollableRef.current) {
          scrollableRef.current.scrollTop = yStart
        }
      },
      { name: 'reaction @ ChartScroll & vState.yStart -> scrollableRef.current' },
    ),
  )

  const onScroll = useCallback(() => {
    if (scrollableRef.current) {
      psChartStore.vState.setYStart(scrollableRef.current.scrollTop)
    }
  }, [psChartStore])

  // If the scroll container width less than 12px with the "scrollbar-width: thin;" it will not be visible;
  return (
    <div
      className="w-[12px] absolute right-0 overflow-y-scroll"
      style={{ height: `${psChartStore.vState.scrollableHeight}px`, top: `${topMargin}px` }}
      ref={scrollableRef}
      onScroll={onScroll}
    >
      <div style={{ height: `${psChartStore.vState.mainTotalHeight}px` }}></div>
    </div>
  )
})
