import React, { useState } from 'react'
import NProgress from 'nprogress'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { ActionModal } from 'components/ActionModal'
import { ProjectFormModal } from './ProjectFormModal'
import { openSecondModal } from 'utils/openSecondModal'
import { ApiError, AuthenticatedUserDto, ProjectDto, ProjectSummaryDto } from 'api/models'
import { queryKeys } from 'hooks/useApiQuery'
import { PATH_TEAM } from 'pages/TeamPage'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'

interface ProjectSettingsProps {
  className?: string
  project: ProjectDto
  isProjectCard?: boolean
  isFlowPage?: boolean
  user: AuthenticatedUserDto
}

export const ProjectSettings = ({
  className,
  project,
  isProjectCard,
  isFlowPage,
  user,
}: ProjectSettingsProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const teamUrlName = project.team.urlName as string

  const [projectSettingsModalOpened, setProjectSettingsModalOpened] = useState(false)
  const handleProjectSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setProjectSettingsModalOpened(true)
  }
  const handleProjectSettingsModalClose = () => setProjectSettingsModalOpened(false)

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const handleDeleteProjectClick = () =>
    openSecondModal(setProjectSettingsModalOpened, setDeleteModalOpened)
  const handleDeleteModalClose = () => setDeleteModalOpened(false)

  const deleteProjectMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteProject({ projectUrlName: project.urlName })
    },
    {
      onSuccess: () => {
        NProgress.done()
        setDeleteModalOpened(false)
        if (isProjectCard) {
          queryClient.setQueryData<ProjectSummaryDto | undefined>(
            queryKeys.projectsSummary({ teamUrlName }),
            (oldData) => {
              if (oldData) {
                return {
                  ...oldData,
                  projects: oldData.projects.filter((item) => item.project.id !== project.id),
                }
              }
            },
          )
        } else {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(t('projects.deleteProject.success', { name: project.name }))
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  return (
    <>
      <Button
        onClick={handleProjectSettingsClick}
        className={className}
        variant={ButtonVariantEnum.Text}
        textColorVariant={ButtonTextColorVariantEnum.Muted}
        withIcon
      >
        {t('projects.projectCard.projectSettings')} <ButtonIcon icon="filters" />
      </Button>
      <ProjectFormModal
        projectModel={project}
        teamDomain={project.team.domain}
        user={user}
        isOpen={projectSettingsModalOpened}
        onClose={handleProjectSettingsModalClose}
        onDeleteProjectClick={handleDeleteProjectClick}
        isProjectCard={isProjectCard}
        isFlowPage={isFlowPage}
        isEdit
      />
      <ActionModal
        isOpen={deleteModalOpened}
        title={t('projects.deleteProject.title')}
        secondaryTitle={project.name}
        text={t('projects.deleteProject.text')}
        onClose={handleDeleteModalClose}
        actionButton={{
          onClick: deleteProjectMutation.mutate,
          disabled: deleteProjectMutation.isLoading,
        }}
      />
    </>
  )
}
