import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'

import { getTeamRoleName } from 'utils/getTeamRoleName'
import { ApiError, ProjectRole, TeamRole, TeamUserDto, TeamUsersResponse } from 'api/models'
import { ButtonIcon } from 'components/Button'
import { queryKeys } from 'hooks/useApiQuery'
import { getProjectRoleName } from 'utils/getProjectRoleName'
import { useApi } from 'contexts/di-context'
import { Popover } from 'components/dropdowns/Popover'

interface TeamUserProjectRoleProps extends Pick<TeamUserDto, 'id' | 'teamRole' | 'user' | 'email'> {
  projectRole?: ProjectRole | null
  projectUrlName?: string
  projectId?: number
}

export const TeamUserProjectRole = ({
  id,
  email,
  user,
  teamRole,
  projectRole,
  projectUrlName,
  projectId,
}: TeamUserProjectRoleProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { teamUrlName } = useParams() as { teamUrlName: string }

  const putProjectUserRoleMutation = useMutation(
    ({ role }: { role: ProjectRole }) =>
      api.putProjectUserRole({ projectUrlName, userId: id }, { role }),
    {
      onSuccess: (_, variables) => {
        NProgress.done()
        queryClient.setQueryData<TeamUsersResponse | undefined>(
          queryKeys.teamUsers({ teamUrlName }),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                users: oldData.users.map((item) => {
                  if (item.id === id) {
                    const newProjectRoles = { ...item.projectRoles }
                    newProjectRoles[projectId as number] = variables.role
                    return { ...item, projectRoles: newProjectRoles }
                  }
                  return item
                }),
              }
            }
          },
        )
        toast.success(
          t('teams.teamUser.roleChanged', { user: user ? `${user.name} ${user.lastName}` : email }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const deleteProjectUserMutation = useMutation(
    () => api.deleteProjectUser({ projectUrlName, userId: id }),
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<TeamUsersResponse | undefined>(
          queryKeys.teamUsers({ teamUrlName }),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                users: oldData.users.map((item) => {
                  if (item.id === id) {
                    const newProjectRoles = { ...item.projectRoles }
                    delete newProjectRoles[projectId as number]
                    return { ...item, projectRoles: newProjectRoles }
                  }
                  return item
                }),
              }
            }
          },
        )
        toast.success(
          t('teams.teamUser.roleChanged', { user: user ? `${user.name} ${user.lastName}` : email }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleProjectRoleOptionClick = useCallback(
    (role: ProjectRole | null) => () => {
      NProgress.start()
      if (role) {
        putProjectUserRoleMutation.mutate({ role })
      } else {
        deleteProjectUserMutation.mutate()
      }
    },
    [putProjectUserRoleMutation, deleteProjectUserMutation],
  )

  const getUserProjectRoleOptions = useCallback(
    (getProjectRole: ProjectRole | null) => {
      const options = [
        {
          name: getProjectRoleName(ProjectRole.ADMIN, t),
          isSelect: getProjectRole === ProjectRole.ADMIN,
          onClick: handleProjectRoleOptionClick(ProjectRole.ADMIN),
        },
        {
          name: t('user.none'),
          isSelect: !getProjectRole,
          onClick: handleProjectRoleOptionClick(null),
        },
      ]
      if (teamRole !== TeamRole.CONTRIBUTOR) {
        options.splice(1, 0, {
          name: getProjectRoleName(ProjectRole.CONTRIBUTOR, t),
          isSelect: getProjectRole === ProjectRole.CONTRIBUTOR,
          onClick: handleProjectRoleOptionClick(ProjectRole.CONTRIBUTOR),
        })
      }
      return options
    },
    [handleProjectRoleOptionClick, t, teamRole],
  )

  return (
    <Popover
      popoverClassName="relative inline-block"
      disabled={Boolean(teamRole === TeamRole.ADMIN)}
      menuSections={[{ options: getUserProjectRoleOptions(projectRole as ProjectRole | null) }]}
      buttonChildren={(open) => (
        <>
          {((teamRole === TeamRole.ADMIN || (teamRole === TeamRole.CONTRIBUTOR && !projectRole)) &&
            getTeamRoleName(teamRole, t)) ||
            getProjectRoleName(projectRole, t) ||
            t('user.none')}
          <ButtonIcon
            icon="arrow-drop-d"
            className={classNames(
              'opacity-0 transition-opacity',
              open && 'opacity-100',
              Boolean(teamRole !== TeamRole.ADMIN) && 'group-hover:opacity-100',
            )}
          />
        </>
      )}
      buttonClass={(open) => {
        return classNames(
          'flex items-center pl-[8px] text-small tracking-wide font-medium transition text-left disabled:text-gray-normal/[.5]',
          open ? 'text-white' : 'text-gray-normal',
        )
      }}
      withSelect
    />
  )
}
