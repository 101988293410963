import { colors } from 'utils/styles/colors'

import { BasicRendererSettings } from '../models/settings'
import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'

export interface RenderTextParams {
  text: string
  x: number
  y: number
  width: number
  textMeasurer: TextMeasurer
  active: boolean
}

export class BasicRenderer {
  private readonly context: CanvasRenderingContext2D

  private readonly settings: BasicRendererSettings

  constructor(context: CanvasRenderingContext2D, settings: BasicRendererSettings) {
    this.context = context
    this.settings = settings
  }

  fillBackground(x: number, y: number, width: number, height: number) {
    this.context.fillStyle = colors.dark.default
    this.context.fillRect(x, y, width, height)
  }

  fillPath(path: Path2D, fillStyle: string) {
    this.context.fillStyle = fillStyle
    this.context.fill(path)
  }

  fillRect(left: number, top: number, width: number, height: number, fillStyle: string) {
    this.context.fillStyle = fillStyle
    this.context.fillRect(left, top, width, height)
  }

  strokePath(path: Path2D, strokeStyle: string, lineWidth: number, dash?: number[]) {
    if (dash != null) {
      this.context.setLineDash(dash)
    }
    this.context.strokeStyle = strokeStyle
    this.context.fillStyle = strokeStyle
    this.context.lineWidth = lineWidth
    this.context.stroke(path)
    if (dash != null) {
      this.context.setLineDash([])
    }
  }

  setSliceTextDrawingSettings(color: string, fontStyle: string) {
    this.context.font = fontStyle
    this.context.fillStyle = color
    this.context.textBaseline = 'middle'
    this.context.textAlign = 'center'
  }

  drawEllipsizedText({ text, x, y, width, textMeasurer }: RenderTextParams) {
    const maxWidth = width - this.settings.blockPaddingX * 2
    const textToDraw = textMeasurer.getEllipsizedText(text, maxWidth)
    if (textToDraw.length > 0) {
      const xCenter = x + Math.round(width / 2)
      const yMiddleBaseline = y + Math.round(this.settings.fontSize / 10)
      this.context.fillText(textToDraw, xCenter, yMiddleBaseline)
    }
  }
}
