import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'

import { Checkbox } from 'components/Checkbox'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { Icon } from 'components/Icon'
import {
  AssignTraceModalContext,
  AssignTraceModalStateContext,
} from 'components/traces/AssignTraceModal'
import { ActionModal } from 'components/ActionModal'
import { TraceEditModal } from 'components/TraceEditModal'
import {
  ApiError,
  ProcessingErrorCodeEnum,
  Trace as ITrace,
  TraceProcessingStateEnum,
  Traces,
} from 'api/models'
import { useMutation, useQueryClient } from 'react-query'
import { useClickOutside } from 'hooks/useClickOutside'
import { queryKeys, useProjectQuery } from 'hooks/useApiQuery'
import { truncate } from 'utils/truncate'
import { AxiosError } from 'axios'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'
import { useApi } from 'contexts/di-context'
import { Popover } from 'components/dropdowns/Popover'
import { OptionType } from 'components/dropdowns/models'

import { useIsReadOnlyProject } from 'utils/feature-flags'

interface TraceProps extends ITrace {
  gridTemplateColumns?: string
  isSelect: boolean
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  withSelect?: boolean
  withAssign?: boolean
  hasSelectedTraces?: boolean
  getActiveOrder: () => string
  canWorkWithFlow?: boolean
}

const DISABLE_MENU_OPEN_CLASS = 'disable-menu-open'
const CONTEXT_MENU_HEIGHT = 201
const NAVIGATION_HEIGHT = 56
const MAX_TRACE_NAME_LENGTH = 29

export const Trace = ({
  projectLocalId,
  name,
  flows,
  createdBy,
  executedBy,
  dateCreated,
  dateUpdated,
  gitBranch,
  gitCommit,
  device,
  osVersion,
  appVersion,
  resolution,
  flowDelayMs,
  performancePct,
  getActiveOrder,
  gridTemplateColumns,
  isSelect,
  onCheckboxChange,
  withSelect,
  withAssign,
  hasSelectedTraces,
  processingState,
  processingErrorCode,
  canWorkWithFlow,
}: TraceProps) => {
  const viewEl = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const navigate = useNavigate()

  const isFailed = processingState === TraceProcessingStateEnum.FAILED
  const isProcessing = processingState === TraceProcessingStateEnum.IN_PROGRESS

  const assignTraceModal = useContext<AssignTraceModalStateContext | null>(AssignTraceModalContext)

  const [menu, setMenu] = useState<{ isShow: boolean; clientX: number; clientY: number }>({
    isShow: false,
    clientX: 0,
    clientY: 0,
  })

  const handleCloseMenu = () => setMenu((prevState) => ({ ...prevState, isShow: false }))
  const handleTraceClick = (event: React.MouseEvent) => {
    if (!(event.target as Element).closest(`.${DISABLE_MENU_OPEN_CLASS}`)) {
      event.preventDefault()
      if (!menu.isShow) {
        const lastClientY = window.innerHeight - CONTEXT_MENU_HEIGHT - NAVIGATION_HEIGHT - 15
        setMenu((prevState) => ({
          isShow: !prevState.isShow,
          clientX: Number(event.clientX) || 0,
          clientY: Number(event.clientY > lastClientY ? lastClientY : event.clientY) || 0,
        }))
      } else {
        handleCloseMenu()
      }
    } else {
      handleCloseMenu()
    }
  }
  useClickOutside(viewEl, handleCloseMenu)

  const handleAssignClick = useCallback(() => {
    setTimeout(handleCloseMenu)
    const getFlows = flows.map((flow) => flow.flowProjectLocalId)
    assignTraceModal?.setState({
      isOpen: true,
      name,
      tracesIds: [projectLocalId],
      activeFlowsIds: getFlows,
      prevFlowsIds: getFlows,
    })
  }, [assignTraceModal, flows, name, projectLocalId])

  const isAssigned = flows.length > 0

  const getCurrentOrFlowName = !!flows?.find(
    (flow) => flow?.flowProjectLocalId === Number(flowProjectLocalId),
  )
    ? t('traces.trace.current')
    : flows[0]?.flowName

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)

  const handleDeleteClick = useCallback(() => {
    handleCloseMenu()
    setDeleteModalOpened(true)
  }, [])
  const handleDeleteModalClose = () => setDeleteModalOpened(false)

  const deleteFlowTraceMutation = useMutation(
    () =>
      api.deleteFlowTrace({
        projectUrlName,
        flowProjectLocalId,
        traceProjectLocalId: String(projectLocalId),
      }),
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (oldData) => {
            if (oldData) {
              return oldData?.filter((e) => e?.projectLocalId !== projectLocalId)
            }
          },
        )
        toast.success(t('traces.trace.flowDeletedToast'))
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const deleteTraceMutation = useMutation(
    () =>
      api.deleteTrace({
        projectUrlName,
        traceProjectLocalId: String(projectLocalId),
      }),
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.unassignedTraces({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData?.filter((e) => e?.projectLocalId !== projectLocalId)
            }
          },
        )
        toast.success(t('traces.trace.deletedToast'))
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleDeleteModalActionClick = () => {
    setDeleteModalOpened(false)
    NProgress.start()
    if (flowProjectLocalId && !withAssign) {
      deleteFlowTraceMutation.mutate()
    } else {
      deleteTraceMutation.mutate()
    }
  }

  const getTraceUrl = flowProjectLocalId
    ? generatePath(PATH_CHART_ROUTER, {
        projectUrlName,
        flowProjectLocalId,
        traceProjectLocalId: String(projectLocalId),
      })
    : ''

  const handleOpenClick = useCallback(() => {
    navigate(getTraceUrl)
  }, [navigate, getTraceUrl])

  const handleOpenAlternativeUiClick = useCallback(() => {
    const url = api.genAlternativeUiUrl({
      projectUrlName,
      traceProjectLocalId: String(projectLocalId),
    })
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [api, projectLocalId, projectUrlName])

  const handleCopyUrlClick = useCallback(async () => {
    const url = `${window.location.protocol}//${window.location.host}${getTraceUrl}`
    try {
      await navigator.clipboard.writeText(url)
      toast.success(t('traces.trace.urlCopied'), { duration: 3000 })
    } catch (error) {
      toast.error(`${t('traces.trace.urlNotCopied')}: ${error}`)
    }
  }, [getTraceUrl, t])

  const [renameModalVisible, setRenameModalVisible] = useState(false)

  const handleOpenRenameModal = useCallback(() => {
    setRenameModalVisible(true)
  }, [])

  const handleCloseRenameModal = useCallback(() => {
    setRenameModalVisible(false)
  }, [])

  const isReadOnlyProject = !!useIsReadOnlyProject()

  const { data: project } = useProjectQuery({ projectUrlName })
  const isMvpSupported = project?.team?.supportsMvp ?? false

  const contextMenuOptions = useMemo(() => {
    const options: OptionType[] = [
      {
        name: t('traces.trace.open'),
        isDisabled: !flowProjectLocalId || isFailed || isProcessing,
        onClick: handleOpenClick,
        dataTid: 'trace-open',
      },

      {
        name: t('traces.trace.copyURL'),
        isDisabled: !flowProjectLocalId || isFailed || isProcessing,
        onClick: handleCopyUrlClick,
        dataTid: 'trace-copy-url',
      },
    ]
    options.push({
      name: t('traces.trace.rename'),
      isDisabled: isFailed || isProcessing || isReadOnlyProject,
      onClick: handleOpenRenameModal,
      dataTid: 'trace-rename',
    })

    if (isMvpSupported) {
      options.push({
        name: t('traces.trace.openAlternative'),
        isDisabled: !flowProjectLocalId || isFailed || isProcessing,
        onClick: handleOpenAlternativeUiClick,
        dataTid: 'trace-open-in-mvp',
      })
    }

    if (canWorkWithFlow) {
      options.push(
        {
          name: t('traces.assign'),
          onClick: handleAssignClick,
          dataTid: 'trace-assign',
          isDisabled: isReadOnlyProject,
        },
        {
          name: t('traces.unassign'),
          onClick: handleDeleteClick,
          dataTid: 'trace-unassign',
          isDisabled: isReadOnlyProject,
        },
      )
    } else {
      options.push({
        name: t('traces.trace.delete'),
        onClick: handleDeleteClick,
        dataTid: 'trace-delete',
        isDisabled: isReadOnlyProject,
      })
    }
    return options
  }, [
    flowProjectLocalId,
    isFailed,
    isProcessing,
    handleAssignClick,
    handleCopyUrlClick,
    handleDeleteClick,
    handleOpenAlternativeUiClick,
    handleOpenClick,
    handleOpenRenameModal,
    canWorkWithFlow,
    t,
    isReadOnlyProject,
    isMvpSupported,
  ])

  const getErrorMessage = (errorCode: ProcessingErrorCodeEnum) => {
    switch (errorCode) {
      case ProcessingErrorCodeEnum.APP_ID_NOT_FOUND:
        return t('traces.processingError.appIdNotFound')
      case ProcessingErrorCodeEnum.UNSUPPORTED_FILE_FORMAT:
        return t('traces.processingError.unsupportedFileFormat')
      case ProcessingErrorCodeEnum.INTERNAL_SERVER_ERROR:
        return t('traces.processingError.internalServerError')
      default:
        return t('errorMessage')
    }
  }

  const getTraceName = (traceName: string) => {
    const isLongName = traceName.length >= MAX_TRACE_NAME_LENGTH

    return (
      <TraceName isFailed={isFailed} isProcessing={isProcessing}>
        {(isLongName || isFailed || isProcessing) && (
          <FullTraceNameModal>
            {isLongName && traceName}
            {processingErrorCode && (
              <TraceStatusText isFailed>
                {getErrorMessage(processingErrorCode)} ({String(processingErrorCode)})
              </TraceStatusText>
            )}
            {isProcessing && <TraceStatusText>{t('traces.trace.processing')}</TraceStatusText>}
          </FullTraceNameModal>
        )}
        {truncate(traceName, MAX_TRACE_NAME_LENGTH)}
      </TraceName>
    )
  }

  return (
    <>
      <View
        gridTemplateColumns={gridTemplateColumns}
        isShowMenu={menu.isShow}
        onClick={handleTraceClick}
        ref={viewEl}
        data-tid="flow-lib-trace-click"
      >
        {withSelect && (
          <Column className={DISABLE_MENU_OPEN_CLASS}>
            <Checkbox checked={isSelect} onChange={onCheckboxChange} />
          </Column>
        )}
        {[
          { name: 'name', content: getTraceName(name) },
          {
            name: 'flows',
            content: hasSelectedTraces ? null : flows?.length > 0 && !withAssign ? (
              t('traces.trace.withoutAssignFlow_interval', {
                postProcess: 'interval',
                count: flows.length,
                length: flows.length - 1,
                name: getCurrentOrFlowName,
              })
            ) : (
              <Button
                className={DISABLE_MENU_OPEN_CLASS}
                variant={ButtonVariantEnum.Text}
                textColorVariant={
                  isAssigned ? ButtonTextColorVariantEnum.Good : ButtonTextColorVariantEnum.Look
                }
                onClick={handleAssignClick}
              >
                {isAssigned ? t('traces.assigned') : t('traces.assign')}
                <ButtonIcon icon="arrow-small-r" />
                {isAssigned && <Notice>{flows.map((flow) => flow.flowName).join(', ')}</Notice>}
              </Button>
            ),
          },
          { name: 'createdBy', content: createdBy },
          { name: 'executedBy', content: executedBy },
          { name: 'dateCreated', content: dayjs(dateCreated).format('MM.DD.YY HH:mm:ss') },
          {
            name: 'dateUpdated',
            content: (
              <>
                {dayjs(dateUpdated).format('MM.DD.YY HH:mm:ss')}
                {/* <DotStatus /> */}
              </>
            ),
          },
          { name: 'gitBranch', content: gitBranch },
          { name: 'gitCommit', content: gitCommit },
          { name: 'device', content: device },
          { name: 'osVersion', content: osVersion },
          { name: 'appVersion', content: appVersion },
          { name: 'resolution', content: resolution },
          { name: 'flowDelayMs', content: flowDelayMs },
          {
            name: 'performancePct',
            isGood: performancePct > 0,
            content: (
              <>
                {performancePct && `${performancePct}%`}{' '}
                {performancePct > 0 && <PerformanceStatusIcon icon="arrow-small-u" />}
              </>
            ),
          },
        ].map((item, index) => (
          <Column isGood={item.isGood} isOrder={getActiveOrder() === item.name} key={String(index)}>
            {item.content}
          </Column>
        ))}
      </View>
      <ContextMenu
        position={menu}
        menuSections={[
          {
            name: t('traces.trace.contextMenuTitle'),
            options: contextMenuOptions,
          },
        ]}
        show={() => menu.isShow}
      />
      <ActionModal
        isOpen={deleteModalOpened}
        title={
          location.pathname.includes('/flows')
            ? t('traces.trace.unassignModal.title')
            : t('traces.trace.deleteModal.title')
        }
        secondaryTitle={name}
        text={
          flowProjectLocalId
            ? t('traces.trace.unassignModal.text')
            : t('traces.trace.deleteModal.text')
        }
        onClose={handleDeleteModalClose}
        actionButton={{
          children: location.pathname.includes('/flows') ? t('traces.unassign') : t('delete'),
          onClick: handleDeleteModalActionClick,
          disabled:
            flowProjectLocalId && !withAssign
              ? deleteFlowTraceMutation.isLoading
              : deleteTraceMutation.isLoading,
        }}
      />
      <TraceEditModal
        visible={renameModalVisible}
        title={name}
        onClose={handleCloseRenameModal}
        flowProjectLocalId={String(flowProjectLocalId)}
        traceProjectLocalId={String(projectLocalId)}
        projectUrlName={projectUrlName}
      />
    </>
  )
}

const View = styled.div<{ gridTemplateColumns?: string; isShowMenu: boolean }>`
  ${tw`transition text-normal tracking-wide`}
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-column: 1 / -1;
  padding: 7px 5px 6px 16px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.strokeLight};
  border-radius: 2px;

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      ${tw`bg-white/[.05]`}
    }
  }

  ${({ isShowMenu }) =>
    isShowMenu &&
    css`
      ${tw`bg-white/[.05]`}
    `}
`

const Column = styled.div<{ isOrder?: boolean; isGood?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 59px;
  color: ${({ theme, isOrder, isGood }) => {
    if (isOrder) {
      return theme.colors.white
    } else if (isGood) {
      return theme.colors.state.good
    } else {
      return theme.colors.gray.normal
    }
  }};
`

// const DotStatus = styled.div`
//   position: relative;
//   width: 4px;
//   height: 4px;
//   margin: 14px;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.colors.state.good};
// `

const PerformanceStatusIcon = styled(Icon)`
  ${tw`text-icon`}
  color: ${({ theme }) => theme.colors.state.good};
`

const Notice = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 142px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray.normal};
`

const ContextMenu = styled(Popover)<{ position: { clientX: number; clientY: number } }>`
  position: fixed;
  z-index: 100;
  top: ${({ position }) => position.clientY + 10}px;
  left: ${({ position }) => position.clientX + 10}px;
`

const TraceName = styled.div<{ isFailed: boolean; isProcessing: boolean }>`
  position: relative;
  white-space: nowrap;

  ${({ isFailed, theme }) =>
    isFailed &&
    css`
      color: ${theme.colors.state.bad};
    `}

  ${({ isProcessing, theme }) =>
    isProcessing &&
    css`
      color: ${theme.colors.state.attention};
    `}
`

const FullTraceNameModal = styled.div`
  ${tw`text-micro`}
  position: absolute;
  bottom: 18px;
  left: -3px;
  padding: 0 8px;
  transition: opacity 250ms ease-in-out;
  white-space: nowrap;
  opacity: 0;
  color: ${({ theme }) => theme.colors.gray.normal};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.dark.dark5};

  ${TraceName}:hover & {
    opacity: 1;
  }
`

const TraceStatusText = styled.div<{ isFailed?: boolean }>`
  color: ${({ theme, isFailed }) =>
    isFailed ? theme.colors.state.bad : theme.colors.state.attention};
`
