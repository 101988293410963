import { makeAutoObservable } from 'mobx'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Point } from 'components/ps-chart/models/helper-types'
import { addConnectionCurve } from 'components/ps-chart/connections-render/addConnectionCurve'
import { ThreadsTopMap } from 'components/ps-chart/flame-chart/RenderEngine'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Slice } from 'components/ps-chart/models/Slice'
import { getBorderRect } from 'components/ps-chart/utils/getBorderRect'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import {
  isConnectionVisible,
  isVisible,
  VISIBLE_FLAG,
} from 'components/ps-chart/connections-render/canvasPositions'
import { TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { walkOverTree } from 'components/ps-chart/stores/connections-store/LinksTree/walkOverTree'
import {
  CanvasesCurvesMap,
  ConnectionPaths,
  getEmptyCanvasesCurvesMap,
  SliceBordersPaths,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import {
  BorderType,
  LineType,
  NodeRenderData,
} from 'components/ps-chart/connections-render/NodeRenderData'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'

export interface ConnectionsRenderCursorParams {
  hoveredSlice: Slice | null
  cursorPoint: Point | null
  isCursorOnFav: boolean
}

export class ConnectionsRender {
  private readonly psChartStore: PsChartStore

  constructor(psChartStore: PsChartStore) {
    makeAutoObservable(this)
    this.psChartStore = psChartStore
  }

  getCanvasCurves(
    mainThreadsTopMap: ThreadsTopMap,
    favThreadsTopMap: ThreadsTopMap,
    getCursorParams: () => ConnectionsRenderCursorParams,
  ): Partial<CanvasesCurvesMap> {
    const linksTreeRoot = this.psChartStore.traceAnalyzeStore.linksTree
    if (linksTreeRoot != null) {
      const curves = getEmptyCanvasesCurvesMap()
      const nodesRenderDataMap = this.getNodesRenderDataMap(
        linksTreeRoot,
        this.psChartStore.traceAnalyzeStore.selectedSlice?.id,
      )
      this.setOrphanLinkModeSliceRenderData(nodesRenderDataMap)
      this.fillSliceBorders(
        nodesRenderDataMap,
        curves.main.sliceBordersPaths,
        curves.fav.sliceBordersPaths,
      )
      this.fillConnections(linksTreeRoot, nodesRenderDataMap, curves)
      this.fillConnectingProcessCurves(nodesRenderDataMap, getCursorParams, curves)
      return curves
    }

    return {}
  }

  private setOrphanLinkModeSliceRenderData = (nodesRenderDataMap: Map<number, NodeRenderData>) => {
    const linkModeSliceId = this.psChartStore.linkModeSliceId
    if (linkModeSliceId != null && !nodesRenderDataMap.has(linkModeSliceId)) {
      nodesRenderDataMap.set(
        linkModeSliceId,
        this.getNodeRenderData(linkModeSliceId, BorderType.SECONDARY),
      )
    }
  }

  private fillConnectingProcessCurves(
    nodesRenderDataMap: Map<number, NodeRenderData>,
    getCursorParams: () => ConnectionsRenderCursorParams,
    curves: CanvasesCurvesMap,
  ) {
    if (this.psChartStore.isLinkModeActive && this.psChartStore.linkModeSliceId != null) {
      const fromRD = nodesRenderDataMap.get(this.psChartStore.linkModeSliceId)!
      const { hoveredSlice, cursorPoint, isCursorOnFav } = getCursorParams()
      if (hoveredSlice != null) {
        const toRD = this.getNodeRenderData(hoveredSlice.id, BorderType.ACTIVE)
        const fromSlice = this.psChartStore.sliceById.get(this.psChartStore.linkModeSliceId)!
        const toSlice = this.psChartStore.sliceById.get(hoveredSlice.id)!
        const conError = getConnectionError(
          fromSlice,
          toSlice,
          this.psChartStore.sliceById,
          this.psChartStore.traceAnalyzeStore.sliceLinksBySliceId,
        )
        const lineType = conError === null ? LineType.ACTIVE : LineType.DISABLED
        this.fillConnection(fromRD, toRD, ConnectionType.MANUAL, lineType, curves)
        if (conError === null) {
          this.fillSliceBorder(toRD, curves.main.sliceBordersPaths, curves.fav.sliceBordersPaths)
        }
      } else if (cursorPoint != null) {
        const toRD: NodeRenderData = {
          rect: { ...cursorPoint, w: 0, h: 0, positionFlag: VISIBLE_FLAG },
          isFav: isCursorOnFav,
          borderType: BorderType.ACTIVE,
        }
        const isOut = cursorPoint.x > fromRD.rect.x + fromRD.rect.w
        const lineType = isOut ? LineType.DISABLED : LineType.ACTIVE
        this.fillConnection(fromRD, toRD, ConnectionType.MANUAL, lineType, curves)
      }
    }
  }

  private fillSliceBorders(
    nodesRenderDataMap: Map<number, NodeRenderData>,
    mainSlicesPaths: SliceBordersPaths,
    favSlicesPaths: SliceBordersPaths,
  ) {
    nodesRenderDataMap.forEach((nodeRD) => {
      if (isVisible(nodeRD.rect.positionFlag)) {
        this.fillSliceBorder(nodeRD, mainSlicesPaths, favSlicesPaths)
      }
    })
  }

  private fillConnections(
    treeRootNode: TreeNode,
    nodesRenderDataMap: Map<number, NodeRenderData>,
    curves: CanvasesCurvesMap,
  ) {
    const mainChainIds = this.psChartStore.traceAnalyzeStore.mainChainIds
    walkOverTree(treeRootNode, (treeNode) => {
      if (treeNode.fromLinks.length == null) {
        return null
      }
      let fromLinks = treeNode.fromLinks
      const linksType = [...new Set(fromLinks.map((link) => link.connectionType))]
      const sameTreeTypeLinks = linksType.length === 1 && linksType[0] === ConnectionType.TREE
      /**
       * There could be multiple connections from children slice to different parents with different depth
       * We draw vertical same-tree connections only between slices from main chain and draw it to the closest parent.
       */
      if (sameTreeTypeLinks) {
        const closestParentLink = fromLinks.reduce((previousLink, nextLink) => {
          const toPrevious = this.psChartStore.sliceById.get(previousLink.toTreeNode.sliceId)!
          const toNext = this.psChartStore.sliceById.get(nextLink.toTreeNode.sliceId)!
          return toPrevious.level > toNext.level && mainChainIds.has(toPrevious.id)
            ? previousLink
            : nextLink
        })
        fromLinks = [closestParentLink]
      }
      for (const link of fromLinks) {
        const toSliceId = link.toTreeNode.sliceId
        const fromSliceId = link.fromTreeNode.sliceId
        if (
          this.psChartStore.traceAnalyzeStore.shouldShowAllPaths &&
          link.connectionType === ConnectionType.TREE &&
          !mainChainIds.has(toSliceId)
        ) {
          continue
        }
        /**
         * In some cases named links can trigger to connect two slices with alike names
         * to same "FROM" slice. While these "TO" slices already linked with each other by auto type link.
         */
        if (this.psChartStore.traceAnalyzeStore.checkMainChainSliceOrder(fromSliceId, toSliceId)) {
          continue
        }
        const fromRD = nodesRenderDataMap.get(fromSliceId)!
        const toRD = nodesRenderDataMap.get(toSliceId)!
        const connectionType = link.connectionType
        if (!isConnectionVisible(fromRD.rect.positionFlag, toRD.rect.positionFlag)) {
          continue
        }
        this.fillConnection(fromRD, toRD, connectionType, this.getLineType(toSliceId), curves)
      }
    })
  }

  private fillConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    curves: CanvasesCurvesMap,
  ) {
    const connectionCurveOuterWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveBackgroundWidth
    const connectionCurveInnerWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveWidth
    if (fromRD.isFav === toRD.isFav) {
      ConnectionsRender.fillNonCrossConnection(
        fromRD,
        toRD,
        connectionType,
        lineType,
        curves.main.localCurvePaths,
        curves.fav.localCurvePaths,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
      )
    } else {
      this.fillCrossBorderConnection(
        fromRD,
        toRD,
        connectionType,
        lineType,
        curves.main.crossCurvePaths,
        curves.fav.crossCurvePaths,
      )
    }
  }

  private fillCrossBorderConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    mainToFavCurvePaths: ConnectionPaths,
    favToMainCurvePaths: ConnectionPaths,
  ) {
    const headerHeight = this.psChartStore.chartSettings.renderEngine.headerHeight
    const favCanvasHeight = this.psChartStore.vState.favCanvasHeight
    const connectionCurveOuterWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveBackgroundWidth
    const connectionCurveInnerWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveWidth

    const mainToFavCurvePath = mainToFavCurvePaths[lineType]
    const favToMainCurvePath = favToMainCurvePaths[lineType]

    if (fromRD.isFav) {
      // On fav-canvas part
      const virtualParentRect = {
        ...toRD.rect,
        // 0 is the min Y for the virtual rect from the main canvas,
        //  because otherwise the connection curve on the fav-canvas will end before the canvas border.
        y: (toRD.rect.y > 0 ? toRD.rect.y : 0) + favCanvasHeight + headerHeight,
      }
      addConnectionCurve(
        fromRD.rect,
        virtualParentRect,
        connectionType,
        favToMainCurvePath,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
      )

      // On main-canvas part
      const parentYCenter = toRD.rect.y + toRD.rect.h / 2
      // We draw a connection only when we can see the curve end and the slice-box border
      if (parentYCenter > 0) {
        const virtualChildRect = {
          ...fromRD.rect,
          y: fromRD.rect.y - favCanvasHeight - headerHeight,
        }
        addConnectionCurve(
          virtualChildRect,
          toRD.rect,
          connectionType,
          mainToFavCurvePath,
          connectionCurveOuterWidth,
          connectionCurveInnerWidth,
        )
      }
    } else {
      // On main-canvas part
      const childYCenter = fromRD.rect.y + fromRD.rect.h / 2
      // We draw a connection only when we can see the curve end and the slice-box border
      if (childYCenter > 0) {
        const virtualParentRect = {
          ...toRD.rect,
          y: toRD.rect.y - favCanvasHeight - headerHeight,
        }
        addConnectionCurve(
          fromRD.rect,
          virtualParentRect,
          connectionType,
          mainToFavCurvePath,
          connectionCurveOuterWidth,
          connectionCurveInnerWidth,
        )
      }

      // On fav-canvas part
      const virtualChildRect = {
        ...fromRD.rect,
        // 0 is the min Y for the virtual rect from the main canvas,
        //  because otherwise the connection curve on the fav-canvas will end before the canvas border.
        y: (fromRD.rect.y > 0 ? fromRD.rect.y : 0) + favCanvasHeight + headerHeight,
      }
      addConnectionCurve(
        virtualChildRect,
        toRD.rect,
        connectionType,
        favToMainCurvePath,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
      )
    }
  }

  private static fillNonCrossConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    mainCurvePaths: ConnectionPaths,
    favCurvePaths: ConnectionPaths,
    connectionCurveOuterWidth: number,
    connectionCurveInnerWidth: number,
  ) {
    const curvePaths = fromRD.isFav ? favCurvePaths : mainCurvePaths
    addConnectionCurve(
      fromRD.rect,
      toRD.rect,
      connectionType,
      curvePaths[lineType],
      connectionCurveOuterWidth,
      connectionCurveInnerWidth,
    )
  }

  private fillSliceBorder(
    nodeRD: NodeRenderData,
    mainSlicesPaths: SliceBordersPaths,
    favSlicesPaths: SliceBordersPaths,
  ) {
    const canvasPaths = nodeRD.isFav ? favSlicesPaths : mainSlicesPaths
    const borderPaths = canvasPaths[nodeRD.borderType]

    borderPaths.forEach((layerPath, layerIndex) => {
      const borderWidth =
        this.psChartStore.chartSettings.renderEngine.threads.sliceBorderWidths[layerIndex]
      const layerRect = getBorderRect(nodeRD.rect, borderWidth)
      layerPath.rect(layerRect.x, layerRect.y, layerRect.w, layerRect.h)
    })
  }

  private getNodesRenderDataMap(
    treeRootNode: TreeNode,
    selectedSliceId?: number,
  ): Map<number, NodeRenderData> {
    const nodeRenderDataMap = new Map<number, NodeRenderData>()

    walkOverTree(treeRootNode, (node) => {
      nodeRenderDataMap.set(
        node.sliceId,
        this.getNodeRenderData(node.sliceId, this.getBorderType(node, selectedSliceId)),
      )
    })

    return nodeRenderDataMap
  }

  private getNodeRenderData(sliceId: number, borderType: BorderType): NodeRenderData {
    const slice = this.psChartStore.sliceById.get(sliceId)!
    const thread = this.psChartStore.traceDataState.threadsById.get(slice.threadId)!
    const threadActiveDepths =
      this.psChartStore.traceAnalyzeStore.activeThreadsDepthsFromChain.get(slice.threadId) ?? []

    const minSliceBorderWidth =
      this.psChartStore.chartSettings.renderEngine.threads.minSliceBorderWidth
    return {
      rect: getSliceVisibleRect(
        thread,
        threadActiveDepths,
        slice,
        this.psChartStore,
        minSliceBorderWidth,
      ),
      isFav: this.psChartStore.traceAnalyzeStore.favIdSet.has(slice.threadId),
      borderType,
    }
  }

  private getLineType(toSliceId: number) {
    if (this.psChartStore.traceAnalyzeStore.mainRegularSliceIds.has(toSliceId)) {
      return this.psChartStore.traceAnalyzeStore.isDetailsChainRegular
        ? LineType.PRIMARY
        : LineType.UNFOCUSED
    }
    if (this.psChartStore.traceAnalyzeStore.mainNativeSlicesIds.has(toSliceId)) {
      return this.psChartStore.traceAnalyzeStore.isDetailsChainNative
        ? LineType.PRIMARY
        : LineType.UNFOCUSED
    }
    return LineType.SECONDARY
  }

  private getBorderType(node: TreeNode, selectedSliceId?: number): BorderType {
    if (selectedSliceId === node.sliceId) {
      return BorderType.ACTIVE
    }
    if (this.psChartStore.traceAnalyzeStore.mainRegularSliceIds.has(node.sliceId)) {
      return this.psChartStore.traceAnalyzeStore.isDetailsChainRegular
        ? BorderType.PRIMARY
        : BorderType.UNFOCUSED
    }
    if (this.psChartStore.traceAnalyzeStore.mainNativeSlicesIds.has(node.sliceId)) {
      return this.psChartStore.traceAnalyzeStore.isDetailsChainNative
        ? BorderType.PRIMARY
        : BorderType.UNFOCUSED
    }
    return BorderType.SECONDARY
  }
}
