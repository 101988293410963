import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalSizeEnum } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'

interface Props {
  onDelete: () => void
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const DeleteAnnotationModal = ({ onDelete, isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('annotations.delete.title')}
      isOpen={isOpen}
      size={ModalSizeEnum.NORMAL}
      onClose={() => setIsOpen(false)}
      actionButton={{
        children: t('delete'),
        textColorVariant: ButtonTextColorVariantEnum.Bad,
        onClick: onDelete,
      }}
      hiddenCloseButton={false}
    >
      <div className="flex min-h-[51px] items-center">
        <p className="text-small text-gray-normal">{t('annotations.delete.text')}</p>
      </div>
    </Modal>
  )
}
