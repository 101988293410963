import { QUICKSTART_ASSETS, CONTENTFUL_TYPES } from 'api/contentful'
import {
  QUICKSTART_PAGES,
  QUICKSTART_PAGE_TO_CONTENTFUL_ENTRIES,
} from 'components/quickstart/constants'
import {
  PageToContentMap,
  QuickstartContent,
  QuickstartContentWithoutVideo,
  QuickstartPageLink,
  QuickstartPages,
} from 'components/quickstart/models'
import { ContentfulEntry } from 'hooks/__generated__/contentful'
import { useContentfulAsset } from 'hooks/useContentfulAsset'
import { useContentfulEntriesByType } from 'hooks/useContentfulEntriesByType'

export const useQuickstartContent = (): QuickstartContent => {
  const { entries } = useContentfulEntriesByType(CONTENTFUL_TYPES.quickstartPage)
  const { pageToContentMap, linksToPages } = mapEntriesToQuickstartPages(entries)
  const video = useContentfulAsset(QUICKSTART_ASSETS.video)
  const videoThumbnail = useContentfulAsset(QUICKSTART_ASSETS.videoThumnail)
  return {
    pageToContentMap,
    linksToPages,
    video,
    videoThumbnail,
  }
}

export const mapEntriesToQuickstartPages = (
  entries: ContentfulEntry[] | undefined,
): QuickstartContentWithoutVideo => {
  const linksToPages: QuickstartPageLink[] = []

  const pageToContentMap: PageToContentMap = {
    [QuickstartPages.Contents]: null,
    [QuickstartPages.FlowLibrary]: null,
    [QuickstartPages.Annotations]: null,
    [QuickstartPages.PxA]: null,
    [QuickstartPages.TraceViewer]: null,
    [QuickstartPages.Insights]: null,
  }

  if (!entries || entries.length === 0) {
    console.warn('No entries found for quickstart pages')
    return { pageToContentMap, linksToPages }
  }

  for (const page of QUICKSTART_PAGES) {
    const entryId = QUICKSTART_PAGE_TO_CONTENTFUL_ENTRIES[page]
    const entry = entries.find((localEntry) => localEntry?.sys?.id === entryId)

    if (entry) {
      pageToContentMap[page] = entry
      linksToPages.push({
        urlQueryParam: page,
        linkText: entry.fields.title || '',
      })
    }
  }

  // remove the first title, which is the title of the index page
  linksToPages.shift()

  return { pageToContentMap, linksToPages }
}
