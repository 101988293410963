import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useParams } from 'react-router-dom'

import { Icon } from 'components/Icon'
import { ProgressBar } from 'components/ProgressBar'

import { useMutation, useQueryClient } from 'react-query'
import { getFileSize } from 'utils/getFileSize'
import { PATH_FLOW } from 'pages/FlowPage'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { useApi } from 'contexts/di-context'
import { AxiosProgressEvent } from 'axios'
import { queryKeys } from 'hooks/useApiQuery'
import type { Traces } from 'api/models'

let dragCounter = 0
let abortController: AbortController | null = null

enum UploaderViewEnum {
  MAIN,
  UPLOADING,
  SUCCESS,
  ERROR,
  ERROR_ONE_FILE,
  OFFLINE,
}

export const Uploader = () => {
  const { t } = useTranslation()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const queryClient = useQueryClient()

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isDrag, setDrag] = useState(false)
  const [percentCompleted, setPercentCompleted] = useState(0)
  const [dropEnabled, setDropEnabled] = useState(true)
  const [view, setView] = useState(UploaderViewEnum.MAIN)
  const [filename, setFilename] = useState('')
  const [filesize, setFilesize] = useState({ loaded: 0, total: 0 })

  const postUploadTraceMutation = useMutation(
    ({ formData }: { formData: FormData }) => {
      return api.postUploadTrace({ projectUrlName, flowProjectLocalId }, formData, {
        onUploadProgress: handleUploadProgress,
        signal: abortController?.signal,
      })
    },
    {
      onSuccess: (newTrace) => {
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (existingTraces = []) => {
            return [...existingTraces, newTrace]
          },
        )
        setView(UploaderViewEnum.SUCCESS)
      },
    },
  )

  const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const getPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
    setPercentCompleted(getPercentCompleted)
    setFilesize({ loaded: progressEvent.loaded, total: progressEvent.total! })
  }

  const handleCancelClick = () => {
    if (abortController) {
      abortController.abort()
    }
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setView(UploaderViewEnum.MAIN)
    setPercentCompleted(0)
    setDropEnabled(true)
  }

  const uploadTrace = (files: FileList | null) => {
    const formData = new FormData()
    setDropEnabled(false)
    if (files && files.length > 0) {
      formData.append('file', files[0])

      abortController = new AbortController()
      setFilename(files[0].name)
      setView(UploaderViewEnum.UPLOADING)
      postUploadTraceMutation.mutate({ formData })
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    uploadTrace(event.target.files)
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      dragCounter++
      if (event.dataTransfer.items.length > 0) {
        setDrag(true)
      }
    }
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      dragCounter--
      if (dragCounter === 0) {
        setDrag(false)
      }
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      setDrag(false)
      if (event.dataTransfer.files.length > 0) {
        if (event.dataTransfer.files.length > 1) {
          setView(UploaderViewEnum.ERROR_ONE_FILE)
        } else {
          uploadTrace(event.dataTransfer.files)
        }
        event.dataTransfer.clearData()
        dragCounter = 0
      }
    }
  }

  const handleOffline = () => {
    handleCancelClick()
    setView(UploaderViewEnum.OFFLINE)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('offline', handleOffline)
    }
  })

  const renderChooseFile = () => (
    <label
      className="cursor-pointer transition-colors text-electro hover:text-sky"
      htmlFor="file-input"
    >
      {t('traces.uploader.browseComputer')}
    </label>
  )

  const renderState = () => {
    switch (view) {
      case UploaderViewEnum.SUCCESS:
        return (
          <div className="flex items-center justify-center">
            <StatusIcon icon="check" isSuccessful />
            <div className="text-small tracking-wide text-gray-normal">
              <span className="text-white">{filename}</span>{' '}
              {t('traces.uploader.successfullyAddedToTheFlow')}{' '}
              <Link to={generatePath(PATH_FLOW, { projectUrlName, flowProjectLocalId })}>
                <span className="transition-colors text-electro hover:text-sky">
                  {t('traces.uploader.returnToFlow')}
                </span>
              </Link>
            </div>
          </div>
        )
      case UploaderViewEnum.OFFLINE:
        return (
          <div className="flex items-center justify-center">
            <StatusIcon icon="unable" isError />
            <div className="text-small tracking-wide text-gray-normal">
              <span className="text-state-bad">{filename}</span> {t('traces.uploader.offlineError')}{' '}
              {renderChooseFile()}
            </div>
          </div>
        )
      case UploaderViewEnum.ERROR:
        return (
          <div className="flex items-center justify-center">
            <StatusIcon icon="unable" isError />
            <div className="text-small tracking-wide text-gray-normal">
              <span className="text-state-bad">{filename}</span>{' '}
              {t('traces.uploader.unableToUploadError')} {renderChooseFile()}
            </div>
          </div>
        )
      case UploaderViewEnum.ERROR_ONE_FILE:
        return (
          <div className="flex items-center justify-center">
            <StatusIcon icon="unable" isError />
            <div className="text-small tracking-wide text-gray-normal">
              <span className="text-state-bad">{t('traces.uploader.errorOneFile')}</span>,{' '}
              {t('traces.uploader.tryAgain')} {t('traces.uploader.dropHereOr')} {renderChooseFile()}
            </div>
          </div>
        )
      case UploaderViewEnum.UPLOADING:
        return (
          <div className="flex items-center justify-center flex-col">
            <div className="relative top-[-3px] flex items-center justify-center">
              <div className="text-small tracking-wide text-gray-normal">
                <span className="text-white">{filename}</span>
              </div>
              <Button
                withIcon
                variant={ButtonVariantEnum.Text}
                textColorVariant={ButtonTextColorVariantEnum.Muted}
                onClick={handleCancelClick}
              >
                <ButtonIcon icon={'cross'} />
              </Button>
            </div>
            <CustomProgressBar setWidth={percentCompleted} />
            <div className="flex justify-between w-[400px] mt-[5px] text-micro tracking-widest text-gray-normal">
              <span>
                {getFileSize(filesize.loaded)}&nbsp;of&nbsp;{getFileSize(filesize.total)}
              </span>
              <span>{percentCompleted}%</span>
            </div>
          </div>
        )
      default:
        return (
          <div className="flex items-center justify-center">
            <StatusIcon icon="upload" withMarginRight />
            <div className="text-small tracking-wide text-gray-normal">
              {t('traces.uploader.dropHereOr')} {renderChooseFile()}
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="file-input"
        onChange={handleFileChange}
        className="hidden"
      />
      <View
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        isDrag={isDrag}
      >
        {renderState()}
      </View>
    </>
  )
}

const View = styled.div<{ isDrag: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  margin-right: 61px;
  margin-bottom: 20px;
  margin-left: 93px;
  border: 1px solid ${({ theme, isDrag }) => (isDrag ? theme.colors.sky : theme.colors.dark.dark2)};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.dark.dark2};
`

const StatusIcon = styled(Icon)<{
  withMarginRight?: boolean
  isSuccessful?: boolean
  isError?: boolean
}>`
  ${tw`text-icon`}
  color: ${({ theme, isSuccessful, isError }) => {
    if (isSuccessful) {
      return theme.colors.state.good
    } else if (isError) {
      return theme.colors.state.bad
    } else {
      return theme.colors.gray.normal
    }
  }};

  ${({ withMarginRight }) =>
    withMarginRight &&
    css`
      margin-right: 8px;
    `}
`

const CustomProgressBar = styled(ProgressBar)`
  width: 400px;
`
