import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'components/tooltip/Tooltip'
import { PlainActionsTooltip } from 'components/ActionTooltip'

interface SliceTitleProps {
  title: string
  sliceId: number
  sourcePath: string | null
}

export const SliceTitle = ({ title, sliceId, sourcePath }: SliceTitleProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    if (sourcePath == null) {
      return
    }

    navigate(sourcePath, {
      state: { sliceId, title },
    })
  }, [navigate, sliceId, sourcePath, title])

  return (
    <Tooltip content={<PlainActionsTooltip title={t('ra.clickToOpen')} />} showDelay={500}>
      <a onClick={onClick} className="hover:text-electro cursor-pointer">
        {title}
      </a>
    </Tooltip>
  )
}
