import { useEffect, useMemo } from 'react'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { RAStore } from 'components/regression-analysis/RAStore'
import { useToaster } from 'hooks/useToaster'

export const useRAData = (projectName: string) => {
  const isRAAvailable = projectName.toLowerCase().includes('telegram')
  const isRAEnabled = useFeatureFlag(FEATURE_FLAGS.REGRESSION_ANALYSIS)
  const raStore = useMemo(() => new RAStore(), [])
  const toaster = useToaster()

  useEffect(() => {
    if (isRAEnabled) {
      raStore.fetch()

      return () => {
        raStore.clean()
      }
    }
  }, [raStore, isRAEnabled, toaster])

  return {
    raStore,
    isRAEnabled: isRAEnabled && isRAAvailable,
  }
}
