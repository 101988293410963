import React, { useContext } from 'react'

import { Layout } from 'components/Layout'
import { PsChart } from 'components/ps-chart/PsChart'
import { HeaderProps } from 'components/Header'
import {
  AnnotationsFeatureState,
  PsChartFeatures,
  psChartStoreContext,
} from 'components/ps-chart/PsChartStore'
import { HeaderTraceBreadcrumbs } from 'components/HeaderTraceBreadcrumbs'
import { useNavigationRules } from 'hooks/useNavigationRules'

export const PATH_PROCESSES_FOLDER = 'processes-x-annotations'
export const PATH_PROCESSES = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_PROCESSES_FOLDER}`

const pxaChartFeatures: PsChartFeatures = {
  flags: false,
  measurementTool: false,
  annotations: { enabled: true, draggable: true } as AnnotationsFeatureState,
  sliceNameTooltip: true,
}

export const PxAPage = () => {
  const headerProps: HeaderProps = { screenTitleKey: 'pxa', leftText: <HeaderTraceBreadcrumbs /> }
  const psChartStore = useContext(psChartStoreContext)!
  psChartStore.setChartFeatures(pxaChartFeatures)

  useNavigationRules()

  return (
    <Layout headerProps={headerProps} pageConfig={{ withoutStyledContent: true }}>
      <PsChart psChartStore={psChartStore} />
    </Layout>
  )
}
