import React from 'react'
import { observer } from 'mobx-react-lite'

import { CommitsChart } from './commits-chart/CommitsChart'
import { DelaysTable } from './delays-table/DelaysTable'
import { RAStore } from './RAStore'

export const CompareCommits = observer(function CompareCommits({ raStore }: { raStore: RAStore }) {
  if (raStore.error != null) {
    return (
      <div className="flex flex-grow flex-col typography-big-accent text-state-bad text-center pt-[10%] pr-[107px]">
        {raStore.error}
      </div>
    )
  }

  return (
    <div className="flex-grow pr-[24px]">
      <CommitsChart raStore={raStore} />
      <DelaysTable raStore={raStore} />
    </div>
  )
})
