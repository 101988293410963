import { TraceDataDto } from 'api/models'
import { GlobalTimeline } from 'components/global-timeline/GlobalTimeline'
import { DetailsView } from 'components/ps-chart/details-view/DetailsView'
import { LocalTimeline } from 'components/ps-chart/local-timeline/LocalTimeline'
import { ScrolledChart } from 'components/ps-chart/PsChart'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { parseSlicesToThreadsFromApi } from 'components/ps-chart/utils/parser'
import { runInAction } from 'mobx'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { DevMonitor } from 'components/ps-chart/dev-monitor/DevMonitor'
import { toast } from 'react-hot-toast'
import { toastWithTitle } from 'components/StyledToaster'
import { useToaster } from 'hooks/useToaster'
import { ChartDataStore } from 'components/ps-chart/stores/ChartDataStore'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { useApi } from 'contexts/di-context'

// hardcoded for now, maybe we can think of a better way to do this or better defaults?
const tracePageParams = {
  flowProjectLocalId: '26895342307', // arbitrary magic number
  projectUrlName: 'Static Analysis',
  traceProjectLocalId: '839405571648239', // arbitrary magic number
}

// we could potentially make this path a bit less user-friendly to prevent crawling or accidental discovery
export const STATIC_PATH_CHART = '/static-chart'

// parses blob data into TraceDataDto
const reader = new FileReader()

export const StaticPsChartPage = () => {
  const [data, setData] = useState<null | TraceDataDto>(null)

  const api = useApi()
  const toaster = useToaster()

  const chartDataStore = useMemo(
    () => new ChartDataStore(api, tracePageParams, new PsChartSettings()),
    [api],
  )
  if (chartDataStore == null) {
    throw new Error('ChartDataStore has not been found')
  }

  const psChartStore = useMemo(
    () =>
      new PsChartStore(
        chartDataStore.settings,
        api,
        tracePageParams,
        toaster,
        chartDataStore.traceDataStore,
        chartDataStore.hStateStore,
        chartDataStore.videoDataStore,
        chartDataStore.annotationsDataStore,
        chartDataStore.flagsDataStore,
        false,
        true,
      ),
    [api, toaster, chartDataStore],
  )

  useEffect(() => {
    psChartStore.chartSettings.addEventListeners()
    return () => psChartStore.chartSettings.removeEventListeners()
  }, [psChartStore])

  useEffect(() => {
    if (data != null) {
      runInAction(() => {
        chartDataStore.traceDataStore.setThreads(
          parseSlicesToThreadsFromApi(data, psChartStore.chartSettings.renderEngine.palette.slice),
        )
      })
    }
  }, [psChartStore, data, chartDataStore.traceDataStore])

  const handleFile = (fileEvent: ChangeEvent<HTMLInputElement>) => {
    try {
      if (fileEvent?.target?.files) {
        reader.onload = (readEvent: ProgressEvent<FileReader>) => {
          if (readEvent.target?.result) {
            const fileContents = JSON.parse(String(readEvent.target.result))
            return setData(fileContents)
          }
        }
        return reader.readAsText(fileEvent.target.files[0])
      }
    } catch (e) {
      console.error(e)
      toast.error(
        toastWithTitle(
          'Error processing trace file',
          'Could not render flame chart, please try a new file.',
          true,
        ),
      )
    }
  }

  return (
    <div className="flex flex-col min-h-full">
      <div className="flex-grow flex relative overflow-hidden">
        <div className="flex flex-grow flex-col overflow-hidden">
          <div className="h-[136px] flex">
            <div className="w-[240px] bg-dark-dark2 relative">
              <DevMonitor psChartStore={psChartStore} />
              <div className="absolute bottom-[14px] right-[8px]">
                <UploadFileInput onChange={handleFile} />
              </div>
            </div>
            <div className="flex-grow h-[136px] bg-dark-dark3">
              <GlobalTimeline psChartStore={psChartStore} />
              <LocalTimeline psChartStore={psChartStore} tracePageParams={tracePageParams} />
            </div>
          </div>
          {data ? (
            <ScrolledChart psChartStore={psChartStore} />
          ) : (
            <div className="flex items-center w-full justify-center h-full">
              please upload a trace
            </div>
          )}
        </div>
        <DetailsView psChartStore={psChartStore} />
      </div>
    </div>
  )
}

function UploadFileInput(props: { onChange: (fileEvent: ChangeEvent<HTMLInputElement>) => void }) {
  return (
    <>
      <label
        htmlFor="upload"
        className="flex items-center justify-center px-2 bg-electro hover:cursor-pointer rounded"
      >
        Upload
      </label>
      <input
        onChange={props.onChange}
        id="upload"
        type="file"
        placeholder="upload trace"
        className="hidden"
      />
    </>
  )
}
