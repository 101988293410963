import { useRef } from 'react'
import { DevMonitor } from './dev-monitor/DevMonitor'
import { LocalTimelineOptionsPanel } from './local-timeline/LocalTimelineOptionsPanel'
import { OverviewTimeline } from './PsChart'
import { LocalTimeline } from './local-timeline/LocalTimeline'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import type { PsChartStore } from './PsChartStore'
import type { ChartPageParams } from 'api/models'

export interface ChartExtensionsProps {
  psChartStore: PsChartStore
  tracePageParams: ChartPageParams
  showSidebar?: boolean
  isVideoPreviewOnly?: boolean
}

export const ChartExtensions = ({
  psChartStore,
  tracePageParams,
  showSidebar = true,
  isVideoPreviewOnly = false,
}: ChartExtensionsProps) => {
  const extensionsRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(extensionsRef)
  return (
    <div ref={extensionsRef} className="h-[136px] flex">
      {showSidebar && (
        <div className="w-[240px] bg-dark-dark2 relative">
          <DevMonitor psChartStore={psChartStore} />
          <LocalTimelineOptionsPanel psChartStore={psChartStore} />
        </div>
      )}
      <div className="flex-grow h-[136px] bg-dark-dark5">
        <OverviewTimeline psChartStore={psChartStore} isVideoPreviewOnly={isVideoPreviewOnly} />
        <LocalTimeline psChartStore={psChartStore} tracePageParams={tracePageParams} />
      </div>
    </div>
  )
}
