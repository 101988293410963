import React, { useEffect, useState } from 'react'
import NProgress from 'nprogress'
import { generatePath, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/Layout'
import { ButtonVariantEnum } from 'components/Button'
import { useTeamsQuery, useUserQuery } from 'hooks/useApiQuery'
import { Empty } from 'components/Empty'
import { PATH_TEAM } from 'pages/TeamPage'
import { FreeTrialStage, TeamsArray } from 'api/models'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'

import { TeamFormModal } from 'components/teams/TeamFormModal'
import { PATH_FREE_TRIAL_QUESTIONNAIRE } from 'pages/FreeTrialQuestionnairePage'
import { PATH_GUIDE_ROUTER } from 'pages/guide/GuideRootPage'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

export const PATH_ROOT = '/'

export const MainPage = () => {
  const { t } = useTranslation()

  const [addTeamModalOpened, setAddTeamModalOpened] = useState(false)
  const handleAddTeamClick = () => setAddTeamModalOpened(true)
  const handleAddTeamModalClose = () => setAddTeamModalOpened(false)

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess, data: teams } = useTeamsQuery()

  const selfServeEnabled = useFeatureFlag(FEATURE_FLAGS.SELF_SERVE)

  const hasTeams = teams && teams?.length > 0
  const freeTrialTeam = teams?.find((team) => team.freeTrial)
  const isOnFreeTrialGuides =
    selfServeEnabled &&
    teams?.length === 1 &&
    freeTrialTeam &&
    freeTrialTeam.freeTrial!.stage !== FreeTrialStage.FLOW_LIBRARY
  const needToFulfillQuestionnaire = selfServeEnabled && user?.askQuestionnaire

  const isLoaded = isTeamsSuccess && isUserSuccess && selfServeEnabled != null

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const getFirstTeamUrlName = (getTeams: TeamsArray) => {
    let teamUrlNameStorage: string | null = null
    try {
      teamUrlNameStorage = psLocalStorage.getLastTeam() || null
    } catch {}
    const hasTeam = getTeams?.find((team) => team.urlName === teamUrlNameStorage)
    if (teamUrlNameStorage && !hasTeam) {
      try {
        psLocalStorage.removeLastTeam()
      } catch {}
    }
    return hasTeam ? teamUrlNameStorage : getTeams[0].urlName
  }

  return (
    <Layout
      headerProps={{ screenTitleKey: 'teams', hiddenProjectSelect: true }}
      pageConfig={{ withoutNavbar: true, withoutStyledContent: true }}
    >
      {isLoaded && (
        <main className="flex flex-1">
          {needToFulfillQuestionnaire ? (
            <Navigate to={generatePath(PATH_FREE_TRIAL_QUESTIONNAIRE)} replace={true} />
          ) : isOnFreeTrialGuides ? (
            <Navigate to={generatePath(PATH_GUIDE_ROUTER)} replace={true} />
          ) : hasTeams ? (
            <Navigate
              to={generatePath(PATH_TEAM, { teamUrlName: getFirstTeamUrlName(teams) })}
              replace={true}
            />
          ) : user?.roles.isSuperAdmin ? (
            <Empty
              title={t('teams.mainPage.createTeam.title')}
              text={t('teams.mainPage.createTeam.text')}
              buttons={[
                {
                  name: t('teams.mainPage.createTeam.button'),
                  variant: ButtonVariantEnum.Outlined,
                  onClick: handleAddTeamClick,
                },
              ]}
            />
          ) : (
            <Empty
              title={t('teams.mainPage.userWithoutTeams.title')}
              text={t('teams.mainPage.userWithoutTeams.text')}
            />
          )}
        </main>
      )}
      <TeamFormModal isOpen={addTeamModalOpened} onClose={handleAddTeamModalClose} />
    </Layout>
  )
}
