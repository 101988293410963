import axios from 'axios'
import { UseQueryResult, useQuery } from 'react-query'
import { ContentfulEntry } from './__generated__/contentful'
import { ONE_DAY } from 'utils/oneDay'

export * from './__generated__/contentful/TypeQuickstartPage'

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_TOKEN } = process.env

export const fetchContentfulEntriesByType = (type: string) => async () => {
  const response = await axios.get(
    `https://cdn.contentful.com/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${REACT_APP_CONTENTFUL_TOKEN}&content_type=${type}`,
  )
  return response.data
}

export type EntriesByType<E = ContentfulEntry> = UseQueryResult<E> & {
  entries?: E[]
}

export const useContentfulEntriesByType = <E = ContentfulEntry>(
  contentType: string,
): EntriesByType<E> => {
  const { data, ...rest } = useQuery(
    [`content-type-${contentType}`, contentType],
    fetchContentfulEntriesByType(contentType),
    {
      retry: false,
      retryOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )

  return {
    entries: data?.items ?? [],
    data,
    ...rest,
  }
}
