import { observer } from 'mobx-react-lite'
import { GuideStore } from 'components/guide/GuideStore'
import { SubmitRequestStore } from 'components/guide/SubmitRequestStore'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import {
  BuildSystemOption,
  FrameworkOption,
  GuideStepType,
  LanguageOption,
  OsOption,
  PlatformType,
  SelectPlatformFormFields,
} from 'components/guide/models'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { Select } from 'components/Select'
import { InDevelopmentState } from 'components/guide/InDevelopmentState'
import * as yup from 'yup'
import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { useToaster } from 'hooks/useToaster'
import { useNavigate, useOutletContext } from 'react-router-dom'
import NProgress from 'nprogress'
import { OsType } from 'api/models'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { NextActionButton } from 'components/guide/NextActionButton'

interface Props {
  submitRequestStore: SubmitRequestStore
}

export const SelectPlatform = observer(function SelectPlatform(props: Props) {
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const { submitRequestStore } = props
  const { t } = useTranslation()
  const [pending, setPending] = useState(false)
  const toaster = useToaster()
  const navigate = useNavigate()
  const freeTrialProjectExists = Boolean(guideStore.freeTrialProjectSummary)
  const formContext = useForm<SelectPlatformFormFields>({
    validationSchema: platformFormSchema,
    formProps: {
      defaultValues:
        submitRequestStore.hasRequests && !guideStore.freeTrialProjectSummary
          ? submitRequestStore.selectPlatformValuesFromRequest
          : guideStore.selectPlatformDefaultValues,
    },
  })

  const handleSubmit = useCallback(
    async (data: SelectPlatformFormFields) => {
      if (guideStore.freeTrialTeam) {
        NProgress.start()
        setPending(true)
        try {
          if (!guideStore.freeTrialProjectSummary) {
            await guideStore.createFreeTrialProject(guideStore.freeTrialTeam.urlName, {
              ...data,
              os: data.os.toUpperCase() as OsType,
            })
          }

          const platform = guideStore.selectPlatformDefaultValues.os.toLowerCase() as PlatformType
          if (platform) {
            navigate(GuideStore.getPageUrl(GuideStepType.InstrumentAndBuild, platform))
          }
        } catch (error) {
          toaster.error(error)
        }
        setPending(false)
        NProgress.done()
      }
    },
    [toaster, guideStore, navigate],
  )

  return (
    <div className="w-full py-[40px]">
      <div className="mb-[40px]">
        <h2 className="text-header-small">{t('guidePage.platformSelect.title')}</h2>
      </div>
      <Form<SelectPlatformFormFields> formContext={formContext} onSubmit={handleSubmit}>
        <Questions
          projectExists={freeTrialProjectExists}
          pending={pending}
          guidesStore={guideStore}
          submitRequestStore={submitRequestStore}
        />
      </Form>
    </div>
  )
})

interface QuestionsProps {
  pending: boolean
  guidesStore: GuideStore
  submitRequestStore: SubmitRequestStore
  projectExists: boolean
}

const Questions = (props: QuestionsProps) => {
  const { pending, guidesStore, submitRequestStore, projectExists } = props
  const formValues = useWatch<SelectPlatformFormFields>() as SelectPlatformFormFields
  const { devPlatform, languages, buildSystem, os } = formValues
  const { setValue, resetField } = useFormContext<SelectPlatformFormFields>()

  const languageController = useController<SelectPlatformFormFields>({ name: 'languages' })
  const buildSystemController = useController<SelectPlatformFormFields>({ name: 'buildSystem' })

  const osValue = os as Nullable<OsOption>
  const frameworkValue = devPlatform as Nullable<FrameworkOption>
  const languagesValue = languages as Nullable<string[]>
  const buildSystemValue = buildSystem as Nullable<BuildSystemOption>

  let languagesList: string[] = []

  if (osValue && frameworkValue && frameworkValue !== FrameworkOption.OTHERS) {
    languagesList = languageOptions[osValue][frameworkValue]
  }

  const { t } = useTranslation()

  const handleOsChange = useCallback(
    (value: string) => {
      setValue('os', value)
      resetField('languages')
      resetField('buildSystem')
      resetField('devPlatform')
    },
    [resetField, setValue],
  )

  const handleFrameworkChange = useCallback(
    (value: string) => {
      setValue('devPlatform', value)
      if (value != FrameworkOption.NATIVE) {
        resetField('languages')
        resetField('buildSystem')
      }
    },
    [resetField, setValue],
  )

  useEffect(() => {
    const selectedTechStack: string[] = [
      os || '',
      devPlatform || '',
      languages ? languages.join('/') : '',
      buildSystem || '',
    ].filter((item) => item)

    guidesStore.setPreselectedTechStack(selectedTechStack)
  }, [devPlatform, languages, buildSystem, os, guidesStore])

  return (
    <>
      <div className="flex items-center mb-[24px]">
        <div className="text-small text-gray-normal w-[260px]">
          {t('guidePage.platformSelect.platform')}
        </div>
        <Select
          buttonClassName="bg-gray-inputBG"
          className="flex-1"
          options={platformOptions.map((key) => ({ label: key, value: key }))}
          value={osValue}
          onChange={handleOsChange}
          placeholder={t('guidePage.platformSelect.choose')}
        />
      </div>

      {osValue === OsOption.IOS ? (
        <InDevelopmentState formData={formValues} submitRequestStore={submitRequestStore} />
      ) : (
        <>
          <div className="flex items-center mb-[24px]">
            <div className="text-small text-gray-normal w-[260px]">
              {t('guidePage.platformSelect.devPlatform')}
            </div>
            <Select
              buttonClassName="bg-gray-inputBG"
              className="flex-1"
              disabled={!osValue}
              options={frameworkOptions.map((key) => ({ label: key, value: key }))}
              value={frameworkValue}
              onChange={handleFrameworkChange}
              placeholder={t('guidePage.platformSelect.choose')}
            />
          </div>

          {frameworkValue && frameworkValue !== FrameworkOption.NATIVE ? (
            <InDevelopmentState formData={formValues} submitRequestStore={submitRequestStore} />
          ) : (
            <>
              <div className="flex items-center mb-[24px]">
                <div className="text-small text-gray-normal w-[260px]">
                  {t('guidePage.platformSelect.language')}
                </div>
                <Select
                  buttonClassName="bg-gray-inputBG"
                  className="flex-1"
                  options={languagesList.map((key) => ({ label: key, value: key }))}
                  disabled={!frameworkValue || projectExists}
                  value={languagesValue || []}
                  onChange={languageController.field.onChange}
                  placeholder={t('guidePage.platformSelect.choose')}
                  multiple
                />
              </div>

              <div className="flex items-center mb-[56px]">
                <div className="text-small text-gray-normal w-[260px]">
                  {t('guidePage.platformSelect.buildSystem')}
                </div>
                <Select
                  buttonClassName="bg-gray-inputBG"
                  className="flex-1"
                  options={buildSystemOptions.map((key) => ({ label: key, value: key }))}
                  disabled={!languagesValue || projectExists}
                  value={buildSystemValue}
                  onChange={buildSystemController.field.onChange}
                  placeholder={t('guidePage.platformSelect.choose')}
                />
              </div>
              <NextActionButton
                type="submit"
                disabled={pending || !buildSystemController.formState.isValid}
                label={t('guidePage.nextStep')}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export const platformFormSchema = yup.object<ValidationSchema<SelectPlatformFormFields>>({
  os: yup.string().required(),
  devPlatform: yup.string().required(),
  languages: yup.array().of(yup.string().required()).required().min(1),
  buildSystem: yup.string().required(),
})

type Nullable<T> = T | null
const platformOptions = Object.values(OsOption)
const frameworkOptions = Object.values(FrameworkOption)
const buildSystemOptions = Object.values(BuildSystemOption)
const languageOptions = {
  [OsOption.ANDROID]: {
    [FrameworkOption.NATIVE]: [LanguageOption.JAVA, LanguageOption.KOTLIN, LanguageOption.OTHERS],
    [FrameworkOption.REACT_NATIVE]: [LanguageOption.TS_JS, LanguageOption.NATIVE_JAVA_KOTLIN],
    [FrameworkOption.FLUTTER]: [LanguageOption.DART, LanguageOption.NATIVE_JAVA_KOTLIN],
  },
  [OsOption.IOS]: {
    [FrameworkOption.NATIVE]: [LanguageOption.SWIFT, LanguageOption.OBJ_C],
    [FrameworkOption.REACT_NATIVE]: [LanguageOption.TS_JS, LanguageOption.NATIVE_SWIFT_OBJ_C],
    [FrameworkOption.FLUTTER]: [LanguageOption.DART, LanguageOption.NATIVE_SWIFT_OBJ_C],
  },
}
