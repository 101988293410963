import { TreeLink, TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { Slice } from 'components/ps-chart/models/Slice'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export const getMainChainFromTree = (
  treeRootNode: TreeNode | null,
  sliceById: ReadonlySliceById,
): Slice[] => {
  if (treeRootNode == null) {
    return []
  }

  const visitedSlices = new Set<number>([treeRootNode.sliceId])
  const mainChain: Slice[] = [sliceById.get(treeRootNode.sliceId)!]
  let curStackSrcNode = treeRootNode

  while (curStackSrcNode.fromLinks.length > 0) {
    /**
     * Find all links outgoing from the current stack,
     *  including links from the ancestors of the current slice.
     */
    const curStackLinksToCompare: TreeLink[] = []
    const curStackLinksSources = [curStackSrcNode]
    const curStackCheckedSlices = new Set<number>()
    while (curStackLinksSources.length > 0) {
      const curLinksSrc = curStackLinksSources.pop()
      if (curLinksSrc == null || curStackCheckedSlices.has(curLinksSrc.sliceId)) {
        continue
      }
      curStackCheckedSlices.add(curLinksSrc.sliceId)
      curStackLinksToCompare.push(
        ...curLinksSrc.fromLinks.filter((link) => link.connectionType != ConnectionType.TREE),
      )
      curStackLinksSources.push(
        ...curLinksSrc.fromLinks
          .filter((link) => link.connectionType == ConnectionType.TREE)
          .map((link) => link.toTreeNode),
      )
    }

    /**
     * Find a link which leads into a slice,
     *  with the smallest distance between it's x-start and the current slice's x-start.
     */
    const curSlice = sliceById.get(curStackSrcNode.sliceId)!
    let closestTimeGap = +Infinity
    let closestLink = null
    let closestNextSlice = null
    for (const curLink of curStackLinksToCompare) {
      const curToNode = curLink.toTreeNode
      const curToSlice = sliceById.get(curToNode.sliceId)!
      const curTimeGap = Math.abs(curSlice.start - curToSlice.start)
      if (curTimeGap < closestTimeGap) {
        closestTimeGap = curTimeGap
        closestLink = curLink
        closestNextSlice = curToSlice
      }
    }

    if (closestLink == null || closestNextSlice == null) {
      break
    }

    if (visitedSlices.has(closestNextSlice.id)) {
      break
    }
    visitedSlices.add(closestNextSlice.id)

    if (closestLink.fromTreeNode.sliceId !== curStackSrcNode.sliceId) {
      mainChain.push(sliceById.get(closestLink.fromTreeNode.sliceId)!)
    }
    mainChain.push(closestNextSlice)
    curStackSrcNode = closestLink.toTreeNode
  }

  return mainChain
}
