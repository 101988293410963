import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'

import { useAuth } from 'contexts/auth-context'
import { Input } from 'components/Input'
import { AuthButton } from 'components/auth/AuthButton'
import { AuthLayout } from 'components/auth/AuthLayout'
import { PATH_SIGN_IN } from './SignInPage'
import { Icon } from 'components/Icon'
import { SignInDto, SignUpRequest, TokenDest } from 'api/models'
import { GoogleButton } from 'components/auth/GoogleButton'
import { PATH_ROOT } from 'pages/MainPage'
import { isPasswordStrong } from 'utils/validation'
import { AxiosError } from 'axios'
import { useToaster } from 'hooks/useToaster'

export const PATH_SIGN_UP = '/sign-up'

export const SignUpPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const auth = useAuth()
  const toaster = useToaster()

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [notSamePasswordsError, setNotSamePasswordsError] = useState(false)
  const [strongPasswordError, setStrongPasswordError] = useState(false)
  const [capsLockError, setCapsLockError] = useState(false)
  const isSamePasswords =
    password.length > 0 && repeatPassword.length > 0 && password === repeatPassword
  const isPasswordValid = useMemo(() => isPasswordStrong(password), [password])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value)
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLastName(event.target.value)
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setNotSamePasswordsError(false)
    if (strongPasswordError) {
      setStrongPasswordError(!isPasswordValid)
    }
  }
  const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value)
    setNotSamePasswordsError(false)
  }
  const handlePasswordInputBlur = (key: 'password' | 'repeatPassword') => () => {
    if (key === 'password') {
      setStrongPasswordError(!isPasswordValid)
    }
    setNotSamePasswordsError(
      (key === 'password' && repeatPassword.length > 0 && !isSamePasswords) ||
        (key === 'repeatPassword' && password.length > 0 && !isSamePasswords),
    )
  }
  const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setCapsLockError(event.getModifierState('CapsLock'))
  }

  const inviteToken = searchParams.get('ver-token')

  useEffect(() => {
    NProgress.start()
    auth.signUpCheckToken({ inviteToken: String(inviteToken) }).finally(() => NProgress.done())
  }, [inviteToken, auth])

  const signInMutation = useMutation((variables: SignInDto) => auth.signIn(variables, PATH_ROOT), {
    onSuccess: () => {
      NProgress.done()
    },
    onError: (error: AxiosError) => {
      NProgress.done()
      toaster.error(error)
    },
  })

  const signUpMutation = useMutation((variables: SignUpRequest) => auth.signUp(variables), {
    onSuccess: () => {
      NProgress.done()
    },
    onError: (error: AxiosError) => {
      NProgress.done()
      toaster.error(error)
    },
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!isSamePasswords) {
      setNotSamePasswordsError(true)
    }

    if (inviteToken && isSamePasswords) {
      NProgress.start()
      signUpMutation.mutate({
        emailData: { inviteToken: String(inviteToken), password, name, lastName },
        rememberMe: true,
        tokenDest: TokenDest.COOKIE,
      })
    }
  }

  const handleSignUpViaGoogle = useCallback(
    (ssoIdToken) => {
      if (inviteToken) {
        signInMutation.mutate({
          ssoData: {
            ssoIdToken,
            inviteToken,
          },
          rememberMe: true,
          tokenDest: TokenDest.COOKIE,
        })
      }
    },
    [inviteToken, signInMutation],
  )

  const renderPasswordsIndicator = () => {
    return (
      <Icon
        icon="check"
        className={classNames(
          'absolute top-0 right-[4px] text-icon text-state-good transition-opacity opacity-0',
          isSamePasswords && isPasswordValid && 'opacity-100',
        )}
      />
    )
  }

  return (
    <AuthLayout
      className="pt-[22px]"
      bottomInfo={[
        <Link to={PATH_SIGN_IN}>{t('auth.common.haveAccount')}</Link>,
        <span>
          <span className="text-gray-normal">{t('auth.common.haveQuestions')}</span>{' '}
          <a href="https://www.productscience.ai/contact" target="_blank" rel="noopener">
            {t('auth.common.contactUs')}
          </a>
        </span>,
      ]}
    >
      <h1 className="text-center text-normal tracking-wide">{t('auth.common.welcome')}</h1>
      <p className="mt-3 text-center text-small tracking-wide text-gray-normal">
        {t('auth.signUpPage.header')}
      </p>
      <form className="mt-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="sr-only">
            {t('auth.signUpPage.firstName')}
          </label>
          <Input
            value={name}
            onChange={handleNameChange}
            id="name"
            autoComplete="given-name"
            placeholder={t('auth.signUpPage.firstName')}
            required
          />
        </div>
        <div>
          <label htmlFor="last-name" className="sr-only">
            {t('auth.signUpPage.lastName')}
          </label>
          <Input
            value={lastName}
            onChange={handleLastNameChange}
            id="last-name"
            autoComplete="family-name"
            placeholder={t('auth.signUpPage.lastName')}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            {t('auth.common.password')}
          </label>
          <Input
            value={password}
            onChange={handlePasswordChange}
            onKeyUp={checkCapsLock}
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            placeholder={t('auth.common.password')}
            className="pr-[40px]"
            onBlur={handlePasswordInputBlur('password')}
            required
          >
            {renderPasswordsIndicator()}
          </Input>
        </div>
        <div>
          <label htmlFor="repeat-password" className="sr-only">
            {t('auth.common.confirmPass')}
          </label>
          <Input
            value={repeatPassword}
            onChange={handleRepeatPasswordChange}
            onKeyUp={checkCapsLock}
            id="repeat-password"
            name="repeat-password"
            type="password"
            autoComplete="new-password"
            placeholder={t('auth.common.confirmPassword')}
            className="pr-[40px]"
            onBlur={handlePasswordInputBlur('repeatPassword')}
            required
          >
            {renderPasswordsIndicator()}
          </Input>
        </div>
        {capsLockError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.capsWarning')}
          </p>
        )}
        {strongPasswordError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.strongPassword')}
          </p>
        )}
        {notSamePasswordsError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.isNotSamePasswords')}
          </p>
        )}
        <AuthButton
          label={t('auth.signUpPage.button')}
          isDisabled={signUpMutation.isLoading || !isPasswordValid || !isSamePasswords}
        />
      </form>
      <GoogleButton onSuccess={handleSignUpViaGoogle} />
    </AuthLayout>
  )
}
