import { TechType } from 'api/models'

export const getTechName = (tech?: TechType) => {
  switch (tech) {
    case TechType.JVM:
      return 'Java'
    case TechType.SWIFT:
      return 'Swift'
    case TechType.REACT_NATIVE:
      return 'React Native'
    default:
      return String(tech)
  }
}
