import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'

import { getTeamRoleName } from 'utils/getTeamRoleName'
import {
  ApiError,
  AuthenticatedUserDto,
  ProjectRole,
  TeamDto,
  TeamRole,
  TeamUserDto,
  TeamUsersResponse,
} from 'api/models'
import { ButtonIcon, ButtonTextColorVariantEnum } from 'components/Button'
import { ActionModal } from 'components/ActionModal'
import { queryKeys } from 'hooks/useApiQuery'
import { PATH_TEAM } from 'pages/TeamPage'
import { useApi } from 'contexts/di-context'
import { Popover } from 'components/dropdowns/Popover'

interface TeamUserRoleProps extends Pick<TeamUserDto, 'id' | 'teamRole' | 'user' | 'email'> {
  authUserId?: AuthenticatedUserDto['id']
  teamId?: TeamDto['id']
  isSuperAdmin?: boolean
  getActiveOrder: string | null
  isLastTeamAdmin: boolean
}

export const TeamUserRole = ({
  id,
  email,
  user,
  teamRole,
  teamId,
  authUserId,
  isSuperAdmin,
  getActiveOrder,
  isLastTeamAdmin,
}: TeamUserRoleProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { teamUrlName } = useParams() as { teamUrlName: string }
  const navigate = useNavigate()

  const [warningPermissionModal, setWarningPermissionModal] = useState<{
    isOpen: boolean
    nextRole?: TeamRole
  }>({ isOpen: false })
  const handleWarningPermissionModalClose = () => setWarningPermissionModal({ isOpen: false })

  const putTeamUserRoleMutation = useMutation(
    ({ role }: { role: TeamRole }) => api.putTeamUserRole({ teamUrlName, userId: id }, { role }),
    {
      onSuccess: (_, variables) => {
        NProgress.done()
        setWarningPermissionModal({ isOpen: false })
        queryClient.setQueryData<TeamUsersResponse | undefined>(
          queryKeys.teamUsers({ teamUrlName }),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                users: oldData.users.map((item) => {
                  if (item.id === id) {
                    if (variables.role === TeamRole.ADMIN) {
                      return { ...item, teamRole: variables.role, projectRoles: {} }
                    } else if (variables.role === TeamRole.CONTRIBUTOR) {
                      return {
                        ...item,
                        teamRole: variables.role,
                        projectRoles: Object.fromEntries(
                          Object.entries(item.projectRoles).filter(([, score]) => {
                            return score !== ProjectRole.CONTRIBUTOR
                          }),
                        ),
                      }
                    }
                    return { ...item, teamRole: variables.role }
                  }
                  return item
                }),
              }
            }
          },
        )
        queryClient.setQueryData<AuthenticatedUserDto | undefined>(queryKeys.user, (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              roles: {
                ...oldData.roles,
                teams: {
                  ...oldData.roles.teams,
                  [teamId as TeamDto['id']]: variables.role,
                },
              },
            }
          }
        })
        if (id === authUserId && variables.role !== TeamRole.ADMIN && !isSuperAdmin) {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(
          t('teams.teamUser.roleChanged', { user: user ? `${user.name} ${user.lastName}` : email }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleTeamRoleOptionClick = useCallback(
    (role: TeamRole, withWarningPermissionModal?: boolean) => () => {
      if (withWarningPermissionModal) {
        setWarningPermissionModal({ isOpen: true, nextRole: role })
        return
      }
      NProgress.start()
      putTeamUserRoleMutation.mutate({ role })
    },
    [putTeamUserRoleMutation],
  )

  const handleWarningPermissionModalActionClick = () => {
    if (warningPermissionModal.nextRole) {
      handleTeamRoleOptionClick(warningPermissionModal.nextRole)()
    }
  }

  const getUserTeamRoleOptions = useCallback(
    (getTeamRole: TeamRole | null) => {
      const withWarningPermissionModal = id === authUserId
      return [
        {
          name: getTeamRoleName(TeamRole.ADMIN, t),
          isSelect: getTeamRole === TeamRole.ADMIN,
          onClick: handleTeamRoleOptionClick(TeamRole.ADMIN, false),
        },
        {
          name: getTeamRoleName(TeamRole.CONTRIBUTOR, t),
          isSelect: getTeamRole === TeamRole.CONTRIBUTOR,
          onClick: handleTeamRoleOptionClick(TeamRole.CONTRIBUTOR, withWarningPermissionModal),
        },
        {
          name: t('user.none'),
          isSelect: !getTeamRole || getTeamRole === TeamRole.NONE,
          onClick: handleTeamRoleOptionClick(TeamRole.NONE, withWarningPermissionModal),
        },
      ]
    },
    [handleTeamRoleOptionClick, t, id, authUserId],
  )

  return (
    <>
      <Popover
        popoverClassName="relative inline-block"
        menuSections={[{ options: getUserTeamRoleOptions(teamRole as TeamRole | null) }]}
        buttonClass={() =>
          classNames(
            'text-white flex items-center pl-[8px] text-small tracking-wide font-medium transition text-left',
            getActiveOrder === 'teamPermissions' && 'text-white',
          )
        }
        buttonChildren={(open) => (
          <div className={`${open ? 'text-white' : 'text-gray-normal'} flex items-center`}>
            {getTeamRoleName(teamRole, t) || t('user.none')}
            <ButtonIcon
              icon="arrow-drop-d"
              className={classNames(
                'opacity-0 transition-opacity group-hover:opacity-100',
                open && 'opacity-100',
              )}
            />
          </div>
        )}
        withSelect
        show={(open) => open}
      />
      <ActionModal
        isOpen={warningPermissionModal.isOpen}
        title={
          isLastTeamAdmin
            ? t('teams.warningPermissionModal_last.title')
            : t('teams.warningPermissionModal.title')
        }
        text={
          isLastTeamAdmin
            ? t('teams.warningPermissionModal_last.text')
            : t('teams.warningPermissionModal.text')
        }
        onClose={handleWarningPermissionModalClose}
        hiddenCloseButton={isLastTeamAdmin}
        actionButton={
          isLastTeamAdmin
            ? {
                onClick: handleWarningPermissionModalClose,
                children: t('teams.warningPermissionModal_last.button'),
                textColorVariant: ButtonTextColorVariantEnum.Primary,
              }
            : {
                onClick: handleWarningPermissionModalActionClick,
                children: t('teams.warningPermissionModal.button'),
                disabled: putTeamUserRoleMutation.isLoading,
              }
        }
      />
    </>
  )
}
