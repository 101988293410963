import {
  useContentfulEntry,
  TypeMarkdownDocument,
  UseQueryResultResponse,
  isTypeMarkdownDocument,
} from 'hooks/useContentfulEntry'

/**
 * Wrapper around useContentfulEntry that returns MarkdownDocument only.
 * If the result entry from Contentful is not a MarkdownDocument, entry will be empty.
 */
export const useContentfulMarkdownDocument = (
  entryId: string,
): UseQueryResultResponse<TypeMarkdownDocument> => {
  const { entry, ...rest } = useContentfulEntry<TypeMarkdownDocument>(entryId)
  if (!entry || !isTypeMarkdownDocument(entry)) {
    return rest
  }

  return {
    entry,
    ...rest,
  }
}
