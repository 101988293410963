import React, { useMemo, useState } from 'react'
import tw from 'twin.macro'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from 'react-query'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { Userpic } from 'components/Userpic'
import { ButtonIcon, ButtonTextColorVariantEnum } from 'components/Button'
import { ActionModal } from 'components/ActionModal'
import {
  ApiError,
  AuthenticatedUserDto,
  ProjectUserDto,
  ProjectUsersResponse,
  TeamRole,
} from 'api/models'
import { queryKeys } from 'hooks/useApiQuery'

import { ProjectUserRole } from './ProjectUserRole'
import { PATH_TEAM } from 'pages/TeamPage'
import { useApi } from 'contexts/di-context'
import { Menu } from 'components/dropdowns/Menu'

interface ProjectUserProps extends ProjectUserDto {
  isSuperAdmin?: boolean
  isSuperOrTeamOrProjectAdmin: boolean
  authUserId?: AuthenticatedUserDto['id']
  teamUrlName: string
  isLastTeamOrProjectAdmin: boolean
}

export const ProjectUser = ({
  id,
  email,
  user,
  teamRole,
  projectRole,
  lastActive,
  isSuperAdmin,
  isSuperOrTeamOrProjectAdmin,
  authUserId,
  teamUrlName,
  isLastTeamOrProjectAdmin,
}: ProjectUserProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { projectUrlName } = useParams() as { projectUrlName: string }
  const navigate = useNavigate()

  const [resendModalOpened, setResendModalOpened] = useState(false)
  const handleResendLink = () => setResendModalOpened(true)
  const handleResendModalClose = () => setResendModalOpened(false)

  const postProjectUserResendMutation = useMutation(
    () => {
      NProgress.start()
      return api.postProjectUserResend({ projectUrlName, userId: id })
    },
    {
      onSuccess: () => {
        NProgress.done()
        setResendModalOpened(false)
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const handleDeleteClick = () => setDeleteModalOpened(true)
  const handleDeleteModalClose = () => setDeleteModalOpened(false)

  const deleteProjectUserMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteProjectUser({ projectUrlName, userId: id })
    },
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<ProjectUsersResponse | undefined>(
          queryKeys.projectUsers({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return { ...oldData, users: oldData.users.filter((item) => item.id !== id) }
            }
          },
        )
        setDeleteModalOpened(false)
        if (id === authUserId && !isSuperAdmin) {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(
          t('projects.projectUser.deleteModal.success', {
            user: user ? `${user.name} ${user.lastName}` : email,
          }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const getUserMenuOptions = useMemo(() => {
    const options = [{ name: t('projects.projectUser.deleteOption'), onClick: handleDeleteClick }]
    if (!user) {
      options.unshift({ name: t('projects.projectUser.resendOption'), onClick: handleResendLink })
    }
    return options
  }, [user, t])

  const deleteModalProps = useMemo(() => {
    if (id === authUserId) {
      if (isLastTeamOrProjectAdmin) {
        return {
          title: t('projects.projectUser.deleteModal.himself_last.title'),
          text: t('projects.projectUser.deleteModal.himself_last.text'),
          hiddenCloseButton: true,
          actionButton: {
            children: t('projects.projectUser.deleteModal.himself_last.button'),
            textColorVariant: ButtonTextColorVariantEnum.Primary,
            onClick: handleDeleteModalClose,
          },
        }
      }
      return {
        title: t('projects.projectUser.deleteModal.himself.title'),
        text: t('projects.projectUser.deleteModal.himself.text'),
      }
    }
    return {
      title: t('projects.projectUser.deleteModal.general.title'),
      secondaryTitle: user ? `${user.name} ${user.lastName}` : email,
      text: t('projects.projectUser.deleteModal.general.text'),
    }
  }, [t, user, email, id, authUserId, isLastTeamOrProjectAdmin])

  return (
    <View className="group">
      <Column className="flex items-center">
        <div className="mr-[16px]">
          {user ? (
            <Userpic {...user} size={32} disabledMarginRight />
          ) : (
            <div className="w-[32px] h-[32px] rounded-2xl bg-white bg-opacity-[0.06]" />
          )}
        </div>
        <div>
          {user ? (
            <div className="text-white">
              {user.name} {user.lastName}
            </div>
          ) : (
            <div className="text-state-attention">{t('user.pending')}</div>
          )}
          <div>{email}</div>
        </div>
      </Column>
      <Column>
        <ProjectUserRole
          id={id}
          email={email}
          user={user}
          teamRole={teamRole}
          projectRole={projectRole}
          isSuperAdmin={isSuperAdmin}
          isSuperOrTeamOrProjectAdmin={isSuperOrTeamOrProjectAdmin}
          authUserId={authUserId}
          teamUrlName={teamUrlName}
          isLastTeamOrProjectAdmin={isLastTeamOrProjectAdmin}
        />
      </Column>
      <Column>{lastActive && dayjs(lastActive).format('MMMM DD HH:mm:ss')}</Column>
      {isSuperOrTeamOrProjectAdmin && (!teamRole || teamRole === TeamRole.NONE) && (
        <div className="absolute z-10 top-1/2 right-[8px] -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100">
          <Menu
            onSelect={(index: number) => getUserMenuOptions[index].onClick()}
            menuSections={[{ options: getUserMenuOptions }]}
            menuClass="right-[0]"
            buttonChildren={() => <ButtonIcon icon="more" />}
            buttonLabel={(open) =>
              t(`projects.projectUser.${open ? 'closeUserMenuAriaLabel' : 'openUserMenuAriaLabel'}`)
            }
          />
        </div>
      )}
      <ActionModal
        isOpen={resendModalOpened}
        title={t('projects.projectUser.resendModal.title')}
        text={t('projects.projectUser.resendModal.text', { email })}
        onClose={handleResendModalClose}
        actionButton={{
          onClick: postProjectUserResendMutation.mutate,
          children: t('projects.projectUser.resendModal.button'),
          textColorVariant: ButtonTextColorVariantEnum.Primary,
          disabled: postProjectUserResendMutation.isLoading,
        }}
      />
      <ActionModal
        isOpen={deleteModalOpened}
        onClose={handleDeleteModalClose}
        actionButton={{
          onClick: deleteProjectUserMutation.mutate,
          disabled: deleteProjectUserMutation.isLoading,
        }}
        {...deleteModalProps}
      />
    </View>
  )
}

const View = tw.div`
  relative grid items-center
  grid-cols-project-users
  py-[11px] rounded-sm
  transition-colors hover:bg-dark-dark6
  after:(absolute bottom-0 inset-x-[16px] h-px bg-black/30)
`

const Column = tw.div`
  px-[16px] text-gray-normal text-small tracking-wide
`
