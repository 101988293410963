import { KonvaPin } from 'components/ps-chart/local-timeline/annotations/KonvaPin'
import { AnnotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'
import { AnnotationDto, AnnotationPinDto } from 'api/models'
import Konva from 'konva'
import { PinType } from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationsFeatureState } from 'components/ps-chart/PsChartStore'

export class KonvaAnnotation {
  private parentWidth: number
  private parentHeight: number
  actionPin: KonvaPin
  reactionPin: KonvaPin
  connectionLine: Konva.Line

  constructor(
    annotationId: number,
    parentWidth: number,
    parentHeight: number,
    settings: AnnotationsSettings,
    featureState: AnnotationsFeatureState,
  ) {
    this.parentWidth = parentWidth
    this.parentHeight = parentHeight
    this.actionPin = new KonvaPin(parentWidth, parentHeight, settings)
    this.actionPin.setDraggable(featureState.draggable)
    this.reactionPin = new KonvaPin(parentWidth, parentHeight, settings)
    this.reactionPin.setDraggable(featureState.draggable)
    this.actionPin.pinGroup.addEventListener('dragmove', () => {
      if (this.reactionPin.time && this.actionPin.x >= this.reactionPin.x) {
        this.actionPin.x = this.reactionPin.x
      }
    })
    this.reactionPin.pinGroup.addEventListener('dragmove', () => {
      if (this.actionPin.time && this.reactionPin.x <= this.actionPin.x) {
        this.reactionPin.x = this.actionPin.x
      }
    })
    this.connectionLine = new Konva.Line({
      points: [0, 0],
      tension: 1,
      stroke: settings.connectionLineColor,
      strokeWidth: settings.strokeWidth,
    })
  }

  updateState(
    parentWidth: number,
    parentHeight: number,
    annotation: AnnotationDto,
    hoveredType: PinType | null,
    selectedType: PinType | null,
    featureState: AnnotationsFeatureState,
  ) {
    if (parentWidth !== this.parentWidth) {
      this.parentWidth = parentWidth
      this.actionPin.updateWidth(parentWidth)
      this.reactionPin.updateWidth(parentWidth)
    }
    if (parentHeight !== this.parentHeight) {
      this.parentHeight = parentHeight
      this.actionPin.updateHeight(parentHeight)
      this.reactionPin.updateHeight(parentHeight)
    }
    this.actionPin.updateState(
      annotation.action.title,
      annotation.delay,
      KonvaAnnotation.pinTime(annotation.action),
      annotation.action.binding !== undefined && annotation.action.binding.sliceId !== undefined,
      hoveredType === PinType.ACTION,
      selectedType === PinType.ACTION,
      featureState.draggable &&
        (annotation.action.binding === undefined ||
          annotation.action.binding.sliceId === undefined),
    )
    this.reactionPin.updateState(
      annotation.reaction.title,
      annotation.delay,
      KonvaAnnotation.pinTime(annotation.reaction),
      annotation.reaction.binding !== undefined &&
        annotation.reaction.binding.sliceId !== undefined,
      hoveredType === PinType.REACTION,
      selectedType === PinType.REACTION,
      featureState.draggable &&
        (annotation.reaction.binding === undefined ||
          annotation.reaction.binding.sliceId === undefined),
    )
  }

  private static pinTime(annotationPin: AnnotationPinDto): number | null {
    if (!annotationPin.binding) {
      return null
    }
    return annotationPin.binding.time
  }

  removeAndUnsubscribe() {
    this.actionPin.removeAndUnsubscribe()
    this.reactionPin.removeAndUnsubscribe()
    this.connectionLine.remove()
  }
}
