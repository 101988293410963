import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import { TagsInput } from 'react-tag-input-component'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { isEmailValid } from 'utils/validation'
import { useToaster } from 'hooks/useToaster'

import './tag-select.css'
import { useApi } from 'contexts/di-context'

export const ShareLink = ({ teamUrlName }: { teamUrlName?: string }) => {
  const { t } = useTranslation()
  const [emailsList, setEmailsList] = useState<string[]>([])
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [shareLink, setShareLink] = useState<string | null>(null)
  const [isSendInProgress, setIsSendInProgress] = useState(false)
  const mailFormRef = useRef<HTMLDivElement>(null)
  const toaster = useToaster()
  const api = useApi()

  const beforeAddValidate = useCallback(
    (tag: string, existingTags: string[]) => isEmailValid(tag) && !existingTags.includes(tag),
    [],
  )

  const onBlur = useCallback(() => {
    setIsInputFocused(false)
  }, [])

  const onFocus = useCallback((e: FocusEvent) => {
    if (e.target.tagName.toLowerCase() === 'input') {
      setIsInputFocused(true)
    }
  }, [])

  const onRendered = useCallback(() => {
    if (isModalOpened && mailFormRef != null) {
      mailFormRef.current?.getElementsByTagName('input')?.item(0)?.focus()
    }
  }, [isModalOpened, mailFormRef])

  const onSend = useCallback(() => {
    if (teamUrlName == null) {
      return
    }
    setIsSendInProgress(true)
    api
      .postShareUrl(teamUrlName, emailsList)
      .then(() => {
        setIsModalOpened(false)
        setEmailsList([])
      })
      .catch((error) => {
        console.error(error)
        toaster.error(error, 'components.shareWithTeam.cantSendEmails')
      })
      .finally(() => {
        setIsSendInProgress(false)
      })
  }, [teamUrlName, emailsList, api, toaster])

  useEffect(() => {
    if (teamUrlName == null) {
      return
    }
    api
      .getShareUrl(teamUrlName)
      .then((receivedShareUrl) => {
        setShareLink(receivedShareUrl.url)
      })
      .catch((error) => {
        console.error(error)
        toaster.error(error, 'components.shareWithTeam.cantFetchShareUrl')
      })
  }, [teamUrlName, api, toaster])

  const onCopyLink = useCallback(async () => {
    if (shareLink == null) {
      return null
    }
    try {
      await navigator.clipboard.writeText(shareLink)
      toaster.success('components.shareWithTeam.onCopy')
    } catch (error) {
      toaster.error(error, 'components.shareWithTeam.onCopyError')
    }
  }, [shareLink, toaster])

  return (
    <>
      <button
        className="text-gray-normal flex items-center mx-[16px] hover:text-white hover:cursor-pointer"
        onClick={() => setIsModalOpened(true)}
      >
        <Icon icon="share" className="mr-[9px]" />
        <div className="text-[11px]">{t('components.header.shareLink')}</div>
      </button>
      <Modal
        title={t('components.shareWithTeam.shareModalTitle')}
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        onRendered={onRendered}
        additionalButton={{
          children: t('components.shareWithTeam.copyLinkBtn'),
          onClick: onCopyLink,
          disabled: shareLink == null,
        }}
        actionButton={{
          children: t('components.shareWithTeam.sendInviteBtn'),
          onClick: onSend,
          disabled: emailsList.length === 0 || teamUrlName == null || isSendInProgress,
        }}
      >
        <div className="text-small text-gray-normal pb-[16px] pt-[15px]">
          {t('components.shareWithTeam.actionText')}
        </div>
        <div
          onFocus={onFocus}
          onBlur={onBlur}
          ref={mailFormRef}
          className="ps-tags-input pb-[12px] text-small"
        >
          <TagsInput
            value={emailsList}
            onChange={setEmailsList}
            name="emails"
            placeHolder={t('components.shareWithTeam.emailInputPlaceholder')}
            beforeAddValidate={beforeAddValidate}
            disabled={teamUrlName == null || isSendInProgress}
            separators={['Enter', ',', ' ', ';']}
          />
          <div
            className={classNames(
              'mx-[12px] mt-[-1px] h-[1px] transition-colors',
              isInputFocused ? 'bg-electro' : 'bg-transparent',
            )}
          />
        </div>
      </Modal>
    </>
  )
}
