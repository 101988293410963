import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ChartExtensions } from 'components/ps-chart/PsChartExtensions'
import { VideoTab } from 'components/ps-chart/details-view/video/VideoTab'
import { AnnotationsDetails } from './details-view/AnnotationsDetails'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { autorun, runInAction } from 'mobx'
import { PsChartEventsHandler } from 'components/ps-chart/PsChartEventsHandler'

export const PsAnnotations = observer(function PsAnnotations(props: {
  psChartStore: PsChartStore
}) {
  const { psChartStore } = props
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = (width: number) => {
      psChartStore.hState.setWidth(width)
    }
    const wrapperElement = containerRef.current

    if (wrapperElement != null) {
      updateWidth(wrapperElement.clientWidth)
      const resizeObserver = new ResizeObserver(([chartWrapperEntry]) => {
        updateWidth(chartWrapperEntry.target.clientWidth)
      })
      resizeObserver.observe(wrapperElement)

      const autorunDisposer = autorun(
        () => {
          if (psChartStore.traceDataState.threads.length > 0 && psChartStore.isEmpty) {
            runInAction(() => psChartStore.setIsLoaded())
          }
        },
        { name: 'autorun @ PsAnnotations -> psChartStore.setIsLoaded' },
      )

      const eventsHandler = new PsChartEventsHandler(psChartStore, wrapperElement)
      eventsHandler.addEventListeners(wrapperElement)

      return () => {
        autorunDisposer()
        resizeObserver.disconnect()
        eventsHandler.removeEventListeners(wrapperElement)
      }
    }
  }, [psChartStore, containerRef])

  return (
    <div className="flex-grow flex relative overflow-hidden">
      <div className="flex flex-grow flex-col overflow-hidden" ref={containerRef}>
        <ChartExtensions
          psChartStore={psChartStore}
          tracePageParams={psChartStore.chartPageParams}
          showSidebar={false}
          isVideoPreviewOnly={true}
        />
        <VideoContainer psChartStore={psChartStore} />
      </div>
      <AnnotationsDetailsPanel psChartStore={psChartStore} />
    </div>
  )
})

// This component is detached to be NOT observable
export const VideoContainer = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  return (
    <div className="flex flex-grow relative">
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-dark-default">
        <VideoTab videoStore={psChartStore.videoPlayerStore} isFullScreen={true} />
      </div>
    </div>
  )
}

const AnnotationsDetailsPanel = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const barRef = React.useRef<HTMLDivElement>(null)
  useDisablePinchZoom(barRef)

  const annotationsStore = psChartStore.annotationsStore
  const videoStore = psChartStore.videoPlayerStore

  return (
    <div className="w-[360px] bg-dark-dark3 relative" ref={barRef}>
      <AnnotationsDetails annotationsStore={annotationsStore} videoPlayerState={videoStore} />
    </div>
  )
}
