import { autorun } from 'mobx'

import { chartSettings } from './CommitsChartSettings'
import { RAStore } from 'components/regression-analysis/RAStore'

export class CommitsRender {
  private readonly canvas: HTMLCanvasElement
  private readonly context: CanvasRenderingContext2D
  private autorunDisposer?: () => void

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    const context = canvas.getContext('2d')
    if (context == null) {
      throw new Error("Can't get canvas context for CommitsRender")
    }
    this.context = context
    const pixelRatio = window.devicePixelRatio
    this.canvas.width = Math.floor(this.canvas.clientWidth * pixelRatio)
    this.canvas.height = Math.floor(this.canvas.clientHeight * pixelRatio)
  }

  init(raStore: RAStore) {
    if (this.autorunDisposer != null) {
      throw new Error('CommitsRender::init should not be called twice')
    }
    this.autorunDisposer = autorun(() => {
      this.render(raStore)
    })
  }

  render(raStore: RAStore) {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    if (raStore.delaySum == null || raStore.chartScale == null) {
      return
    }

    const columnsDistance = this.canvas.width / raStore.delaySum.durations.length
    const tableHeightTime = raStore.chartScale.scaleStep * chartSettings.stepsCount

    this.context.save()
    this.context.lineWidth = chartSettings.line.width * window.devicePixelRatio
    this.context.strokeStyle = chartSettings.line.color
    this.context.beginPath()

    let pointColumnX = columnsDistance / 2
    let draw = this.context.moveTo.bind(this.context)
    for (const pointDuration of raStore.delaySum.durations) {
      const pointYTime = raStore.chartScale.maxDuration - pointDuration
      const pointYPx = (pointYTime * this.canvas.height) / tableHeightTime

      draw(pointColumnX, pointYPx)

      pointColumnX += columnsDistance
      draw = this.context.lineTo.bind(this.context)
    }

    this.context.stroke()
    this.context.restore()
  }

  destruct() {
    if (this.autorunDisposer != null) {
      this.autorunDisposer()
    }
    this.autorunDisposer = undefined
  }
}
