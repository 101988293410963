import { colors } from 'utils/styles/colors'
import { flagsSettings } from 'components/ps-chart/local-timeline/flags/FlagsSettings'
import { commonTimelineSettings } from 'components/global-timeline/models/CommonTimelineSettings'
import { BorderType, LineType } from 'components/ps-chart/connections-render/NodeRenderData'
import { videoPointerSettings } from 'components/ps-chart/local-timeline/video-pointer/VideoPointerSettings'
import { GhostIndicatorSettings } from 'components/global-timeline/models/GhostIndicatorSettings'
import { annotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'

export const CLUSTER_COLOR = '#656565'
export const CLUSTER_COLOR_DIMMED = '#343436'
export const GLOBAL_ALPHA_DEFAULT_VALUE = 1

export class BasicRendererSettings {
  readonly fontSize = 12

  readonly fontFamily = '"Manrope"'

  readonly blockPaddingX = 2

  readonly minLengthForText = 8
}

export class ThreadsRenderSettings {
  readonly blockHeight = 16

  readonly minHeight = 16

  readonly dividerHeight = 1

  readonly bottomPadding = 15

  readonly sliceBorderWidths = [3, 1]

  readonly minSliceBorderWidth = 5
}

type ConnectionCurveDashes = {
  readonly [key in LineType]?: number[] | undefined
}

export class RenderEngineSettings {
  readonly basicRenderer = new BasicRendererSettings()

  readonly threads = new ThreadsRenderSettings()

  readonly palette = new ChartPalette()

  readonly ghostIndicator = new GhostIndicatorSettings()

  readonly annotation = annotationsSettings

  readonly headerHeight = 32

  readonly connectionCurveWidth = 1

  readonly connectionCurveDashes: ConnectionCurveDashes = {
    [LineType.DISABLED]: [5, 3],
  }

  readonly connectionCurveBackgroundWidth = 5

  readonly connectionsTransparentModeOpacity = 0.5

  readonly foundSliceBorderWidth = 2

  readonly flags = flagsSettings

  readonly measurementStrokeWidth = 1

  readonly measurementColor = colors.gray.normal

  readonly commonTimeline = commonTimelineSettings

  readonly videoPointer = videoPointerSettings
}

export class TimelinePalette {
  readonly delimiterLine = colors.gray.strokeLight
  readonly text = '#5f5f61'
}

export class SliceBordersPalette {
  readonly [BorderType.PRIMARY] = [colors.dark.default, colors.lime];

  readonly [BorderType.UNFOCUSED] = [colors.dark.default, colors.turquoise];

  readonly [BorderType.SECONDARY] = [colors.dark.default, colors.electro];

  readonly [BorderType.ACTIVE] = [colors.dark.default, colors.white]
}

export class ConnectionLinesPalette {
  readonly [LineType.PRIMARY] = colors.lime;

  readonly [LineType.UNFOCUSED] = colors.turquoise;

  readonly [LineType.SECONDARY] = colors.electro;

  readonly [LineType.ACTIVE] = colors.white;

  readonly [LineType.DISABLED] = colors.gray.normal

  readonly background = colors.dark.default
}

export class ThreadPalette {
  readonly background = '#E5E7EB'

  readonly border = '#9CA3AF'

  readonly text = '#FFFFFF'

  readonly delimiter = colors.gray.strokeLight
}

export class SlicePalette {
  readonly borders = new SliceBordersPalette()

  readonly text = '#FFFFFF'

  readonly textInactive = '#A0A0A0'

  readonly frequentColors = [
    '#742532',
    '#551B35',
    '#4F247A',
    '#4B338F',
    '#3C338F',
    '#28387C',
    '#133C58',
    '#183D3F',
    '#14423A',
    '#23482B',
    '#494B20',
    '#6C3623',
    '#543C36',
  ]

  readonly frequentColorsDimmed = [
    '#37272C',
    '#31252D',
    '#30273A',
    '#2F2A3E',
    '#2C2A3E',
    '#282B3B',
    '#242C34',
    '#252C2F',
    '#242D2E',
    '#272E2B',
    '#2F2F28',
    '#362B29',
    '#312C2D',
  ]

  readonly _dimmedColors: Map<string, string>

  get dimmedColors(): ReadonlyMap<string, string> {
    return this._dimmedColors
  }

  readonly normalColors = [
    '#9B3143',
    '#7B274C',
    '#6930A1',
    '#5F41B4',
    '#4C41B4',
    '#3449A2',
    '#1C5882',
    '#266064',
    '#184F45',
    '#2B5A36',
    '#796E34',
    '#87442C',
    '#705048',
  ]

  readonly normalColorsDimmed = [
    '#3F2A2F',
    '#392831',
    '#352A42',
    '#332D46',
    '#2F2D46',
    '#2A2F42',
    '#26323C',
    '#283336',
    '#253030',
    '#29322D',
    '#38362C',
    '#3B2E2B',
    '#363030',
  ]

  constructor() {
    const result = new Map()
    this.frequentColors.forEach((value, index) =>
      result.set(value, this.frequentColorsDimmed[index]),
    )
    this.normalColors.forEach((value, index) => result.set(value, this.normalColorsDimmed[index]))
    this._dimmedColors = result
  }
}

export class ChartPalette {
  readonly timeline = new TimelinePalette()

  readonly thread = new ThreadPalette()

  readonly slice = new SlicePalette()

  readonly headerColor = colors.dark.dark1

  readonly connectionLines = new ConnectionLinesPalette()
  readonly delay = colors.delay
}

export class PsChartSettings {
  private readonly psChartSettingsKey = 'chartSettings.'

  private clusteringMinSliceSizePxKey = 'clusteringMinSliceSizePx'
  private clusteringMinSliceSizePxDefaultValue = 2
  private _clusteringMinSliceSizePx = this.clusteringMinSliceSizePxDefaultValue

  private clusteringStickSizePxKey = 'clusteringStickSizePx'
  private clusteringStickSizePxDefaultValue = 2
  private _clusteringStickSizePx = this.clusteringStickSizePxDefaultValue

  constructor() {
    this.parseClusteringMinSliceSizePx(
      localStorage.getItem(this.psChartSettingsKey + this.clusteringMinSliceSizePxKey),
    )
    this.parseClusteringStickSizePx(
      localStorage.getItem(this.psChartSettingsKey + this.clusteringStickSizePxKey),
    )
  }

  readonly renderEngine = new RenderEngineSettings()

  addEventListeners() {
    window.addEventListener('storage', this.storageListener)
  }

  removeEventListeners() {
    window.removeEventListener('storage', this.storageListener)
  }

  private storageListener = (event: StorageEvent) => this.parseStorageEvent(event)

  readonly zoomChangeFactor = 0.03

  readonly changePosCoefficient = 0.75

  get clusteringMinSliceSizePx(): number {
    return this._clusteringMinSliceSizePx
  }

  get clusteringStickSizePx(): number {
    return this._clusteringStickSizePx
  }

  readonly sliceRectMinWidth = 5

  readonly clusterColor = CLUSTER_COLOR

  readonly localTimelineGridLinesCount = 4 // when settings refactoring, try to move to timeline settings

  readonly stepAnimationTime = 100

  private parseStorageEvent(event: StorageEvent) {
    if (event.key?.startsWith(this.psChartSettingsKey)) {
      const split = event.key.split('.')
      if (split.length > 1) {
        const key: string = split[1]
        if (split.length === 2 && key === this.clusteringMinSliceSizePxKey) {
          this.parseClusteringMinSliceSizePx(event.newValue)
        }
        if (split.length === 2 && key === 'clusteringStickSizePx') {
          this.parseClusteringStickSizePx(event.newValue)
        }
      }
    }
  }

  private parseClusteringMinSliceSizePx(value: string | null) {
    const intValue = parseInt(value ?? '')
    if (isNaN(intValue) || intValue < 1 || intValue > 10) {
      this._clusteringMinSliceSizePx = this.clusteringMinSliceSizePxDefaultValue
      console.log(
        `Couldn't parse ${this.psChartSettingsKey}${this.clusteringMinSliceSizePxKey} value.` +
          `Setting to default = ${this._clusteringMinSliceSizePx}`,
      )
    } else {
      this._clusteringMinSliceSizePx = intValue
      console.log(
        `Setting ${this.psChartSettingsKey}${this.clusteringMinSliceSizePxKey} = ${this._clusteringMinSliceSizePx}`,
      )
    }
  }

  private parseClusteringStickSizePx(value: string | null) {
    const intValue = parseInt(value ?? '')
    if (isNaN(intValue) || intValue < 1 || intValue > 100) {
      this._clusteringStickSizePx = this.clusteringStickSizePxDefaultValue
      console.log(
        `Couldn't parse ${this.psChartSettingsKey}${this.clusteringStickSizePxKey} value.` +
          `Setting to default = ${this._clusteringStickSizePx}`,
      )
    } else {
      this._clusteringStickSizePx = intValue
      console.log(
        `Setting ${this.psChartSettingsKey}${this.clusteringStickSizePxKey} = ${this._clusteringStickSizePx}`,
      )
    }
  }
}
