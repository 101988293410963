import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { Checkbox } from 'components/Checkbox'
import { HeadColumn, TableHeadColumn } from './TableHeadColumn'
import { Trace } from './Trace'
import {
  AssignTraceModal,
  AssignTraceModalContext,
  AssignTraceModalProps,
} from 'components/traces/AssignTraceModal'

import { AppliedFilters } from 'components/traces/AppliedFilters'
import { Trace as ITrace, Traces } from 'api/models'
import { useUserQuery } from 'hooks/useApiQuery'
import { useOrderStorage, OrderStorageKey } from 'hooks/useOrderStorage'
import { getFlowName } from 'utils/getFlowName'

interface TableProps {
  traces?: Traces
  withSelect?: boolean
  withAssign?: boolean
  canWorkWithFlow?: boolean
}

const COLUMNS = [
  'name',
  'flows',
  'createdBy',
  'executedBy',
  'dateCreated',
  'dateUpdated',
  'gitBranch',
  'gitCommit',
  'device',
  'osVersion',
  'appVersion',
  'resolution',
  'flowDelayMs',
  'performancePct',
]

dayjs.extend(isBetween)

export const Table = ({
  traces: tracesData,
  withSelect,
  withAssign,
  canWorkWithFlow,
}: TableProps) => {
  const traces = tracesData?.map((item) => ({
    ...item,
    flows: item.flows.map((flow) => ({
      ...flow,
      flowName: getFlowName(flow.flowName, flow.flowProjectLocalId),
    })),
  }))

  const gridTemplateColumns = `${withSelect ? '32px' : ''} 260px ${
    withAssign ? '280px repeat(2, 180px)' : 'repeat(3, 180px)'
  } repeat(2, 140px) repeat(8, 141px)`

  const { currentSortingType } = useOrderStorage(OrderStorageKey.TRACE_SORTING_KEY)

  const [searchParams] = useSearchParams()

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()

  const { t } = useTranslation()
  const [assignModalState, setAssignModalState] = useState<AssignTraceModalProps>({
    isOpen: false,
  })
  const handleAssignTraceModalSetState = (state: AssignTraceModalProps) =>
    setAssignModalState(state)
  const handleAssignModalClose = () =>
    setAssignModalState((prevState) => ({ ...prevState, isOpen: false }))
  const handleAssignModalDone = () => setSelectedTraces([])

  const [selectedTraces, setSelectedTraces] = useState<number[]>([])

  const handleAllSelectedCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTraces(
      traces && event.target.checked ? traces.map((trace) => trace.projectLocalId) : [],
    )
  }

  const handleTraceCheckboxChange =
    (projectLocalId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSelectedTraces = [...selectedTraces]
      if (event.target.checked) {
        newSelectedTraces.push(projectLocalId)
        setSelectedTraces(newSelectedTraces)
      } else {
        const findIndex = newSelectedTraces.indexOf(projectLocalId)
        if (findIndex > -1) {
          newSelectedTraces.splice(findIndex, 1)
        }
      }
      setSelectedTraces(newSelectedTraces)
    }

  const searchParamObject = Object.fromEntries(searchParams)
  const activeFilters: typeof searchParamObject = {}

  COLUMNS.forEach((option: keyof typeof searchParamObject) => {
    if (searchParamObject.hasOwnProperty(option)) {
      activeFilters[option] = searchParamObject[option]
    }
  })

  const hasFilters = Object.keys(activeFilters).length > 0

  const getActiveOrder = () =>
    (searchParams.get('columnOrder')
      ? searchParams.get('columnOrder')
      : currentSortingType?.columnOrder) || 'dateCreated'

  const isOrderDesc = () => {
    if (searchParams.get('order')) {
      return searchParams.get('order') === 'desc'
    } else if (currentSortingType?.order) {
      return currentSortingType.order === 'desc'
    }
    return false
  }

  const orderedTraces =
    traces &&
    [...traces]?.sort((a, b) => {
      const activeOrder = getActiveOrder() as keyof ITrace
      const multiplier = isOrderDesc() ? -1 : 1

      if (a[activeOrder] === null || a[activeOrder] === undefined) {
        return 1 * multiplier
      }
      if (b[activeOrder] === null || b[activeOrder] === undefined) {
        return -1 * multiplier
      }
      return (
        String(a[activeOrder]).localeCompare(String(b[activeOrder]), undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * multiplier
      )
    })

  const filterTraceAsFlows = (item: ITrace, values: string[]) => {
    let newResult = false
    item.flows.forEach((flow) => {
      if (values.indexOf(flow.flowName) !== -1) {
        newResult = true
      }
    })

    return newResult
  }

  const filterTraceAsDateCreatedUpdated = (
    searchedParams: URLSearchParams,
    item: ITrace,
    filterFieldName: string,
  ) => {
    const getDateFrom = dayjs(searchedParams.get(filterFieldName))
    const getDateTo = dayjs(searchedParams.get(`${filterFieldName}To`))

    if (getDateFrom.isValid() && getDateTo.isValid()) {
      if (getDateFrom.unix() === getDateTo.unix()) {
        return dayjs(item[filterFieldName as keyof ITrace] as string).unix() === getDateFrom.unix()
      }
      return dayjs(item[filterFieldName as keyof ITrace] as string).isBetween(
        getDateFrom,
        getDateTo,
      )
    }

    return false
  }

  const filterTraceAsDefault = (values: string[], item: ITrace, key: string) =>
    values.indexOf(item[key as keyof ITrace] as string) !== -1

  const params = Object.entries(activeFilters)

  let filteredTraces =
    orderedTraces &&
    orderedTraces.filter((item) => {
      if (isUserSuccess && searchParams.get('myTracesOnly') === 'true') {
        return item.createdBy === user?.name
      } else {
        return true
      }
    })

  params.forEach(([key, value]) => {
    const values = (value as string).split(',')
    if (filteredTraces) {
      return (filteredTraces = filteredTraces.filter((item) => {
        if (key === 'flows') {
          return filterTraceAsFlows(item, values)
        } else if (key === 'dateCreated' || key === 'dateUpdated') {
          return filterTraceAsDateCreatedUpdated(searchParams, item, key)
        } else {
          return filterTraceAsDefault(values, item, key)
        }
      }))
    }
  })

  const getTraceTimes = (column: keyof ITrace) => {
    const orderedFilteredTraces =
      filteredTraces &&
      [...filteredTraces]?.sort((a, b) =>
        (a[column] as keyof ITrace) > (b[column] as keyof ITrace) ? 1 : -1,
      )

    const firstTrace = orderedFilteredTraces?.shift()
    const lastTrace = orderedFilteredTraces?.slice(-1).pop()

    return {
      first: firstTrace && (firstTrace[column] as string),
      last: lastTrace && (lastTrace[column] as string),
    }
  }

  const getFilterOptions = (param: keyof ITrace) => {
    const flowsArray: string[] | [] =
      traces?.map((item) => item.flows.map((flow) => flow.flowName)).flat() || []
    const data = param === 'flows' ? flowsArray : traces?.map((item) => item[param] as string)
    return [...Array.from(new Set(data))].filter(Boolean).sort(
      (a, b) =>
        String(a).localeCompare(String(b), undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * 1,
    )
  }

  const handleShowOrder = (column: keyof ITrace) => {
    return !!traces?.find((item) => (column === 'flows' ? item?.flows?.length > 0 : item[column]))
  }

  return (
    <AssignTraceModalContext.Provider value={{ setState: handleAssignTraceModalSetState }}>
      <View>
        <Container>
          <Head gridTemplateColumns={gridTemplateColumns}>
            {withSelect && (
              <HeadColumn className="flex">
                <Checkbox
                  checked={selectedTraces.length === traces?.length && traces?.length > 0}
                  onChange={handleAllSelectedCheckboxChange}
                />
              </HeadColumn>
            )}
            {COLUMNS.map((column) => {
              const isDateFilter = column === 'dateCreated' || column === 'dateUpdated'

              return {
                param: column,
                name: t(`traces.columns.${column}`),
                showOrder: handleShowOrder(column as keyof ITrace),
                filter: {
                  param: column,
                  name: `${t('traces.filters.by')} ${t(
                    `traces.filters.columnsBy.${column}`,
                  ).toLocaleLowerCase()}`,
                  options: getFilterOptions(column as keyof ITrace),
                },
                dateFilter: isDateFilter,
                traceTimes: isDateFilter ? getTraceTimes(column as keyof ITrace) : null,
              }
            }).map((item, index) => (
              <TableHeadColumn
                {...item}
                getActiveOrder={getActiveOrder}
                isOrderDesc={isOrderDesc}
                key={String(index)}
              />
            ))}
          </Head>
          {((withAssign && selectedTraces.length > 0) || hasFilters) && (
            <AppliedFilters
              traces={traces}
              selectedTraces={selectedTraces}
              withAssign={withAssign}
              hasFilters={hasFilters}
              activeFilters={activeFilters}
            />
          )}
          {filteredTraces?.map((item) => (
            <Trace
              {...item}
              gridTemplateColumns={gridTemplateColumns}
              isSelect={selectedTraces.indexOf(item.projectLocalId) !== -1}
              onCheckboxChange={handleTraceCheckboxChange(item.projectLocalId)}
              withSelect={withSelect}
              withAssign={withAssign}
              hasSelectedTraces={selectedTraces.length > 0}
              getActiveOrder={getActiveOrder}
              canWorkWithFlow={canWorkWithFlow}
              key={String(item.projectLocalId)}
            />
          ))}
        </Container>
        <AssignTraceModal
          {...assignModalState}
          onClose={handleAssignModalClose}
          onDone={handleAssignModalDone}
        />
      </View>
    </AssignTraceModalContext.Provider>
  )
}

const View = styled.div`
  position: relative;
  overflow-x: auto;
  flex: 1;
`

const Container = styled.div`
  width: fit-content;
  padding-right: 16px;
  padding-left: 93px;
`

const Head = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  padding: 0 16px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.dark.dark3};
`
