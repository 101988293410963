import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Input } from 'components/Input'
import { Textarea } from 'components/Textarea'
import { PATH_FLOW } from 'pages/FlowPage'
import { ApiError, AppStartType, FlowDto } from 'api/models'
import { queryKeys } from 'hooks/useApiQuery'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'

interface FlowFormModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  flowModel?: Pick<FlowDto, 'name' | 'description' | 'appStartType'>
  isEdit?: boolean
  onlyDomainEdit?: boolean
  onDeleteFlowClick?: () => void
}

const NAME_VALUE_MIN_LENGTH = 0
const NAME_VALUE_MAX_LENGTH = 35
const DESCRIPTION_VALUE_MAX_LENGTH = 240

export const FlowFormModal = ({
  isOpen,
  onClose,
  isEdit,
  onDeleteFlowClick,
  flowModel,
}: FlowFormModalProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const getValues = useCallback(() => {
    return {
      name: flowModel?.name || '',
      description: flowModel?.description || '',
    }
  }, [flowModel])

  const [nameValue, setNameValue] = useState(getValues().name)
  const [descriptionValue, setDescriptionValue] = useState(getValues().description)

  useEffect(() => {
    setNameValue(getValues().name)
    setDescriptionValue(getValues().description)
  }, [getValues, isOpen])

  const handleNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNameValue(event.target.value)
  const handleDescriptionValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescriptionValue(event.target.value)

  const postFlowMutation = useMutation(
    () => {
      NProgress.start()
      return api.postFlow({ projectUrlName }, { name: nameValue, description: descriptionValue })
    },
    {
      onSuccess: (data) => {
        NProgress.done()
        onClose()
        queryClient.setQueryData<FlowDto[] | undefined>(
          queryKeys.flows({ projectUrlName }),
          (existingFlows = []) => {
            return [...existingFlows, data]
          },
        )

        navigate(
          generatePath(PATH_FLOW, {
            projectUrlName,
            flowProjectLocalId: String(data.projectLocalId),
          }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const putFlowMutation = useMutation(
    () => {
      NProgress.start()
      return api.putFlow(
        { projectUrlName, flowProjectLocalId },
        {
          name: nameValue,
          description: descriptionValue,
          appStartType: flowModel?.appStartType as AppStartType,
        },
      )
    },
    {
      onSuccess: (data) => {
        NProgress.done()
        onClose()
        queryClient.setQueryData<FlowDto | undefined>(
          queryKeys.flow({ projectUrlName, flowProjectLocalId }),
          (oldData) => {
            if (oldData) {
              return { ...oldData, ...data }
            }
          },
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const isValid = useMemo(() => {
    return (
      nameValue.length > NAME_VALUE_MIN_LENGTH &&
      nameValue.length <= NAME_VALUE_MAX_LENGTH &&
      descriptionValue.length <= DESCRIPTION_VALUE_MAX_LENGTH
    )
  }, [nameValue, descriptionValue])

  return (
    <Modal
      title={isEdit ? t('flows.flowFormModal.editTitle') : t('flows.flowFormModal.addTitle')}
      isOpen={isOpen}
      onClose={onClose}
      additionalButton={
        isEdit
          ? { children: t('flows.flowFormModal.deleteButton'), onClick: onDeleteFlowClick }
          : {}
      }
      actionButton={{
        children: isEdit ? t('flows.flowFormModal.editButton') : t('flows.flowFormModal.addButton'),
        onClick: isEdit ? putFlowMutation.mutate : postFlowMutation.mutate,
        disabled: (isEdit ? putFlowMutation.isLoading : postFlowMutation.isLoading) || !isValid,
      }}
    >
      <div className="mt-[16px]">
        <Input
          value={nameValue}
          onChange={handleNameValueChange}
          placeholder={t('flows.flowFormModal.flowName')}
          maxLength={NAME_VALUE_MAX_LENGTH}
          inModal
        />
        <Textarea
          value={descriptionValue}
          onChange={handleDescriptionValueChange}
          placeholder={t('flows.flowFormModal.description')}
          maxLength={DESCRIPTION_VALUE_MAX_LENGTH}
          inModal
        />
      </div>
    </Modal>
  )
}
