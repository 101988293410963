import React from 'react'
import classNames from 'classnames'

import { Icon } from 'components/Icon'

export interface CheckboxProps {
  children?: React.ReactNode
  id?: string
  checked: boolean
  name?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  labelClassName?: string
}

export const Checkbox = ({
  children,
  id,
  checked,
  name,
  onChange,
  className,
  labelClassName,
}: CheckboxProps) => {
  const inputProps = { id, checked, name, onChange }
  return (
    <div className={classNames('relative flex items-center', className)}>
      <div className="relative w-[16px] h-[16px] mr-[8px] flex items-center justify-center">
        <input
          type="checkbox"
          {...inputProps}
          className="peer absolute w-full h-full opacity-0 z-[2] cursor-pointer"
        />
        <div className="w-full h-full border border-gray-faded rounded-sm peer-checked:border-electro peer-checked:bg-electro transition-colors peer-focus:outline peer-focus:outline-offset-1 peer-focus:outline-electro" />
        <Icon
          icon="check"
          className="absolute text-icon text-white opacity-0 transition-opacity peer-checked:opacity-100 z-[1]"
        />
      </div>
      {children && (
        <label
          className={classNames(
            'text-small tracking-wide text-gray-normal select-none flex-1',
            labelClassName,
          )}
          htmlFor={id}
        >
          {children}
        </label>
      )}
    </div>
  )
}

export const CheckboxV2 = ({
  children,
  id,
  checked,
  name,
  onChange,
  className,
  labelClassName,
}: CheckboxProps) => {
  const inputProps = { id, checked, name, onChange }
  return (
    <div className={classNames('', className)}>
      <div className="relative w-[16px] h-[16px] flex items-center justify-center"></div>
      <label
        className={classNames(
          'text-small tracking-wide text-gray-normal select-none flex hover:cursor-pointer',
          labelClassName,
        )}
        htmlFor={id}
      >
        <div className="relative w-[16px] h-[16px] items-center flex justify-center">
          <input
            type="checkbox"
            {...inputProps}
            className="peer absolute w-full h-full opacity-0 z-[2] cursor-pointer"
          />
          <div className="w-full h-full border border-gray-faded rounded-sm peer-checked:border-electro peer-checked:bg-electro transition-colors peer-focus:outline peer-focus:outline-offset-1 peer-focus:outline-electro" />
          <Icon
            icon="check"
            className="absolute text-icon text-white opacity-0 transition-opacity peer-checked:opacity-100 z-[1]"
          />
        </div>
        <span className="ml-[8px]">{children}</span>
      </label>
    </div>
  )
}
