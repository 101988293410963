import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Rect } from 'components/ps-chart/models/helper-types'

export const addConnectionCurve = (
  childRect: Rect,
  parentRect: Rect,
  connectionType: ConnectionType,
  curvePath: Path2D,
  connectionCurveOuterWidth: number,
  connectionCurveInnerWidth: number,
) => {
  const minCurveWidth = connectionCurveOuterWidth
  const minCurveHeight = childRect.h

  const curveWidthShift = connectionCurveOuterWidth / 2
  const curveInnerWidthShift = connectionCurveInnerWidth / 2

  const parentRectRightBorder = parentRect.x + parentRect.w
  const childRectRightBorder = childRect.x + childRect.w
  const parentRectBottomBorder = parentRect.y + parentRect.h
  const parentRectBottomCurvePoint = parentRectBottomBorder - curveWidthShift
  const childRectBottomBorder = childRect.y + childRect.h
  const childRectBottomCurvePoint = childRectBottomBorder - curveWidthShift

  if (childRect.x - parentRectRightBorder >= minCurveWidth) {
    /**
     * The parent rect is on the LEFT side of the child one,
     *  and the distance between child's LEFT border and parent's RIGHT border is big enough.
     *
     * The measure looks like:
     *  PPP◄──►CCC
     *
     * The curve will look like:
     *  PPP──┐       |  PPP─────CCC  |       ┌──CCC
     *       └──CCC  |               |  PPP──┘
     *
     */
    const lineXCenter = parentRectRightBorder + (childRect.x - parentRectRightBorder) / 2

    curvePath.moveTo(parentRectRightBorder, parentRectBottomCurvePoint)
    curvePath.lineTo(lineXCenter, parentRectBottomCurvePoint)
    curvePath.lineTo(lineXCenter, childRectBottomCurvePoint)
    curvePath.lineTo(childRect.x, childRectBottomCurvePoint)
  } else if (parentRect.x - childRectRightBorder >= minCurveWidth) {
    /**
     * The parent rect is on the RIGHT side of the child,
     *  and the distance between parent's RIGHT border and child's LEFT border is big enough.
     *
     * The measure looks like:
     *  CCC◄──►PPP
     *
     * The curve will look like:
     *  CCC──┐       |  CCC─────PPP  |       ┌──PPP
     *       └──PPP  |               |  CCC──┘
     */
    const lineXCenter = childRectRightBorder + (parentRect.x - childRectRightBorder) / 2

    curvePath.moveTo(parentRect.x, parentRectBottomCurvePoint)
    curvePath.lineTo(lineXCenter, parentRectBottomCurvePoint)
    curvePath.lineTo(lineXCenter, childRectBottomCurvePoint)
    curvePath.lineTo(childRectRightBorder, childRectBottomCurvePoint)
  } else if (Math.abs(parentRect.y - childRect.y) > minCurveHeight) {
    /**
     * We will draw a connection line only for the cases
     *  when two slices have enough distance at least horizontally or vertically.
     *
     * Also, after the two horizontal distance checks before
     *  the relative horizontal positions in this branch can be only:
     *   PPP     |  PPP   |  PPPPPP  |   PPP  |     PPP
     *      CCC  |   CCC  |   CCC    |  CCC   |  CCC
     */

    if (childRect.x - parentRectRightBorder > 0) {
      /**
       * The parent rect is on the LEFT side of the child,
       *  and there is a non-zero distance between child's LEFT border and parent's RIGHT border.
       *
       * The measure looks like:
       *  PPP◄─►CCC
       *
       * The curve will look like:
       *  PPP      |    ┌─CCC
       *    │      |    │
       *    └─CCC  |  PPP
       */
      curvePath.moveTo(childRect.x, childRectBottomCurvePoint)
      const parentRectRightXCurveStart = parentRectRightBorder - curveInnerWidthShift
      curvePath.lineTo(parentRectRightXCurveStart, childRectBottomCurvePoint)

      if (parentRect.y < childRect.y) {
        curvePath.lineTo(parentRectRightXCurveStart, parentRectBottomBorder)
      } else {
        curvePath.lineTo(parentRectRightXCurveStart, parentRect.y)
      }
    } else if (parentRect.x - childRectRightBorder > 0) {
      /**
       * The parent rect is on the RIGHT side of the child,
       *  and there is a non-zero distance between parent's LEFT border and child's RIGHT border.
       *
       * The measure looks like:
       * CCC◄─►PPP
       *
       * The curve will look like:
       *  CCC      |    ┌─PPP
       *    │      |    │
       *    └─PPP  |  CCC
       */

      curvePath.moveTo(parentRect.x, parentRectBottomCurvePoint)
      const childRectRightXCurveStart = childRectRightBorder - curveInnerWidthShift
      curvePath.lineTo(childRectRightXCurveStart, parentRectBottomCurvePoint)

      if (parentRect.y < childRect.y) {
        curvePath.lineTo(childRectRightXCurveStart, childRect.y)
      } else {
        curvePath.lineTo(childRectRightXCurveStart, childRectBottomBorder)
      }
    } else {
      if (connectionType === ConnectionType.TREE) {
        /**
         * The parent rect and the child are from same chart tree
         *
         * The curve will look like:
         *  PPP   |  PPP
         *   │    |   │
         *   CCC  |   C
         */
        const childMiddleBorderCurveStart =
          childRect.w <= minCurveWidth
            ? childRect.x + childRect.w / 2
            : childRect.x + curveWidthShift
        curvePath.moveTo(childMiddleBorderCurveStart, childRect.y)
        curvePath.lineTo(childMiddleBorderCurveStart, parentRectBottomBorder)
      } else if (connectionType === ConnectionType.ASYNC) {
        /**
         * The ASYNC connection will be look like regular connection but in opposite direction
         * so parent now is a child and child become async parent (ASC)
         *
         * The curve will look like:
         *    ASC   |  ASC
         *     │    |   │
         *   CCC    |   C
         */
        const asyncParentRect = childRect
        const asyncChildRect = parentRect
        const middleBorderCurveStart =
          asyncChildRect.w <= minCurveWidth
            ? asyncChildRect.x + curveWidthShift
            : asyncChildRect.x + asyncChildRect.w - curveWidthShift
        curvePath.moveTo(middleBorderCurveStart, asyncChildRect.y)
        curvePath.lineTo(middleBorderCurveStart, asyncParentRect.y + asyncParentRect.h)
      } else {
        /**
         * The parent rect and the child one have a crossing horizontally
         *
         * The curve will look like:
         *  PPP   |  PPPPPP  |   PPP
         *   │    |   │      |   │
         *   CCC  |   CCC    |  CCC
         */
        const childLeftBorderCurveStart = childRect.x + curveInnerWidthShift
        const parentLeftBorderCurveStart = parentRect.x + curveInnerWidthShift
        const curveX = Math.max(childLeftBorderCurveStart, parentLeftBorderCurveStart)

        if (parentRect.y < childRect.y) {
          curvePath.moveTo(curveX, childRect.y)
          curvePath.lineTo(curveX, parentRectBottomBorder)
        } else {
          curvePath.moveTo(curveX, parentRect.y)
          curvePath.lineTo(curveX, childRectBottomBorder)
        }
      }
    }
  }
}
