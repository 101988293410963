import { makeAutoObservable } from 'mobx'
import { getQuantile } from 'components/ps-chart/dev-monitor/utils'

export class RenderingMeasuringStore {
  isStarted = false
  private readonly measures: number[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get renderTime(): string {
    return getQuantile(Array.from(this.measures), 0.9).toFixed(2)
  }

  get measuresCount(): number {
    return this.measures.length
  }

  start() {
    this.clear()
    this.isStarted = true
  }

  stop() {
    this.isStarted = false
  }

  clear() {
    this.measures.splice(0, this.measures.length)
  }

  addMeasure(measure: number) {
    this.measures.push(measure)
  }
}
