import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { FpsMeter } from './FpsMeter'
import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHotKeys } from '../hooks/useHotKeys'
import { useDi } from 'contexts/di-context'
import { PerfMeasurer } from './PerfMeasurer'

export const DevMonitor = observer(function DevMonitor({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const di = useDi()
  const exceptionHandler = di.compositionRoot.exceptionHandler
  const [isShowingFPS, setIsShowingFPS] = useState(!di.compositionRoot.isProdOrBeta)

  const toggleFPSMeter = () => {
    setIsShowingFPS(!isShowingFPS)
  }
  useHotKeys(['ctrlKey', 'altKey', 'shiftKey', 'KeyA'], toggleFPSMeter)

  const onResolutionWidthClick = useCallback(() => {
    exceptionHandler.handleMessage(`Test message ${Date.now()}`)
  }, [exceptionHandler])

  const onResolutionHeightClick = useCallback(() => {
    const err = new Error(`Test exception ${Date.now()}`)
    exceptionHandler.handleException(err)
  }, [exceptionHandler])

  return (
    <>
      {isShowingFPS && (
        <>
          <FpsMeter />
          <div className="pr-8 text-right text-small">
            <span onDoubleClick={onResolutionWidthClick}>{psChartStore.hState.width}</span>
            <span>x</span>
            <span onDoubleClick={onResolutionHeightClick}>{psChartStore.vState.height}</span>
          </div>
          <PerfMeasurer psChartStore={psChartStore} />
        </>
      )}
    </>
  )
})
