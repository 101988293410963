import { useTranslation } from 'react-i18next'
import { ButtonIcon } from 'components/Button'
import { Dispatch, SetStateAction } from 'react'
import { Menu } from 'components/dropdowns/Menu'
import { useIsReadOnlyProject } from 'utils/feature-flags'

export const VideoContextMenu = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}: {
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const isReadOnlyProject = useIsReadOnlyProject()
  return (
    <Menu
      className="w-8 text-left"
      onSelect={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
      menuSections={[{ options: [{ name: 'Delete video', isDisabled: isReadOnlyProject }] }]}
      menuClass="z-0 absolute right-[-210px] top-[-10px]"
      buttonChildren={() => <ButtonIcon icon="more" />}
      buttonClass={() => 'rounded-sm'}
      buttonLabel={(open) =>
        open
          ? t('psChart.video.deleteVideoModalClosedAriaLabel')
          : t('psChart.video.deleteVideoModalOpenAriaLabel')
      }
    />
  )
}
