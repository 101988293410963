import { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { Icon } from 'components/Icon'
import { PlaybackRate, VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { LoopVideoButton } from 'components/ps-chart/details-view/video/LoopVideoButton'
import { PlayVideoButton } from 'components/ps-chart/details-view/video/PlayVideoButton'
import { EmptyFramePreview } from './EmptyFramePreview'
import { useObserveVideoSize } from './VideoSizeObserver'
import { DeleteVideoModal } from './DeleteVideoModal'
import { VideoContextMenu } from './VideoContextMenu'

import { FEATURE_FLAGS, useFeatureFlag, useIsReadOnlyProject } from 'utils/feature-flags'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

export interface VideoPlayerProps {
  videoStore: VideoPlayerStore
  isFullScreen: boolean
}

export const VideoPlayer = observer(function VideoPlayer({
  videoStore,
  isFullScreen,
}: VideoPlayerProps) {
  const videoBox = useRef<HTMLDivElement>(null)
  const { videoWidth } = useObserveVideoSize(videoBox)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)

  const isReadOnlyProject = useIsReadOnlyProject()

  useEffect(() => {
    const videoBoxEl: HTMLDivElement | null = videoBox.current
    if (videoBoxEl === null) {
      return
    }
    videoBoxEl.innerHTML = ''
    if (videoStore.tag !== null) {
      videoBoxEl.appendChild(videoStore.tag)
    }
  }, [videoStore.tag])

  const handlePlayButtonPress = useCallback(() => {
    if (videoStore.isVideoPlaying) {
      videoStore.pauseVideo()
    } else {
      videoStore.playVideo()
    }
  }, [videoStore])

  const handleToggleDeleteVideoModal = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
  }, [isDeleteModalOpen])

  if (isFullScreen) {
    return (
      <>
        <div className="w-full relative">
          <div className="flex flex-col items-center justify-center h-[100%]">
            <div className="relative w-[100%] flex flex-col items-center justify-center">
              <VideoContainer
                className={`[&>video]:h-fullScreenVideo`}
                ref={videoBox}
                isFullScreen={isFullScreen}
              />
              <EmptyFramePreview videoState={videoStore} videoWidth={videoWidth} />
              <div className="mt-4 pl-8 flex justify-center space-x-2">
                <SlowdownVideoButton videoStore={videoStore} />
                <PlayVideoButton
                  isPlaying={videoStore.isVideoPlaying}
                  onPress={handlePlayButtonPress}
                />
                <LoopVideoButton
                  mode={videoStore.loopMode}
                  onPress={videoStore.toggleVideoLoopMode}
                />
                <VideoContextMenu
                  isDeleteModalOpen={isDeleteModalOpen}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                />
              </div>
            </div>
          </div>
        </div>
        <DeleteVideoModal
          videoStore={videoStore}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      </>
    )
  }

  return (
    <>
      <div className="w-full relative px-[16px]">
        {annotationsEnabled === false && !isReadOnlyProject && (
          <div className="h-[15px]">
            <Icon
              icon="bin"
              className="absolute right-[4px] h-[20px] text-[32px] top-0 cursor-pointer hover:text-electro tracking-tight "
              onClick={handleToggleDeleteVideoModal}
            />
          </div>
        )}
        <div className="flex flex-col justify-center h-[100%]">
          <div className=" relative flex flex-col items-center">
            <VideoContainer className="[&>video]:h-video" ref={videoBox} isFullScreen={false} />
            <EmptyFramePreview videoState={videoStore} videoWidth={videoWidth} />
            <div className="mt-4 flex justify-center">
              <SlowdownVideoButton videoStore={videoStore} />
              <PlayVideoButton
                isPlaying={videoStore.isVideoPlaying}
                onPress={handlePlayButtonPress}
              />
              <LoopVideoButton
                mode={videoStore.loopMode}
                onPress={videoStore.toggleVideoLoopMode}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteVideoModal
        videoStore={videoStore}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
    </>
  )
})

const VideoContainer = styled.div<{ isFullScreen: boolean }>`
  z-index: 1;
  overflow: hidden;
  border-radius: 20px;

  @media (min-height: 0px) and (max-height: 400px) {
    border-radius: 4px;
  }
  @media (min-height: 400px) and (max-height: 600px) {
    border-radius: 10px;
  }
  @media (min-height: 600px) and (max-height: 670px) {
    border-radius: 12px;
  }

  video {
    min-height: 124px;
    max-height: ${(props) => (props.isFullScreen ? 'unset !important' : '')};
  }
`

export const SlowdownVideoButton = observer(function SlowdownVideoButton({
  videoStore,
}: {
  videoStore: VideoPlayerStore
}) {
  const videoButtonSlowdownTextVariant = (
    playbackRate: PlaybackRate,
  ): ButtonTextColorVariantEnum => {
    return playbackRate === PlaybackRate.NORMAL
      ? ButtonTextColorVariantEnum.Muted
      : ButtonTextColorVariantEnum.Primary
  }
  return (
    <Button
      className="w-16"
      textColorVariant={videoButtonSlowdownTextVariant(videoStore.playbackRate)}
      variant={ButtonVariantEnum.Text}
      isSmall
      onClick={() => videoStore.togglePlaybackRate()}
    >
      0.5x
    </Button>
  )
})
