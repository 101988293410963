import hljs from 'highlight.js/lib/core'
import React, { HTMLProps } from 'react'
import kotlin from 'highlight.js/lib/languages/kotlin'
import plaintext from 'highlight.js/lib/languages/plaintext'
import bash from 'highlight.js/lib/languages/bash'
import properties from 'highlight.js/lib/languages/properties'
import groovy from 'highlight.js/lib/languages/groovy'
import 'highlight.js/styles/github-dark.css'
import { Icon } from 'components/Icon'
import { useToaster } from 'hooks/useToaster'
import { MdCodeStyle } from 'components/guide/markdown/MarkdownConfig'

hljs.registerLanguage('kotlin', kotlin)
hljs.registerLanguage('groovy', groovy)
hljs.registerLanguage('plaintext', plaintext)
hljs.registerLanguage('properties', properties)
hljs.registerLanguage('bash', bash)
hljs.registerAliases('proguard', { languageName: 'plaintext' })

export interface MdCodeBlockProps extends HTMLProps<HTMLDivElement> {
  code: string
  codeLang: string | undefined | null
  codeTitle: string | undefined | null
  codeStyle: MdCodeStyle
}

export const MdCode = (props: MdCodeBlockProps) => {
  const toaster = useToaster()
  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigator.clipboard.writeText(props.code).then(() => {
      toaster.info('guidePage.codeCopiedToClipboard')
    })
  }

  const { codeStyle, code, codeLang, codeTitle, ...rest } = props
  const highlightedHtml = hljs.highlight(code, { language: codeLang ?? 'plaintext' })
  return (
    <div {...rest} className="relative">
      <Icon
        icon="copy"
        className={`absolute top-2.5 right-2.5 ${codeStyle.iconClassName}`}
        onClick={handleCopyClick}
      />
      <div className={codeStyle.wrapperClassName}>
        {codeTitle && <code className={`block ${codeStyle.titleClassName}`}>{codeTitle}</code>}
        <code
          className="whitespace-pre-wrap break-all"
          dangerouslySetInnerHTML={{ __html: highlightedHtml.value }}
        />
      </div>
    </div>
  )
}
