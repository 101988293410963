import {
  AnnotationDto,
  AnnotationSliceSuggestionDto,
  ApkSupportIssueDtoOut,
  ApkSupportIssueReq,
  AppBuildAndRunStatusDto,
  AuthenticatedUserDto,
  ChartPageParams,
  CheckInviteTokenReqDto,
  CheckInviteTokenResDto,
  CheckRecoveryTokenRequest,
  ChoreographerPathsDto,
  ChoreographerReq,
  ComputationView,
  CreateFlowDto,
  CreateFreeTrialProjectReqDto,
  CreateNamedLinkRequestData,
  DeleteEntityReq,
  FlagPostReq,
  FlagPutReq,
  FlowComputationReq,
  FlowDto,
  FlowReq,
  Flows,
  FlowTraceReq,
  FreeTrialExtensionDto,
  GetSubmittedFreeTrialOnboardingRequestsResDto,
  ImageDataDto,
  InviteUserToProjectDto,
  InviteUserToTeamDto,
  InviteWhitelistReq,
  NamedLinkDto,
  NO_AIRTABLE_CONNECTION_RECORD_CODE,
  NO_AIRTABLE_PROJECT_STATUS_CODE,
  ProjectDto,
  ProjectDtoForm,
  ProjectReq,
  ProjectRole,
  ProjectSummaryDto,
  ProjectUserDto,
  ProjectUsersResponse,
  RecoveryTokenRequest,
  RequestFreeTrialReqDto,
  ResendEmailVerificationTokenRequest,
  ResetPasswordRequest,
  SendShareUrlDto,
  SignInDto,
  SignInResDto,
  SignUpFreeTrialReqDto,
  SignUpRequest,
  SliceSuggestionsReq,
  SubmitFreeTrialOnboardingRequestReqDto,
  SubmitFreeTrialOnboardingRequestResDto,
  TeamDto,
  TeamDtoForm,
  TeamReq,
  TeamRole,
  TeamsArray,
  TeamShareUrlDto,
  TeamUserDto,
  TeamUsersResponse,
  Trace,
  TraceDataDto,
  TracePageParams,
  TraceReq,
  Traces,
  TraceVideoMetadataDto,
  UpdateFlowDto,
  UserIdReq,
  UserQuestionnaireDto,
  UserSettingsDto,
} from './models'
import { Axios, AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Flag } from 'components/ps-chart/models/Flag'
import { isAxiosRequestCancelled } from 'utils/axios'
import { getExtensionRequestUrl, VERSION_PREFIX } from 'api/urls'

const SIGN_IN = '/sign-in'
const SIGN_UP = '/sign-up'
const SIGN_UP_SSO = '/sign-up-sso'
const SIGN_UP_CHECK = `${SIGN_UP}/check`
const INVITE_WHITELIST = '/request-invite'
const RECOVERY_TOKEN = '/recovery-token'
const RESET_PASSWORD = '/reset-password'
const RESET_PASSWORD_CHECK = `${RESET_PASSWORD}/check`
const REQUEST_FREE_TRIAL = '/request-free-trial'
const SIGN_UP_FREE_TRIAL = '/sign-up-free-trial'

export class Api {
  readonly host: string

  private readonly path: string

  private readonly axios: Axios

  private originalMethods: Partial<this> = {}

  constructor(host: string, axios: Axios) {
    this.host = host
    this.path = `${host}${VERSION_PREFIX}`
    this.axios = axios
  }

  public overrideMethod<T extends keyof this>(methodName: T, callback: typeof this[T]): void {
    this.resetOverrideMethod(methodName)
    this.originalMethods[methodName] = this[methodName]
    this[methodName] = callback
  }

  public resetOverrideMethod<T extends keyof this>(methodName: T): void {
    const originalMethod = this.originalMethods[methodName]
    if (originalMethod) {
      this[methodName] = originalMethod as this[T] // You can remove this[T] after we migrate to TS 4.9.5
    }
  }

  urlsNotRequireAuth(): string[] {
    return [
      this.path + SIGN_IN,
      this.path + SIGN_UP,
      this.path + SIGN_UP_SSO,
      this.path + SIGN_UP_CHECK,
      this.path + INVITE_WHITELIST,
      this.path + RECOVERY_TOKEN,
      this.path + RESET_PASSWORD,
      this.path + RESET_PASSWORD_CHECK,
      this.path + REQUEST_FREE_TRIAL,
      this.path + SIGN_UP_FREE_TRIAL,
    ]
  }

  getAuthCheck(): Promise<void> {
    return this.axios
      .get<void, void>(`${this.path}/auth-check`)
      .catch((error) => this.rejectOnError(error))
  }

  postSignIn(data: SignInDto): Promise<SignInResDto> {
    return this.axios
      .post<SignInResDto>(`${this.path}${SIGN_IN}`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postInviteWhitelist(data: InviteWhitelistReq): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${INVITE_WHITELIST}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUpCheckToken(data: CheckInviteTokenReqDto): Promise<CheckInviteTokenResDto> {
    return this.axios
      .post<CheckInviteTokenResDto>(`${this.path}${SIGN_UP_CHECK}`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUp(data: SignUpRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${SIGN_UP}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postRequestFreeTrial(data: RequestFreeTrialReqDto): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${REQUEST_FREE_TRIAL}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUpFreeTrial(data: SignUpFreeTrialReqDto): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${SIGN_UP_FREE_TRIAL}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postRecoveryToken(data: RecoveryTokenRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RECOVERY_TOKEN}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postResetPasswordCheckToken(data: CheckRecoveryTokenRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RESET_PASSWORD_CHECK}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postResetPassword(data: ResetPasswordRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RESET_PASSWORD}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignOut(): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}/sign-out`)
      .catch((error) => this.rejectOnError(error))
  }

  postImages(formData: FormData, config?: AxiosRequestConfig<FormData>): Promise<ImageDataDto> {
    return this.axios
      .post(`${this.path}/images`, formData, config)
      .then((resp) => resp.data as ImageDataDto)
      .catch((error) => this.rejectOnError(error))
  }

  getUser(abortSignal?: AbortSignal): Promise<AuthenticatedUserDto> {
    return this.axios
      .get(`${this.path}/user`, { signal: abortSignal })
      .then((resp) => resp.data as AuthenticatedUserDto)
      .then((user) => {
        pendo.initialize({
          visitor: {
            id: user.email,
          },
          account: {
            id: 'Product Science',
          },
        })
        return user
      })
      .catch((error) => this.rejectOnError(error))
  }

  getTeams(abortSignal?: AbortSignal): Promise<TeamDto[]> {
    return this.axios
      .get(`${this.path}/teams`, { signal: abortSignal })
      .then((resp) =>
        (resp.data as TeamsArray).sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        ),
      )
      .catch((error) => this.rejectOnError(error))
  }

  postTeam(data: TeamDtoForm): Promise<TeamDto> {
    return this.axios
      .post(`${this.path}/teams`, data)
      .then((resp) => resp.data as TeamDto)
      .catch((error) => this.rejectOnError(error))
  }

  putTeam(req: TeamReq, data: TeamDtoForm): Promise<TeamDto> {
    return this.axios
      .put(`${this.path}/teams/${req.teamUrlName}`, data)
      .then((resp) => resp.data as TeamDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteTeam(req: TeamReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/teams/${req.teamUrlName}`)
      .catch((error) => this.rejectOnError(error))
  }

  getTeamUsers(req: TeamReq): Promise<TeamUsersResponse> {
    return this.axios
      .get(`${this.path}/teams/${req.teamUrlName}/users`)
      .then((resp) => resp.data as TeamUsersResponse)
      .catch((error) => this.rejectOnError(error))
  }

  postTeamUser(req: TeamReq, data: InviteUserToTeamDto): Promise<TeamUserDto> {
    return this.axios
      .post(`${this.path}/teams/${req.teamUrlName}/users`, data)
      .then((resp) => resp.data as TeamUserDto)
      .catch((error) => this.rejectOnError(error))
  }

  putTeamUserRole(req: TeamReq & UserIdReq, data: { role: TeamRole }): Promise<void> {
    return this.axios
      .put<void, void>(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/role`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postTeamUserResend(req: TeamReq & UserIdReq): Promise<TeamUserDto> {
    return this.axios
      .post(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/resend-invite`)
      .then((resp) => resp.data as TeamUserDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteTeamUser(req: TeamReq & UserIdReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/role`)
      .catch((error) => this.rejectOnError(error))
  }

  getProject(req: ProjectReq): Promise<ProjectDto> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}`)
      .then((resp) => resp.data as ProjectDto)
      .catch((error) => this.rejectOnError(error))
  }

  getProjectsSummary(req: TeamReq, abortSignal?: AbortSignal): Promise<ProjectSummaryDto> {
    return this.axios
      .get(`${this.path}/teams/${req.teamUrlName}/projects-summary`, { signal: abortSignal })
      .then((resp) => {
        const projectsSummary = resp.data as ProjectSummaryDto
        projectsSummary.projects.sort((a, b) =>
          a.project.name.localeCompare(b.project.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )
        return projectsSummary
      })
      .catch((error) => this.rejectOnError(error))
  }

  postProject(req: TeamReq, data: ProjectDtoForm): Promise<ProjectDto> {
    return this.axios
      .post(`${this.path}/teams/${req.teamUrlName}/projects`, data)
      .then((resp) => resp.data as ProjectDto)
      .catch((error) => this.rejectOnError(error))
  }

  putProject(req: ProjectReq, data: ProjectDtoForm): Promise<ProjectDto> {
    return this.axios
      .put(`${this.path}/projects/${req.projectUrlName}`, data)
      .then((resp) => resp.data as ProjectDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProject(req: ProjectReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${req.projectUrlName}`)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectFavorite(data: { id: ProjectDto['id'] }): Promise<UserSettingsDto> {
    return this.axios
      .post(`${this.path}/user/projects/favorites`, data)
      .then((resp) => resp.data as UserSettingsDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProjectFavorite(req: { id: ProjectDto['id'] }): Promise<UserSettingsDto> {
    return this.axios
      .delete(`${this.path}/user/projects/favorites/${req.id}`)
      .then((resp) => resp.data as UserSettingsDto)
      .catch((error) => this.rejectOnError(error))
  }

  getProjectUsers(req: ProjectReq): Promise<ProjectUsersResponse> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/users`)
      .then((resp) => resp.data as ProjectUsersResponse)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectUser(req: ProjectReq, data: InviteUserToProjectDto): Promise<ProjectUserDto> {
    return this.axios
      .post(`${this.path}/projects/${req.projectUrlName}/users`, data)
      .then((resp) => resp.data as ProjectUserDto)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectUserResend(req: ProjectReq & UserIdReq): Promise<ProjectUserDto> {
    return this.axios
      .post(`${this.path}/projects/${req.projectUrlName}/users/${req.userId}/resend-invite`)
      .then((resp) => resp.data as ProjectUserDto)
      .catch((error) => this.rejectOnError(error))
  }

  putProjectUserRole(req: ProjectReq & UserIdReq, data: { role: ProjectRole }): Promise<void> {
    return this.axios
      .put<void, void>(`${this.path}/projects/${req.projectUrlName}/users/${req.userId}/role`, data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProjectUser(req: ProjectReq & UserIdReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${req.projectUrlName}/users/${req.userId}/role`)
      .catch((error) => this.rejectOnError(error))
  }

  getFlows(req: ProjectReq): Promise<Flows> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/flows`)
      .then((resp) => resp.data as Flows)
      .catch((error) => this.rejectOnError(error))
  }

  postFlowFavorite(req: FlowReq): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/favorites/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowFavorite(req: FlowReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/favorites/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  postFlowSubscribe(req: FlowReq): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/subscriptions/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowSubscribe(req: FlowReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/subscriptions/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  getUnassignedTraces(req: ProjectReq): Promise<Traces> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/traces`)
      .then((resp) => resp.data as Traces)
      .catch((error) => this.rejectOnError(error))
  }

  getFlow(req: FlowReq): Promise<FlowDto> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}`)
      .then((resp) => resp.data as FlowDto)
      .catch((error) => this.rejectOnError(error))
  }

  putFlow(req: FlowReq, data: UpdateFlowDto): Promise<FlowDto> {
    return this.axios
      .put(`${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}`, data)
      .then((resp) => resp.data as FlowDto)
      .catch((error) => this.rejectOnError(error))
  }

  getFlowTraces(req: FlowReq): Promise<Traces> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/traces`)
      .then((resp) => resp.data as Traces)
      .catch((error) => this.rejectOnError(error))
  }

  async postTraceVideo(
    req: TracePageParams,
    formData: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
  ): Promise<TraceVideoMetadataDto> {
    const config: AxiosRequestConfig<FormData> = {
      onUploadProgress,
      signal: abortSignal,
    }
    const response = await this.axios.post(
      `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/video`,
      formData,
      config,
    )
    return response.data as TraceVideoMetadataDto
  }

  getTraceVideoMetadata(req: TracePageParams): Promise<TraceVideoMetadataDto | null> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/video`)
      .then((res) => res.data as TraceVideoMetadataDto)
      .catch((reason) => {
        const axiosError = reason as AxiosError
        if (axiosError.isAxiosError && axiosError?.response?.status === 404) {
          return Promise.resolve(null)
        }
        return Promise.reject(reason)
      })
  }

  async getTraceVideo(
    url: string,
    onDownloadProgress: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<Blob> {
    const result = await this.axios.get(url, {
      responseType: 'blob',
      onDownloadProgress,
    })
    return result.data as Blob
  }

  deleteTraceVideo(req: TracePageParams): Promise<void> {
    return this.axios.delete(
      `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/video`,
    )
  }

  getTrace(req: TracePageParams): Promise<TraceDataDto> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/storage/traces/${req.traceProjectLocalId}`)
      .then((resp) => resp.data as TraceDataDto)
      .catch((error) => this.rejectOnError(error))
  }

  getSingleTrace(req: TracePageParams): Promise<Trace> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`)
      .then((resp) => resp.data as Trace)
      .catch((error) => this.rejectOnError(error))
  }

  updateTraceTitle(req: TracePageParams, title: string): Promise<Trace> {
    const url = `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`
    return this.axios
      .patch(url, { name: title })
      .then((resp) => resp.data as Trace)
      .catch((error) => this.rejectOnError(error))
  }

  getNamedLinks(req: Required<ProjectReq>): Promise<NamedLinkDto[]> {
    return this.axios
      .get(`${this.path}/projects/${req.projectUrlName}/named-links`)
      .then((resp) => resp.data as NamedLinkDto[])
      .catch((error) => {
        if (error.response?.status === NO_AIRTABLE_PROJECT_STATUS_CODE) {
          //TODO: should we disable the links creation in this case?
          //TODO: show an error msg (let's implemented when the toasts changes are merged)
          console.warn(error.response?.data?.message)
          return []
        } else {
          return this.rejectOnError(error)
        }
      })
  }

  postNamedLink(projectUrlName: string, data: CreateNamedLinkRequestData): Promise<NamedLinkDto> {
    return this.axios
      .post(`${this.path}/projects/${projectUrlName}/named-links`, data)
      .then((resp) => resp.data as NamedLinkDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteNamedLink(projectUrlName: string, id: string): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${projectUrlName}/named-links/${id}`)
      .catch((error) => {
        if (error.response?.status === NO_AIRTABLE_CONNECTION_RECORD_CODE) {
          //TODO: show an error msg (let's implemented when the toasts changes are merged)
          console.warn(error.response?.data?.message)
        } else {
          return this.rejectOnError(error)
        }
      })
  }

  postFlow(req: ProjectReq, data: CreateFlowDto): Promise<FlowDto> {
    return this.axios
      .post(`${this.path}/projects/${req.projectUrlName}/flows`, data)
      .then((resp) => resp.data as FlowDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlow(req: FlowReq, withTraces: boolean): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}`,
        {
          params: { withTraces },
        },
      )
      .catch((error) => this.rejectOnError(error))
  }

  postFlowsRestore(req: ProjectReq, projectLocalId: number): Promise<Flows> {
    return this.axios
      .post(`${this.path}/projects/${req.projectUrlName}/flows/restore`, [projectLocalId])
      .then((resp) => resp.data as Flows)
      .catch((error) => this.rejectOnError(error))
  }

  postAssignTrace(req: TraceReq, flowProjectLocalIds: number[]): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`,
        flowProjectLocalIds,
      )
      .catch((error) => this.rejectOnError(error))
  }

  postUploadTrace(
    req: FlowReq,
    formData: FormData,
    config?: AxiosRequestConfig<FormData>,
  ): Promise<Trace> {
    return this.axios
      .post(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/traces`,
        formData,
        config,
      )
      .then((resp) => resp.data as Trace)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowTrace(req: FlowTraceReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/traces/${req.traceProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteTrace(req: TraceReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  getFlags({
    projectUrlName,
    flowProjectLocalId,
    traceProjectLocalId,
  }: Required<ChartPageParams>): Promise<Flag[]> {
    return this.axios
      .get(
        `${this.path}/projects/${projectUrlName}/flows/${flowProjectLocalId}/traces/${traceProjectLocalId}/flags`,
      )
      .then((resp) => resp.data as Flag[])
      .catch((error) => this.rejectOnError(error))
  }

  postFlag(req: ChartPageParams, flag: Flag): Promise<Flag> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const timestamp = Date.now()
    const data: FlagPostReq = { ...flag, timestamp: timestamp }
    return this.axios
      .post(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/flags`,
        data,
      )
      .then((resp) => resp.data as Flag)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlag(req: ChartPageParams, flag: Flag): Promise<void> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const proj = req.projectUrlName
    const flagDeleteDto: DeleteEntityReq = {
      id: flag.id > 0 ? flag.id : undefined,
      cid: flag.cid ? flag.cid : undefined,
      timestamp: Date.now(),
    }
    return this.axios
      .delete<void, void>(`${this.path}/projects/${proj}/flows/${flowId}/traces/${traceId}/flags`, {
        data: flagDeleteDto,
      })
      .catch((error) => this.rejectOnError(error))
  }

  putFlag(req: ChartPageParams, flag: Flag): Promise<Flag> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const data: FlagPutReq = {
      id: flag.id > 0 ? flag.id : undefined,
      cid: flag.cid ? flag.cid : undefined,
      color: flag.color,
      time: flag.time,
      title: flag.title,
      timestamp: Date.now(),
    }
    return this.axios
      .put(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/flags`,
        data,
      )
      .then((resp) => resp.data as Flag)
      .catch((error) => this.rejectOnError(error))
  }

  genAlternativeUiUrl(req: { projectUrlName?: string; traceProjectLocalId?: string }): string {
    return `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/alternative-ui`
  }

  rejectOnError<T = never>(axiosError: AxiosError): Promise<T> {
    return Promise.reject(axiosError)
  }

  getChoreographerPaths(params: TracePageParams): Promise<ChoreographerPathsDto> {
    return this.axios
      .get(
        `${this.path}/projects/${params.projectUrlName}/traces/${params.traceProjectLocalId}/settings`,
      )
      .then((resp) => {
        return resp.data as ChoreographerPathsDto
      })
      .catch((error) => this.rejectOnError(error))
  }

  putChoreographerPaths(
    req: ChoreographerReq,
    paths: ChoreographerPathsDto,
  ): Promise<ChoreographerPathsDto> {
    return this.axios
      .put(
        `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/settings`,
        paths,
      )
      .then((resp) => {
        return resp.data as ChoreographerPathsDto
      })
      .catch((error) => this.rejectOnError(error))
  }

  getAnnotations(req: ChartPageParams): Promise<AnnotationDto[]> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    return this.axios
      .get(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`,
      )
      .then((resp) => resp.data as AnnotationDto[])
      .catch((error) => this.rejectOnError(error))
  }

  postAnnotation(req: ChartPageParams, annotation: AnnotationDto): Promise<AnnotationDto> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    annotation.timestamp = Date.now()
    return this.axios
      .post(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`,
        annotation,
      )
      .then((resp) => resp.data as AnnotationDto)
      .catch((error) => this.rejectOnError(error))
  }

  deleteAnnotation(req: ChartPageParams, annotation: AnnotationDto): Promise<void> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const url = `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`
    const params = `id=${annotation.id}&cid=${annotation.cid}&timestamp=${Date.now()}`
    return this.axios
      .delete<void, void>(`${url}?${params}`)
      .catch((error) => this.rejectOnError(error))
  }

  putAnnotation(req: ChartPageParams, annotation: AnnotationDto): Promise<AnnotationDto> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    annotation.timestamp = Date.now()
    const url = `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`
    return this.axios
      .put(url, annotation)
      .then((resp) => resp.data as AnnotationDto)
      .catch((error) => this.rejectOnError(error))
  }

  putUserQuestionnaire(data: UserQuestionnaireDto): Promise<UserQuestionnaireDto> {
    return this.axios
      .put<UserQuestionnaireDto>(`${this.path}/user/questionnaire`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancelled(reason: any) {
    return isAxiosRequestCancelled(reason)
  }

  getShareUrl(teamUrlName: string): Promise<TeamShareUrlDto> {
    return this.axios.get(`${this.path}/teams/${teamUrlName}/share-url`).then((res) => res.data)
  }

  postShareUrl(teamUrlName: string, emails: string[]) {
    return this.axios.post(`${this.path}/teams/${teamUrlName}/share-url/send`, {
      emails,
    } as SendShareUrlDto)
  }

  createFreeTrialProject(teamUrlName: string, data: CreateFreeTrialProjectReqDto) {
    return this.axios.post(`${this.path}/teams/${teamUrlName}/free-trial/projects`, data)
  }

  submitFreeTrialOnboardingRequest(
    teamUrlName: string,
    data: SubmitFreeTrialOnboardingRequestReqDto,
  ): Promise<SubmitFreeTrialOnboardingRequestResDto> {
    return this.axios
      .post(`${this.path}/teams/${teamUrlName}/free-trial/onboarding-requests`, data)
      .then((res) => res.data)
  }

  getFreTrialOnboardingRequests(
    teamUrlName: string,
  ): Promise<GetSubmittedFreeTrialOnboardingRequestsResDto> {
    return this.axios
      .get(`${this.path}/teams/${teamUrlName}/free-trial/onboarding-requests`)
      .then((res) => res.data)
  }

  postBuildProperties(projectUrlName: string): Promise<string> {
    const url = `${this.path}/projects/${projectUrlName}/build-properties`
    return this.axios
      .post<string>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getAppBuildAndRunStatus(projectUrlName: string): Promise<AppBuildAndRunStatusDto> {
    const url = `${this.path}/projects/${projectUrlName}/instructions-state`
    return this.axios
      .get<AppBuildAndRunStatusDto>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postFinishInstructions(projectIdOrUrlName: string) {
    return this.axios.post(`${this.path}/projects/${projectIdOrUrlName}/finish-instructions`)
  }

  postResendVerToken(request: ResendEmailVerificationTokenRequest) {
    if (request.email == null && request.inviteToken == null) {
      throw new Error(
        'The request has to have at least one of the fields "email" or "inviteToken" not empty',
      )
    }
    return this.axios.post(`${this.path}/sign-up/resend-ver-token`, request)
  }

  getSliceSuggestions(request: SliceSuggestionsReq): Promise<AnnotationSliceSuggestionDto[]> {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/traces/${request.traceProjectLocalId}/annotations/slice-suggestions`
    return this.axios
      .get<AnnotationSliceSuggestionDto[]>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getFlowComputation(request: FlowComputationReq): Promise<ComputationView> {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/computations/SLICE_MATCHING`
    return this.axios
      .get<ComputationView>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSubmitFlowToProcessing = (request: FlowComputationReq): Promise<undefined> => {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/airflow-process-flow`
    return this.axios
      .post<undefined>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSupportIssue(req: ApkSupportIssueReq): Promise<ApkSupportIssueDtoOut> {
    return this.axios.postForm(`${this.path}/support`, req).then((res) => res.data)
  }

  postExtensionRequest(teamUrlName: string): Promise<FreeTrialExtensionDto> {
    return this.axios.post(getExtensionRequestUrl(teamUrlName)).then((res) => res.data)
  }

  getExtensionRequest(teamUrlName: string): Promise<FreeTrialExtensionDto> {
    return this.axios.get(getExtensionRequestUrl(teamUrlName)).then((res) => res.data)
  }
}
