import { useEffect } from 'react'
import { GuideStepType, PlatformType } from 'components/guide/models'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { GuideStore } from 'components/guide/GuideStore'
import { PATH_GUIDE_SELECT_PLATFORM } from 'pages/guide/GuideRootPage'

const getGuidesStepsUrls = (platform: PlatformType) =>
  Object.values(GuideStepType).map((stepPrefix) => GuideStore.getPageUrl(stepPrefix, platform))

const isPathDirectToStep = (curPath: string, platform: PlatformType) =>
  Boolean(getGuidesStepsUrls(platform).find((stepUrl) => matchPath(stepUrl, curPath)))

export function useGuideNavigationRules(guideStore: GuideStore) {
  const location = useLocation()
  const navigate = useNavigate()
  const { isReady, freeTrialProjectSummary } = guideStore

  useEffect(() => {
    if (!isReady) {
      return
    }

    let redirectUrl: null | string = null
    if (freeTrialProjectSummary) {
      const platform = freeTrialProjectSummary.project.os.toLowerCase() as PlatformType
      if (isPathDirectToStep(location.pathname, platform)) {
        return
      }

      const firstUncompletedStep = guideStore.steps.find((step) => !step.completed)
      const stepToShow = firstUncompletedStep?.type ?? GuideStepType.ViewTrace

      redirectUrl = GuideStore.getPageUrl(stepToShow, platform)
    } else {
      redirectUrl = PATH_GUIDE_SELECT_PLATFORM
    }

    if (redirectUrl && !matchPath(redirectUrl, location.pathname)) {
      navigate(redirectUrl)
    }
  }, [navigate, location, isReady, freeTrialProjectSummary, guideStore])
}
