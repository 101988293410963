import { Slice } from './Slice'
import { walkSlices } from '../utils/slice'

export class Thread {
  readonly id: number

  readonly title: string

  readonly isAsync: boolean

  /**
   * Map of thread's slices per thread depth.
   * Useful when you know exact thread level/depth from which you need to find a slice.
   */
  readonly slicesByDepth: Map<number, Slice[]>

  /**
   * Array of thread's slices stored in tree structure.
   */
  readonly slices: ReadonlyArray<Slice>

  private _state = ThreadViewState.Expanded

  private readonly _depth: number

  private readonly _minSlicesValue

  private readonly _maxSlicesValue

  private _totalSlicesCount?: number

  constructor(
    id: number,
    title: string,
    slices: Slice[],
    depth: number,
    isAsync: boolean,
    slicesByDepth: Map<number, Slice[]> | null = null,
    state: ThreadViewState = ThreadViewState.Expanded,
  ) {
    this.id = id
    this.title = title
    this.isAsync = isAsync
    this.slices = slices
    this.slicesByDepth = slicesByDepth ?? this.slicesTreeToDepthMap(slices)
    this._depth = depth
    if (slices.length > 0) {
      this._minSlicesValue = slices[0].start
      this._maxSlicesValue = slices[slices.length - 1].end
    } else {
      this._minSlicesValue = 0
      this._maxSlicesValue = 0
    }
    this._state = state
  }

  slicesTreeToDepthMap(slices: Slice[]): Map<number, Slice[]> {
    const slicesByDepth = new Map<number, Slice[]>()
    walkSlices(slices, (slice, threadDepth) => {
      const depthSlices = slicesByDepth.get(threadDepth) ?? []
      depthSlices.push(slice)
      slicesByDepth.set(threadDepth, depthSlices)
    })
    return slicesByDepth
  }

  cloneAndSubstituteSlices(slices: Slice[]): Thread {
    return new Thread(this.id, this.title, slices, this.depth, this.isAsync, null, this.state)
  }

  cloneAndSubstituteSlicesByDepth(slicesByDepth: Map<number, Slice[]>): Thread {
    return new Thread(
      this.id,
      this.title,
      [...this.slices],
      this.depth,
      this.isAsync,
      slicesByDepth,
      this.state,
    )
  }

  get state(): ThreadViewState {
    return this._state
  }

  /**
   * Starts with 1 if at least one slice row exists, 0 if thread is collapsed or no slices
   */
  get depth(): number {
    if (this.isCollapsed) {
      return 0
    }
    return this._depth
  }

  get totalSlicesCount(): number {
    if (this._totalSlicesCount == null) {
      let totalSlicesCount = 0
      walkSlices(this.slices, () => totalSlicesCount++)
      this._totalSlicesCount = totalSlicesCount
    }
    return this._totalSlicesCount
  }

  get isCollapsed(): boolean {
    return this._state === ThreadViewState.Collapsed
  }

  get isExpanded(): boolean {
    return this._state === ThreadViewState.Expanded
  }

  switchState() {
    if (this.state === ThreadViewState.Expanded) {
      this._state = ThreadViewState.Collapsed
    } else {
      this._state = ThreadViewState.Expanded
    }
  }
}

enum ThreadViewState {
  Expanded,
  Collapsed,
}
