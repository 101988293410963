import { Input, InputProps } from 'components/Input'
import { Icon } from 'components/Icon'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export type InputPasswordProps = {
  checked?: boolean
  visible?: boolean
  onMakeVisibleButtonClick?: () => void
} & InputProps

export const InputPassword = (props: InputPasswordProps) => {
  const { className, visible, onMakeVisibleButtonClick, checked, ...otherProps } = props
  const { t } = useTranslation()

  return (
    <Input
      type={visible ? 'text' : 'password'}
      className={classNames('relative pr-[30px]', className)}
      autoComplete="new-password"
      name="new-password"
      required
      {...otherProps}
    >
      <button
        className="flex absolute top-0 right-0 bottom-0 pr-[9px]"
        type="button"
        name="toggle-password"
        aria-label={t('auth.common.showPasswordLabel')}
      >
        {checked && (
          <Icon icon="check" className="text-[32px] text-state-good translate-y-[-10px]" />
        )}
        <Icon
          onClick={onMakeVisibleButtonClick}
          icon="eye"
          className={classNames(
            'block text-[20px] text-gray-normal cursor-pointer hover:text-white',
            visible && 'text-electro hover:text-sky',
          )}
        />
      </button>
    </Input>
  )
}
