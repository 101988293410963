import { useTranslation } from 'react-i18next'
import { COMMON_NS } from 'locale/i18n'
import { useCallback, useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { toastWithTitle } from 'components/StyledToaster'
import { AxiosError } from 'axios'
import { isAxiosError } from 'utils/axios'
import { ApiError } from 'api/models'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ToasterErrorFunc = (reason: any, titleCode?: string) => void
type ToasterMessageByCodeFunc = (messageCode: string, titleCode?: string) => void

/**
 * We should use this class to show toasts within the app.
 * It forces us not to hardcode texts, but to use them through react-i18next library and the one localisation file.
 *
 * Error by code (reason: any, titleCode?: string) — finds reason.code for the code and optional title
 * Info (messageCode: string, titleCode?: string)
 * Success (messageCode: string, titleCode?: string)
 *
 */
export interface Toaster {
  /**
   * Parses {@link reason} and tries to {@link reason.code} property and find a correspondent text
   * in a i18n locale file (en-common.json). If not found, shows a common error
   */
  error: ToasterErrorFunc
  info: ToasterMessageByCodeFunc
  success: ToasterMessageByCodeFunc
}

export const useToaster = (): Toaster => {
  const { t, i18n } = useTranslation(COMMON_NS)

  const textByCodeOrDefault = useCallback(
    (code: string, defaultCode: string): string => {
      if (i18n.exists(code)) {
        return t(code)
      } else {
        if (i18n.exists(defaultCode)) {
          return t(defaultCode)
        } else {
          throw new Error('Default code not found')
        }
      }
    },
    [i18n, t],
  )

  return useMemo<Toaster>(() => {
    const toastInfoOrSuccess = (type: ToastType, messageCode: string, titleCode?: string) => {
      const message = textByCodeOrDefault(messageCode, ERROR_MESSAGE_CODE)
      const title = titleCode ? textByCodeOrDefault(titleCode, ERROR_TITLE_CODE) : undefined
      switch (type) {
        case ToastType.INFO:
          if (title) {
            toast.success(toastWithTitle(title, message, false))
          } else {
            toast.success(message)
          }
          break
        case ToastType.SUCCESS:
          if (title) {
            toast.success(toastWithTitle(title, message, false))
          } else {
            toast.success(message)
          }
          break
      }
    }

    return {
      info: (messageCode, titleCode) => toastInfoOrSuccess(ToastType.INFO, messageCode, titleCode),
      success: (messageCode, titleCode) =>
        toastInfoOrSuccess(ToastType.SUCCESS, messageCode, titleCode),
      error: (reason, titleCode) => {
        const title = titleCode ? textByCodeOrDefault(titleCode, ERROR_TITLE_CODE) : undefined
        let message = ''
        if (isAxiosError(reason)) {
          const axiosError = reason as AxiosError<ApiError>
          if (
            axiosError.response?.data.code &&
            i18n.exists('apiError.' + axiosError.response.data.code)
          ) {
            message = t('apiError.' + axiosError.response.data.code)
          } else {
            if (axiosError.response?.data.message) {
              message = axiosError.response?.data.message
            }
          }
        }
        if (!message) {
          if (reason) {
            message = String(reason)
            if (i18n.exists(message)) {
              message = t(reason)
            }
          } else {
            message = t(ERROR_MESSAGE_CODE)
          }
        }
        if (title) {
          toast.error(toastWithTitle(title, message, true))
        } else {
          toast.error(message)
        }
      },
    }
  }, [i18n, t, textByCodeOrDefault])
}

enum ToastType {
  INFO,
  SUCCESS,
}

const ERROR_TITLE_CODE = 'errorTitle'
const ERROR_MESSAGE_CODE = 'errorMessage'
