import {
  GlobalTimelineBaseRenderer,
  GlobalTimelineListener,
} from 'components/global-timeline/GlobalTimelineBaseRenderer'
import { GlobalTimelineContent } from 'components/common/models/Segment'
import { GlobalTimelineSettings } from 'components/global-timeline/models/GlobalTimelineSettings'
import { RefObject } from 'react'
import { createLine, createRect } from 'utils/konva'

export class GlobalTimelineContentRenderer extends GlobalTimelineBaseRenderer {
  private content: GlobalTimelineContent
  private highlightsContent: GlobalTimelineContent

  constructor(
    containerId: string,
    globalTimelineContainerRef: RefObject<HTMLDivElement>,
    xMin: number,
    xMax: number,
    settings: GlobalTimelineSettings,
    listener: GlobalTimelineListener,
    content: GlobalTimelineContent,
    highlightsContent: GlobalTimelineContent,
  ) {
    super(containerId, globalTimelineContainerRef, xMin, xMax, settings, listener)

    this.content = content
    this.renderContent()

    this.highlightsContent = highlightsContent
    this.renderSearchHighlights()
  }

  private renderContent() {
    this.contentLayer.destroyChildren()
    const threadCount = this.content.length
    const threadsPerRow = Math.ceil(threadCount / this.settings.previewDataLineCount)
    const height = Math.floor(this.settings.previewDataHeight / this.settings.previewDataLineCount)
    const color = this.settings.common.palette.previewDataColor
    for (let i = 0; i < threadCount; i++) {
      const threadLine = Math.floor(i / threadsPerRow)
      this.content[i].forEach((slice) => {
        const x = (slice.start - this.min) / this.timePerPx
        const width = (slice.end - this.min) / this.timePerPx - x
        const sliceRect = createRect(
          x,
          this.settings.topOffset + threadLine * height,
          width,
          height,
          color,
        )
        this.contentLayer.add(sliceRect)
      })
    }
  }

  private renderSearchHighlights() {
    const layer = this.highlightsLayer
    const widthInNano = this.width / this.max - this.min
    const threadHeight = (this.height - this.settings.topOffset) / this.content.length
    layer.destroyChildren()

    this.highlightsContent.forEach((clusteredRow, threadIndex) => {
      clusteredRow.forEach((clusteredSlice) => {
        const start = clusteredSlice.start * widthInNano
        const end = start + (clusteredSlice.end - clusteredSlice.start) * widthInNano
        const y = this.settings.topOffset + threadIndex * threadHeight
        if (end - start > 0.2) {
          layer.add(createLine([start, y, end, y], this.settings.searchHighlightColor, 1))
        }
      })
    })
  }

  updateState(width: number, xStart: number, xEnd: number) {
    const widthChanged = this.width !== width
    super.updateState(width, xStart, xEnd)
    if (widthChanged) {
      this.renderContent()
      this.renderSearchHighlights()
    }
  }

  updateContent(content: GlobalTimelineContent, highlightsContent: GlobalTimelineContent) {
    this.content = content
    this.highlightsContent = highlightsContent
    this.renderContent()
    this.renderSearchHighlights()
  }

  updateSearchHighlights(globalHighlightsContent: GlobalTimelineContent) {
    this.highlightsContent = globalHighlightsContent
    this.renderSearchHighlights()
  }
}
