import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { ChartVideoUploader } from 'components/ps-chart/details-view/video/ChartVideoUploader'
import { VideoPlayer } from 'components/ps-chart/details-view/video/VideoPlayer'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { usePreventAccidentalDelete } from './preventAccidentalDeletion'
import { VideoStatusMsg } from './VideoStatus'
import { getFileSize } from 'utils/getFileSize'
import { ProgressBar } from 'components/ProgressBar'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

const {
  INITIALIZATION,
  UPLOADING,
  PROCESSING,
  DELETING,
  DOWNLOADING,
  HAS_VIDEO_AND_READY,
  EMPTY,
  ERROR,
} = VideoDataStoreStatus

const DownloadVideo = observer(function DownloadVideo({
  videoStore,
}: {
  videoStore: VideoPlayerStore
}) {
  const { t } = useTranslation()

  const { fileSize, progress } = videoStore

  const isVideoInfoAvailable = fileSize !== 0 && progress !== 100

  return (
    <div className="h-[100%] px-[16px] mt-4 max-w-[400px] flex flex-col justify-center">
      <p className="px-[16px] text-gray-normal text-small text-center w-full  mb-4">
        {t('psChart.video.downloadingVideo')}
      </p>
      <ProgressBar setWidth={progress} />
      <span className="w-full flex justify-between pt-2">
        <div className="text-mini">
          {isVideoInfoAvailable && (
            <>
              {getFileSize(fileSize * (progress / 100))}&nbsp;of&nbsp;
              {getFileSize(fileSize)}
            </>
          )}
        </div>
        <div className="text-mini">{progress}%</div>
      </span>
    </div>
  )
})

export interface VideoTabProps {
  videoStore: VideoPlayerStore
  isFullScreen: boolean
}

export const VideoTab = observer(function VideoTab({ videoStore, isFullScreen }: VideoTabProps) {
  const { t } = useTranslation()
  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)

  usePreventAccidentalDelete(videoStore.status)

  return (
    <div className="relative flex justify-center w-full h-[100%]">
      {(() => {
        switch (videoStore.status) {
          case INITIALIZATION:
            return <ModuleProgressCircle />
          case DOWNLOADING: {
            if (isFullScreen) {
              return <DownloadVideo videoStore={videoStore} />
            } else {
              return (
                <VideoStatusMsg
                  msg={t('psChart.video.downloading')}
                  videoStore={videoStore}
                  shouldShowProgress={true}
                />
              )
            }
          }
          case UPLOADING:
            return (
              <VideoStatusMsg
                msg={t('psChart.video.uploading')}
                videoStore={videoStore}
                shouldShowDelete={false}
                shouldShowProgress={true}
                shouldShowCancelUpload={true}
              />
            )
          case DELETING:
            return (
              <VideoStatusMsg
                msg={t('psChart.video.deleting')}
                videoStore={videoStore}
                shouldShowProgress={true}
              />
            )
          case PROCESSING:
            return (
              <VideoStatusMsg
                shouldShowProgress={true}
                msg={t('psChart.video.processing')}
                videoStore={videoStore}
                shouldShowDelete={true}
                isFullScreen={isFullScreen}
              />
            )
          case HAS_VIDEO_AND_READY:
            return <VideoPlayer isFullScreen={isFullScreen} videoStore={videoStore} />
          case EMPTY: {
            if (isFullScreen || annotationsEnabled === false) {
              return (
                <ChartVideoUploader videoPlayerStore={videoStore} isFullScreen={isFullScreen} />
              )
            } else {
              return (
                <VideoStatusMsg
                  msg={t('psChart.video.notFound')}
                  videoStore={videoStore}
                  shouldShowProgress={false}
                  shouldShowDelete={false}
                  isFullScreen={false}
                  shouldGoBackToAnnotations={true}
                />
              )
            }
          }
          case ERROR:
            return (
              <VideoStatusMsg
                msg={videoStore.errorReason}
                videoStore={videoStore}
                shouldShowDelete={
                  (isFullScreen || annotationsEnabled === false) && videoStore.isDeletable
                }
                isFullScreen={isFullScreen}
              />
            )
        }
      })()}
    </div>
  )
})
