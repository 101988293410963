import { useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Trans } from 'react-i18next'

import { NextButton } from 'components/guide/NextButton'
import { CompleteStepWarning } from 'components/guide/CompleteStepWarning'
import { CreateFlowForm } from 'components/guide/CreateFlowForm'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { GuideStepType } from 'components/guide/models'

const headerOne = 'text-[26px] mt-[40px] mb-[32px] text-white font-[500] leading-[39px]'
const headerTwo = 'text-[16px] mb-[16px] text-white font-[500] leading-[24px]'
const parentSummary =
  'text-[20px] mb-[16px] text-white font-[500] leading-[30px] hover:cursor-pointer'
const childSummary =
  'text-[14px] mb-[16px] text-gray-normal font-[400] leading-[21px] hover:cursor-pointer'
const childDetails = 'ml-[20px] text-[14px] text-gray-normal leading-[21px] font-[400]'
const listItem = 'ml-[35px] mb-[16px]'
const listItemNested = 'ml-[55px] mb-[16px]'
const list = 'list-disc marker:text-dark-dark6'
const paragraph = 'text-[14px] text-gray-normal leading-[21px] font-[400] mb-[16px]'
const image = 'pl-[20px] mb-[16px]'
const link = 'text-[#495BFB]'

export const RecordTrace = observer(function RecordTrace() {
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const isInstrumentAndBuildCompleted = Boolean(
    guideStore.steps.find((step) => step.type === GuideStepType.InstrumentAndBuild)?.completed,
  )

  return (
    <div className="py-[40px]">
      {!isInstrumentAndBuildCompleted && (
        <CompleteStepWarning>
          <Trans i18nKey={'guidePage.pleaseCompleteStep'} values={{ step: 2 }} />
        </CompleteStepWarning>
      )}
      <SetUp />
      <RecordYourTrace />
      <UploadTraceAndVideo />
      <NextButton />
    </div>
  )
})

const SetUp = () => {
  return (
    <div>
      <p className={paragraph}>
        Follow these steps to record trace. Once you successfully upload the trace to PS Cloud - you
        will be able to proceed to next step.
      </p>
      <h1 className={headerOne}>Record trace</h1>
      <details>
        <summary className={parentSummary}>First, let’s get you set up</summary>
        <InstallCompanionApp />
        <EnableTracing />
        <UseFlowOverview />
        <CreateFlow />
      </details>
    </div>
  )
}

const InstallCompanionApp = () => {
  return (
    <details className={childDetails}>
      <summary className={childSummary}>
        1. Install the{' '}
        <a
          href="https://play.google.com/store/apps/details?id=com.ps.companion&hl=en_US&gl=US&pli=1"
          target="_blank"
          className={link}
        >
          PS Companion app
        </a>{' '}
        and log in to your account
      </summary>
      <p className={`${childDetails} mb-[16px]`}>Make sure to log in to your account.</p>
    </details>
  )
}

const EnableTracing = () => {
  return (
    <details className={childDetails}>
      <summary className={childSummary}>2. Make sure tracing is enabled</summary>

      <details className={childDetails}>
        <summary className={childSummary}>Enable developer options and USB debugging</summary>
        <ul className={list}>
          <li className={listItem}>
            Settings &gt; About Phone &gt; Software information &gt; Build Number
          </li>
          <li className={listItem}>
            Tap the Build Number option <strong>7 times</strong>
          </li>
        </ul>
        <img
          src="/enable-developer-settings.png"
          alt="enable developer settings"
          className={image}
        />
      </details>

      <details className={childDetails}>
        <summary className={childSummary}>Enable Tracing</summary>

        <ul className={list}>
          <li className={listItem}>
            Settings &gt; System &gt; Developer Options &gt; Debugging section &gt; System Tracing
          </li>
          <li className={listItem}>
            Make sure to turn on all systems tracing categories{' '}
            <strong>
              <i>except</i>
            </strong>{' '}
            view: View System.
          </li>
        </ul>
        <img src="/enable-tracing.png" alt="enable tracing" className={image} />
      </details>

      <details className={childDetails}>
        <summary className={childSummary}>Enable show quick setting tiles</summary>

        <ul className={list}>
          <li className={listItem}>Hold down the 'bug' button to yield the full trace menu</li>
          <li className={listItem}>Enable 'Long traces'</li>
        </ul>
        <img src="/enable-long-traces.png" alt="enable long traces" className={image} />
      </details>
    </details>
  )
}

const UseFlowOverview = () => {
  return (
    <details className={childDetails}>
      <summary className={childSummary}>3. Select a user flow you want to analyze</summary>

      <div className="ml-[17px]">
        <p className={paragraph}>
          At Product Science, performance optimization starts with defining key user flows that
          bring the most value to users.
        </p>
        <h2 className={headerTwo}>What is a user flow?</h2>
        <p className={paragraph}>
          A user flow is a one-step process that starts with an Action (an action user performs) and
          ends with a Reaction (a response from the app):
        </p>
        <img src="/user-flow.png" alt="select user flow to analyze" className={`${image} ml-0`} />
        <p className={paragraph}>
          When an Action is triggered, a sequence of functions executes before it reaches a
          Reaction. This is where our AI technology comes into play—highlighting these sequences
          that formed into what we call <strong>execution paths.</strong>
        </p>
      </div>
    </details>
  )
}

const CreateFlow = () => {
  return (
    <details className={childDetails}>
      <summary className={childSummary}>4. Type your user flow name here</summary>

      <div className="ml-[17px]">
        <p className={paragraph}>
          Once you’ve chosen which flow to optimize, you can create a folder to keep your traces
          organized.
        </p>
        <CreateFlowForm />
        <p className={paragraph}>P.S. You can change the name anytime once you open the tool.</p>
      </div>
    </details>
  )
}

const RecordYourTrace = () => {
  return (
    <div>
      <details>
        <summary className={parentSummary}>Record your screen and trace</summary>
        <StartRecording />
      </details>
    </div>
  )
}

const StartRecording = () => {
  return (
    <div>
      <details className={childDetails}>
        <summary className={childSummary}>1. Start screen and trace recording.</summary>

        <details className={childDetails}>
          <summary className={childSummary}>Close all applications</summary>
          <ul className={list}>
            <li className={listItem}>Press home</li>
            <li className={listItem}>Long press home to see recent apps</li>
            <li className={listItem}>Swipe up to kill all the applications</li>
          </ul>
        </details>

        <details className={childDetails}>
          <summary className={childSummary}>Start screen recording</summary>

          <details className={childDetails}>
            <summary className={childSummary}>Learn how to record your screen</summary>

            <ul className={list}>
              <li className={listItem}>Swipe down twice from the top of your screen</li>
              <li className={listItem}>Tap Screen record</li>
              <li className={listItemNested}>You might need to swipe right to find it</li>
              <li className={listItemNested}>
                If it's not there, tap Edit and drag Screen record to your Quick Settings
              </li>
              <li className={listItem}>
                Choose what you want to record and tap 'start'. The recording begins after the
                countdown
              </li>
              <li className={listItem}>
                To stop recording, swipe down from the top of the screen and tap the Screen recorder
                notification
              </li>
            </ul>
            <p className={`${paragraph} pl-[15px]`}>
              Source{' '}
              <a
                href="https://support.google.com/android/answer/9075928?hl=en"
                target="_blank"
                className={link}
              >
                Google
              </a>
            </p>
          </details>

          <details className={childDetails}>
            <summary className={childSummary}>
              Learn how to record when there is no screen recorder feature
            </summary>

            <p className={`${paragraph} pl-[15px]`}>
              We recommend you to download 3rd party apps such as{' '}
              <a
                target="_blank"
                className={link}
                href="https://play.google.com/store/apps/details?id=videoeditor.videorecorder.screenrecorder&hl=en_US&gl=US"
              >
                Screen Recorder - XRecorder
              </a>
            </p>
          </details>

          <details className={childDetails}>
            <summary className={childSummary}>Find your screen recordings</summary>

            <ul className={list}>
              <li className={listItem}>Open your phone's Photos app</li>
              <li className={listItem}>Tap Library Movies</li>
            </ul>
            <p className={`${paragraph} pl-[15px]`}>
              Source{' '}
              <a
                href="https://support.google.com/android/answer/9075928?hl=en"
                target="_blank"
                className={link}
              >
                Google
              </a>
            </p>
          </details>
        </details>

        <details className={childDetails}>
          <summary className={childSummary}>Start trace recording</summary>

          <ul className={list}>
            <li className={listItem}>
              Select bug icon / ‘record trace’ button from the quick settings tile
            </li>
          </ul>
        </details>
      </details>

      <details className={childDetails}>
        <summary className={childSummary}>
          2. Open the targeted app and walk through the user flow.
        </summary>

        <ul className={list}>
          <li className={listItem}>
            Complete a user action and wait until the requested page is fully loaded (What’s a user
            flow? Check out the previous step to review)
          </li>
        </ul>
      </details>

      <details className={childDetails}>
        <summary className={childSummary}>3. Stop trace and screen recording</summary>

        <details className={childDetails}>
          <summary className={childSummary}>Stop trace recording</summary>

          <ul className={list}>
            <li className={listItem}>Swipe down from the top of the screen</li>
            <li className={listItem}>Tap the bug icon or ‘stop tracing’ button</li>
          </ul>
        </details>

        <details className={childDetails}>
          <summary className={childSummary}>Stop screen recording</summary>

          <ul className={list}>
            <li className={listItem}>Swipe down from the top of the screen</li>
            <li className={listItem}>Tap the Screen recorder notification</li>
          </ul>
        </details>
      </details>
    </div>
  )
}

const UploadTraceAndVideo = () => {
  return (
    <details>
      <summary className={parentSummary}>Upload your trace and video</summary>

      <ul className={`${list} text-gray-normal text-[14px]`}>
        <li className={listItem}>Open your notification panel &gt; “Tap to share you trace”</li>
        <li className={listItem}>
          Tap the notification. You will have the option to save it or export it to different apps."
        </li>
        <img src="/upload-trace.png" alt="upload trace image" className={`${image} ml-0`} />
        <li className={listItem}>Export your trace file to the PS Companion App.</li>
        <li className={listItem}>Name the trace, assign it to the relevant user flow</li>
      </ul>

      <details className={childDetails}>
        <summary className={childSummary}>If you have a screen recording…</summary>

        <ul className={list}>
          <li className={listItem}>
            On the same screen, while the trace details screen is still opened, tap "Upload"
          </li>
          <img src="/upload-video.png" alt="upload video image" className={`${image} ml-0`} />
          <li className={listItem}>
            Phone library opens &gt; select the screen recording &gt; "Upload"
          </li>
          <li className={listItem}>
            Wait for the status bar to change from "Video processing in progress" to "Video upload
            successfully"
          </li>
          <li className={listItem}>Tap "Upload"</li>
          <li className={listItem}>You can now view your trace in the PS Companion App</li>
        </ul>
      </details>
    </details>
  )
}
