import { useCallback } from 'react'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { useTranslation } from 'react-i18next'
import { FlagColorSelect } from 'components/ps-chart/details-view/flags/color-select/FlagColorSelect'
import { FlagTitle } from 'components/ps-chart/details-view/flags/title/FlagTitle'
import { Icon } from 'components/Icon'
import { ActionTooltip } from 'components/ActionTooltip'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { useToaster } from 'hooks/useToaster'

interface FlagDetailsProps {
  flagsStore: FlagsStore
}

export const FlagDetails = ({ flagsStore }: FlagDetailsProps) => {
  const { t } = useTranslation()
  const toaster = useToaster()
  const deleteFlag = useCallback(() => {
    flagsStore
      .removeSelectedFlag()
      .catch((reason) => toaster.error(reason, 'psChart.flag.error.delete'))
  }, [toaster, flagsStore])

  useHotKeys(['Delete'], deleteFlag)

  return (
    <div className="p-[24px]">
      <div className="text-gray-normal text-small mb-[15px] flex justify-between">
        <span>{t('psChart.flag.title')}</span>
        <ActionTooltip tooltipId="flagDelete">
          <button
            className="px-1 hover:text-white focus:text-white focus:outline-1"
            onClick={deleteFlag}
          >
            <Icon icon="bin-small" />
          </button>
        </ActionTooltip>
      </div>
      <FlagColorSelect flagsStore={flagsStore} />
      <FlagTitle flagsStore={flagsStore} />
    </div>
  )
}
