import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { RendererType } from 'components/ps-chart/stores/ChartRendererStore'

interface RenderTypeModeActionProps {
  psChartStore: PsChartStore
}

export const RenderTypeModeAction = observer(function RenderTypeModeAction({
  psChartStore,
}: RenderTypeModeActionProps) {
  return (
    <ActionTooltip place="top" tooltipId="renderType">
      <div className="flex items-center justify-center align-center w-[64px] h-[32px] bg-dark-dark3">
        <Button
          variant={ButtonVariantEnum.Text}
          textColorVariant={ButtonTextColorVariantEnum.Muted}
          onClick={() => psChartStore.toggleRenderTypeMode()}
        >
          {psChartStore.rendererStore.renderType === RendererType.CLUSTERED ? 'LO' : 'HI'}
        </Button>
      </div>
    </ActionTooltip>
  )
})
